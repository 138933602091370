import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import { SelectionContext } from "../../../../selection.context";
import styles from "./evaluation-details.module.scss";
import EvaluationDetailsHeader from "./components/evaluation-details-header/evaluation-details-header.component";
import RequirementCategories from "./components/requirement-categories/requirement-categories.component";

const EvaluationDetails = () => {
  const { t } = useTranslation();
  const { requirementCategories, awardedProduct } = useContext(SelectionContext);

  return (
    <div className={styles.root}>
      <TypographyComponent boldness={"medium"} styling={"p1"}>
        {t("Evaluation details")}
      </TypographyComponent>
      {awardedProduct && <EvaluationDetailsHeader awardedProduct={awardedProduct} />}
      <>{requirementCategories && <RequirementCategories requirementCategories={requirementCategories} />}</>
    </div>
  );
};

export default EvaluationDetails;
