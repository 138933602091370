const startScoreCardStyles = {
  rootWrapper: {
    alignItems: "start",
    display: "flex",
    justifyContent: "space-between",
  },
  leftSide: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginRight: "29px",
    maxWidth: "440px",
    minWidth: "260px",
    "& $supportingSubtitle": {
      marginTop: "12px",
      marginBottom: "48px",
    },
    "& $nonOwnerSubtitle": {
      marginBottom: "24px",
    },
  },
  supportingSubtitle: {},
  nonOwnerSubtitle: {},
  rightSide: {
    overflow: "hidden",
    "@media (max-width: 1600px)": {
      position: "relative",
      right: -56,
      "& img": { position: "relative", right: -20 },
    },
    "@media (max-width: 1240px)": {
      right: -24,
    },
  },
  quickstartGuideComponent: {
    marginTop: 24,
  },
};

export default startScoreCardStyles;
