import React, { FC } from "react";
import { LAST_TABLE_COLUMN_CLASS_NAME, RowSet, SCROLLABLE_DIV_CLASS_NAME } from "../table.constants";
import classNames from "classnames";
import styles from "./table-body.styles.module.scss";
import { Accordion, AccordionDetails, AccordionSummary, TooltipProps } from "@mui/material";
import { ArrowCircledIcon } from "gx-npm-icons";
import { ToolTipIconButton } from "gx-npm-ui";

type TableBodyProps = {
  rowSets: RowSet[];
  isOverflowing: boolean;
  accordionArrowTooltipProps?: TooltipProps;
};

export const TableBodyComponent: FC<TableBodyProps> = ({ rowSets, isOverflowing, accordionArrowTooltipProps }) => {
  return (
    <div className={styles.root}>
      {rowSets.map((rowSet, rowSetIndex) => {
        return (
          <Accordion
            classes={{
              root: styles.accordionRoot,
              expanded: styles.accordionExpanded,
            }}
            key={`accordion-${rowSetIndex}`}
          >
            <AccordionSummary
              classes={{
                root: classNames(styles.accordionSummaryRoot, rowSetIndex !== rowSets.length - 1 && styles.bordered),
              }}
              expandIcon={
                <ToolTipIconButton {...accordionArrowTooltipProps}>
                  <ArrowCircledIcon />
                </ToolTipIconButton>
              }
            >
              {rowSet.title}
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: styles.accordionDetailsRoot,
              }}
            >
              {rowSet.rows.map((row, rowIndex) => (
                <div
                  className={classNames(styles.row, rowSetIndex !== 0 && rowIndex === 0 && styles.firstRow)}
                  key={`row-${rowIndex}`}
                >
                  <div
                    className={classNames({
                      [styles.firstColumn]: true,
                    })}
                  >
                    {row[0]}
                  </div>
                  <div className={classNames(styles.columnContainer, SCROLLABLE_DIV_CLASS_NAME)}>
                    {row.slice(1).map((column, columnIndex) => (
                      <div
                        className={classNames(styles.column, {
                          [styles.darkColumn]: columnIndex % 2 === 0,
                          [styles.responsiveColumns]: row.length >= 4,
                          [styles.moreThanFourColumns]: row.length >= 5,
                        })}
                        key={`column-${columnIndex + 1}`}
                      >
                        {column}
                      </div>
                    ))}
                  </div>
                  {isOverflowing && (
                    <div
                      className={classNames({
                        [styles.lastColumn]: true,
                        [LAST_TABLE_COLUMN_CLASS_NAME]: true,
                      })}
                    />
                  )}
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
