import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { handleEvent } from "gx-npm-lib";
import { TextField } from "gx-npm-ui";
import { makeStyles } from "@material-ui/core";
import { listHeaderTitleStyles } from "./styles";

const propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => listHeaderTitleStyles);
const ListHeaderTitle = ({ name, onBlur, placeholder, rootClassName = "" }) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (name) {
      setTitle(name);
    }
  }, [name]);

  const classes = useStyles();
  return (
    <TextField
      InputProps={{ readOnly: true }}
      onBlur={() => handleEvent(onBlur, title)}
      onChange={(e) => handleEvent(setTitle, e?.target?.value || "")}
      placeholder={placeholder}
      rootClassName={classNames("gx-list-header-title-text", "list-header-title", classes.title, rootClassName)}
      value={title}
    />
  );
};

ListHeaderTitle.propTypes = propTypes;
export default ListHeaderTitle;
