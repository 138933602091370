import React, { memo } from "react";
import PropTypes from "prop-types";
import { ListItem } from "../../../../ui/dragAndDropList/body";

const propsAreEqual = (prev, next) => {
  return (
    prev.draggable === next.draggable &&
    prev.index === next.index &&
    prev.isComplete === next.isComplete &&
    prev.isViewOnly === next.isViewOnly &&
    prev.rootClassName === next.rootClassName &&
    prev.taskId === next.taskId &&
    prev._description === next._description &&
    prev._name === next._name &&
    prev._ownerName === next._ownerName &&
    prev._dueDate === next._dueDate &&
    prev._assignedUsers?.length === next._assignedUsers?.length &&
    prev._activeChecklistId === next._activeChecklistId
  );
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  draggable: PropTypes.bool,
  index: PropTypes.number,
  isComplete: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  rootClassName: PropTypes.string,
  taskId: PropTypes.string,
  // props used for memo
  _description: PropTypes.string,
  _name: PropTypes.string,
  _ownerName: PropTypes.string,
  _dueDate: PropTypes.string,
  _assignedUsers: PropTypes.any,
  _activeChecklistId: PropTypes.string,
};

const ChecklistListItem = ({
  children = null,
  draggable = true,
  index = -1,
  isComplete = false,
  isViewOnly = false,
  onBlur = null,
  rootClassName = "",
  taskId = "",
}) => {
  return (
    <ListItem
      isViewOnly={isViewOnly}
      draggable={draggable}
      id={taskId}
      index={index}
      isComplete={isComplete}
      onBlur={onBlur}
      rootClassName={rootClassName}
    >
      {children}
    </ListItem>
  );
};

ChecklistListItem.propTypes = propTypes;
const MemorizedChecklistListItem = memo(ChecklistListItem, propsAreEqual);
export { ChecklistListItem, MemorizedChecklistListItem };
