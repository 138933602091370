import { colorPalette } from "gx-npm-common-styles";

const evaluationCardV2Styles = {
  root: {
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: "8px",
    padding: "32px 40px 40px 40px",
    marginBottom: "24px",
  },
};

export { evaluationCardV2Styles };
