import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { handleEvent } from "gx-npm-lib";
import { PopoverMenu } from "gx-npm-ui";
import { priorityItems } from "../../../types";
import { requirementPopoverMenuStyles as styles } from "./requirements-popover-menu.styles";

const propTypes = {
  handleAutoSave: PropTypes.func,
  handlePopoverOpen: PropTypes.func,
  isViewOnly: PropTypes.bool,
  name: PropTypes.string,
  setEdit: PropTypes.func,
  value: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const RequirementsPopoverMenu = ({
  handleAutoSave = null,
  handlePopoverOpen = null,
  isViewOnly = false,
  name = "",
  setEdit,
  value = null,
}) => {
  const [display, setDisplay] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setDisplay(value);
  }, [value]);

  const handlePopoverClick = (newValue) => {
    if (value !== newValue) {
      handleEvent(handleAutoSave, { name, value: newValue });
    }
  };

  const handlePopoverOpenStatus = (status) => {
    setIsOpen((prev) => {
      if (prev !== status) {
        handleEvent(setEdit, status);
      }
      return status;
    });
    handleEvent(handlePopoverOpen, status);
  };
  const { t } = useTranslation();
  const tooltipV2Options = {
    placement: "top",
    PopperProps: { modifiers: { offset: { offset: "0, 10px" } } },
    title: isViewOnly
      ? t("This action is only available to evaluation owners and contributors.")
      : t("Change requirement priority"),
  };
  const classes = useStyles();
  return (
    <div data-testid="popOverTest" className={classNames(classes.flexChild, classes.menuRoot)}>
      <PopoverMenu
        disabled={isViewOnly}
        handleOpenStatus={handlePopoverOpenStatus}
        menuItems={priorityItems}
        onClick={(event) => handlePopoverClick(event)}
        rootClassName={classNames("gx-req-popover-menu", "gx-req-popover-menu-icon-btn", isOpen && "open-popover")}
        selectedIndex={display}
        iconType={"arrowDynamic"}
        useIconButton={true}
        showTooltip={true}
        tooltipOptions={tooltipV2Options}
      />
    </div>
  );
};

RequirementsPopoverMenu.propTypes = propTypes;
export { RequirementsPopoverMenu };
