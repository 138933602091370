import { colorPalette } from "gx-npm-common-styles";

const listButtenAddStyles = {
  btnRoot: {
    "&.disabled svg path": {
      fill: colorPalette.neutrals.iron.hex,
    },
  },
  btnLabel: {
    paddingLeft: "8px",
  },
};

const listFooterStyles = {
  listFooterContainer: {
    position: "relative",
  },
  listFooter: {
    background: colorPalette.basic.white.hex,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "center",
  },
  listFooterEnd: {
    background: colorPalette.basic.white.hex,
    borderRadius: "0px 0px 8px 8px",
    height: 8,
    position: "absolute",
    width: "100%",
    zIndex: 1000,
  },
};

export { listButtenAddStyles, listFooterStyles };
