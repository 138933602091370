import React, { memo } from "react";
import PropTypes from "prop-types";
import { ListItem } from "../../../../../ui/dragAndDropList/body";

const propsAreEqual = (prev, next) => {
  return (
    prev.draggable === next.draggable &&
    prev.id === next.id &&
    prev.index === next.index &&
    prev.isViewOnly === next.isViewOnly &&
    prev.rootClassName === next.rootClassName &&
    prev._checked === next._checked &&
    prev._description === next._description &&
    prev._isModified === next._isModified &&
    prev._isOpen === next._isOpen &&
    prev._name === next._name &&
    prev._priority === next._priority
  );
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  draggable: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.number,
  isViewOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  rootClassName: PropTypes.string,
  // props used for memo
  _checked: PropTypes.bool,
  _description: PropTypes.string,
  _isModified: PropTypes.bool,
  _isOpen: PropTypes.bool,
  _name: PropTypes.string,
  _priority: PropTypes.number,
};
const RequirementListItem = ({
  children = null,
  draggable = true,
  id = "",
  index = -1,
  isViewOnly = false,
  onBlur = null,
  rootClassName,
}) => {
  return (
    <ListItem
      draggable={draggable}
      id={id}
      index={index}
      isViewOnly={isViewOnly}
      onBlur={onBlur}
      rootClassName={rootClassName}
    >
      {children}
    </ListItem>
  );
};

RequirementListItem.propTypes = propTypes;
const MemorizedRequirementListItem = memo(RequirementListItem, propsAreEqual);
export { RequirementListItem, MemorizedRequirementListItem };
