import { ProductsAddContextProvider } from "./products-add.context";
import ProductsAddButtonV2Component from "./products-add-button-v2/products-add-button-v2.component";
import ProductsAddDialogV2Component from "./products-add-dialog-v2/products-add-dialog-v2.component";

const ProductsAddV2Component = () => {
  return (
    <ProductsAddContextProvider>
      <ProductsAddButtonV2Component />
      <ProductsAddDialogV2Component />
    </ProductsAddContextProvider>
  );
};

export default ProductsAddV2Component;
