// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vkzOVI2BfC7XR4ZUPpna{margin-top:24px;height:216px}.Yg1PzRqEHxdqI5u4Q5Cp{margin-top:32px;max-width:600px}.Yg1PzRqEHxdqI5u4Q5Cp .S4zutiUS6zv4JE5xTI8x{color:var(--color-neutrals-carbon)}.Yg1PzRqEHxdqI5u4Q5Cp .BToyhw4D9hzenxrUHsOp{margin-top:16px}.Yg1PzRqEHxdqI5u4Q5Cp .muyBOMiKD9_SD6kmcKko{margin:12px 0 0}.epa8MTbJFCLHjCQDEFSm{margin-top:32px}.KMOuZ5GvltrCHmSruZIV{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/survey/quick-start/creating-the-questionnaire/creating-the-questionnaire.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAGF,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".mediaContainer {\n  margin-top: 24px;\n  height: 216px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n\n  .link {\n    margin: 12px 0 0;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaContainer": `vkzOVI2BfC7XR4ZUPpna`,
	"description": `Yg1PzRqEHxdqI5u4Q5Cp`,
	"heading": `S4zutiUS6zv4JE5xTI8x`,
	"additionalInfo": `BToyhw4D9hzenxrUHsOp`,
	"link": `muyBOMiKD9_SD6kmcKko`,
	"helpLink": `epa8MTbJFCLHjCQDEFSm`,
	"loader": `KMOuZ5GvltrCHmSruZIV`
};
export default ___CSS_LOADER_EXPORT___;
