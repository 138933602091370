// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OYCHwLNoPicOg6Sp8XFP{align-items:center;border:1px solid var(--color-accent-grape);border-radius:30px;display:flex;height:32px;justify-content:center;width:120px}.Bg_UYPVDJ9yOegTOBs1T{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/sections/header-v2/components/read-only-badge/read-only-badge.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,0CAAA,CACA,kBAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,WAAA,CAGF,sBACE,eAAA","sourcesContent":[".badgeWrapper {\n  align-items: center;\n  border: 1px solid var(--color-accent-grape);\n  border-radius: 30px;\n  display: flex;\n  height: 32px;\n  justify-content: center;\n  width: 120px;\n}\n\n.badgeText {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": `OYCHwLNoPicOg6Sp8XFP`,
	"badgeText": `Bg_UYPVDJ9yOegTOBs1T`
};
export default ___CSS_LOADER_EXPORT___;
