import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as singleSpa from "single-spa";
import { useParams } from "react-router-dom";
import { AdditionCircledIcon, SelectedVendorIconMedium, SelectedVendorStar } from "gx-npm-icons";
import { InitProdState, InitStatus, ScorecardProgress, ScoringLevel } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  ProductLogo,
  SnackbarBanner,
  Switch,
  TextLink,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListHeaderExpandButton } from "../../../../ui/dragAndDropList/header";
import { getStoredJsonItem, setStoredJsonItem } from "../../../../ui/dragAndDropList/lib";
import { TableCarousel } from "../../../../ui/tableCarousel";
import { TableCarouselBody, TableCarouselBodyCol, TableCarouselBodyRow } from "../../../../ui/tableCarousel/body";
import { TableCarouselHeader, TableCarouselHeaderRowCol } from "../../../../ui/tableCarousel/header";
import NoProductsInEvaluation from "./no-products-in-evaluation/no-products-in-evaluation.component";
import { getListHeaderStyles } from "./evaluation-scorecard.styles";
import EvaluationScorecardAssignmentsAvatar from "./evaluation-scorecard-assignments-avatar.component";
import ScoringLevelDrawer from "./scoringLevelDrawer/";
import ProductScoreCarouselColumn from "./product-score-carousel-column.component";
import ScorecardHeaderComponent from "./scorecard-header/scorecard-header.component";
import {
  GCOM_3755_GCOM_3756__scoreCardVendorSelections,
  GCOM_493_GCOM_3780__scorecardScoreRowSticky,
} from "../../../../lib/feature-flags";
import { colorPalette } from "gx-npm-common-styles";
import EvaluationStatusColumn from "./evaluation-status-column/evaluation-status-column.component";
import { AvailableActions, customEvents } from "../../../../lib";
import ScorecardRequirementsComponent from "./evaluation-scorecard/scorecard-requirements.component";

const propTypes = {
  isFtuxView: PropTypes.bool,
  isOwner: PropTypes.bool,
  onAvatarClick: PropTypes.func,
  handleClose: PropTypes.func,
  onError: PropTypes.func,
  onNavigation: PropTypes.func,
  onSuccessSaveRequirementLevel: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      addedByUser: PropTypes.bool,
      id: PropTypes.string,
      imageLoc: PropTypes.string,
      name: PropTypes.string,
      overallScore: PropTypes.number,
      overallScoreDisplay: PropTypes.string,
      progress: PropTypes.oneOf([...Object.values(ScorecardProgress), ""]),
      reqCategoryScoreMap: PropTypes.object,
      state: PropTypes.string,
    })
  ),
  setProducts: PropTypes.func,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      evaluators: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      name: PropTypes.string,
      weight: PropTypes.number,
    })
  ),
  scoringLevel: PropTypes.string,
  scoringMode: PropTypes.string,
};
const EvaluationScorecard = ({
  isFtuxView = false,
  isOwner = false,
  onAvatarClick = () => {},
  onError = () => {},
  handleClose,
  onNavigation = (_tabName) => {},
  onSuccessSaveRequirementLevel = (_scoringLevel) => {},
  products = [],
  setProducts = (prods) => prods,
  requirements = [],
  scoringLevel = "",
  scoringMode = "",
}) => {
  let tabs = [];
  const lineHeight = 21;
  const originalHeaderHeight = 94;
  const { t } = useTranslation();

  const [height, setHeight] = useState(originalHeaderHeight);
  const styles = getListHeaderStyles(height);
  const useStyles = makeStyles(() => styles);
  const classes = useStyles();

  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [hasAnAwardedVendor, setHasAnAwardedVendor] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isThreshHold, setIsThreshHold] = useState(false);
  const [requirementsOpenIdxList, setRequirementsListsOpenArray] = useState([]);
  const [screenedOutCount, setScreenedOutCount] = useState(0);
  const [showScreenedOut, setShowScreenedOut] = useState(false);
  const { initiativeId = "" } = useParams();
  const sessionStorageId = `initiatives-evaluation-${initiativeId}`;
  const isVendorSelectionsFFOn = useFeatureFlag(GCOM_3755_GCOM_3756__scoreCardVendorSelections);
  const [awardedProductDetails, setAwardedProductDetails] = useState({ name: "", imageLoc: "", id: "" });
  const [triggerRefreshColumnsCount, setTriggerRefreshColumnsCount] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: "",
    isDefaultErrorMessage: true,
    type: "ERROR",
    setIsOpen: (val) => setSnackbarOpen(val),
  });

  const updateInitStatus = (status) => {
    window.dispatchEvent(new CustomEvent(customEvents.initiativeStatusUpdate, { detail: { status } }));
  };

  useEffect(() => {
    const sessionData = getStoredJsonItem(sessionStorageId);
    const defaultShowScreenedOut = !!sessionData?.showScreenedOut;
    setShowScreenedOut(defaultShowScreenedOut);
    const defaultOpenArray = !!sessionData?.requirementsOpenIdxList;
    setRequirementsListsOpenArray(defaultOpenArray ? sessionData?.requirementsOpenIdxList : []);
  }, [sessionStorageId]);

  useEffect(() => {
    const screenedOutVendors = products.filter((vendor) => vendor.state === InitProdState.SCREENED_OUT);
    setScreenedOutCount(screenedOutVendors.length);
  }, [products]);

  useEffect(() => {
    const awardedProduct = products.find((product) => product.state === InitProdState.AWARDED);
    if (awardedProduct) {
      setAwardedProductDetails({
        name: awardedProduct.name,
        imageLoc: awardedProduct.imageLoc,
        id: awardedProduct.id,
      });
    }
  }, [products]);

  useEffect(() => {
    const vendors = showScreenedOut
      ? products
      : products.filter((vendor) => vendor.state !== InitProdState.SCREENED_OUT);
    setDisplayedProducts(vendors);
  }, [products, showScreenedOut]);

  useEffect(() => {
    for (let i = 0; i < displayedProducts.length; i++) {
      if (displayedProducts[i].state === InitProdState.AWARDED) {
        setHasAnAwardedVendor(true);
        return;
      }
    }
  }, [displayedProducts]);

  useEffect(() => {
    const observerArray = [];
    if (tabs) {
      let heights = [];
      for (let i = 0; i < tabs.length; i++) {
        const pClassTextRO = new ResizeObserver((elem) => {
          heights[i] = elem[0]?.contentRect?.height;
          handleExtend(heights);
        });
        pClassTextRO.observe(tabs[i]);
        observerArray.push(pClassTextRO);
      }
    }
    return () => {
      observerArray.map((obs) => {
        obs?.disconnect();
      });
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [tabs]);

  const handleClickExpandButton = (clickIndex) => {
    if (requirementsOpenIdxList.includes(clickIndex)) {
      const openCopy = requirementsOpenIdxList.filter((index) => {
        return index !== clickIndex;
      });
      setRequirementsListsOpenArray(openCopy);
      const sessionData = getStoredJsonItem(sessionStorageId);
      setStoredJsonItem(sessionStorageId, {
        ...sessionData,
        requirementsOpenIdxList: openCopy,
      });
    } else {
      const openCopy = [...requirementsOpenIdxList];
      openCopy.push(clickIndex);
      setRequirementsListsOpenArray(openCopy);
      const sessionData = getStoredJsonItem(sessionStorageId);
      setStoredJsonItem(sessionStorageId, {
        ...sessionData,
        requirementsOpenIdxList: openCopy,
      });
    }
  };

  const handleClickScoreToggle = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = (selectedLevel) => {
    onSuccessSaveRequirementLevel(selectedLevel);
    setIsDrawerOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  const handleExtend = (heights) => {
    let maxLine = 0;
    const offSetHeight = 65;
    if (heights.length === tabs.length) {
      setIsThreshHold(heights.some((el) => el > lineHeight * 2));
      setHeight(
        heights.some((el) => el > lineHeight * 2)
          ? offSetHeight + Math.max(maxLine, Math.max(...heights) / lineHeight) * lineHeight
          : originalHeaderHeight
      );
    }
  };

  const handleOpenPopper = (isOpen) => {
    setIsPopperOpen(isOpen);
  };

  const footerRowMapper = [<div key="ft" className="gx-footer-divider" />];

  const handleScreenedOutToggle = (value) => {
    setShowScreenedOut(value);
    const sessionData = getStoredJsonItem(sessionStorageId);
    setStoredJsonItem(sessionStorageId, {
      ...sessionData,
      showScreenedOut: value,
    });
  };

  const handleNavigate = (tab) => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/${tab}`);
  };

  const handleNavigation = (event, url) => {
    event.preventDefault();
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", {
      sourcePage: "scorecard",
    });
    singleSpa.navigateToUrl(url);
  };

  const updateProductState = (productId, action) => {
    const productStateMapping = {
      [AvailableActions.SCREEN_OUT_PRODUCT]: InitProdState.SCREENED_OUT,
      [AvailableActions.REVERT_AWARD]: InitProdState.IN_EVALUATION,
      [AvailableActions.CHOOSE_PRODUCT]: InitProdState.AWARDED,
      [AvailableActions.ADD_PRODUCT_TO_EVAL]: InitProdState.IN_EVALUATION,
    };
    let updatedProducts = [];
    if (action === AvailableActions.REMOVE_PRODUCT_FROM_EVAL) {
      if (products.find((product) => product.id === productId)) {
        updateInitStatus(InitStatus.EVAL_STARTED);
      }
      updatedProducts = products.filter((product) => {
        return product.id !== productId;
      });
      let updatedDisplayedProducts = displayedProducts.filter((product) => {
        return product.id !== productId;
      });
      setDisplayedProducts(updatedDisplayedProducts);
      if (productId === awardedProductDetails.id) {
        setHasAnAwardedVendor(false);
      }
    } else {
      updatedProducts = products.map((product) => {
        const newState = productStateMapping[action];
        let updatedProductDetails;
        if (product.id === productId) {
          updatedProductDetails = { ...product, state: newState };
        } else if (newState === InitProdState.AWARDED && product.state === InitProdState.AWARDED) {
          updatedProductDetails = {
            ...product,
            state: InitProdState.IN_EVALUATION,
          };
        } else {
          updatedProductDetails = { ...product };
        }
        return updatedProductDetails;
      });
    }
    setProducts(updatedProducts);

    if (action === AvailableActions.REVERT_AWARD) {
      updateInitStatus(InitStatus.EVAL_STARTED);
      setHasAnAwardedVendor(false);
      setAwardedProductDetails(null);
    }
    setTriggerRefreshColumnsCount((prev) => !prev);

    if (action === AvailableActions.CHOOSE_PRODUCT) {
      updateInitStatus(InitStatus.SELECTED);
      const showAnimation = localStorage.getItem(`showAnimationOnSelection-${initiativeId}`);
      if (showAnimation === null) {
        localStorage.setItem(`showAnimationOnSelection-${initiativeId}`, "true");
      }
      handleNavigate("selection");
    }
    setSnackbarOpen(true);
  };

  const onShowSnackbar = (type, msg) => {
    if (type === "SUCCESS") {
      setSnackbarProps({
        setIsOpen: (val) => setSnackbarOpen(val),
        type,
        message: msg,
        isDefaultErrorMessage: false,
      });
    } else {
      setSnackbarProps({
        setIsOpen: (val) => setSnackbarOpen(val),
        type: "ERROR",
        message: "",
        isDefaultErrorMessage: true,
      });
    }
    setSnackbarOpen(true);
  };

  const handleKeyDownNavigation = (event, url) => {
    if (event.key === "Enter") {
      handleNavigation(event, url);
    }
  };

  const renderRequirementLineItems = (index, reqItems = []) => {
    if (reqItems.length < 1) {
      footerRowMapper.push(
        <div className="gx-req-root-footer gx-set-element-height-footer">
          <div className="gx-footer-divider" />
        </div>
      );
      return null;
    }
    footerRowMapper.push(
      <>
        <div className="gx-req-root-footer gx-set-element-height-footer">
          <div className="gx-footer-divider" />
        </div>
        <Collapse className="gx-req-item-footer-container" in={requirementsOpenIdxList.includes(index)}>
          <ul className="gx-req-item-list-footer">
            {reqItems.map(({ id }) => (
              <li key={id} className="gx-req-item-footer gx-set-element-height-footer">
                <div className="gx-footer-divider" />
              </li>
            ))}
          </ul>
        </Collapse>
      </>
    );
    return (
      <Collapse className="gx-req-item-name-container" in={requirementsOpenIdxList.includes(index)}>
        <ul className={classNames("gx-req-item-list", displayedProducts.length === 0 && "right-border")}>
          {reqItems.map(({ name, id }) => (
            <li key={id} className={classNames("gx-req-item", "gx-get-element-height")}>
              <TypographyComponent color={"coal"}>
                {name || (
                  <TypographyComponent rootClassName={"italic"} color={"coal"}>
                    {t("Untitled requirement")}
                  </TypographyComponent>
                )}
              </TypographyComponent>
            </li>
          ))}
        </ul>
      </Collapse>
    );
  };

  const renderRequirements = () => {
    return (
      <React.Fragment>
        {requirements.map((requirement, index, arr) => {
          const { id, name, weight, items } = requirement;
          return (
            <TableCarouselHeaderRowCol
              key={id || index}
              rootClass={classNames(
                "gx-req-root-head-row",
                "gx-req-scoring-assignment",
                arr.length - 1 === index && "gx-last-req-root"
              )}
            >
              <div
                className={classNames(
                  "gx-req-root-category gx-get-element-height",
                  displayedProducts.length === 0 && "right-border",
                  (index === 0 || index === requirements.length - 1) && "no-right-border",
                  requirementsOpenIdxList.includes(index) && "req-root-category-open"
                )}
              >
                <ListHeaderExpandButton
                  expand={requirementsOpenIdxList.includes(index)}
                  setExpand={() => handleClickExpandButton(index)}
                />
                <div className={"gx-req-root-category-name-weight-wrapper"}>
                  <TypographyComponent boldness={"medium"} rootClassName="gx-req-root-category-name" styling={"p2"}>
                    {name || <span>{t("Untitled category")}</span>}
                  </TypographyComponent>
                  <TypographyComponent boldness={"semi"} rootClassName={"gx-req-root-category-weight"} styling={"p4"}>
                    {weight}%
                  </TypographyComponent>
                </div>
                <EvaluationScorecardAssignmentsAvatar
                  categoriesV2={requirements.map((req) => ({
                    evaluators: req.evaluators || [],
                    id: req.id,
                  }))}
                  id={id}
                  isOwner={isOwner}
                  mode={scoringMode}
                  onClick={onAvatarClick}
                />
              </div>
              {renderRequirementLineItems(index, items)}
              {index === 0 && displayedProducts.length === 0 && <div className="right-border-top" />}
              {index === requirements.length - 1 && displayedProducts.length === 0 && (
                <div className="right-border-bottom" />
              )}
            </TableCarouselHeaderRowCol>
          );
        })}
      </React.Fragment>
    );
  };

  const renderProducts = () => {
    const productHead = [];
    const productEvalStatus = [];
    const productStatus = [];
    const productScores = [];
    for (let idx = 0; idx < requirements.length; idx++) {
      productScores.push([]);
    }

    let getRef = (element) => tabs.push(element);
    displayedProducts.forEach((product, productIndex) => {
      const { addedByUser, imageLoc, name, overallScoreDisplay, progress, state } = product;
      const isAwarded = state === InitProdState.AWARDED;
      const isScreenedOut = state === InitProdState.SCREENED_OUT;
      const overallScore = Math.floor(product.overallScore);
      const prodInitId = product.id;
      const url = `/s/evaluation/${initiativeId}/product/${prodInitId}/scoresheet`;
      const vendorProfileUrl = `/s/evaluation/${initiativeId}/product/${prodInitId}/profile`;

      productHead.push(
        <TableCarouselBodyCol
          key={`vendor-info-${prodInitId}`}
          rootClass={classNames(
            "gx-req-vendor-body-row-td",
            isScreenedOut && "gx-screened-out-bk-gnd",
            isAwarded ? "gx-awarded" : "gx-not-awarded"
          )}
        >
          <div className="header-wrapper">
            <div className="gx-evaluations-scorecard-table-vendor">
              <TooltipV2
                enterDelay={1500}
                enterNextDelay={1500}
                placement="top-start"
                PopperProps={{ modifiers: { offset: { offset: "-50, 8" } } }}
                rootClassName={classNames(classes.toolTip)}
                title={isVendorSelectionsFFOn ? t(`Click to view ${name} profile`) : t("Click to view profile")}
              >
                <div
                  aria-label={"Vendor logo and name"}
                  className={classNames(
                    classes.vendorNameLogo,
                    isVendorSelectionsFFOn && classes.vendorNameLogoGCOM3755
                  )}
                  onClick={(e) => handleNavigation(e, vendorProfileUrl)}
                  onKeyDown={(e) => handleKeyDownNavigation(e, vendorProfileUrl)}
                  role="button"
                  tabIndex="0"
                >
                  <ProductLogo
                    logo={imageLoc}
                    addedByUser={addedByUser}
                    name={name}
                    fontSize="12px"
                    imageWidth="36px"
                    imageHeight="36px"
                    rootClassName={classNames(addedByUser && "gx-vendor-avatar")}
                  />
                  <TypographyComponent
                    boldness={"medium"}
                    ref={getRef}
                    rootClassName={classNames(classes.paraVendorName, "gx-evaluations-scorecard-table-vendor-name")}
                    styling={"p3"}
                  >
                    {name}
                  </TypographyComponent>
                </div>
              </TooltipV2>
            </div>
            <div className="header-buttons">
              <TextLink
                ariaLabel={`${t("Evaluation button")} ${name}`}
                href={url}
                onClick={(e) => handleNavigation(e, url)}
                rootClassName={classNames(
                  "gx-evaluations-scorecard-table-vendor-view-eval",
                  "gx-evaluation-view-vendor"
                )}
                text={t("Score vendor")}
              />
              {!isVendorSelectionsFFOn && state === InitProdState.AWARDED && (
                <React.Fragment>
                  <span className="gx-link-pipe">|</span>
                  <TextLink
                    ariaLabel={`${t("Selection button")} ${name}`}
                    onClick={() => handleNavigate("selection")}
                    text={t("View summary")}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </TableCarouselBodyCol>
      );

      if (isVendorSelectionsFFOn) {
        productEvalStatus.push(
          <EvaluationStatusColumn
            initiativeId={initiativeId}
            prodInitId={prodInitId}
            state={state}
            productName={name}
            imageLoc={imageLoc}
            key={`vendor-eval-${prodInitId}`}
            awardedProductDetails={awardedProductDetails}
            updateProductState={updateProductState}
            onShowSnackbar={onShowSnackbar}
          />
        );
      }

      const getProgressMessage = () => {
        let selectedMessage = t("Not started");
        if (progress === ScorecardProgress.COMPLETE) {
          selectedMessage = t("Complete");
        } else if (progress === ScorecardProgress.IN_PROGRESS) {
          selectedMessage = t("In progress");
        }
        return (
          <TypographyComponent
            boldness={"medium"}
            color={progress === ScorecardProgress.COMPLETE ? "darkPear" : "iron"}
          >
            {selectedMessage}
          </TypographyComponent>
        );
      };

      const noScoreAndNotStarted = overallScore === 0 && progress === ScorecardProgress.NOT_STARTED;
      productStatus.push(
        <TableCarouselBodyCol
          key={`vendor-status-${productIndex}`}
          rootClass={classNames(
            "gx-req-scorecard-body-row-td",
            isScreenedOut && "gx-screened-out-bk-gnd",
            isAwarded ? "gx-awarded" : "gx-not-awarded"
          )}
        >
          {!isVendorSelectionsFFOn && state === InitProdState.AWARDED && (
            <span className="selectedIcon">
              <SelectedVendorIconMedium />
            </span>
          )}

          <TypographyComponent
            styling={"h2"}
            element={"h5"}
            boldness={"medium"}
            color={isScreenedOut ? "iron" : "carbon"}
            rootClassName={classNames(
              classes.overallScoreDisplay,
              noScoreAndNotStarted && "gx-dash-placeholder gx-no-score",
              state === InitProdState.AWARDED && "gx-text-awarded"
            )}
          >
            {overallScoreDisplay}
          </TypographyComponent>
          <TypographyComponent
            rootClassName={classNames(classes.paraProgress, state === InitProdState.AWARDED && "gx-p-awarded")}
            styling={"p3"}
            boldness={"medium"}
          >
            {getProgressMessage()}
          </TypographyComponent>

          {!isVendorSelectionsFFOn && state === InitProdState.AWARDED && (
            <div
              className={classNames(
                "gx-selected-vendor",
                state === InitProdState.AWARDED && "gx-text-awarded",
                noScoreAndNotStarted && "gx-no-score-vendor-award"
              )}
            >
              <div className="gx-selected-vendor-icon">
                <SelectedVendorStar />
              </div>
              <TypographyComponent
                rootClassName={"gx-selected-vendor-text"}
                element={"span"}
                boldness={"medium"}
                styling={"p3"}
              >
                Selected Vendor
              </TypographyComponent>
            </div>
          )}
        </TableCarouselBodyCol>
      );

      requirements.forEach((requirement, requirementIndex, arr) => {
        const { id: reqCatId, items } = requirement;
        if (!product?.reqCategoryScoreMap?.[reqCatId]) {
          return;
        }
        const lastReqRoot = arr.length - 1 === requirementIndex;
        const { catScore, catScoreDisplay, reqItemScoreMap } = product.reqCategoryScoreMap[reqCatId];
        productScores[requirementIndex].push({
          lastReqRoot,
          type: "reqRoot",
          displayCol: (
            <ProductScoreCarouselColumn
              key={`product-requirements-${prodInitId}-${reqCatId}`}
              catId={reqCatId}
              catName={requirement.name}
              initId={initiativeId}
              isRequirementExpanded={requirementsOpenIdxList.includes(requirementIndex)}
              isCarouselPopperOpen={isPopperOpen}
              isScreenedOut={isScreenedOut}
              onOpenPopper={handleOpenPopper}
              prodImageLoc={imageLoc}
              prodInitId={prodInitId}
              prodName={name}
              requirementItems={items}
              rootClassName={isAwarded ? "gx-awarded" : "gx-not-awarded"}
              scoreCategory={catScore}
              scoreCategoryDisplay={catScoreDisplay}
              scoreMapRequirementItems={reqItemScoreMap}
              scoringLevel={scoringLevel}
            />
          ),
        });
      });
    });

    return (
      <Fragment>
        <TableCarouselBodyRow>{productHead}</TableCarouselBodyRow>
        <FeatureFlagBooleanContainer flagName={GCOM_3755_GCOM_3756__scoreCardVendorSelections}>
          <FeatureFlagBooleanOn>
            <TableCarouselBodyRow>{productEvalStatus}</TableCarouselBodyRow>
          </FeatureFlagBooleanOn>
        </FeatureFlagBooleanContainer>
        <TableCarouselBodyRow>{productStatus}</TableCarouselBodyRow>
        {productScores.map((prodScore, prodScoreIndex) => {
          // todo this will always be undefined since prodScore is an array - need to see if causing bug in styling before correcting
          const { lastReqRoot } = prodScore;
          return (
            <TableCarouselBodyRow
              /* todo need to switch prodScoreIndex with reqCatId to be valid key */
              key={`product-score-${prodScoreIndex}`}
              rootClass={classNames("gx-req-root-body-row", lastReqRoot && "gx-last-req-root")}
            >
              {prodScore.map((item) => item.displayCol)}
            </TableCarouselBodyRow>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div className={classNames(classes.container, isFtuxView && "gx-ftux-adjusted-container")}>
      {!isFtuxView && <ScorecardHeaderComponent />}
      <ScoringLevelDrawer
        initiativeId={initiativeId}
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        onError={onError}
        scoringLevel={scoringLevel}
        requirements={requirements}
      />
      <TableCarousel
        fixedRow={2} //used along with rowSelectorClass to include non requirements fixed row (vendor and total score)
        footerRowMapper={footerRowMapper}
        rootClassName={classNames(
          "gx-evaluations-scorecard-table",
          isVendorSelectionsFFOn && "gx-evaluations-scorecard-table-GCOM-3756",
          "gx-evaluations-scorecard-tableV2",
          hasAnAwardedVendor && "gx-evaluations-scorecard-awarded",
          isThreshHold && classes.isExtended,
          requirementsOpenIdxList.includes(requirements.length - 1)
            ? "gx-show-requirement-items"
            : "gx-hide-requirement-items",
          hasAnAwardedVendor && "gx-evaluations-scorecard-table-wrap"
        )}
        rowSelectorClass={classNames(requirementsOpenIdxList.length === 0 ? "gx-req-root-head-row" : "")} //decrease the row count in tableCarousel when not showing all requirements
        showAllRecords={requirementsOpenIdxList.length > 0}
        showNextColumnLabel={t("Show next column")}
        showScreenedOut={showScreenedOut}
        triggerRefreshColumnsCount={triggerRefreshColumnsCount}
      >
        <TableCarouselHeader>
          <TableCarouselHeaderRowCol rootClass="gx-req-vendor-head-row">
            <div className={classNames(classes.headerBoxShadowWrapper, "gx-req-vendor-head-row-shadow")}>
              <div className={classes.headerBoxShadow} />
            </div>
            <div
              className={classNames(
                classes.vendorHeadCountWrapper,
                displayedProducts.length === 0 && classNames(classes.gxRightBorder)
              )}
            >
              <TypographyComponent boldness={"medium"} styling={"p2"}>{`${t("Vendors")} (${
                displayedProducts.length || 0
              })`}</TypographyComponent>

              <TooltipV2
                title={!isOwner ? t("This action is only available to the evaluation owners.") : ""}
                placement="top"
                rootClassName={classes.addVendorTooltip}
              >
                <div>
                  <button
                    aria-label="add vendor"
                    disabled={!isOwner}
                    className="gx-evaluations-scorecard-table-add-vendor"
                    onClick={() => handleNavigate("products")}
                  >
                    <AdditionCircledIcon fillPath={colorPalette.interactions.defaultCta.hex} />
                    <TypographyComponent
                      boldness={"medium"}
                      rootClassName={classNames(
                        "gx-evaluations-scorecard-table-add-vendor-button-text",
                        classes.btnAddVendor
                      )}
                      styling="p3"
                    >
                      {t("Add vendor")}
                    </TypographyComponent>
                  </button>
                </div>
              </TooltipV2>
            </div>
          </TableCarouselHeaderRowCol>
          <FeatureFlagBooleanContainer flagName={GCOM_3755_GCOM_3756__scoreCardVendorSelections}>
            <FeatureFlagBooleanOn>
              <TableCarouselHeaderRowCol rootClass="gx-req-scorecard-eval-row">
                <TypographyComponent boldness={"medium"} styling={"p2"}>
                  {t("Evaluation status")}
                </TypographyComponent>
              </TableCarouselHeaderRowCol>
            </FeatureFlagBooleanOn>
          </FeatureFlagBooleanContainer>
          <TableCarouselHeaderRowCol rootClass="gx-req-scorecard-head-row">
            <TypographyComponent rootClassName={"medium-heading"} styling={"h5"} boldness={"medium"}>
              {t("Scorecard")}
            </TypographyComponent>

            <div>
              <TypographyComponent
                rootClassName={"gx-evaluations-scorecard-table-update-requirements-desc"}
                styling={"p4"}
              >
                <TypographyComponent element={"span"}>
                  {t("This evaluation is set to score at the")}
                </TypographyComponent>
                <TypographyComponent element={"span"}>{` ${
                  scoringLevel === ScoringLevel.REQUIREMENT ? t("Requirement") : t("Category")
                } `}</TypographyComponent>
                <TypographyComponent element={"span"}>{t("level. Need to make changes?")}</TypographyComponent>
              </TypographyComponent>
              <TooltipV2
                PopperProps={{ modifiers: { offset: { offset: "0, 5" } } }}
                deactivate={isOwner}
                placement="top"
                title={t("This action is only available to the evaluation owners.")}
              >
                <span>
                  <TextLink
                    onClick={handleClickScoreToggle}
                    rootClassName="gx-evaluations-scorecard-table-update-your-requirements"
                    text={t("Change scoring level")}
                    variant="secondary"
                    disabled={!isOwner}
                  />
                </span>
              </TooltipV2>
              {/* TODO add in product column headers from product render function as a component */}
            </div>
          </TableCarouselHeaderRowCol>
          <FeatureFlagBooleanContainer flagName={GCOM_493_GCOM_3780__scorecardScoreRowSticky}>
            <FeatureFlagBooleanOn>
              <ScorecardRequirementsComponent
                initiativeId={initiativeId}
                onAvatarClick={onAvatarClick}
                isOwner={isOwner}
                scoringMode={scoringMode}
                requirements={requirements}
                displayedProductCount={displayedProducts.length}
              />
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>{renderRequirements()}</FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </TableCarouselHeader>
        <TableCarouselBody>
          <FeatureFlagBooleanContainer flagName={GCOM_493_GCOM_3780__scorecardScoreRowSticky}>
            <FeatureFlagBooleanOff>{displayedProducts.length > 0 && renderProducts()}</FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          {!displayedProducts.length && (
            <TableCarouselBodyRow key={"empty-vendors"}>
              <TableCarouselBodyCol rootClass={classes.noVendorsWrapper}>
                <NoProductsInEvaluation onNavigation={onNavigation} />
              </TableCarouselBodyCol>
            </TableCarouselBodyRow>
          )}
        </TableCarouselBody>
      </TableCarousel>
      <TooltipV2
        PopperProps={{ modifiers: { offset: { offset: "0, 5" } } }}
        placement="top"
        deactivate={screenedOutCount > 0}
        title={t("There are no screened out vendors.")}
      >
        <span>
          <Switch
            rootClassName={"gx-evaluation-toggle-screened-out-vendor"}
            checked={showScreenedOut && screenedOutCount > 0}
            disabled={screenedOutCount < 1}
            handleSwitchToggle={handleScreenedOutToggle}
          />
        </span>
      </TooltipV2>
      <TypographyComponent rootClassName={classes.paraScreenedOut} styling="p3">
        {t("Show screened-out vendors")}
        {` (${screenedOutCount})`}
      </TypographyComponent>
      {isVendorSelectionsFFOn && <SnackbarBanner isOpen={snackbarOpen} {...snackbarProps} />}
    </div>
  );
};

EvaluationScorecard.propTypes = propTypes;
export { EvaluationScorecard };
