// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YGAHggkQC9z1lFRCZme3{padding-right:81px}.YGAHggkQC9z1lFRCZme3 .Ax2vQf5_siy2_WA__Ygh{padding-bottom:56px}.YGAHggkQC9z1lFRCZme3 .RT8UA7oLAsvLNhhEPINM{display:flex;align-items:center;gap:15px;padding-bottom:12px}.YGAHggkQC9z1lFRCZme3 .hqbKMtgmDXKFghXWewww{color:var(--color-neutrals-quartz);width:400px}.YGAHggkQC9z1lFRCZme3 .sLDkmojK55tTzwHxjQqC{width:400px;margin-bottom:56px}.YGAHggkQC9z1lFRCZme3 ._VWrNuXi_K92Yt6Ahlwj{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.YGAHggkQC9z1lFRCZme3 .soD0mIAFCqvHgFdOFGH3{margin-bottom:16px}.YGAHggkQC9z1lFRCZme3 .Ayz6g5JviXP4Mll8Cw78 .JwzGfcsUgJonNZIILeHC{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `YGAHggkQC9z1lFRCZme3`,
	"gx-gartner-mg-desc-text": `Ax2vQf5_siy2_WA__Ygh`,
	"contentBodyMidSectionTitleText": `RT8UA7oLAsvLNhhEPINM`,
	"contentBodyMidSectionDescText": `hqbKMtgmDXKFghXWewww`,
	"contentBodyMidSectionDescTextGCOM3523": `sLDkmojK55tTzwHxjQqC`,
	"contentLearnMoreLine": `_VWrNuXi_K92Yt6Ahlwj`,
	"contentBodyMidSectionLearnMore": `soD0mIAFCqvHgFdOFGH3`,
	"contentLink": `Ayz6g5JviXP4Mll8Cw78`,
	"contentBodyMidSectionLinkText": `JwzGfcsUgJonNZIILeHC`
};
export default ___CSS_LOADER_EXPORT___;
