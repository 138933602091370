import { Box, makeStyles } from "@material-ui/core";
import { Loader } from "gx-npm-ui";
import { tabSectionLoaderStyles as styles } from "./styles";

const useStyles = makeStyles(() => styles);
const TabSectionLoader = () => {
  const classes = useStyles();
  return (
    <Box
      alignItems="center"
      borderRadius="8px"
      className={classes.loading}
      display="flex"
      justifyContent="center"
      position="relative"
    >
      <Loader size={40} />
    </Box>
  );
};

export default TabSectionLoader;
