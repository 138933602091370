// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WHwQwKy7xGaP9szLETDa{color:var(--color-neutrals-carbon);display:flex;justify-content:space-between;align-items:flex-start;position:relative}.WHwQwKy7xGaP9szLETDa h3{margin-bottom:24px}.nQUp06lugAEBH0mekJVu{display:flex;gap:24px}.jmBPoYi5PiQ21Flz1Bt5{display:flex;flex-direction:column;gap:24px}.FEuyE_LzV7LH2yuKayOV{display:flex;flex-direction:column;gap:24px;width:100%;background-color:var(--color-white);border-radius:8px}.CZdLTrO5O0vKhFzgng1K{display:flex;flex-direction:column;gap:24px;margin-top:24px}.PKnxs424Hfj4B_EimbkG span{margin-left:6px}.PKnxs424Hfj4B_EimbkG svg{margin-right:0 !important}.V55BaYxZXPvb01Xw0XA8{margin-right:3px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/selection-report.module.scss"],"names":[],"mappings":"AAAA,sBACE,kCAAA,CACA,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,iBAAA,CAEA,yBACE,kBAAA,CAIJ,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA,CACA,mCAAA,CAEA,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAIA,2BACE,eAAA,CAGF,0BACE,yBAAA,CAIJ,sBACE,gBAAA","sourcesContent":[".exportPdf {\n  color: var(--color-neutrals-carbon);\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  position: relative;\n\n  & h3 {\n    margin-bottom: 24px;\n  }\n}\n\n.initiativeInfo {\n  display: flex;\n  gap: 24px;\n}\n\n.vendorInfo {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.initDetails {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  width: 100%;\n  background-color: var(--color-white);\n\n  border-radius: 8px;\n}\n\n.evaluationInfo {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n\n.btnWrapper {\n  & span {\n    margin-left: 6px;\n  }\n\n  & svg {\n    margin-right: 0 !important;\n  }\n}\n\n.loader {\n  margin-right: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exportPdf": `WHwQwKy7xGaP9szLETDa`,
	"initiativeInfo": `nQUp06lugAEBH0mekJVu`,
	"vendorInfo": `jmBPoYi5PiQ21Flz1Bt5`,
	"initDetails": `FEuyE_LzV7LH2yuKayOV`,
	"evaluationInfo": `CZdLTrO5O0vKhFzgng1K`,
	"btnWrapper": `PKnxs424Hfj4B_EimbkG`,
	"loader": `V55BaYxZXPvb01Xw0XA8`
};
export default ___CSS_LOADER_EXPORT___;
