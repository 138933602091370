const revertChangesClasses = {
  descriptionContainer: {
    paddingBottom: "24px",
  },
  descriptionStart: {
    padding: "4px 0 16px",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& button:last-child": {
      marginLeft: "12px",
    },
  },
};

export default revertChangesClasses;
