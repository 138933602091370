import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { listFooterStyles as styles } from "./styles";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const ListFooter = ({ children, rootClassName = "" }) => {
  const classes = useStyles();
  return (
    <div className={classNames("gx-list-footer", rootClassName, classes.listFooterContainer)}>
      <div className={classes.listFooter}>{children}</div>
      <div className={classes.listFooterEnd} />
    </div>
  );
};

ListFooter.propTypes = propTypes;
export default ListFooter;
