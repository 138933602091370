import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tableCarouselScrollLeft: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rootClass: PropTypes.string,
};
const TableCarouselHeaderRowCol = ({ children, tableCarouselScrollLeft, rootClass }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child) ? React.cloneElement(child) : child;
  });

  return (
    <tr className={rootClass}>
      <th>
        {childrenWithProps}
        {tableCarouselScrollLeft}
      </th>
    </tr>
  );
};

TableCarouselHeaderRowCol.propTypes = propTypes;
export default TableCarouselHeaderRowCol;
