import { FC, UIEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TextField } from "@material-ui/core";
import { sumArrayObjects, UUID } from "gx-npm-lib";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { Button, InlineAlert, SlideInOverlay, TypographyComponent } from "gx-npm-ui";
import { PercentageIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { useTranslation } from "react-i18next";
import { EvaluationStateContext } from "../../../../../context";
import { CategoryList } from "./category-weight.types";
import ErrorIcon from "../../../drawers/libraryDrawer/importLibraryBody/errorIcon";
import { getApiUrl } from "../../../../../context/actions/actionUtils";
import { putRequest } from "../../../../../context/actions/apiRequests";
import { loadRequirementsData } from "../../../../../context/actions/requirementsActions";
import styles from "./category-weight-slidein.styles.module.scss";

type EditCategoryWeightSlideInProps = {
  initiativeId?: UUID;
  isOpen: boolean;
  onClick?: () => void;
};

const validWeightAmount = 100;
const determineValidWeight = (weight: number, list: CategoryList[]) => {
  return weight === validWeightAmount || (list?.length || 0) === 0;
};

const formatNumber = (num: number) => {
  return String(num % 1 === 0 ? num : num.toFixed(1));
};

const EditCategoryWeightSlideIn: FC<EditCategoryWeightSlideInProps> = ({
  initiativeId = "",
  isOpen = false,
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const [currentInitTitle, setCurrentInitTitle] = useState("");
  const [isScrolledTop, setIsScrolledTop] = useState(false);
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [weightAmount, setWeightAmount] = useState(0);
  const [isValidWeight, setIsValidWeight] = useState(true);
  const [categoryList, setCategoryList] = useState<Array<CategoryList>>([]);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [displayedWeights, setDisplayedWeights] = useState<Array<string>>([]);
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useContext(EvaluationStateContext);

  const handleScroll = (event: UIEvent) => {
    const el = event.target as HTMLDivElement;
    setIsScrolledTop(el.scrollTop > 0);
    setIsScrolledBottom(el.scrollTop < el.scrollHeight - el.clientHeight);
  };

  const resetWeights = useCallback(() => {
    const originalCatList = state.requirements.list.map((cat: CategoryList, index: number) => {
      const catName = cat.name || t("Untitled Category");
      return { id: cat.id, index, name: catName, weight: cat.weight };
    });
    setCategoryList(originalCatList);

    const originalWeight = Number.parseFloat(formatNumber(sumArrayObjects(state.requirements.list, "weight")));
    setWeightAmount(originalWeight);

    const originalIsValidWeight = determineValidWeight(originalWeight, state.requirements.list);
    setIsValidWeight(originalIsValidWeight);

    setDisplayedWeights(state.requirements.list.map((cat: CategoryList) => cat.weight.toFixed(1)));
  }, [state.requirements.list, t]);

  useEffect(() => {
    resetWeights();
    setDisplayedWeights(state.requirements.list.map((cat: CategoryList) => cat.weight.toFixed(1)));
  }, [state.requirements.list, resetWeights]);

  useEffect(() => {
    if (scrollableDivRef.current && (categoryList.length > 5 || (categoryList.length > 4 && !isValidWeight))) {
      const el = scrollableDivRef.current;
      const isScrollbarPresent = el.scrollHeight > el.clientHeight;
      setHasScrollbar(isScrollbarPresent);
    }
  }, [categoryList, isValidWeight, scrollableDivRef]);

  useEffect(() => {
    const currentInitSubscription = createStateSubscription(
      "CURRENT_INITIATIVE",
      (updatedState: { name?: string | null }) => {
        setCurrentInitTitle(updatedState?.name || "");
      }
    );
    return () => {
      destroyStateSubscription(currentInitSubscription);
    };
  }, []);

  const handleWeightChange = (newWeight: number, index: number) => {
    const newWeightAmountRaw = weightAmount + newWeight - categoryList[index].weight;
    const newWeightAmount = Math.round(newWeightAmountRaw * 10) / 10;
    setWeightAmount(newWeightAmount);
    setIsValidWeight(determineValidWeight(newWeightAmount, categoryList));
    const newCategoryList = [...categoryList];
    newCategoryList[index].weight = newWeight;
    setCategoryList(newCategoryList);
  };

  const handleEditClick = (index: number) => {
    setSelectedIdx(index);
  };

  const handleUpdateWeights = () => {
    handleUpdateCategoryListWeights(categoryList);
    onClick();
  };

  const handleUpdateCategoryListWeights = async (list: CategoryList[]) => {
    const catWeights: Array<Partial<CategoryList>> = [];
    list.forEach((cat, idx) => {
      if (cat.weight !== state.requirements.list[idx].weight) {
        const updatedCategory: Partial<CategoryList> = { weight: cat.weight, id: cat.id };
        catWeights.push(updatedCategory);
      }
    });
    if (catWeights.length > 0) {
      const section = "requirements/action/update/category/weights";
      const url = getApiUrl(initiativeId, section, 2);
      const payload = { catWeights };
      const response = await putRequest(url, payload);
      if (response?.status === 200) {
        loadRequirementsData(null, dispatch, { initiativeId, isReloading: true });
      }
    }
  };

  const handleOnClose = () => {
    resetWeights();
    onClick();
  };

  const hasSingleDecimal = (value: string) => {
    const regex = /^\d+(\.\d)?$/;
    return regex.test(value);
  };

  const body = (
    <div
      className={classNames(
        styles.editWeightBodyContainer,
        isScrolledTop && styles.editWeightBodyContainerScrolledTop,
        isScrolledBottom && styles.editWeightBodyContainerScrolledBottom
      )}
    >
      <div
        className={classNames(styles.editWeightBody, hasScrollbar && "scrollbar")}
        onScroll={handleScroll}
        ref={scrollableDivRef}
        data-testid="scrollableElement"
      >
        <div>
          {categoryList.map((cat, index) => {
            return (
              <button
                key={cat.id}
                className={classNames(styles.editWeightBodyCategory)}
                aria-label="Edit weight category"
              >
                <div className={classNames(styles.editCategoryBox, selectedIdx === index && styles.editWeightSelected)}>
                  <TypographyComponent boldness="medium" rootClassName={styles.editCategoryName} styling="p1">
                    {cat.name}
                  </TypographyComponent>
                  <div className={classNames(styles.weightedText)}>
                    <TextField
                      className={classNames(
                        styles.hideSpinners,
                        styles.hideOutline,
                        !isValidWeight && styles.invalidOutline
                      )}
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: selectedIdx !== index && (
                          <div className={styles.percentageIcon}>
                            <PercentageIcon
                              fillPath={
                                !isValidWeight ? colorPalette.status.poisonCherry.hex : colorPalette.neutrals.iron.hex
                              }
                            />
                          </div>
                        ),
                      }}
                      value={displayedWeights[index]}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (hasSingleDecimal(value) || value === "") {
                          const newWeights = [...displayedWeights];
                          newWeights[index] = value;
                          setDisplayedWeights(newWeights);
                        }
                      }}
                      onBlur={() => {
                        let value = parseFloat(displayedWeights[index]);
                        if (isNaN(value)) {
                          value = 0;
                        }
                        handleWeightChange(value, index);

                        const newWeights = [...displayedWeights];
                        newWeights[index] = value.toFixed(1);
                        setDisplayedWeights(newWeights);
                        setSelectedIdx(null);
                      }}
                      onFocus={() => handleEditClick(index)}
                      inputProps={{
                        style: {
                          textAlign: "right",
                        },
                        step: "0.1",
                        min: "0",
                        max: "100",
                      }}
                    />
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <SlideInOverlay
      isOpen={isOpen}
      isOverlayNavBar={true}
      onClose={handleOnClose}
      rootClassName={classNames(styles.slideIn, styles.slideInRoot)}
      textBackBtn={t("requirements")}
      textTitle={currentInitTitle}
    >
      <TypographyComponent boldness="medium" element="h3" rootClassName={styles.editWeightTitle}>
        {t("Edit category weights")}
      </TypographyComponent>
      <div className={styles.editWeightHeaderAlert}>
        {!isValidWeight && (
          <InlineAlert alertType="error" isShadowed={false} textMessage={t("Total weight must equal 100%")} />
        )}
        <div className={styles.editWeightHeader}>
          <TypographyComponent boldness="medium" color="coal" styling="p4">
            {t("Total weight")}
          </TypographyComponent>
          <div className={classNames(styles.editWeightHeaderRight)}>
            {!isValidWeight && <ErrorIcon />}
            <TypographyComponent
              rootClassName={classNames(
                styles.editWeightHeaderPercentage,
                !isValidWeight && styles.editWeightHeaderInvalid
              )}
              styling="h5"
            >
              {weightAmount === validWeightAmount ? String(validWeightAmount) : formatNumber(weightAmount)}
            </TypographyComponent>
            <PercentageIcon
              fillPath={!isValidWeight ? colorPalette.status.poisonCherry.hex : colorPalette.neutrals.iron.hex}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.editWeightBodyLine, isScrolledTop && styles.editWeightBodyLineScrolledTop)} />
      {body}
      <div className={styles.footer}>
        <Button rootClassName="btn-tertiary" onClick={handleOnClose}>
          {t("CANCEL")}
        </Button>
        <Button rootClassName="btn-primary" disabled={!isValidWeight} onClick={handleUpdateWeights}>
          {t("SAVE CATEGORY WEIGHTS")}
        </Button>
      </div>
    </SlideInOverlay>
  );
};

export default EditCategoryWeightSlideIn;
