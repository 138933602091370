import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { TextField } from "gx-npm-ui";
import { handleEvent } from "gx-npm-lib";
import { blurRef } from "../../../lib";
import { getListTextBoxStyles } from "./styles";

const propTypes = {
  autoFocus: PropTypes.bool,
  handleAutoSave: PropTypes.func,
  isDragging: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
  setEdit: PropTypes.func,
  style: PropTypes.object,
  text: PropTypes.string,
  width: PropTypes.string,
};

const ListTextBox = ({
  autoFocus,
  handleAutoSave = null,
  isDragging = false,
  isViewOnly = false,
  onBlur = null,
  onFocus = null,
  placeholder,
  rootClassName = "",
  setEdit = null,
  style,
  text,
  width,
}) => {
  const [display, setDisplay] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setDisplay(text || "");
  }, [text]);

  useEffect(() => {
    if (isFocused && isDragging) {
      blurRef(fieldRef);
      setIsFocused(false);
    }
  }, [isDragging, isFocused]);

  const handleBlur = (event) => {
    setIsFocused(false);
    handleEvent(setEdit, false);
    handleEvent(onBlur, event);
    const newValue = event?.target?.value || "";
    if (initialValue !== newValue) {
      handleEvent(handleAutoSave, newValue);
    }
    setInitialValue(null);
  };

  const handleFocus = (event) => {
    const value = event?.target?.value || "";
    setIsFocused(true);
    handleEvent(setEdit, true);
    handleEvent(onFocus, event);
    setInitialValue(value);
  };

  const styles = getListTextBoxStyles(width);
  const useStyles = makeStyles(() => styles);
  const classes = useStyles();
  const fieldRef = useRef();
  return (
    <TextField
      disabled={isViewOnly}
      onBlur={handleBlur}
      onChange={(e) => setDisplay(e?.target?.value)}
      onFocus={handleFocus}
      placeholder={placeholder}
      inputRef={fieldRef}
      rootClassName={classNames("gx-list-text-box", rootClassName, classes.name, isViewOnly && "gx-read-only")}
      value={display}
      {...(autoFocus ? { autoFocus: true } : {})}
      {...(style ? { style: style } : {})}
    />
  );
};

ListTextBox.propTypes = propTypes;
export default ListTextBox;
