import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorWhite = colorPalette.basic.white.hex;
const variationSemiBold = weightPalette.semiBold.variation;
const weightSemiBold = weightPalette.semiBold.amount;
const colorIron = colorPalette.neutrals.iron.hex;
const weightMedium = weightPalette.medium.amount;
const weightRegularAmount = weightPalette.regular.amount;
const weightRegularVariation = weightPalette.regular.variation;

const planningCardV2Styles = {
  planContentRoot: {
    display: "flex",
    justifyContent: "space-between",
    color: colorIron,
    fontSize: "14px",
    fontVariationSettings: weightRegularVariation,
    fontWeight: weightRegularAmount,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    marginTop: "32px",
    padding: "0px 0px 0px 0px",
    "@media (max-width: 1239px)": {
      minWidth: "820px",
    },
  },
  planningCardWrapper: {
    backgroundColor: colorWhite,
    borderRadius: "8px",
    padding: "32px 40px 40px 40px",
    width: "calc(66.66% - 8px)",
    height: "372px",
    marginTop: 0,
  },
  setUpCardWrapper: {
    backgroundColor: colorWhite,
    borderRadius: "8px",
    padding: "24px 46px 53px 40px",
    width: "100%",
  },
  headerLargeTitle: {
    color: colorCarbon,
  },
  headerSmallTitle: {
    color: colorIron,
    fontSize: "12px",
    fontWeight: weightMedium,
    letterSpacing: "0.15px",
    lineHeight: "18px",
    paddingTop: "12px",
  },
  planTitle: {
    color: colorCarbon,
    fontVariationSettings: variationSemiBold,
    fontWeight: weightSemiBold,
  },
  objectivesWrapper: {
    width: "548px",
    marginRight: "24px",
    "@media (max-width: 1300px)": {
      width: "390px",
      marginRight: "",
    },
  },
  budgetAndDatePickerWrapper: {
    width: "183px",
  },
  datePickerWrapper: {
    marginTop: "27px",
    maxHeight: "50px",
  },
  calendar: {
    position: "relative" as const,
    left: "-55px",
    top: "-30px",
    marginTop: "16px",
    "& button": {
      minHeight: 37,
    },
    "& input": {
      color: colorIron,
      fontVariationSettings: weightRegularVariation,
      fontWeight: weightRegularAmount,
      marginLeft: 18,
    },
    "& .MuiButtonBase-root ": {
      justifyContent: "start",
    },
  },
  planContent: {
    display: "inline-block",
    transition: "width 0.5s",
  },
  planContentObjectives: {
    display: "flex",
  },
  planBudgetSpacer: {
    width: "183px",
  },
  planContentTeam: {
    borderLeft: `1px solid ${colorPalette.neutrals.silver.hex}`,
    float: "right" as const,
    paddingLeft: "40px",
    width: "331px",
    "& .gx-user": {
      paddingTop: "12px",
    },
    "@media (max-width: 1439px)": {
      width: "252px",
    },
    "@media (max-width: 1050px)": {
      width: "200px",
    },
  },
  teamManagementTitle: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    "&&::placeholder": {
      color: `${colorIron} !important`,
    },
  },
  inlineTextRoot: {},
  "@global": {
    ".gx-date-picker-tooltip": {
      "& .MuiTooltip-arrow": {
        left: "120px !important",
        "&::before": {},
      },
    },
  },
};

export { planningCardV2Styles };
