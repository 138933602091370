import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { dragAndDropRootStyles as styles } from "./styles";

const propTypes = {
  children: PropTypes.any, // issue with children propType being node when using in TS components
  defaultExpand: PropTypes.bool,
  handleExpand: PropTypes.func,
  isIdExpanded: PropTypes.bool,
  rootClassName: PropTypes.string,
  useExpandedId: PropTypes.bool,
};
const useStyles = makeStyles(() => styles);
const DragAndDropList = ({
  children,
  defaultExpand = true,
  handleExpand,
  isIdExpanded = null,
  rootClassName = "",
  useExpandedId = false,
}) => {
  const classes = useStyles();
  const [expand, setExpandCollapse] = useState(defaultExpand);

  const setExpand = (val) => {
    setExpandCollapse(val);
    handleEvent(handleExpand, val);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child)
      ? React.cloneElement(child, {
          expand: (expand && !useExpandedId) || (isIdExpanded && useExpandedId),
          setExpand,
          in: (expand && !useExpandedId) || (isIdExpanded && useExpandedId),
        })
      : child;
  });

  return (
    <section className={classNames("gx-dnd-list", "list", classes.root, rootClassName)}>{childrenWithProps}</section>
  );
};

DragAndDropList.propTypes = propTypes;
export default DragAndDropList;
