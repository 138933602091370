import { colorPalette } from "gx-npm-common-styles";

const grapeColor = colorPalette.accent.sweetGrape.hex;
const whiteNeutral = colorPalette.basic.white.hex;

const ftuxBannerStyles = {
  wrapper: {
    backgroundColor: grapeColor,
    marginLeft: "-500px",
    marginRight: "-56px",
    marginTop: "-48px",
    "@media (max-width: 1240px)": {
      marginRight: "-24px",
    },
  },
  backdrop: {
    position: "relative",
    minHeight: "330px",
    marginLeft: "450px",
    display: "flex",
    paddingBottom: "84px",
    paddingTop: "48px",
    justifyContent: "space-between",
    "& .p3": {
      color: whiteNeutral,
      marginTop: 12,
    },
    "& button.gx-assign-scorers-button": {
      marginRight: 54,
      marginTop: 11,
    },
  },
  purpleBanner: {
    backgroundColor: grapeColor,
    right: "0",
    position: "absolute",
    width: "10000px",
    height: "330px",
  },

  left: {
    marginLeft: "54px",
  },

  headingWrapper: {
    display: "flex",
  },

  divider: {
    marginInline: 24,
  },

  description: {
    color: whiteNeutral,
    marginTop: 12,
    maxWidth: 425,
  },

  imageWrapper: {
    position: "absolute",
    display: "flex",
    bottom: "80px",
    right: "350px",
    "& svg": {
      width: 300,
    },
  },
  imagePerson: {
    marginRight: "115px",
  },
};
export default ftuxBannerStyles;
