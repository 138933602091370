// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iDvuzSz3JoeBRJnn7pdj{margin-top:32px}.iDvuzSz3JoeBRJnn7pdj .AoLk2YEjkWkOyAYMB8n4{color:var(--color-neutrals-iron)}.yFBbxktlMndULplvYOX2{border-top:1px var(--color-neutrals-silver) solid;display:flex;justify-content:space-between;align-items:center;padding-bottom:8px;padding-top:8px}.zLMEC2Ek3iBcQU7Th6Jp{display:inline-block}.hZJlkUx7wwA8RN57BecE{display:inline-block;float:right}.AoLk2YEjkWkOyAYMB8n4{margin-bottom:12px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/initiative-details-card/components/details-checklist-section.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEA,4CACE,gCAAA,CAIJ,sBACE,iDAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,oBAAA,CAGF,sBACE,oBAAA,CACA,WAAA,CAGF,sBACE,kBAAA","sourcesContent":[".checklistContainer {\n  margin-top: 32px;\n\n  .checklistTitle {\n    color: var(--color-neutrals-iron);\n  }\n}\n\n.checklistSection {\n  border-top: 1px var(--color-neutrals-silver) solid;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 8px;\n  padding-top: 8px;\n}\n\n.checklistSectionName {\n  display: inline-block;\n}\n\n.checklistSectionStatus {\n  display: inline-block;\n  float: right;\n}\n\n.checklistTitle {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checklistContainer": `iDvuzSz3JoeBRJnn7pdj`,
	"checklistTitle": `AoLk2YEjkWkOyAYMB8n4`,
	"checklistSection": `yFBbxktlMndULplvYOX2`,
	"checklistSectionName": `zLMEC2Ek3iBcQU7Th6Jp`,
	"checklistSectionStatus": `hZJlkUx7wwA8RN57BecE`
};
export default ___CSS_LOADER_EXPORT___;
