import { colorPalette } from "gx-npm-common-styles";

const checklistHeaderV2Styles = {
  headerLeft: {
    alignItems: "center",
    display: "flex",
    height: "48px",
    width: "calc(25% + 54px)",
    "& $titleName": {
      paddingLeft: "8px",
    },
  },
  titleName: {},
  headerMiddle: {
    alignItems: "center",
    display: "flex",
    height: "48px",
    "& $statusName": {
      color: `var(${colorPalette.neutrals.iron.name})`,
      paddingLeft: "8px",
    },
    "&$completed $statusName": {
      color: `var(${colorPalette.status.darkPear.name})`,
    },
  },
  statusName: {},
  completed: {},
  headerRight: {
    marginLeft: "auto",
    width: 350,
    "& $dateRangeWrapper": {
      alignItems: "center",
      display: "flex",
      flex: 1,
      height: "48px",
      "& > button:first-child": {
        // todo - needed to override inline style applied in the DateRangePicker
        // todo - component. since the component is already being worked on, can
        // todo - address after that refactor/rework is complete
        marginLeft: "0 !important",
      },
    },
  },
  dateRangeWrapper: {},
  toolTip: {
    left: "-54px !important",
    top: "-10px !important",
    "& .MuiTooltip-arrow": {
      left: "65px !important",
    },
  },
  toolTipViewOnly: {
    left: "-10px !important",
    top: "-2px !important",
  },
};

export default checklistHeaderV2Styles;
