import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { requirementDrawerTitleStyles as styles } from "./styles";
import { handleEvent } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  selectedIndex: PropTypes.number,
};

const useStyles = makeStyles(() => styles);
const ImportLibraryNavBar = ({ items = [], onClick = {}, selectedIndex = 0 }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClick = (index) => {
    handleEvent(onClick, index);
  };

  const renderItems = () => {
    return items.map((item, index) => {
      return (
        <button
          aria-label={`${t(item)}${index === selectedIndex ? " is selected" : ""}`}
          className={classes.libraryNavAnchor}
          key={index}
          onClick={() => {
            handleClick(index);
          }}
        >
          <TypographyComponent
            rootClassName={classNames(
              classes.libraryNavItem,
              index === selectedIndex && classes.isSelected,
              "medium-bold"
            )}
            styling={selectedIndex === index ? "p3" : "p4"}
          >
            {item}
          </TypographyComponent>
        </button>
      );
    });
  };

  return (
    <div>
      <section className={classes.navContainer}>{renderItems()}</section>
    </div>
  );
};

ImportLibraryNavBar.propTypes = propTypes;
export default ImportLibraryNavBar;
