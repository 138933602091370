const operations = {
  childAdd: "CHILD_ADD",
  childDelete: "CHILD_DELETE",
  childEdit: "CHILD_EDIT",
  childReorder: "CHILD_REORDER",
  parentAdd: "PARENT_ADD",
  parentDelete: "PARENT_DELETE",
  parentEdit: "PARENT_EDIT",
  parentReorder: "PARENT_REORDER",
  removeChecklistAssignment: "REMOVE_CHECKLIST_ASSIGNMENT",
};

const isAddOp = (op) => {
  return [operations.childAdd, operations.parentAdd].indexOf(op) > -1;
};

const isDeleteOp = (op) => {
  return [operations.childDelete, operations.parentDelete].indexOf(op) > -1;
};

const isEditOp = (op) => {
  return [operations.childEdit, operations.parentEdit].indexOf(op) > -1;
};

const isReorderOp = (op) => {
  return [operations.childReorder, operations.parentReorder].indexOf(op) > -1;
};

export { isAddOp, isDeleteOp, isEditOp, isReorderOp, operations };
