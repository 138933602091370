import classNames from "classnames";
import { getAsyncRequest, InitProdState, useCaptureEventsV2, useWebSocket, UUID } from "gx-npm-lib";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import { TabSectionLoader } from "../../ui/loader";
import BoxedProductHeaderComponent from "./boxed-product-header/boxed-product-header.component";
import ProductsEmptyViewV2Component from "./products/products-empty-view-v2/products-empty-view-v2.component";
import ProductsListV2Component from "./products/products-list-v2/products-list-v2.component";
import ProductsSnackbarsV2Component from "./products/products-snackbars-v2/products-snackbars-v2.component";
import { ProductWSEvent, ProductWSStateUpdate } from "./products.constants";
import { ProductsContext } from "./products.context";
import { InitiativeProduct, ProductsTabApiResponse, ProductWSMessage } from "./products.types";
import styles from "./products-tab-content-v2.styles.module.scss";
import InitiativeWeightedPrioritiesDrawerComponent from "./priorities/initiative-weighted-priorities-drawer/initiative-weighted-priorities-drawer.component";
import PrioritiesBarV2Component from "./priorities/priorities-bar/priorities-bar-v2.component";

type ProductsTabContentV2Props = { initiativeId: UUID };
const ProductsTabContentV2Component = ({ initiativeId = "" }: ProductsTabContentV2Props) => {
  const { t } = useTranslation();
  const {
    hasPriorities,
    isFading,
    products,
    setGcomCcResId,
    setHasPriorities,
    setInitId,
    setInitName,
    setIsBaseTemplate,
    setIsMqBasedTemplate,
    setPriorities,
    setProducts,
    setProductsErrorMessage,
    setSelectedPriorityIds,
  } = useContext(ProductsContext);
  const [wsMessage]: [ProductWSMessage, unknown] = useWebSocket();
  const [hasProducts, setHasProducts] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const captureEvents = useCaptureEventsV2();

  const fetchProducts = useCallback(
    async (id: UUID) => {
      const url = `/api/v5/initiatives/${id}/products`;
      const response: ProductsTabApiResponse = await getAsyncRequest(url);
      if (response.status === 200 && response.data?.data) {
        setGcomCcResId(response.data.data.gcomCcResId || -1);
        setHasPriorities(response.data.data.priorities?.length > 0);
        setInitName(response.data.data.initName || "");
        setIsBaseTemplate(response.data.data.templateId === -1);
        setIsMqBasedTemplate(response.data.data.gcomMqResId > 0);
        setPriorities(response.data.data.priorities || []);
        setProducts(response.data.data.products || []);
        if (response.data.data.priorities?.length > 0) {
          const { priorities } = response.data.data;
          setSelectedPriorityIds(priorities.reduce((acc, curr) => (curr.isSelected ? [...acc, curr.id] : acc), []));
        }
      } else {
        setProductsErrorMessage(`${t("Oops... something went wrong. Please try again.")}`);
      }
    },
    [
      setGcomCcResId,
      setHasPriorities,
      setInitName,
      setIsBaseTemplate,
      setIsMqBasedTemplate,
      setPriorities,
      setProducts,
      setProductsErrorMessage,
      setSelectedPriorityIds,
      t,
    ]
  );

  useEffect(() => {
    setHasProducts(products.length > 0);
  }, [products]);

  useEffect(() => {
    setInitId(initiativeId);
  }, [initiativeId, setInitId]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setIsLoading(true);
    (async () => {
      await fetchProducts(initiativeId);
      setIsLoading(false);
    })();
  }, [fetchProducts, initiativeId]);

  useEffect(() => {
    const eventsToProcess = [ProductWSEvent.RELOAD_PRODUCT_DATA];
    if (!wsMessage || !eventsToProcess.includes(wsMessage.event) || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    (async () => await fetchProducts(initiativeId))();
  }, [fetchProducts, initiativeId, wsMessage]);

  useEffect(() => {
    const eventsToProcess = [
      ProductWSEvent.PRODUCT_CUSTOM_ADDED,
      ProductWSEvent.PRODUCT_CUSTOM_DELETED,
      ProductWSEvent.UPDATE_PRODUCT_POSITION,
      ProductWSEvent.UPDATE_PRODUCT_STATE,
    ];
    if (!wsMessage || !eventsToProcess.includes(wsMessage.event) || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event === ProductWSEvent.PRODUCT_CUSTOM_ADDED) {
      setProducts((prev) => {
        if (prev.findIndex((prod) => prod.id === wsMessage.productId) !== -1) {
          return prev;
        }
        const newProduct: InitiativeProduct = {
          addedByUser: true,
          id: wsMessage.productId,
          imageLoc: wsMessage.productImageLoc,
          mqPosition: "",
          name: wsMessage.productName,
          peerRating: 0,
          peerReviewCount: 0,
          piProductId: 0,
          productId: 0,
          state: InitProdState.LISTED,
          priorityScore: 0,
          isEligiblePR: false,
        };
        return [newProduct, ...prev];
      });
    } else if (wsMessage.event === ProductWSEvent.PRODUCT_CUSTOM_DELETED) {
      setProducts((prev) => {
        if (prev.findIndex((prod) => prod.id === wsMessage.productId) === -1) {
          return prev;
        }
        return prev.filter((p) => p.id !== wsMessage.productId);
      });
    } else if (wsMessage.event === ProductWSEvent.UPDATE_PRODUCT_POSITION) {
      setProducts((prev) => {
        const product = prev.find((p) => p.id === wsMessage.productId);
        if (!product) {
          return prev;
        }
        const newProducts = prev.filter((p) => p.id !== wsMessage.productId);
        newProducts.splice(wsMessage.productIndex, 0, product);
        return newProducts;
      });
    } else if (wsMessage.event === ProductWSEvent.UPDATE_PRODUCT_STATE) {
      const { productId, type } = wsMessage;
      setProducts((prev) => {
        if (prev.findIndex((prod) => prod.id === wsMessage.productId) === -1) {
          return prev;
        }
        let state = InitProdState.LISTED;
        if (type === ProductWSStateUpdate.ADD_TO_EVAL || type === ProductWSStateUpdate.REVERT_AWARD) {
          state = InitProdState.IN_EVALUATION;
        } else if (type === ProductWSStateUpdate.AWARD) {
          state = InitProdState.AWARDED;
        } else if (type === ProductWSStateUpdate.SCREEN_OUT) {
          state = InitProdState.SCREENED_OUT;
        }
        return prev.map((product) => (product.id === productId ? { ...product, state } : product));
      });
    }
  }, [initiativeId, setProducts, wsMessage]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_PRODUCT_PAGE_VIEWED,
        metaData: { initiativeId },
      },
    ]);
  }, [captureEvents, initiativeId]);

  return (
    <div className={classNames(styles.container, isFading && styles.fade)}>
      {(isLoading || hasProducts) && <BoxedProductHeaderComponent />}
      {isLoading && <TabSectionLoader />}
      {hasPriorities && <PrioritiesBarV2Component />}
      {!isLoading && hasProducts && <ProductsListV2Component />}
      {!isLoading && !hasProducts && <ProductsEmptyViewV2Component />}
      <InitiativeWeightedPrioritiesDrawerComponent />
      <ProductsSnackbarsV2Component />
    </div>
  );
};

export default ProductsTabContentV2Component;
