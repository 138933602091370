import { SaveIndicator } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { EvaluationStateContext } from "../../../../context";
import { customEvents } from "../../../../lib";
import { HeaderV2Context } from "../../header-v2.context";
import styles from "./save-indicator.styles.module.scss";

const SaveIndicatorComponent = () => {
  const { isSavingInitName } = useContext(HeaderV2Context);
  const [isLegacySpinning, setIsLegacySpinning] = useState(false);
  const [overviewEditCount, setOverviewEditCount] = useState(0);
  const [selectionEditCount, setSelectionEditCount] = useState(0);
  const [legacyState] = useContext(EvaluationStateContext);

  useEffect(() => {
    const overviewListener = (event: CustomEvent) => setOverviewEditCount(event.detail.count || 0);
    const selectionListener = (event: CustomEvent) => setSelectionEditCount(event.detail.count || 0);

    window.addEventListener(customEvents.overviewEditCount, overviewListener);
    window.addEventListener(customEvents.selectionEditCount, selectionListener);
    return () => {
      window.removeEventListener(customEvents.overviewEditCount, overviewListener);
      window.removeEventListener(customEvents.selectionEditCount, selectionListener);
    };
  }, []);

  useEffect(() => {
    setIsLegacySpinning(
      !!legacyState.checklist.isErrorLoading ||
        !!legacyState.checklist.isErrorTransaction ||
        !!legacyState.checklist.savingCount ||
        !!legacyState.requirements.error ||
        !!legacyState.requirements.isOutOfSync ||
        !!legacyState.requirements.isProcessing ||
        !!legacyState.requirements.savingCount
    );
  }, [legacyState.checklist, legacyState.requirements]);

  return (
    <SaveIndicator
      isSaving={isLegacySpinning || isSavingInitName || overviewEditCount > 0 || selectionEditCount > 0}
      rootClassName={styles.saveIndicator}
    />
  );
};

export default SaveIndicatorComponent;
