const acceptedFileTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const fileTypes = {
  xlsx: "xlsx",
  xls: "xls",
};

const processSteps = { none: 0, upload: 1, process: 2 };

const libraryDrawerTabIndexes = {
  gartnerLibrary: 0,
  importExport: 1,
  none: -1,
};

export { acceptedFileTypes, fileTypes, libraryDrawerTabIndexes, processSteps };
