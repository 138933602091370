import { useTranslation } from "react-i18next";
import { SquareQuadrantIcon } from "gx-npm-icons";
import styles from "./quadrant-box.module.scss";
import { colorPalette } from "gx-npm-common-styles";
import { TypographyComponent } from "gx-npm-ui";

type QuadrantBoxProps = {
  position: string;
  qdPosition: string;
};

type QDPositionText = {
  [key: string]: string;
};
const QuadrantBox = ({ position, qdPosition }: QuadrantBoxProps) => {
  const { t } = useTranslation();

  const qdPositionText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leaders"),
    "Niche Player": t("Niche players"),
    Visionary: t("Visionaries"),
  };

  const message = qdPositionText[qdPosition];

  return (
    <div>
      {position === qdPosition ? (
        <TypographyComponent
          rootClassName={styles.qdBoxSelected}
          boldness={"medium"}
          color={"gartnerBlue"}
          styling={"p4"}
        >
          {message}
        </TypographyComponent>
      ) : (
        <SquareQuadrantIcon
          letterSpacing="0.35px"
          textFill={colorPalette.neutrals.coal.hex}
          text={qdPositionText[position]}
          fontSize="12px"
          fontWeight="500"
        />
      )}
    </div>
  );
};

export default QuadrantBox;
