import { colorPalette } from "gx-npm-common-styles";

const colorIronVar = `var(${colorPalette.neutrals.iron.name})`;

const vendorIconAndTextLinkStyles = {
  "@global": {
    "& .gx-selected-body-icon-vendor-name-tooltip": {
      "& .MuiTooltip-arrow": {
        left: "56px !important",
      },
    },
  },
  productBrand: {
    alignItems: "center",
    display: "flex",
    "& img, & svg": {
      marginRight: "24px",
      maxHeight: "32px",
      width: "auto",
    },
    "& .gx-survey-screened-out": {
      color: colorIronVar,
    },
    "&.hover": {
      cursor: "pointer",
      "& .gx-survey-not-screened-out": {
        color: colorPalette.interactions.hoverCta.hex,
        backgroundColor: `rgba(180, 205, 247, 0.60)`,
        borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
        textDecoration: "none",
        marginBottom: -1,
      },
    },
  },
  logoWrapper: { height: "32px", width: "56px" },
};

export default vendorIconAndTextLinkStyles;
