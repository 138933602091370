const deleteDialogStyles = {
  root: {
    float: "right",
    "& .btn-tertiary": {
      marginRight: "24px",
    },
  },
};

export { deleteDialogStyles };
