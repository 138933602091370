const emptyVendorListV2Styles = {
  root: {
    alignItems: "center" as const,
    margin: 0,
    paddingTop: "55px",
    textAlign: "center" as const,
    "& $emptyText": {
      padding: "34px 0",
    },
  },
  emptyText: {},
};

export { emptyVendorListV2Styles };
