import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core";
import { KebabDoubleIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { IconButton } from "gx-npm-ui";
import { listItemStyles as styles } from "./styles";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  draggable: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.number,
  isViewOnly: PropTypes.bool,
  isComplete: PropTypes.bool,
  onBlur: PropTypes.func,
  rootClassName: PropTypes.string,
  transparentDragIcon: PropTypes.bool,
};

const useStyles = makeStyles(() => styles);
const ListItem = ({
  children = [],
  draggable = true,
  id = "",
  index = -1,
  isViewOnly = false,
  isComplete = false,
  onBlur = null,
  rootClassName = "",
  transparentDragIcon = false,
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [hover, setHover] = useState(false);

  const handleDragIconMouseDown = () => {
    if (!isViewOnly) {
      setEdit(false);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        setEdit,
        hover,
        onBlur: (...args) => {
          if (onBlur) {
            onBlur(...args);
          }
          if (child.props && child.props.onBlur) {
            child.props.onBlur(...args);
          }
        },
      });
    }
    return child;
  });

  const classes = useStyles();

  const renderList = (provided) => {
    return (
      <li
        className={classNames(
          "gx-dnd-item",
          classes.task,
          isViewOnly && "gx-read-only",
          rootClassName,
          edit && "edit",
          hover && "hover",
          isComplete ? "complete" : "not-complete",
          draggable && !isViewOnly ? "gx-is-draggable" : "gx-is-not-draggable"
        )}
        // TODO need to make a11y with proper func calls
        onBlur={() => handleEvent()}
        onFocus={() => handleEvent()}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...(provided
          ? {
              ref: provided.innerRef,
              ...provided.draggableProps,
            }
          : {})}
        tabIndex="-1"
      >
        {(provided || isViewOnly) && (
          <div
            aria-label={t("drag to reorder")}
            className={classNames("gx-dnd-icon", classes.draggable)}
            onMouseDown={handleDragIconMouseDown}
            role="button"
            tabIndex={0}
            {...(provided && { ...provided.dragHandleProps })}
          >
            <IconButton
              rootClassName={classNames("gx-drag-kebab", hover && !isViewOnly && "gx-visible-kebab")}
              tabIndex={-1}
              transparentBackground={transparentDragIcon}
            >
              <KebabDoubleIcon title="drag" />
            </IconButton>
          </div>
        )}
        {childrenWithProps}
        <div style={{ clear: "both" }} />
      </li>
    );
  };

  if (!draggable || isViewOnly) {
    return renderList();
  } else {
    return (
      <Draggable key={id} draggableId={id.toString()} index={index}>
        {(provided) => renderList(provided)}
      </Draggable>
    );
  }
};

ListItem.propTypes = propTypes;
export default ListItem;
