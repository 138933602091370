import { colorPalette } from "gx-npm-common-styles";

const ExportScorecardIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M21.7071 12.2929C21.8946 12.4804 22 12.7348 22 13L22 22C22 22.5523 21.5523 23 21 23L3 23C2.44772 23 2 22.5523 2 22L2 13C2 12.4477 2.44772 12 3 12L6 12C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14L4 14L4 21L20 21L20 14L18 14C17.4477 14 17 13.5523 17 13C17 12.4477 17.4477 12 18 12L21 12C21.2652 12 21.5196 12.1054 21.7071 12.2929Z"
        fill={colorPalette.interactions.blueBerry.hex}
        fillRule="evenodd"
      />
      <path
        d="M11.2993 13.2332L11.2993 3.86301L9.4993 3.86301C9.0593 3.86301 8.82931 3.32301 9.14931 3.01302L11.9393 0.233015C12.1393 0.0430151 12.4493 0.0430151 12.6493 0.233015L14.9378 3.01302C15.2578 3.32302 15.5393 3.86302 15.0893 3.86302L13.2993 3.86301L13.2993 13.2332C13.2993 13.7832 12.8493 14.2332 12.2993 14.2332C11.7493 14.2332 11.2993 13.7832 11.2993 13.2332Z"
        fill={colorPalette.interactions.blueBerry.hex}
      />
    </svg>
  );
};

export default ExportScorecardIcon;
