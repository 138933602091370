import {
  actionRequirementsTransaction,
  loadRequirementsData,
  updateScoringLevelStatus,
} from "../../context/actions/requirementsActions";
import { operations } from "../../context/actions/operationTypes";
import { ScoringLevel } from "gx-npm-lib";

const root = "requirements";

const reqWsEvents = {
  RELOAD_REQUIREMENTS_DATA: "RELOAD_REQUIREMENTS_DATA",
  REQUIREMENTS_CATEGORY_CREATED: "REQUIREMENTS_CATEGORY_CREATED",
  REQUIREMENTS_CATEGORY_DELETED: "REQUIREMENTS_CATEGORY_DELETED",
  REQUIREMENTS_CATEGORY_UPDATED: "REQUIREMENTS_CATEGORY_UPDATED",
  REQUIREMENTS_ITEM_CREATED: "REQUIREMENTS_ITEM_CREATED",
  REQUIREMENTS_ITEM_DELETED: "REQUIREMENTS_ITEM_DELETED",
  REQUIREMENTS_ITEM_UPDATED: "REQUIREMENTS_ITEM_UPDATED",
  REQUIREMENTS_RECORD_UPDATE: "REQUIREMENTS_RECORD_UPDATE",
};

/**
 * takes a ws message, assuming that the message as already been validated for
 * matching initiativeId, and dispatches any updates to the global state
 * @param {{ requirements:
 *   { list: Array<{
 *     id: string,
 *     itemList: Array<{ id: string }>,
 *   }> }
 * }} state global state
 * @param {Function} dispatch function to dispatch changes through global state context
 * @param {{
 *   categoryId: string,
 *   event: string,
 *   fieldName: string,
 *   fieldValue: string | number,
 *   initiativeId: string,
 *   itemId: string,
 *   scoringLevel: string,
 * }} wsMessage
 */
const interpretRequirementWsMessages = (state, dispatch, wsMessage) => {
  const data = {
    initiativeId: wsMessage.initiativeId,
    noSave: true,
    parentId: wsMessage.categoryId,
  };

  if (wsMessage.event === reqWsEvents.RELOAD_REQUIREMENTS_DATA) {
    loadRequirementsData(null, dispatch, { initiativeId: wsMessage.initiativeId, isReloading: true });
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_CATEGORY_CREATED) {
    data.operation = operations.parentAdd;
    data.parentIndex = state[root].list.length || 0;
    data.id = data.parentId;
    const existingIndex = findParentIndex(state, data.parentId);
    if (existingIndex !== -1) {
      return;
    }
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_CATEGORY_DELETED) {
    data.operation = operations.parentDelete;
    data.parentIndex = findParentIndex(state, data.parentId);
    if (data.parentIndex === -1) {
      return;
    }
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_CATEGORY_UPDATED) {
    data.operation = operations.parentEdit;
    data.key = wsMessage.fieldName;
    data.value = wsMessage.fieldValue;
    data.parentIndex = findParentIndex(state, data.parentId);
    if (data.parentIndex === -1) {
      return;
    }
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_ITEM_CREATED) {
    data.operation = operations.childAdd;
    data.childId = wsMessage.itemId;
    data.id = data.childId;
    data.parentIndex = findParentIndex(state, data.parentId);
    const existingIndex = findChildIndex(state, data.parentIndex, data.childId);
    if (data.parentIndex === -1 || existingIndex !== -1) {
      return;
    }
    data.childIndex = state[root].list[data.parentIndex].itemList.length || 0;
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_ITEM_DELETED) {
    data.operation = operations.childDelete;
    data.childId = wsMessage.itemId;
    data.parentIndex = findParentIndex(state, data.parentId);
    data.childIndex = findChildIndex(state, data.parentIndex, data.childId);
    if (data.childIndex === -1) {
      return;
    }
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_ITEM_UPDATED) {
    data.childId = wsMessage.itemId;
    data.parentIndex = findParentIndex(state, data.parentId);
    data.childIndex = findChildIndex(state, data.parentIndex, data.childId);
    if (data.childIndex === -1) {
      return;
    }
    if (wsMessage.fieldName === "index") {
      data.operation = operations.childReorder;
      data.indexSource = data.childIndex;
      data.indexDest = wsMessage.fieldValue;
    } else {
      data.operation = operations.childEdit;
      data.key = wsMessage.fieldName;
      data.value = wsMessage.fieldValue;
    }
  } else if (wsMessage.event === reqWsEvents.REQUIREMENTS_RECORD_UPDATE) {
    if (Object.values(ScoringLevel).includes(wsMessage.scoringLevel)) {
      const level = { scoringLevel: wsMessage.scoringLevel };
      updateScoringLevelStatus(state, dispatch, level);
    }
    return;
  } else {
    return;
  }

  actionRequirementsTransaction(state, dispatch, data);
};

/**
 * breakdown global requirement state to find parent index from a parent id
 * @param {Object} state
 * @param {string} parentId
 * @return {number}
 */
const findParentIndex = (state, parentId) => {
  return state[root].list.findIndex((x) => x.id === parentId);
};

/**
 * breakdown global requirement state to find child index from a parent id
 * @param {Object} state
 * @param {number} parentIndex
 * @param {number} childId
 * @return {number}
 */
const findChildIndex = (state, parentIndex, childId) => {
  let index = -1;
  if (parentIndex >= 0) {
    index = state[root].list[parentIndex].itemList.findIndex((x) => x.id === childId);
  }
  return index;
};

export { interpretRequirementWsMessages };
