// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tdlmn2ET2NK8ldBBje61{display:flex;justify-content:center;align-items:center;width:85px;height:85px;border:1px solid var(--color-brand-gartner-blue);background:var(--color-white);letter-spacing:.35px;margin-bottom:8px;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/gartner-insights/components/magic-quadrant/components/quadrant-box/quadrant-box.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,gDAAA,CACA,6BAAA,CACA,oBAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".qdBoxSelected {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 85px;\n  height: 85px;\n  border: 1px solid var(--color-brand-gartner-blue);\n  background: var(--color-white);\n  letter-spacing: 0.35px;\n  margin-bottom: 8px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qdBoxSelected": `tdlmn2ET2NK8ldBBje61`
};
export default ___CSS_LOADER_EXPORT___;
