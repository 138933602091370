import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { InitStatus } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import EvaluationPhase from "../../../../assets/images/evaluationPhase.svg";
import SelectionPhase from "../../../../assets/images/selectionPhase.svg";
import SurveyVendors from "../../../../assets/images/surveyVendors.svg";
import ScoreTeam from "../../../../assets/images/scoreTeam.svg";
import { upNextCardV2Styles as styles } from "./up-next-card-v2.styles";
import classNames from "classnames";

const initStateMap: Record<
  string,
  {
    altImgText: string;
    illustration: string;
  }
> = {
  [InitStatus.EVAL_STARTED]: {
    altImgText: "selection report example",
    illustration: SelectionPhase,
  },
  [InitStatus.PLANNING]: {
    altImgText: "scorecard example",
    illustration: EvaluationPhase,
  },
};

type UpNextCardV2Props = {
  initStatus: InitStatus;
};

const useStyles = makeStyles(() => styles);
const UpNextCardV2 = ({ initStatus }: UpNextCardV2Props) => {
  const { t } = useTranslation();
  const { altImgText, illustration } = initStateMap[initStatus];
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.columnWrapper}>
        <div className={classes.leftColumn}>
          <TypographyComponent rootClassName={classNames(classes.title)} boldness={"medium"} styling={"h4"}>
            {initStatus === InitStatus.PLANNING && t("Up next — Evaluate your vendors")}
            {initStatus === InitStatus.EVAL_STARTED && t("Up next — Vendor selection")}
          </TypographyComponent>
          <div>
            {initStatus === InitStatus.PLANNING && (
              <TypographyComponent color={"iron"} styling={"p3"}>
                {t("Evaluate vendors using a questionnaire and/or scorecard.")}
              </TypographyComponent>
            )}
            {initStatus === InitStatus.EVAL_STARTED && (
              <ul className={classes.phaseHighlights}>
                <li>
                  <TypographyComponent rootClassName={classes.phaseHighlightsList} color={"coal"} styling={"p4"}>
                    {t(
                      "Manage your vendor evaluation with a custom scorecard built from your requirements, and scores unique to each vendor."
                    )}
                  </TypographyComponent>
                </li>
                <li>
                  <TypographyComponent color={"coal"} rootClassName={classes.phaseHighlightsList} styling={"p4"}>
                    {t("Invite your team to collaborate on the scorecard and share their feedback.")}
                  </TypographyComponent>
                </li>
              </ul>
            )}
          </div>
        </div>
        {initStatus === InitStatus.PLANNING && (
          <div className={classes.cards}>
            <div className={classes.card}>
              <img alt="" src={SurveyVendors} style={{ marginBottom: "6%" }} />
              <div className={classes.cardDescriptionWrapper}>
                <TypographyComponent boldness={"medium"} styling={"p2"}>
                  {t("Vendor questionnaires")}
                </TypographyComponent>
                <TypographyComponent color={"iron"} rootClassName={classes.cardDescription} styling="p3">
                  {t(
                    "Request information from vendors and view their responses to evaluate vendors inside the application."
                  )}
                </TypographyComponent>
              </div>
            </div>
            <div className={classes.card}>
              <img alt="" src={ScoreTeam} />
              <div className={classes.cardDescriptionWrapper}>
                <TypographyComponent boldness={"medium"} styling={"p2"}>
                  {t("Score with your team")}
                </TypographyComponent>
                <TypographyComponent color={"iron"} rootClassName={classes.cardDescription} styling="p3">
                  {t(
                    "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
                  )}
                </TypographyComponent>
              </div>
            </div>
          </div>
        )}
        {initStatus !== InitStatus.PLANNING && (
          <img alt={altImgText} className={classes.evalStartedImage} src={illustration} />
        )}
      </div>
    </section>
  );
};
export default UpNextCardV2;
