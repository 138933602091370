// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lXIhQ4gmvSBtZ023pEfh{display:flex;margin:0 0 0 auto}.lXIhQ4gmvSBtZ023pEfh .RX5H13eKRnZxvjETfLF2{border:unset;background:unset;cursor:pointer;display:inline-flex;padding:12px 12px 9px 12px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/product-list-row-v3/cells/buttons-cell/buttons/delete-product-button.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CAEA,4CACE,YAAA,CACA,gBAAA,CACA,cAAA,CACA,mBAAA,CACA,0BAAA","sourcesContent":[".deleteButtonWrapper {\n  display: flex;\n  margin: 0 0 0 auto;\n\n  & .deleteButton {\n    border: unset;\n    background: unset;\n    cursor: pointer;\n    display: inline-flex;\n    padding: 12px 12px 9px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteButtonWrapper": `lXIhQ4gmvSBtZ023pEfh`,
	"deleteButton": `RX5H13eKRnZxvjETfLF2`
};
export default ___CSS_LOADER_EXPORT___;
