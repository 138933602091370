import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "gx-npm-ui";
import { Fade } from "../../../../ui/dragAndDropList";
import { MemorizedRequirementListItem } from "../components/requirementListItem";
import { ListItemButtonDelete, ListItemTextArea } from "../../../../ui/dragAndDropList/body";
import { RequirementsPopoverMenu } from "../components/popoverMenu/requirements-popover-menu.component";
import isInViewPortUtilFunc from "./view-port-lib";
import { listContentStyles as styles } from "./styles";

const fadeDurationMs = 500;
const propTypes = {
  addIndex: PropTypes.number,
  autoFocusIndex: PropTypes.number,
  deleteId: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  indexCat: PropTypes.number,
  indexReq: PropTypes.number,
  isAboveReqCountThreshold: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isDragging: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isModified: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  itemListLength: PropTypes.number,
  listHighlightedItemIds: PropTypes.arrayOf(PropTypes.string),
  listIsMenuOpen: PropTypes.arrayOf(PropTypes.bool),
  listSelectedBulkOpIds: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  newReqIndex: PropTypes.number,
  onAutoSave: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  onClearAutoFocus: PropTypes.func,
  onFadeExit: PropTypes.func,
  onOpenDeleteDialog: PropTypes.func,
  onPopoverAutoSave: PropTypes.func,
  onPopoverOpen: PropTypes.func,
  priority: PropTypes.number,
  scrollModulo: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const RequirementsListRowView = ({
  addIndex = NaN,
  autoFocusIndex = NaN,
  deleteId = "",
  description = "",
  id = "",
  indexCat = NaN,
  indexReq = NaN,
  isAboveReqCountThreshold = false,
  isDeleting = false,
  isDragging = false,
  isExpanded = false,
  isModified = false,
  isMenuOpen = false,
  isViewOnly = false,
  itemListLength = NaN,
  listHighlightedItemIds = [],
  listIsMenuOpen = [],
  listSelectedBulkOpIds = [],
  name = "",
  newReqIndex = NaN,
  onAutoSave = (_name, _value, _reqIdx) => {},
  onCheckboxChange = (_event) => {},
  onClearAutoFocus = () => {},
  onFadeExit = (_isFadeIn, _isFadeOut) => {},
  onOpenDeleteDialog = (_reqId, _reqIdx) => {},
  onPopoverAutoSave = (_name, _value, _reqIdx) => {},
  onPopoverOpen = (_isOpen, _reqIdx) => {},
  priority = NaN,
  scrollModulo = NaN,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isInViewPort, setIsInViewPort] = useState(false);

  const ariaCheckbox = `${t("Select")} ${name} + ${t("for bulk operation")}`;
  const isAdding = addIndex === indexCat && newReqIndex === indexReq;
  const isLastItemIndex = [0, indexReq + 1].indexOf(itemListLength) > -1;
  const isFadeIn = addIndex === indexCat && isLastItemIndex;
  const isFadeOut = !isFadeIn && isDeleting && id === deleteId;

  useEffect(() => {
    if (!isAboveReqCountThreshold || !ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    let isCurrentInViewPort = isInViewPortUtilFunc(rect);
    setIsInViewPort(isCurrentInViewPort);
  }, [isAboveReqCountThreshold, ref, scrollModulo]);

  useEffect(() => {
    if (!isAboveReqCountThreshold || !ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    let isCurrentInViewPort = isInViewPortUtilFunc(rect);
    setIsInViewPort(isCurrentInViewPort);
  }, [isAboveReqCountThreshold, isExpanded, ref]);

  const handleClickDelete = () => {
    onOpenDeleteDialog(id, indexReq);
  };

  const handleExitFade = () => {
    onFadeExit(isFadeIn, isFadeOut);
  };

  const handleBlurTextName = () => {
    onClearAutoFocus();
    handleBlurText();
  };

  const handleBlurText = () => {
    setIsFocused(false);
  };

  const handleFocusText = () => {
    setIsFocused(true);
  };

  const handleOpenPriorityPopover = (isOpen) => {
    onPopoverOpen(isOpen, indexReq);
  };

  const handleSaveDescription = (val) => {
    onAutoSave("description", val, indexReq);
  };

  const handleSaveName = (val) => {
    onAutoSave("name", val, indexReq);
  };

  const handleSavePriority = (event) => {
    onPopoverAutoSave(event?.name, event?.value, indexReq);
  };

  const isSkeleton = isAboveReqCountThreshold && !isInViewPort && !isFocused;
  return (
    !isAdding && (
      <div ref={ref}>
        {isSkeleton && <div className={classNames("gx-req-row-skeleton", classes.skeletonClass)} />}
        {!isSkeleton && (
          <Fade
            key={id || indexReq}
            duration={fadeDurationMs}
            fadeOut={isFadeOut}
            fadeIn={isFadeIn}
            onExit={handleExitFade}
          >
            <MemorizedRequirementListItem
              draggable={true}
              id={id}
              index={indexReq}
              isViewOnly={isViewOnly}
              rootClassName={classNames("root-list-item", listHighlightedItemIds.includes(id) && "highlighted-item")}
              _checked={listSelectedBulkOpIds.includes(id)}
              _description={description}
              _isModified={isModified}
              _isOpen={isMenuOpen}
              _name={name}
              _priority={priority}
            >
              {isModified && <div className="gx-item-modified" />}
              {!isViewOnly && (
                <Checkbox
                  checked={listSelectedBulkOpIds.includes(id)}
                  inputProps={{ "aria-label": ariaCheckbox }}
                  onChange={onCheckboxChange}
                  rootClassName={classNames("bulkCheckbox")}
                  value={id}
                />
              )}
              <ListItemTextArea
                autoFocus={autoFocusIndex === indexCat && isLastItemIndex}
                handleAutoSave={handleSaveName}
                isDragging={isDragging}
                isIndexMenuOpen={listIsMenuOpen[indexReq]}
                isMenuOpen={isMenuOpen}
                isViewOnly={isViewOnly}
                maxAllowedChars={0}
                onBlur={handleBlurTextName}
                onFocus={handleFocusText}
                placeholder={t("Enter name")}
                rootBaseClassName="gx-req-text-area"
                rootClassName="req-text-name-field"
                type="requirements"
                value={name}
              />
              <ListItemTextArea
                handleAutoSave={handleSaveDescription}
                isDragging={isDragging}
                isIndexMenuOpen={listIsMenuOpen[indexReq]}
                isMenuOpen={isMenuOpen}
                isViewOnly={isViewOnly}
                maxAllowedChars={0}
                placeholder={t("Enter description")}
                onBlur={handleBlurText}
                onFocus={handleFocusText}
                rootBaseClassName="gx-req-text-area"
                rootClassName="req-text-desc-field"
                type="requirements"
                value={description}
              />

              <RequirementsPopoverMenu
                handleAutoSave={handleSavePriority}
                handlePopoverOpen={handleOpenPriorityPopover}
                isViewOnly={isViewOnly}
                name="priority"
                value={priority}
              />
              {isViewOnly && <div className="button-filler" />}
              {!isViewOnly && (
                <ListItemButtonDelete
                  isAlwaysInDom={true}
                  onClick={handleClickDelete}
                  rootClassName={classNames(classes.deleteButton, classes.flexChild, "gx-req-delete-button")}
                />
              )}
            </MemorizedRequirementListItem>
          </Fade>
        )}
      </div>
    )
  );
};

RequirementsListRowView.propTypes = propTypes;
export default RequirementsListRowView;
