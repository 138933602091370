import { colorPalette, weightPalette } from "gx-npm-common-styles";

const gartnerLibraryBodyStyles = {
  container: {
    paddingTop: 48,
    "& $list": {
      margin: 0,
      padding: 0,
      "& li": {
        padding: 0,
        listStyleType: "none",
        borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
        "&:hover": {
          backgroundColor: colorPalette.interactions.paleBerry.hex,
        },
        "& .gx-req-btn-wrapper": {
          display: "flex",
          padding: "24px 28px",
        },
        "& .gx-req-btn-wrapper:hover": {
          cursor: "pointer",
        },
        "& $listItemLeft": {
          marginRight: 75,
          width: 527,
          "& $description": {
            color: colorPalette.neutrals.iron.hex,
          },
          "& $title": {
            paddingBottom: 12,
          },
        },
        "& $listItemRight": {
          marginLeft: "auto",
          paddingRight: 28,
          whiteSpace: "nowrap",
          "& $reqCount": {
            fontVariationSettings: weightPalette.semiBold.variation,
            fontWeight: weightPalette.semiBold.amount,
          },
        },
      },
    },
  },
  description: {},
  list: {},
  listItemLeft: {},
  listItemRight: {},
  reqCount: {},
  title: {},
};

export { gartnerLibraryBodyStyles };
