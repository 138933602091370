import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import { ButtonLoader, TextField } from "gx-npm-ui";
import styles from "./add-survey-team-member-form.styles";
import { makeStyles } from "@material-ui/core/styles";
import { validate as emailValidator } from "email-validator";

const useStyles = makeStyles(styles);

const proptypes = {
  error: propTypes.string,
  recipients: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      fullName: propTypes.string,
      email: propTypes.string,
      hasViewed: propTypes.bool,
    })
  ),
  isLoading: propTypes.bool,
  onButtonClick: propTypes.func,
  sendInviteSuccessful: propTypes.bool,
  addMemberSuccessful: propTypes.string,
  addMemberFailure: propTypes.string,
};
const AddSurveyTeamMemberForm = ({
  error = "",
  recipients = [],
  isLoading,
  onButtonClick = (_email) => {},
  addMemberSuccessful = "",
  addMemberFailure = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const inputRef = useRef(null);

  const notCompanyMsg = t(
    "Use a company-issued email address. Gmail, Yahoo, and other free email services are not accepted."
  );
  const alreadyAddedMsg = t("This email address has already been added.");

  const handleValueUpdate = useCallback(
    (val) => {
      setIsDuplicateEmail(false);
      setIsError(false);
      setErrorMsg("");
      setValue(val);
      const validEmailCheck = emailValidator(val);
      setIsValidEmail(validEmailCheck);
      for (const memberIdx of Object.keys(recipients)) {
        const email = recipients[memberIdx].email;
        setIsError(false);
        if (email.toLowerCase() === val.toLowerCase()) {
          setIsDuplicateEmail(true);
          setIsError(true);
          setIsValidEmail(false);
          break;
        }
      }
    },
    [recipients]
  );

  useEffect(() => {
    if (isDuplicateEmail) {
      setIsError(true);
      setErrorMsg(alreadyAddedMsg);
    } else {
      if (addMemberFailure === "INVALID_EMAIL") {
        setIsError(true);
        setErrorMsg(notCompanyMsg);
      }
    }
  }, [addMemberFailure, isError, isDuplicateEmail, alreadyAddedMsg, notCompanyMsg]);

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    if (addMemberSuccessful !== "") {
      handleValueUpdate("");
    }
  }, [handleValueUpdate, addMemberSuccessful]);

  useEffect(() => {
    if (error === "INVALID_EMAIL") {
      setIsError(true);
    }
  }, [error, isError]);

  const handleInputChange = (e) => {
    const emailInput = e.target.value;

    handleValueUpdate(emailInput.trim());
  };

  return (
    <>
      <div className={classes.addUserForm}>
        <TextField
          rootClassName={classes.addUserInput}
          inputRef={inputRef}
          placeholder="Email address"
          label="Email address"
          isError={isError}
          onChange={handleInputChange}
          helperText={errorMsg}
          maxAllowedChars={100}
        />
        <ButtonLoader
          btnClass="btn-primary"
          disabled={!isValidEmail || isError}
          handleButtonClick={() => onButtonClick(value)}
          isLoading={isLoading}
        >
          {t("Send")}
        </ButtonLoader>
      </div>
    </>
  );
};

AddSurveyTeamMemberForm.propTypes = proptypes;
export default AddSurveyTeamMemberForm;
