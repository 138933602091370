import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { scoringTitleStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const ScoringLevelTitle = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <TypographyComponent styling="h3" boldness="medium">
        {t("Set the scoring level")}
      </TypographyComponent>
      <TypographyComponent styling={"p4"} color={"iron"} rootClassName={classes.description}>
        {t(
          "For quicker evaluations, score at the category level. For more in-depth evaluations, score at the requirement level. You’ll have the opportunity to change this once you’ve started the evaluation."
        )}
      </TypographyComponent>
    </Fragment>
  );
};

export default ScoringLevelTitle;
