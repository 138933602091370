import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Loader, ProductLogo, SlideInOverlay, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import styles from "./survey-team-management-v2.styles.module.scss";
import { getAsyncRequest, useCaptureEventsV2, postAsyncRequest } from "gx-npm-lib";
import AddSurveyTeamMemberForm from "./components/add-survey-team-member-form.component";
import SurveyTeamList from "./components/survey-team-list.component";
import { getApiUrl } from "../../../context/actions/actionUtils";
import { ClientEvent } from "../../../app.constants";

const BANNER_DELAY_MS = 3000;

type Vendor = {
  id: string;
  imageLoc: string;
  name: string;
};

interface SurveyTeamManagementSliderProps {
  isOpen: boolean;
  onClose: () => void;
  vendors: Vendor[];
  initiativeId?: string;
  surveyId: string;
  reloadSurveyData: () => void;
}

const SurveyTeamManagementSlider: React.FC<SurveyTeamManagementSliderProps> = ({
  isOpen = false,
  onClose = () => {},
  vendors = [],
  initiativeId = "",
  surveyId = "",
  reloadSurveyData = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [recipients, setRecipients] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [addMemberSuccess, setAddMemberSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [resendEmailSuccess, setResendEmailSuccess] = useState("");
  const [loadList, setLoadList] = useState(true);
  const captureEvents = useCaptureEventsV2();

  const fetchRecipients = useCallback(
    async (vendorId) => {
      setIsLoadingList(true);
      try {
        const response = await getAsyncRequest(
          `/api/v2/initiatives/${initiativeId}/survey-team/${surveyId}/${vendorId}`
        );
        if (response.status === 200) {
          setRecipients(response.data?.data || []);
        } else {
          throw new Error();
        }
      } catch {
        setIsError(true);
        setRecipients([]);
      } finally {
        setIsLoadingList(false);
      }
    },
    [initiativeId, surveyId]
  );

  useEffect(() => {
    setSelectedTab(0);
    if (isOpen && !hasFetched && initiativeId && surveyId && loadList && vendors[0]?.id) {
      fetchRecipients(vendors[0].id);
      setHasFetched(true);
    }

    if (!isOpen) {
      setHasFetched(false);
    }
  }, [isOpen, hasFetched, initiativeId, surveyId, vendors, loadList, fetchRecipients]);

  const handleChange = useCallback(
    (newValue) => {
      setSelectedTab(newValue);
      fetchRecipients(vendors[newValue].id);
      setTimeout(() => {
        setAddMemberSuccess("");
      }, 3000);
    },
    [fetchRecipients, vendors]
  );

  const handleAction = async (url: string, email: string, successCallback: (email: string) => void) => {
    setIsLoadingAction(true);
    try {
      const response = await postAsyncRequest(url, { email });
      if (response.status === 201) {
        successCallback(email);
      } else {
        throw new Error(response?.data?.message || "Action failed");
      }
    } catch (err) {
      setIsError(true);
      setErrorMsg(err.message);
      setTimeout(() => setErrorMsg(""), BANNER_DELAY_MS);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleAddMember = (email: string) => {
    const url = `api/v3/initiatives/${initiativeId}/survey-team/${surveyId}/${vendors[selectedTab].id}/add`;
    handleAction(url, email, () => {
      setAddMemberSuccess(email);
      setTimeout(() => setAddMemberSuccess(""), BANNER_DELAY_MS);
      setLoadList(true);
      fetchRecipients(vendors[selectedTab].id);
      reloadSurveyData();
      const metaData = {
        initiativeId,
        surveyId,
        initProductId: vendors[selectedTab].id,
        productName: vendors[selectedTab].name,
      };
      captureEvents([{ eventType: ClientEvent.INITIATIVE_SURVEY_SENT, metaData }]);
      setTimeout(() => {
        setAddMemberSuccess("");
      }, 3000);
    });
  };

  const handleRemoveTeamMember = (email?: string) => {
    const url = `api/v2/initiatives/${initiativeId}/survey-team/${surveyId}/${vendors[selectedTab].id}/remove`;
    if (email) {
      handleAction(url, email, () => {
        setLoadList(true);
        fetchRecipients(vendors[selectedTab].id);
        reloadSurveyData();
      });
    }
  };

  const handleResendInvite = (email?: string) => {
    const url = getApiUrl(initiativeId, `survey-team/${surveyId}/${vendors[selectedTab].id}/resend-notification`);
    if (email) {
      handleAction(url, email, () => {
        setResendEmailSuccess(email);
        setTimeout(() => setResendEmailSuccess(""), BANNER_DELAY_MS);
      });
    }
  };

  return (
    <SlideInOverlay
      rootClassName={styles.recipientManageSlideIn}
      isOpen={isOpen}
      textBackBtn="questionnaire"
      onClose={onClose}
    >
      <div>
        <TypographyComponent color="carbon" boldness="medium" styling="h3">
          {t("Manage questionnaire recipients")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.recipientPrargraph} color="coal" styling="p3" boldness="regular">
          {t(
            "Send questions to your vendor contacts. You can add or remove contacts at any time. Vendor responses in BuySmart are not reviewed by Gartner Experts and do not necessarily represent the views or opinions of Gartner."
          )}
        </TypographyComponent>
      </div>

      <div className={classNames(styles.tabsContainer)}>
        <Tabs
          TabIndicatorProps={{ className: styles.tabIndicator }}
          orientation="vertical"
          value={selectedTab}
          onChange={(event, newValue) => handleChange(newValue)}
          className={classNames(styles.tabs)}
        >
          {vendors?.map((vendorData, index) => (
            <Tab
              disableRipple
              disableFocusRipple
              className={styles.tabLabel}
              key={`tab-label-${vendorData.name}`}
              label={
                <div className={styles.tabContent}>
                  <ProductLogo
                    rootClassName={styles.tabIcon}
                    imageHeight="24px"
                    imageWidth="24px"
                    logo={vendorData.imageLoc}
                    name={vendorData.name}
                  />

                  <TypographyComponent color={selectedTab === index ? "yam" : "coal"} styling="p4" boldness="medium">
                    {vendorData.name}
                  </TypographyComponent>
                </div>
              }
            />
          ))}
        </Tabs>
        <div>
          <AddSurveyTeamMemberForm
            recipients={recipients}
            isLoading={isLoadingAction}
            addMemberSuccessful={addMemberSuccess}
            addMemberFailure={errorMsg}
            onButtonClick={handleAddMember}
          />
          {isLoadingList && (
            <div className={styles.loaderClass}>
              <Loader size={42} variant="indeterminate" />
            </div>
          )}
          {!isLoadingList && (
            <SurveyTeamList
              recipients={recipients}
              onRemoveTeamMember={handleRemoveTeamMember}
              onResendInvite={handleResendInvite}
              resendEmailSuccess={resendEmailSuccess}
              addEmailSuccess={addMemberSuccess}
            />
          )}
        </div>
        <SnackbarBanner isOpen={isError} isDefaultErrorMessage type="ERROR" setIsOpen={setIsError} />
      </div>
    </SlideInOverlay>
  );
};

export default SurveyTeamManagementSlider;
