import { defaultToEmptyArray, defaultToEmptyObject, defaultToZero } from "gx-npm-lib";
import { checklistTypes } from "../actions/actionTypes";

/**
 * @var failures array of API save transactions objects that failed when on last attempt to save
 * @var isErrorLoading indicates an error occured either on load
 * @var isErrorTransaction indicates a failure has occured either on save - cleared after user is informed
 * @var list existing checklist phases/tasks
 * @var savingCount current number of API save requests waiting for response
 */
const checklistState = {
  failures: [],
  isErrorLoading: false,
  isErrorTransaction: false,
  list: [],
  savingCount: 0,
};

const root = "checklist";
const checklistReducer = (state, action) => {
  let newState;
  let temp = null;
  const { childIndex, indexDest, indexSource, parentIndex } = action || {};
  const { key, type, value } = action || {};
  switch (type) {
    case checklistTypes.CLEAR_CHECKLIST_DATA:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].error = null;
      newState[root].failures = [];
      newState[root].isErrorTransaction = false;
      break;
    case checklistTypes.LOAD_CHECKLIST_DATA:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].error = action.error ? { loading: true, ...defaultToEmptyObject(action.error) } : null;
      newState[root].isErrorLoading = !!action.error;
      newState[root].isLoading = !!action.isLoading;
      newState[root].list = [...defaultToEmptyArray(action.list)];
      break;
    case checklistTypes.CHECKLIST_ADD_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[parentIndex]),
      };
      newState[root].list[parentIndex].taskList = defaultToEmptyArray(newState[root].list[parentIndex].taskList);
      newState[root].list[parentIndex].taskList.push(action.item);
      break;
    case checklistTypes.CHECKLIST_DELETE_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[parentIndex]),
      };
      newState[root].list[parentIndex].taskList = defaultToEmptyArray(newState[root].list[parentIndex].taskList);
      newState[root].list[parentIndex].taskList.splice(childIndex, 1);
      break;
    case checklistTypes.CHECKLIST_EDIT_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[parentIndex]),
      };
      newState[root].list[parentIndex].taskList = defaultToEmptyArray(newState[root].list[parentIndex].taskList);
      newState[root].list[parentIndex].taskList[childIndex][key] = value;
      break;
    case checklistTypes.CHECKLIST_EDIT_PARENT:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[parentIndex]),
      };
      newState[root].list[parentIndex][key] = value;
      break;
    case checklistTypes.CHECKLIST_REORDER_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[parentIndex]),
      };
      newState[root].list[parentIndex].taskList = defaultToEmptyArray(newState[root].list[parentIndex].taskList);
      if (newState[root].list[parentIndex].taskList.findIndex((x) => x.id === action.childId) === indexDest) {
        break;
      }
      temp = newState[root].list[parentIndex].taskList.splice(indexSource, 1);
      newState[root].list[parentIndex].taskList.splice(indexDest, 0, temp[0]);
      break;
    // granular saving types
    case checklistTypes.CHECKLIST_SAVE_DATA_START:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].savingCount = defaultToZero(newState[root].savingCount) + 1;
      break;
    case checklistTypes.CHECKLIST_SAVE_DATA_SUCCESS:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].isErrorTransaction = newState[root].failures?.length > 0;
      break;
    case checklistTypes.CHECKLIST_SAVE_DATA_ERROR:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].failures = defaultToEmptyArray(newState[root].failures);
      newState[root].failures.push(action.transaction);
      newState[root].isErrorTransaction = true;
      newState[root].error = { isNotEditable: action.isNotEditable };
      break;
    case checklistTypes.CHECKLIST_SAVE_DATA_COMPLETE:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].savingCount = defaultToZero(newState[root].savingCount) - 1;
      break;
    case checklistTypes.CHECKLIST_CLEAR_FAILURES:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].failures = [];
      newState[root].isErrorTransaction = false;
      newState[root].error = null;
      break;
    case checklistTypes.CHECKLIST_REMOVE_ASSIGNMENTS:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list?.map((listItem) => {
        listItem?.taskList?.map((task) => {
          task.assignedUsers = task?.assignedUsers?.filter((user) => user?.email !== value);
          return task;
        });
      });
      break;
    default:
      newState = state;
  }
  return newState;
};

export { checklistState, checklistReducer };
