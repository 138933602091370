import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
import classNames from "classnames";
import { FileTypePdfIcon } from "gx-npm-icons";
import { FreeTrialRole, getAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../../../app.constants";
import { SelectionContext } from "../../selection.context";
import SelectedVendor from "./components/selected-vendor/selected-vendor.component";
import InitiativeObjective from "./components/initiative-objective/initiative-objective.component";
import InitiativeDetailsCard from "./components/initiative-details-card/initiative-details-card.component";
import EvaluationSummary from "./components/evaluation-summary/evaluation-summary.component";
import EvaluationDetails from "./components/evaluation-details/evaluation-details.component";
import GartnerInsights from "./components/gartner-insights/gartner-insights.component";
import styles from "./selection-report.module.scss";
import { GCOM_3755__vendorSelections } from "../../../../lib/feature-flags";

const SelectionReport = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { initiativeId, requirementCategories } = useContext(SelectionContext);
  const captureEvents = useCaptureEventsV2();
  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  const handleClickPDFDownload = async () => {
    setIsLoading(true);
    const response = await getAsyncRequest(`api/v3/pdf/generate-report?initId=${initiativeId}`);
    if (response?.status === 200 && response.data?.data?.url) {
      const link = document.createElement("a");
      link.href = response.data.data.url;
      link.click();
    } else {
      setIsError(true);
    }
    setIsLoading(false);
    captureEvents([{ eventType: ClientEvent.INITIATIVE_SELECTION_REPORT_DOWNLOADED, metaData: { initiativeId } }]);
  };

  const handleCloseSnackbar = () => {
    setIsError(false);
  };

  return (
    <Fragment>
      <div className={styles.exportPdf}>
        <TypographyComponent styling="h3" boldness={"medium"}>
          {t("Selection summary")}
        </TypographyComponent>
        <TooltipV2
          deactivate={!isTrialUser}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
          title={t("Exporting is not available in the Gartner BuySmart free trial.")}
        >
          <div className={styles.btnWrapper}>
            <MiniButton
              disabled={isTrialUser}
              isSvgAffected={false}
              onClick={handleClickPDFDownload}
              rootClassName="exportPdf"
              variant="default"
            >
              {isLoading && <MuiCircularProgress className={styles.loader} size={20} />}
              {!isLoading && <FileTypePdfIcon />}
              <span>{` ${t("Export as PDF")}`}</span>
            </MiniButton>
          </div>
        </TooltipV2>
      </div>
      <div className={styles.initiativeInfo}>
        <div className={styles.vendorInfo}>
          <SelectedVendor />
          <InitiativeObjective />
        </div>
        <div className={classNames(styles.vendorInfo, styles.initDetails)}>
          <InitiativeDetailsCard />
        </div>
      </div>
      <div className={styles.evaluationInfo}>
        <EvaluationSummary />
      </div>
      <div className={styles.evaluationInfo}>
        <FeatureFlagBooleanContainer flagName={GCOM_3755__vendorSelections}>
          <FeatureFlagBooleanOn>{requirementCategories?.length > 0 && <EvaluationDetails />}</FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <EvaluationDetails />
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={styles.evaluationInfo}>
        <GartnerInsights />
      </div>
      <SnackbarBanner
        isOpen={isError}
        message={t("We were unable to download your PDF")}
        setIsOpen={handleCloseSnackbar}
      />
    </Fragment>
  );
};

export default SelectionReport;
