import { weightPalette } from "gx-npm-common-styles";

const radioButtonOptionStyles = {
  countWrapper: {
    left: "32px",
    position: "relative",
  },
  countText: {
    fontVariationSettings: `${weightPalette.regular.variation}`,
    fontWeight: `${weightPalette.regular.amount}`,
  },
};

export default radioButtonOptionStyles;
