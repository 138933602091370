import { useWebSocket, UUID } from "gx-npm-lib";
import { Button, Dialog, TypographyComponent } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { WebSocketMessageEvent } from "../../../../app.constants";

type WsInMsgRevokeAccess = {
  event: WebSocketMessageEvent;
  initiativeId: UUID;
  name: string;
};

const InitAccessRevokedDialogComponent = () => {
  const { initiativeId } = useParams();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [wsMessage] = useWebSocket<WsInMsgRevokeAccess, unknown>();

  useEffect(() => {
    if (!initiativeId || !wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.INITIATIVE_ACCESS_REVOKED) {
      return;
    }
    setIsOpen(true);
    setUsername(wsMessage.name);
  }, [initiativeId, wsMessage]);

  const handleClick = () => {
    setIsOpen(false);
    singleSpa.navigateToUrl("/s/evaluations");
  };
  return (
    <Dialog
      body={
        <TypographyComponent styling="p3">
          {username}
          {` `}
          {t("has revoked your access to edit and view this evaluation.")}
        </TypographyComponent>
      }
      footer={
        <Button rootClassName="btn-primary" onClick={handleClick}>
          {t("BACK TO MY EVALUATIONS")}
        </Button>
      }
      open={isOpen}
      title={t("You no longer have access to this evaluation")}
      variant="dialog"
    />
  );
};

export default InitAccessRevokedDialogComponent;
