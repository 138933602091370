const bulkOpsDialogClasses = {
  descriptionContainer: {
    paddingBottom: "56px",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& button:last-child": {
      marginLeft: "12px",
    },
  },
};

export { bulkOpsDialogClasses };
