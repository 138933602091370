import { MQChallengersIcon, MQLeadersIcon, MQNichePlayersIcon, MQVisionariesIcon } from "gx-npm-icons";
import { GcomDocType } from "gx-npm-lib";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { QuadPosition } from "./quadrant-cell.constants";
import styles from "./quadrant-cell.styles.module.scss";

type QDPositionText = { [key: string]: string };
type QuadrantCellProps = { docType: GcomDocType | ""; position: QuadPosition | "" };
const QuadrantCellComponent = ({ position = QuadPosition.NONE, docType = "" }: QuadrantCellProps) => {
  const { t } = useTranslation();
  const mqPositionText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leader"),
    "Niche Player": t("Niche Player"),
    Visionary: t("Visionary"),
  };
  const emergingMarketQuadPositionText: QDPositionText = {
    Challenger: t("Emerging Challengers"),
    Leader: t("Emerging Leader"),
    "Niche Player": t("Emerging Specialist"),
    Visionary: t("Emerging Visionary"),
  };

  let toolTip = t(`This vendor is not positioned in the Magic Quadrant for this technology.`);

  if (docType === GcomDocType.EMERGING_MARKET_QUAD) {
    toolTip = t(`This vendor is not positioned in the Emerging Market Quadrant for this technology.`);
  }

  if (position === QuadPosition.LEADER) {
    toolTip = t(
      `Leaders provide mature offerings to meet wide market demand and invest to sustain their position. They typically have large, satisfied customer bases (relative to the size of the market) and should be able to remain viable in a challenging economy.`
    );
  } else if (position === QuadPosition.CHALLENGER) {
    toolTip = t(
      `Challengers have a strong current offering but may not have a plan to maintain this. Vendors in mature markets may be positioned as Challengers because they choose to avoid disrupting established customers or may lack strong vision, innovation, or an overall understanding of market needs.`
    );
  } else if (position === QuadPosition.VISIONARY) {
    toolTip = t(
      `Visionaries fall into the higher-risk-higher-reward category and align with how a market will evolve. They often introduce new technology, services, or business models, but could be building financial clout and operational capabilities.`
    );
  } else if (position === QuadPosition.NICHE_PLAYER) {
    if (docType === GcomDocType.EMERGING_MARKET_QUAD) {
      toolTip = t(
        `Specialists may specialize on a particular functionality, market segment, industry sector or region, or they may have a limited ability to innovate or outperform others in the market.`
      );
    } else {
      toolTip = t(
        `Niche Players may specialize on a particular functionality, market segment, industry sector or region, or they may have a limited ability to innovate or outperform others in the market.`
      );
    }
  }

  return (
    <TooltipV2 placement="top" PopperProps={{ modifiers: { offset: { offset: "0, 16px" } } }} title={toolTip}>
      <div
        aria-label={
          docType === GcomDocType.MAGIC_QUAD ? t("Magic Quadrant position") : t("Emerging Market Quadrant position")
        }
        className={styles.mqWrapper}
      >
        {position === QuadPosition.LEADER && <MQLeadersIcon />}
        {position === QuadPosition.CHALLENGER && <MQChallengersIcon />}
        {position === QuadPosition.VISIONARY && <MQVisionariesIcon />}
        {position === QuadPosition.NICHE_PLAYER && <MQNichePlayersIcon />}
        {(position === QuadPosition.NONE || !position) && (
          <TypographyComponent boldness={"medium"} styling={"p4"}>
            {`-`}
          </TypographyComponent>
        )}
        <div>
          {docType === GcomDocType.MAGIC_QUAD && (
            <TypographyComponent element={"span"} color={"iron"} boldness={"medium"} styling={"p4"}>
              {position && position !== QuadPosition.NONE ? mqPositionText[position] : t("None")}
            </TypographyComponent>
          )}
          {docType === GcomDocType.EMERGING_MARKET_QUAD && (
            <TypographyComponent element={"span"} color={"iron"} boldness={"medium"} styling={"p4"}>
              {position && position !== QuadPosition.NONE ? emergingMarketQuadPositionText[position] : t("None")}
            </TypographyComponent>
          )}
        </div>
      </div>
    </TooltipV2>
  );
};

export default QuadrantCellComponent;
