import { isValidResponse, RequirementsState } from "gx-npm-lib";
import { requirementTypes } from "./actionTypes";
import { generateUuid } from "../../lib";
import {
  getApiUrl,
  isValidTransactionResponse,
  processGetResponse,
  transactionPayload,
  transactionUniqueArray,
  transactionUrl,
} from "./actionUtils";
import { deleteRequest, getRequest, postRequest } from "./apiRequests";
import { isAddOp, isDeleteOp, isEditOp, isReorderOp, operations } from "./operationTypes";

const childName = "item";
const parentName = "category";
const section = "requirements";

const messages = {
  buttonPublish: "GO TO EVALUATION",
  buttonRepublish: "GO TO YOUR EVALUATION",
  errorPublish: "Oops... something went wrong. Please try again.",
  successPublish: "Your requirements have been published.",
  successRepublish: "Your scorecard has been updated.",
  successRevert: "Your requirements changes have been reverted.",
};

/**
 * transaction requests for checklist, based on data objects variables, will update
 * state, dispatch changes to reducer, and make API requests
 *
 * @param {Object} state current state when request was made
 * @param {Function} dispatch function to fire event to reducer
 * @param {{
 *   childId: string,
 *   childIndex: number,
 *   indexDest: number,
 *   indexSource: number,
 *   initiativeId: string,
 *   key: string,
 *   operation: string,
 *   parentId: string,
 *   parentIndex: number,
 *   value: string | number,
 * }} data information for request - the transaction object
 */
const actionRequirementsTransaction = (state, dispatch, data) => {
  switch (data?.operation) {
    case operations.childAdd:
    case operations.parentAdd:
      actionRequirementAddItem(state, dispatch, data);
      break;
    case operations.childDelete:
    case operations.parentDelete:
      actionRequirementDeleteItem(state, dispatch, data);
      break;
    case operations.childEdit:
    case operations.parentEdit:
      actionRequirementEditItem(state, dispatch, data);
      break;
    case operations.childReorder:
      actionRequirementReorderItem(state, dispatch, data);
      break;
    default:
    // do nothing
  }
};

const getDefaultItem = (isChild, id) => {
  const obj = {
    id: id || generateUuid(),
    name: "",
  };
  if (isChild) {
    obj.description = "";
    obj.priority = 3;
  } else {
    obj.itemList = [];
    obj.weight = 0;
  }
  return obj;
};

const actionRequirementAddItem = (state, dispatch, data) => {
  const { childIndex, id, initiativeId, parentId, parentIndex } = data;
  const isModified = state.requirements.state !== RequirementsState.DRAFT;
  const reqState = isModified ? RequirementsState.INEDIT : state.requirements.state;
  const typeAdd =
    !!parentId && childIndex > -1 ? requirementTypes.REQUIREMENT_ADD_CHILD : requirementTypes.REQUIREMENT_ADD_PARENT;
  const isChild = !!parentId && parentId !== id;
  dispatch({
    childIndex,
    initiativeId,
    isModified,
    item: getDefaultItem(isChild, id),
    parentId,
    parentIndex,
    state: reqState,
    type: typeAdd,
  });
  const saveData = { ...data, isModified, state: reqState };
  actionSavePendingTransactions(state, dispatch, saveData);
};

const actionRequirementDeleteItem = (state, dispatch, data) => {
  const { childId, childIndex, initiativeId, parentId, parentIndex } = data;
  const isModified = state.requirements.state !== RequirementsState.DRAFT;
  const reqState = isModified ? RequirementsState.INEDIT : state.requirements.state;
  const typeDelete = childId ? requirementTypes.REQUIREMENT_DELETE_CHILD : requirementTypes.REQUIREMENT_DELETE_PARENT;
  dispatch({
    childIndex,
    initiativeId,
    isModified,
    parentId,
    parentIndex,
    state: reqState,
    type: typeDelete,
  });
  actionSavePendingTransactions(state, dispatch, data);
};

const actionRequirementEditItem = (state, dispatch, data) => {
  const { childId, childIndex, parentId, parentIndex } = data;
  const { initiativeId, key, value } = data;
  const isModified = state.requirements.state !== RequirementsState.DRAFT;
  const reqState = isModified ? RequirementsState.INEDIT : state.requirements.state;
  const typeEdit = childId ? requirementTypes.REQUIREMENT_EDIT_CHILD : requirementTypes.REQUIREMENT_EDIT_PARENT;
  dispatch({
    childId,
    childIndex,
    key,
    initiativeId,
    isModified,
    parentId,
    parentIndex,
    state: reqState,
    type: typeEdit,
    value,
  });
  actionSavePendingTransactions(state, dispatch, data);
};

const actionRequirementReorderItem = (state, dispatch, data) => {
  const { childId, childIndex, parentId, parentIndex } = data;
  const { indexDest, indexSource, initiativeId } = data;
  const typeReorderChild = requirementTypes.REQUIREMENT_REORDER_CHILD;
  const isModified = state.requirements.state !== RequirementsState.DRAFT;
  const reqState = isModified ? RequirementsState.INEDIT : state.requirements.state;
  dispatch({
    childId,
    childIndex,
    indexDest,
    indexSource,
    initiativeId,
    isModified,
    parentId,
    parentIndex,
    state: reqState,
    type: typeReorderChild,
  });
  actionSavePendingTransactions(state, dispatch, data);
};

/**
 * creates a list of transactions based on any existing failed transactions that
 * did not return 2xx from API, removes any possible colluisions, and sends out
 * all API requests
 *
 * @param {*} state current api state
 * @param {*} dispatch function to comm with reducer
 * @param {*} data transaction information for API
 */
const actionSavePendingTransactions = (state, dispatch, data) => {
  if (data.noSave) {
    return null;
  }
  const transactions = transactionUniqueArray(state[section].failures, data);
  // only dispatch a clear if there are failures in state (perf)
  if (state[section].failures?.length > 0) {
    dispatch({ type: requirementTypes.REQUIREMENT_CLEAR_FAILURES });
  }
  transactions.forEach((transaction) => {
    actionRequestSaveTransaction(dispatch, transaction);
  });
};

const actionRequestSaveTransaction = async (dispatch, data) => {
  dispatch({ type: requirementTypes.REQUIREMENT_SAVE_DATA_START });
  const url = transactionUrl(data, section, parentName, childName);
  const payload = transactionPayload(data);
  let response = null;
  if (isAddOp(data.operation) || isEditOp(data.operation) || isReorderOp(data.operation)) {
    response = await postRequest(url, payload);
  } else if (isDeleteOp(data.operation)) {
    response = await deleteRequest(url);
  }

  const isSuccess = isValidTransactionResponse(response, data.operation);
  if (isSuccess) {
    dispatch({ type: requirementTypes.REQUIREMENT_SAVE_DATA_SUCCESS });
  } else if (response?.status === 401) {
    const typeError = requirementTypes.REQUIREMENT_SAVE_DATA_ERROR;
    dispatch({ transaction: data, type: typeError, isNotEditable: true });
  } else {
    const typeError = requirementTypes.REQUIREMENT_SAVE_DATA_ERROR;
    dispatch({ transaction: data, type: typeError });
  }
  dispatch({ type: requirementTypes.REQUIREMENT_SAVE_DATA_COMPLETE });

  // update id value of adds from API response
  const addOperations = [operations.childAdd, operations.parentAdd];
  if (addOperations.indexOf(data.operation) > -1) {
    const { childIndex, initiativeId, isModified } = data;
    const { parentId, parentIndex, state } = data;
    const value = response?.data?.data?.id || "";
    const typeEdit =
      operations.childAdd === data.operation && childIndex > -1
        ? requirementTypes.REQUIREMENT_EDIT_CHILD
        : requirementTypes.REQUIREMENT_EDIT_PARENT;
    dispatch({
      childIndex,
      initiativeId,
      isModified,
      key: "id",
      parentId,
      parentIndex,
      state,
      type: typeEdit,
      value,
    });
  }
};

const processRevertRequest = (response) => {
  const payload = {};
  if (isValidResponse(response)) {
    payload.success = { message: messages.successRevert };
  } else {
    payload.error = { message: messages.errorPublish };
  }
  return payload;
};

const clearRequirementsData = (_state, dispatch, _data) => {
  const type = requirementTypes.CLEAR_REQUIREMENT_DATA;
  dispatch({ type });
};

const clearRequirementsErrors = (state, dispatch, data) => {
  clearRequirementsData(state, dispatch, data);
};

const clearRequirementsSuccesses = (state, dispatch, data) => {
  clearRequirementsData(state, dispatch, data);
};

/**
 * @param {*} _state existing state
 * @param {*} dispatch function
 * @param {{
 *  initiativeId: string,
 *  isPublished?: boolean,
 *  isReloading?: boolean,
 *  importedCatIds?: Array<string>,
 *  movedCatId?: string,
 *  movedItemsIds?: Array<string>,
 * }} data config
 */
const loadRequirementsData = (_state, dispatch, data) => {
  if (!data) {
    return;
  }
  async function loadData() {
    const version = data.isPublished ? "published" : "draft";
    const url = getApiUrl(data.initiativeId, `${section}/${version}`);
    const typeLoad = requirementTypes.LOAD_REQUIREMENTS_DATA;
    if (!data.isReloading) {
      dispatch({ error: null, isLoading: true, type: typeLoad });
    }
    const response = await getRequest(url);
    const payload = processGetResponse(response);
    const { data: payloadData, ...others } = payload;
    const isPublishInEdit = payloadData?.state === RequirementsState.INEDIT;
    const actionResponse = {
      ...others,
      hasSurveyRecipientViewed: !!payloadData?.hasSurveyRecipientViewed,
      isPublishInEdit,
      lastPublishedDate: payloadData?.lastPublishedDate,
      list: payloadData?.requirements,
      scoringLevel: payloadData?.scoringLevel,
      state: payloadData?.state,
      type: typeLoad,
    };
    dispatch(actionResponse);
    if (data.importedCatIds) {
      const typeClear = requirementTypes.REQUIREMENT_IMPORT_CATS_LIST_CLEAR;
      const typeLoadIds = requirementTypes.REQUIREMENT_IMPORT_CATS_LIST_LOAD;
      dispatch({ catIds: data.importedCatIds, type: typeLoadIds });
      setTimeout(() => dispatch({ type: typeClear }), 5000);
    }
    if (data.movedCatId) {
      const typeClear = requirementTypes.REQUIREMENT_MOVED_CAT_ID_CLEAR;
      const typeLoadIds = requirementTypes.REQUIREMENT_MOVED_CAT_ID_LOAD;
      dispatch({ catId: data.movedCatId, type: typeLoadIds });
      setTimeout(() => dispatch({ type: typeClear }), 5000);
    }
    if (data.movedItemsIds) {
      const typeClear = requirementTypes.REQUIREMENT_MOVED_ITEMS_LIST_CLEAR;
      const typeLoadIds = requirementTypes.REQUIREMENT_MOVED_ITEMS_LIST_LOAD;
      dispatch({ itemIds: data.movedItemsIds, type: typeLoadIds });
      setTimeout(() => dispatch({ type: typeClear }), 5000);
    }
  }
  loadData();
};

const revertAllInEditPublishChanges = (_state, dispatch, data = {}) => {
  async function revertData() {
    const url = getApiUrl(data.initiativeId, `${section}/action/revert`);
    const type = requirementTypes.REVERT_ALL_PUBLISH_CHANGES;
    dispatch({ error: null, isProcessing: true, type });
    const response = await postRequest(url, {});
    const payload = processRevertRequest(response);
    dispatch({ ...payload, type });
  }
  revertData();
};

/**
 * update the status of scoring level
 * @param {Object} _state
 * @param {Function} dispatch
 * @param {{ scoringLevel: string }} data
 */
const updateScoringLevelStatus = (_state, dispatch, data) => {
  const { scoringLevel } = data;
  const type = requirementTypes.REQUIREMENT_SCORING_LEVEL_UPDATE;
  dispatch({ scoringLevel, type });
};

export {
  actionRequirementsTransaction,
  clearRequirementsErrors,
  clearRequirementsSuccesses,
  loadRequirementsData,
  revertAllInEditPublishChanges,
  updateScoringLevelStatus,
};
