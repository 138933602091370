import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { PlusIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { MiniButton, PopoverMenu, TypographyComponent } from "gx-npm-ui";
import { bulkOpButtonStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";

const propTypes = {
  btnLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  btnVariant: PropTypes.string,
  hasPopover: PropTypes.bool,
  icon: PropTypes.node,
  index: PropTypes.number,
  onClickButton: PropTypes.func,
  onClickOption: PropTypes.func,
  popoverItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  isRequirementLibrary: PropTypes.bool,
};
const useStyles = makeStyles(() => styles);
const BulkOperationsBarButton = ({
  btnLabel = "",
  btnVariant = "",
  hasPopover = false,
  icon = null,
  index = -1,
  onClickButton = null,
  onClickOption = null,
  popoverItems = [],
  isRequirementLibrary = true,
}) => {
  const handleClickButton = () => {
    if (!hasPopover) {
      handleEvent(onClickButton, index);
    }
  };

  const handleClickOption = (idx) => {
    handleEvent(onClickOption, popoverItems[idx].id);
  };

  const handleClickFooter = () => {
    handleEvent(onClickOption, "NEW_CATEGORY");
  };

  const renderedButton = () => {
    return (
      <MiniButton onClick={handleClickButton} variant={btnVariant}>
        {icon} {btnLabel}
      </MiniButton>
    );
  };
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      {hasPopover && (
        <PopoverMenu
          listFooter={
            <div className={classes.popoverFooter}>
              <MiniButton onClick={handleClickFooter}>
                <PlusIcon />
                {` `}
                {t("New category")}
              </MiniButton>
            </div>
          }
          listHeader={
            <TypographyComponent rootClassName={classNames(classes.popoverHeader)} styling={"p3"} boldness={"semi"}>
              {isRequirementLibrary ? t("Add to") : t("Move to")}:
            </TypographyComponent>
          }
          menuItems={popoverItems}
          onClick={handleClickOption}
          selectedIndex={-1}
          showChildren={true}
          showSelectedOption={false}
          variant="select"
          listItemClassName={isRequirementLibrary ? "gx-add-requirement-popover-item" : ""}
        >
          {renderedButton()}
        </PopoverMenu>
      )}
      {!hasPopover && renderedButton()}
    </Fragment>
  );
};

BulkOperationsBarButton.propTypes = propTypes;
export default BulkOperationsBarButton;
