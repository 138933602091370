const evaluationClasses = {
  tableCarousel: {
    display: "flex",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    "& thead, tbody, tfoot": {
      "& tr:nth-child(1)": {
        position: "sticky",
        top: 48,
      },
    },
    "& thead, tbody": {
      display: "table-cell",
    },
    "& thead": {
      width: "25%",
    },
    "& tbody": {
      display: "inline-block",
      width: "100%",
      tableLayout: "fixed",
      "& td": {
        minWidth: "25%",
        display: "inline-block",
        "@media (max-width:1439px)": {
          minWidth: "33.3333%",
        },
        "@media (max-width:1239px)": {
          minWidth: "50%",
        },
        "@media (max-width:1023px)": {
          minWidth: "100%",
        },
      },
      "& tr": {
        display: "flex",
        width: "100%",
        overflow: "hidden",
      },
    },
    "& td, & th": {
      textAlign: "left",
    },
    "&.gx-display-all-columns": {
      "&.num-cols-1": {
        "& tbody td": {
          minWidth: "100%",
        },
      },
      "&.num-cols-2": {
        "& tbody td": {
          minWidth: "50%",
        },
      },
      "&.num-cols-3": {
        "& tbody td": {
          minWidth: "33.3333%",
        },
      },
      "&.num-cols-4": {
        "& tbody td": {
          minWidth: "25%",
        },
      },
      "&.num-cols-5": {
        "& tbody td": {
          minWidth: "20%",
        },
      },
    },
  },
  scrollLeftContainer: {
    marginLeft: "auto",
  },
  "@global": {
    ".gx-table-scroll-right-tooltip": {
      top: "3px !important",
      left: "-4px !important",
    },
    ".gx-table-scroll-left-tooltip": {
      top: "11px !important",
      left: "-2px !important",
    },
  },
};

export { evaluationClasses };
