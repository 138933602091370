import { colorPalette } from "gx-npm-common-styles";
import { CloseIcon } from "gx-npm-icons";
import { InitUserRole, postAsyncRequest, PriorityWeightOption, useUserInitAccess, UUID } from "gx-npm-lib";
import { Button, MiniButton, SnackbarBanner, TextLink, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PrioritiesBannerSVG from "../../../../assets/images/priorities-banner.svg";
import { SELECTED_PRIORITY_WEIGHTS } from "../../products.constants";
import { ProductsContext } from "../../products.context";
import styles from "./priorities-bar-v2.styles.module.scss";

const PrioritiesBarV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { t } = useTranslation();
  const [isClearError, setIsClearError] = useState<boolean>(false);
  const [isClearSuccess, setIsClearSuccess] = useState<boolean>(false);
  const [selectedPriorityCount, setSelectedPriorityCount] = useState<number>(0);
  const { priorities, setPriorities, setProducts, setIsWeightedPriorityDrawerOpen } = useContext(ProductsContext);
  const { role } = useUserInitAccess(initiativeId);
  const isOwner = role === InitUserRole.OWNER;

  useEffect(() => {
    if (priorities.length === 0) {
      return;
    }
    const count = priorities.filter((priority) => SELECTED_PRIORITY_WEIGHTS.includes(priority.weight)).length;
    setSelectedPriorityCount(count);
  }, [priorities]);

  const handleClick = () => {
    setIsWeightedPriorityDrawerOpen(true);
  };

  const handleClear = async () => {
    const url = `/api/v7/initiatives/${initiativeId}/products/priorities`;
    const response = await postAsyncRequest(url, { weightedPriorities: [] });
    if (response.status !== 201 || !Array.isArray(response.data?.data?.products)) {
      setIsClearError(true);
      return;
    }
    setPriorities((prev) => prev.map((item) => ({ ...item, weight: PriorityWeightOption.NONE })));
    setProducts(response.data.data.products);
    setIsClearSuccess(true);
  };

  return (
    <Fragment>
      <div className={styles.prioritiesBarWrapper}>
        <div>
          <TypographyComponent color={"carbon"} boldness={"medium"} rootClassName={styles.bannerTitle} styling={"p1"}>
            {t("Set your priorities and see how well each vendor fits your needs.")}
          </TypographyComponent>

          <TextLink
            href="https://intercom.help/gartner-buysmart-help-center/en/articles/9000863-vendor-fit"
            rootClassName="gx-vendor-shortlist-link"
            text={t("Learn more")}
            target="_blank"
            variant="secondary"
          />
        </div>
        <img className={styles.bannerSvg} alt="" src={PrioritiesBannerSVG} />

        <div className={styles.buttonsWrapper}>
          <TooltipV2
            deactivate={isOwner && selectedPriorityCount === 0}
            placement="top"
            PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
            title={
              !isOwner
                ? t("This action is available only for evaluation owners.")
                : `${t("The following priorities are selected")}: ${priorities
                    .filter((p) => SELECTED_PRIORITY_WEIGHTS.includes(p.weight))
                    .map((p) => p.name)
                    .join(", ")}`
            }
          >
            <div>
              <Button onClick={handleClick} rootClassName="btn-primary" disabled={!isOwner}>
                {selectedPriorityCount > 0 ? t("Edit Priorities") : t("Set Priorities")}
                {selectedPriorityCount > 0 && (
                  <TypographyComponent
                    boldness={"medium"}
                    color={"defaultCta"}
                    rootClassName={styles.priorityCount}
                    styling="p3"
                  >
                    {selectedPriorityCount}
                  </TypographyComponent>
                )}
              </Button>
            </div>
          </TooltipV2>
          {selectedPriorityCount > 0 && (
            <TooltipV2
              placement="top"
              PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
              title={
                isOwner ? t("Clear all priority settings") : t("This action is available only for evaluation owners.")
              }
            >
              <div>
                <MiniButton onClick={handleClear} disabled={!isOwner}>
                  <CloseIcon fillPath={colorPalette.interactions.blueBerry.hex} />
                  {t("Clear all")}
                </MiniButton>
              </div>
            </TooltipV2>
          )}
        </div>
      </div>
      <SnackbarBanner
        isOpen={isClearSuccess}
        message={t("Your vendor priorities have been removed.")}
        setIsOpen={setIsClearSuccess}
        type="SUCCESS"
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isClearError} setIsOpen={setIsClearError} type="ERROR" />
    </Fragment>
  );
};

export default PrioritiesBarV2Component;
