// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hK9ulJXWoHBigId0kWDP{background-color:rgba(6,196,176,.45);border-radius:8px;display:flex;height:123px;justify-content:space-between;margin:24px 0;padding:24px 36px;width:100%}.hK9ulJXWoHBigId0kWDP .mAbfdBIIsusALsQUrdLV{margin-bottom:5px;width:328px}@media screen and (max-width: 1240px){.M1JbBlEiRla8D57tZmrc{display:none}}.MEJ5uwNu28Zt4iaL2pBy{align-items:center;display:inline-flex;gap:24px;margin-left:42px}.MEJ5uwNu28Zt4iaL2pBy .pmhRNwZVXb7gAUcfJkg0{background-color:var(--color-white);border-radius:50%;margin-left:12px;padding:1px 0;width:24px}@media screen and (max-width: 1240px){.MEJ5uwNu28Zt4iaL2pBy{margin-left:0}}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/priorities/priorities-bar/priorities-bar-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CACA,iBAAA,CACA,YAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CAEA,4CACE,iBAAA,CACA,WAAA,CAKF,sCADF,sBAEI,YAAA,CAAA,CAIJ,sBACE,kBAAA,CACA,mBAAA,CACA,QAAA,CACA,gBAAA,CAEA,4CACE,mCAAA,CACA,iBAAA,CACA,gBAAA,CACA,aAAA,CACA,UAAA,CAGF,sCAdF,sBAeI,aAAA,CAAA","sourcesContent":[".prioritiesBarWrapper {\n  background-color: rgba(6, 196, 176, 0.45);\n  border-radius: 8px;\n  display: flex;\n  height: 123px;\n  justify-content: space-between;\n  margin: 24px 0;\n  padding: 24px 36px;\n  width: 100%;\n\n  & .bannerTitle {\n    margin-bottom: 5px;\n    width: 328px;\n  }\n}\n\n.bannerSvg {\n  @media screen and (max-width: 1240px) {\n    display: none;\n  }\n}\n\n.buttonsWrapper {\n  align-items: center;\n  display: inline-flex;\n  gap: 24px;\n  margin-left: 42px;\n\n  & .priorityCount {\n    background-color: var(--color-white);\n    border-radius: 50%;\n    margin-left: 12px;\n    padding: 1px 0;\n    width: 24px;\n  }\n\n  @media screen and (max-width: 1240px) {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prioritiesBarWrapper": `hK9ulJXWoHBigId0kWDP`,
	"bannerTitle": `mAbfdBIIsusALsQUrdLV`,
	"bannerSvg": `M1JbBlEiRla8D57tZmrc`,
	"buttonsWrapper": `MEJ5uwNu28Zt4iaL2pBy`,
	"priorityCount": `pmhRNwZVXb7gAUcfJkg0`
};
export default ___CSS_LOADER_EXPORT___;
