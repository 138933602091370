import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InitProdState, ScoringMode, UUID, ScoringLevel } from "gx-npm-lib";
import { Fade } from "@mui/material";

import { Requirement, Product } from "../../../../../../app.types";
import ScorecardReqCat from "../scorecard-requirements/scorecard-req-cat/scorecard-req-cat.component";
import ProductScoreCarouselColumn from "../scorecard-product-scores/product-score-carousel-column/product-score-carousel-column";
import { CarouselContext } from "../../carousel.context";
import styles from "./scorecard-docked-req-cat-prod-scores.module.scss";

type ScorecardDockedCategoryType = {
  width: number;
  reqCat: Requirement;
  reqCatCount: number;
  displayedProducts: Product[];
  isOwner: boolean;
  isPopperOpen: boolean;
  scoringMode: ScoringMode;
  scoringLevel: ScoringLevel;
  onAvatarClick?: () => void;
  onOpenPopper: () => void;
  currentPosition: number;
};

const ScorecardDockedReqCatProdScores = ({
  width = 0,
  reqCat,
  reqCatCount,
  displayedProducts,
  isOwner,
  isPopperOpen,
  scoringMode,
  scoringLevel,
  onAvatarClick,
  onOpenPopper,
  currentPosition = 0,
}: ScorecardDockedCategoryType) => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { dockedReqCatIdx, reqCatOpenIdxList } = useContext(CarouselContext);
  const [scoreItems, setScoreItems] = useState<Product[]>();

  useEffect(() => {
    /* TODO: Add logic so the items set are the ones displayed in view */
    setScoreItems(displayedProducts.slice(currentPosition, 4));
  }, [currentPosition, displayedProducts]);

  if (!reqCat || dockedReqCatIdx === -1 || !displayedProducts) {
    return null;
  }

  const isReqCatClosed = reqCatOpenIdxList.indexOf(dockedReqCatIdx) === -1;
  const reqCatId = reqCat.id as UUID;

  return (
    <Fade in={!isReqCatClosed}>
      <div
        style={{
          width: `${width}px`,
        }}
        className={styles.dockedCategory}
      >
        <div className={styles.rowWrapper}>
          <div className={styles.requirementName}>
            <ScorecardReqCat
              reqCat={reqCat}
              reqCatCount={reqCatCount}
              displayedProductsCount={displayedProducts.length}
              index={dockedReqCatIdx}
              isOwner={isOwner}
              onAvatarClick={onAvatarClick}
              scoringMode={scoringMode}
              docked={true}
            />
          </div>
          {scoreItems &&
            scoreItems.map((product) => (
              <ProductScoreCarouselColumn
                key={product.id}
                prodInitId={product.id as UUID}
                prodName={product.name}
                prodState={product.state as InitProdState}
                prodImageLoc={product.imageLoc}
                catId={reqCatId}
                catName={reqCat.name}
                initId={initiativeId}
                isRequirementExpanded={true}
                isScreenedOut={false}
                onOpenPopper={onOpenPopper}
                isCarouselPopperOpen={isPopperOpen}
                scoringLevel={scoringLevel}
                scoreCategory={product.overallScore}
                scoreCategoryDisplay={product.overallScoreDisplay}
                scoreMapRequirementItems={{}}
              />
            ))}
        </div>
      </div>
    </Fade>
  );
};

export default ScorecardDockedReqCatProdScores;
