import { MiniButton, TooltipV2 } from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import ImportReqIcon from "./import-req.icon";

const propTypes = { isViewOnly: PropTypes.bool, onLibraryDrawerOpen: PropTypes.func, disabled: PropTypes.bool };
const ImportReqButtons = ({ isViewOnly = false, onLibraryDrawerOpen = () => {}, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <TooltipV2
      deactivate={!isViewOnly}
      placement="top"
      PopperProps={{ modifiers: { offset: { offset: "0, 12" } } }}
      title={t("This action is only available to evaluation owners and contributors.")}
    >
      <div>
        <MiniButton
          disabled={isViewOnly || disabled}
          onClick={() => onLibraryDrawerOpen()}
          rootClassName="gx-import-req-button"
          variant="default"
        >
          <ImportReqIcon />
          {t("Import")}
        </MiniButton>
      </div>
    </TooltipV2>
  );
};

ImportReqButtons.propTypes = propTypes;

export default ImportReqButtons;
