import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { ProductLogo, TooltipV2, TypographyComponent } from "gx-npm-ui";
import * as singleSpa from "single-spa";
import { updateStateSubject } from "gx-npm-messenger-util";
import vendorIconAndTextLinkStyles from "./vendor-icon-and-text-link.styles";

const useStyles = makeStyles(() => vendorIconAndTextLinkStyles);
const propTypes = {
  isScreenedOut: PropTypes.bool,
  imageLoc: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  initiativeId: PropTypes.string,
};
const VendorIconAndTextLink = ({ initiativeId = "", imageLoc = "", isScreenedOut = false, name = "", id = "" }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);

  const handleClick = () => {
    let url = `/s/evaluation/${initiativeId}/product/${id}/profile`;
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "questionnaire" });
    singleSpa.navigateToUrl(url);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <TooltipV2
      enterDelay={1500}
      enterNextDelay={1500}
      placement={"top-start"}
      PopperProps={{ modifiers: { offset: { offset: "-52x, 8px" } } }}
      rootClassName="gx-selected-body-icon-vendor-name-tooltip"
      title={t("Click to view profile")}
    >
      <div aria-label={t("Vendor logo and name")} className={classNames(classes.productBrand, isHover && "hover")}>
        <div
          aria-label={t("Vendor icon link")}
          className={classes.logoWrapper}
          onBlur={() => setIsHover(false)}
          onClick={handleClick}
          onFocus={() => setIsHover(true)}
          onKeyDown={handleKeyDown}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          role="button"
          tabIndex="0"
        >
          <ProductLogo logo={imageLoc} rootClassName="gx-survey-product-logo" />
        </div>
        <div
          aria-label={t("Vendor name link")}
          onBlur={() => setIsHover(false)}
          onClick={handleClick}
          onFocus={() => setIsHover(true)}
          onKeyDown={handleKeyDown}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          role="button"
          tabIndex="0"
        >
          <TypographyComponent rootClassName={"gx-survey-not-screened-out"} boldness={"medium"} styling={"p1"}>
            {name}
          </TypographyComponent>
          {isScreenedOut && (
            <TypographyComponent rootClassName={"gx-survey-screened-out"} styling={"p4"} boldness={"medium"}>
              {t("Screened out")}
            </TypographyComponent>
          )}
        </div>
      </div>
    </TooltipV2>
  );
};

VendorIconAndTextLink.propTypes = propTypes;
export default VendorIconAndTextLink;
