import classNames from "classnames";
import { ProductLogo, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { InitProdState, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import styles from "./product-score-box.styles.module.scss";

type ProductScoreBoxProps = {
  imageLoc: string;
  initId: UUID;
  initProdId: UUID;
  name: string;
  scoreDisplay: string;
  source: "overview" | "selection";
  state: InitProdState | "";
  inEvalProductCount: number;
};
const ProductScoreBoxComponent = ({
  imageLoc = "",
  initId = "",
  initProdId = "",
  name = "",
  scoreDisplay = "",
  source = "overview",
  state = "",
  inEvalProductCount = 0,
}: ProductScoreBoxProps) => {
  const { t } = useTranslation();
  const isAwarded = state === InitProdState.AWARDED;
  const isScreenedOut = state === InitProdState.SCREENED_OUT;
  const href = `/s/evaluation/${initId}/product/${initProdId}/profile`;

  let styleWidth = 0;

  if (inEvalProductCount !== 0) {
    const minWidth = 14.285;
    styleWidth = Math.floor(Math.max(minWidth, 100 / inEvalProductCount));
  }

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: source });
    singleSpa.navigateToUrl(href);
  };

  return (
    <div
      className={classNames(
        styles.container,
        isAwarded && styles.awardedContainer,
        isScreenedOut && styles.screenedOutContainer,
        !isAwarded && "colWidth" + styleWidth
      )}
    >
      <TooltipV2
        enterDelay={1500}
        enterNextDelay={1500}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
        title={t("Click to view profile")}
      >
        <a
          aria-label={`${t("view")} ${name} ${t("profile")}`}
          className={styles.productLink}
          href={href}
          onClick={(e) => handleClick(e)}
        >
          <div className={classNames(styles.logo, isAwarded && styles.awardedLogo)}>
            <ProductLogo
              imageHeight={isAwarded ? "40px" : "32px"}
              imageWidth={isAwarded ? "40px" : "32px"}
              logo={imageLoc}
              name={name}
            />
          </div>
          <div className={classNames(styles.productName)}>
            <TypographyComponent boldness={"medium"} styling={"p4"}>
              {name}
            </TypographyComponent>
          </div>
        </a>
      </TooltipV2>
      {!isScreenedOut && (
        <TypographyComponent
          ariaLabel={`${name} overall score is ${scoreDisplay}`}
          rootClassName={styles.subProductName}
          color={"coal"}
          styling={"h5"}
        >
          {scoreDisplay}
        </TypographyComponent>
      )}
      {isScreenedOut && (
        <TypographyComponent
          ariaLabel={t("Screened Out")}
          boldness={"semi"}
          rootClassName={styles.subProductName}
          styling={"p4"}
        >
          {t("Screened Out")}
        </TypographyComponent>
      )}
    </div>
  );
};

export default ProductScoreBoxComponent;
