import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse, makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ExclamationCircledIcon, RevertIcon } from "gx-npm-icons";
import { ButtonLoader, MiniButton, TooltipV2, TypographyComponent } from "gx-npm-ui";
import styles from "./reqs-post-publish-inline-alert.styles";

const propTypes = {
  isInvalidWeightTotal: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isPublishedInEdit: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  isContributor: PropTypes.bool,
  onApplyUpdateButtonClick: PropTypes.func,
  onRevertButtonClick: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ReqsPostPublishInlineAlert = ({
  isInvalidWeightTotal = false,
  isProcessing = true,
  isPublishedInEdit = true,
  isViewOnly = false,
  isContributor = false,
  onApplyUpdateButtonClick = () => {},
  onRevertButtonClick = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmationTooltipText, setConfirmationTooltipText] = useState("");
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(true);
    }, 1000);
  }, []);

  useEffect(() => {
    let text = t("Apply requirement updates to your questionnaire and/or scorecard.");
    if (isViewOnly || isContributor) {
      text = t("This action available only for the evaluation owners.");
    } else if (isInvalidWeightTotal) {
      text = t("The total weight of all requirements must equal 100%.");
    }
    setConfirmationTooltipText(text);
  }, [isInvalidWeightTotal, isViewOnly, isContributor, t]);

  return (
    <Collapse in={showBanner}>
      <div className={classNames("inline-alert", classes.container)}>
        <div className={classes.message}>
          <div className={classes.icon}>
            <div className={classes.flipIcon}>
              <ExclamationCircledIcon fillPath={colorPalette.status.darkLemon.hex} />
            </div>
          </div>

          <TypographyComponent boldness={"medium"} rootClassName={"warning-message"} styling={"p3"}>
            {isInvalidWeightTotal
              ? t("Unable to apply updates. The total weight of all requirements must equal 100%.")
              : t(
                  "Your requirements have been modified. To update your scorecard and/or questionnaire, apply updates."
                )}
          </TypographyComponent>
        </div>
        <div className={classes.buttonWrapper}>
          <MiniButton
            ariaLabel={t("revert")}
            disabled={isViewOnly || !isPublishedInEdit}
            onClick={onRevertButtonClick}
            rootClassName={classNames("btn-tertiary ")}
          >
            <RevertIcon />
            {t("Revert all changes")}
          </MiniButton>
          <TooltipV2
            deactivate={isProcessing}
            placement="top"
            PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
            title={confirmationTooltipText}
          >
            <div>
              <ButtonLoader
                ariaLabel={t("apply updates")}
                btnClass="btn-primary"
                disabled={isViewOnly || isContributor || isInvalidWeightTotal}
                isLoading={isProcessing}
                onClick={onApplyUpdateButtonClick}
              >
                {t("APPLY UPDATES")}
              </ButtonLoader>
            </div>
          </TooltipV2>
        </div>
      </div>
    </Collapse>
  );
};

ReqsPostPublishInlineAlert.propTypes = propTypes;
export default ReqsPostPublishInlineAlert;
