// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svnn2eJCcxJUP1QQputP{align-items:center;display:flex;margin-bottom:24px}.svnn2eJCcxJUP1QQputP .tmlSWkhQxbyYWVVrdzZo{color:var(--color-neutrals-carbon);float:left;margin-bottom:0;padding-right:22px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/boxed-product-header/boxed-product-header.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CAEA,4CACE,kCAAA,CACA,UAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".header {\n  align-items: center;\n  display: flex;\n  margin-bottom: 24px;\n\n  & .headerText {\n    color: var(--color-neutrals-carbon);\n    float: left;\n    margin-bottom: 0;\n    padding-right: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `svnn2eJCcxJUP1QQputP`,
	"headerText": `tmlSWkhQxbyYWVVrdzZo`
};
export default ___CSS_LOADER_EXPORT___;
