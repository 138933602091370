import { InitProdState, InitStatus, UUID } from "gx-npm-lib";
import { createContext, ReactNode, useState } from "react";
import { CommentType } from "./selection.constants";
import {
  AwardedProductOverview,
  BudgetType,
  ChecklistPhases,
  Comment,
  Product,
  SelectReportReqCat,
  SelectionContextValue,
  TeamMember,
} from "./selection.types";

const SelectionContext = createContext<SelectionContextValue>({} as SelectionContextValue);
const SelectionContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [budget, setBudget] = useState<BudgetType>({ amount: 0, currency: "" });
  const [checklistPhases, setChecklistPhases] = useState<ChecklistPhases[]>([]);
  const [createdDate, setCreatedDate] = useState<string>("");
  const [evalComment, setEvalComment] = useState<Comment>({
    id: "",
    description: "",
    commentType: CommentType.NONE,
    parentId: "",
  });
  const [objectives, setObjectives] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [requirementCategories, setRequirementCategories] = useState<SelectReportReqCat[]>([]);
  const [requirementItemCount, setRequirementItemCount] = useState<number>(0);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [status, setStatus] = useState<InitStatus | "">("");
  const [statusUpdateDate, setStatusUpdateDate] = useState<string>("");
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [templateDesc, setTemplateDesc] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  // todo rm with FF GCOM-4297
  const [awardedProduct, setAwardedProduct] = useState<Product>({
    id: "",
    name: "",
    imageLoc: "",
    state: InitProdState.LISTED,
    overallScoreDisplay: "",
    scorecardCategories: [],
  });
  const [awardedProductOverview, setAwardedProductOverview] = useState<AwardedProductOverview>({
    mq: {
      qdPosition: "",
      qdDesc: "",
      docURL: "",
    },
    productReviews: {
      overallRating: 0,
      reviewCount: 0,
      reviewsURL: "",
      mostCriticalReview: {
        id: 0,
        headline: "",
        summary: "",
        rating: 0,
        date: "",
        URL: "",
      },
      mostFavorableReview: {
        id: 0,
        headline: "",
        summary: "",
        rating: 0,
        date: "",
        URL: "",
      },
    },
  });
  const [initiativeId, setInitiativeId] = useState<UUID>("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [requirementComment, setRequirementComment] = useState<Comment>({
    id: "",
    description: "",
    commentType: CommentType.NONE,
    parentId: "",
  });
  const [selectionEditCount, setSelectionEditCount] = useState<number>(0);

  const contextValue = {
    budget,
    checklistPhases,
    createdDate,
    evalComment,
    objectives,
    products,
    requirementCategories,
    requirementItemCount,
    showAnimation,
    status,
    statusUpdateDate,
    teamMembers,
    templateDesc,
    templateName,
    title,
    setBudget,
    setChecklistPhases,
    setCreatedDate,
    setEvalComment,
    setObjectives,
    setProducts,
    setRequirementCategories,
    setRequirementItemCount,
    setShowAnimation,
    setStatus,
    setStatusUpdateDate,
    setTeamMembers,
    setTemplateDesc,
    setTemplateName,
    setTitle,

    // todo rm with FF GCOM-4297
    awardedProduct,
    awardedProductOverview,
    initiativeId,
    isSnackbarOpen,
    requirementComment,
    selectionEditCount,
    setAwardedProduct,
    setAwardedProductOverview,
    setInitiativeId,
    setIsSnackbarOpen,
    setRequirementComment,
    setSelectionEditCount,
  };

  return <SelectionContext.Provider value={contextValue}>{props.children}</SelectionContext.Provider>;
};

export { SelectionContext, SelectionContextProvider };
