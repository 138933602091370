import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { getAsyncRequest, InitStatus, InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EvalSummaryTableV2Component from "../../ui/eval-summary-table-v2/eval-summary-table-v2.component";
import { TabSectionLoader } from "../../ui/loader";
import { CustomAppEvent } from "../../app.constants";
import EvaluationCardV2 from "./components/evaluation-card/evaluation-card-v2.component";
import SelectionCardV2 from "./components/selection-card/selection-card-v2.component";
import SetUpCardV2Component from "./components/set-up-card/set-up-card-v2.component";
import UpNextCardV2 from "./components/up-next-card/up-next-card-v2.component";
import PlanningCardV2 from "./components/planning-card/planning-card-v2.component";
import { OverviewContext } from "./overview.context";
import { OverviewTabApiResponse } from "./overview.types";
import { overviewTabContentV2Styles as styles } from "./overview-tab-content-v2.styles";
import TeamManagementCard from "./components/team-management-card/team-management-card.component";

const selector = "gx-overview-tab-root";
type OverviewTabContentV2Props = { initiativeId: UUID };
const useStyles = makeStyles(() => styles);
const OverviewTabContentV2 = ({ initiativeId = "" }: OverviewTabContentV2Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);
  const {
    isSnackbarOpen,
    overviewEditCount,
    products,
    status,
    setBudget,
    setChecklistCompletedCount,
    setChecklistCount,
    setObjectives,
    setIsSnackbarOpen,
    setProducts,
    setRequirementCount,
    setStatus,
    setTeamMembers,
    setVendorSelectionDate,
  } = useContext(OverviewContext);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    (async () => {
      try {
        const url = `api/v2/initiatives/${initiativeId}/overview`;
        const response: OverviewTabApiResponse = await getAsyncRequest(url);
        if (response?.status !== 200 || typeof response.data?.data !== "object") {
          throw new Error();
        }
        setBudget(response.data.data.budget);
        setChecklistCompletedCount(response.data.data.checklistCompletedCount);
        setChecklistCount(response.data.data.checklistCount);
        setObjectives(response.data.data.objectives);
        setProducts(response.data.data.products);
        setRequirementCount(response.data.data.requirementCount);
        setStatus(response.data.data.status);
        setTeamMembers(response.data.data.teamMembers);
        setVendorSelectionDate(response.data.data.vendorSelectionDate);
      } catch (err) {
        setIsSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    initiativeId,
    setBudget,
    setChecklistCompletedCount,
    setChecklistCount,
    setIsLoading,
    setIsSnackbarOpen,
    setObjectives,
    setProducts,
    setRequirementCount,
    setStatus,
    setTeamMembers,
    setVendorSelectionDate,
  ]);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent(CustomAppEvent.OVERVIEW_EDIT_COUNT, { detail: { count: overviewEditCount } }));
  }, [overviewEditCount]);

  return (
    <Fragment>
      <div className={classNames(selector, classes.overviewRoot)}>
        {isLoading && <TabSectionLoader />}
        {!isLoading && (
          <Fragment>
            {status === InitStatus.PLANNING && (
              <Fragment>
                <div className={classes.planningContainer} data-testid={"planning-container"}>
                  <PlanningCardV2
                    initiativeId={initiativeId}
                    isViewOnly={hasLoadedAccess && role !== InitUserRole.OWNER}
                  />
                  <TeamManagementCard />
                </div>
              </Fragment>
            )}
            {status === InitStatus.PLANNING && <SetUpCardV2Component />}
            {status === InitStatus.EVAL_STARTED && (
              <EvaluationCardV2
                initiativeId={initiativeId}
                isViewOnly={hasLoadedAccess && role !== InitUserRole.OWNER}
              />
            )}
            {status === InitStatus.SELECTED && (
              <Fragment>
                <SelectionCardV2 />
                <div className={classNames(classes.evaluationSummary)}>
                  <TypographyComponent boldness={"medium"} styling={"h4"} rootClassName={classes.headerTitle}>
                    {t("Evaluation summary")}
                  </TypographyComponent>
                  <EvalSummaryTableV2Component initId={initiativeId} products={products} sourcePage="overview" />
                </div>
              </Fragment>
            )}

            {status !== InitStatus.PLANNING && (
              <div className={classes.planningContainer}>
                <PlanningCardV2
                  initiativeId={initiativeId}
                  isViewOnly={hasLoadedAccess && role !== InitUserRole.OWNER}
                />
                <TeamManagementCard />
              </div>
            )}

            {(status === InitStatus.PLANNING || status === InitStatus.EVAL_STARTED) && (
              <UpNextCardV2 initStatus={status} />
            )}
          </Fragment>
        )}
      </div>
      <SnackbarBanner isOpen={isSnackbarOpen} setIsOpen={() => setIsSnackbarOpen(false)} isDefaultErrorMessage={true} />
    </Fragment>
  );
};

export default OverviewTabContentV2;
