import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { InitUserRole } from "gx-npm-lib";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import RevertChangesDialog from "../dialogs/revert-changes-dialog/revert-changes-dialog.component";
import UpdateReqsWithNotificationDialog from "../dialogs/update-requirements-with-notification-dialog/update-reqs-with-notification-dialog.component";
import ReqsPostPublishInlineAlert from "./inline-alerts/reqs-post-publish-inline-alert.component";
import ReqsPrePublishInlineAlert from "./inline-alerts/reqs-pre-publish-inline-alert.component";
import styles from "./requirements-title.styles";
import TitleTopRow from "./title-top-row.component";

const propTypes = {
  hasScorecard: PropTypes.bool,
  hasSurvey: PropTypes.bool,
  hasSurveyRecipientViewed: PropTypes.bool,
  initiativeId: PropTypes.string,
  isLoaded: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isPublishInEdit: PropTypes.bool,
  isValidWeight: PropTypes.bool,
  onPublishFinish: PropTypes.func,
  onRevertButtonClick: PropTypes.func,
  role: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const RequirementsTitle = ({
  hasScorecard = false,
  hasSurvey = false,
  hasSurveyRecipientViewed = false,
  initiativeId = "",
  isLoaded = false,
  isProcessing = false,
  isPublishInEdit = false,
  isValidWeight = true,
  onPublishFinish = (_isSuccessful) => {},
  onRevertButtonClick = () => {},
  role = "",
}) => {
  const classes = useStyles();
  const [isRevertChangesDialogOpen, setIsRevertChangesDialogOpen] = useState(false);
  const [isUpdateReqDialogOpen, setIsUpdateReqDialogOpen] = useState(false);

  const handleBannerButtonClick = () => {
    setIsRevertChangesDialogOpen(true);
  };

  const handleRevertChangesConfirm = () => {
    onRevertButtonClick();
    setIsRevertChangesDialogOpen(false);
  };

  const handleRevertChangesCancel = () => {
    setIsRevertChangesDialogOpen(false);
  };

  const handleCloseUpdateDialog = (isUpdated = false) => {
    setIsUpdateReqDialogOpen(false);
    onPublishFinish(isUpdated);
  };

  return (
    <Fragment>
      <div className={classNames(classes.bannerV2, isPublishInEdit && classes.bannerShownV2)}>
        {isLoaded && (
          <ReqsPostPublishInlineAlert
            initiativeId={initiativeId}
            isInvalidWeightTotal={!isValidWeight}
            isProcessing={isProcessing}
            isPublishInEdit={isPublishInEdit}
            isViewOnly={role === InitUserRole.VIEWER}
            isContributor={role === InitUserRole.CONTRIBUTOR}
            onApplyUpdateButtonClick={() => setIsUpdateReqDialogOpen(true)}
            onRevertButtonClick={handleBannerButtonClick}
          />
        )}
      </div>
      {isLoaded && <ReqsPrePublishInlineAlert initId={initiativeId} isShown={!isPublishInEdit && !isValidWeight} />}
      <TitleTopRow />
      <RevertChangesDialog
        isOpen={isRevertChangesDialogOpen}
        onClickCancel={handleRevertChangesCancel}
        onClickConfirm={handleRevertChangesConfirm}
      />
      <UpdateReqsWithNotificationDialog
        hasSurvey={hasSurvey}
        hasScorecard={hasScorecard}
        hasSurveyRecipientViewed={hasSurveyRecipientViewed}
        isOpen={isUpdateReqDialogOpen}
        onClose={handleCloseUpdateDialog}
        initiativeId={initiativeId}
      />
    </Fragment>
  );
};

RequirementsTitle.propTypes = propTypes;
export { RequirementsTitle };
