// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rUthZh7PHGo3AMNzwEOA{background-color:var(--color-white);border-radius:8px;padding:40px;transition:width .5s;width:100%}.BCyuW2q9iynuipZkNLzx{width:624px;margin-top:24px;margin-bottom:32px;margin-left:-12px}.BCyuW2q9iynuipZkNLzx .gx-add-comments-title{margin-top:24px;margin-bottom:44px}.BCyuW2q9iynuipZkNLzx .gx-add-comments-title p{color:var(--color-neutrals-iron)}.BCyuW2q9iynuipZkNLzx .gx-edit-comments-title{margin-top:15px;margin-bottom:43px}.BCyuW2q9iynuipZkNLzx .gx-edit-comments-title p{color:var(--color-neutrals-iron)}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/evaluation-summary/evaluation-summary.module.scss"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACA,iBAAA,CACA,YAAA,CACA,oBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CAEA,6CACE,eAAA,CACA,kBAAA,CAEA,+CACE,gCAAA,CAIJ,8CACE,eAAA,CACA,kBAAA,CAEA,gDACE,gCAAA","sourcesContent":[".root {\n  background-color: var(--color-white);\n  border-radius: 8px;\n  padding: 40px;\n  transition: width 0.5s;\n  width: 100%;\n}\n\n.comments {\n  width: 624px;\n  margin-top: 24px;\n  margin-bottom: 32px;\n  margin-left: -12px;\n\n  & :global(.gx-add-comments-title) {\n    margin-top: 24px;\n    margin-bottom: 44px;\n\n    & :global(p) {\n      color: var(--color-neutrals-iron);\n    }\n  }\n\n  & :global(.gx-edit-comments-title) {\n    margin-top: 15px;\n    margin-bottom: 43px;\n\n    & p {\n      color: var(--color-neutrals-iron);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `rUthZh7PHGo3AMNzwEOA`,
	"comments": `BCyuW2q9iynuipZkNLzx`
};
export default ___CSS_LOADER_EXPORT___;
