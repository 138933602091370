import classNames from "classnames";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import evaluationNoVendors from "../../../../../assets/images/evaluationNoVendors.svg";
import styles from "./no-products-in-evaluation.styles.module.scss";

const NoProductsInEvaluation = () => {
  const { t } = useTranslation();
  const { initiativeId } = useParams();
  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/products`);
  };

  return (
    <div className={styles.noProductsWrapper}>
      <TypographyComponent boldness={"semi"} color={"iron"} styling={"p3"}>
        {t("It looks like there are no active vendors in your eval.")}
      </TypographyComponent>
      <Button rootClassName={classNames(styles.noProductsButton, "btn-tertiary")} onClick={handleClick}>
        {t("Go to vendor list")}
      </Button>
      <img alt="" className={styles.noProductsImg} src={evaluationNoVendors} />
    </div>
  );
};
export default NoProductsInEvaluation;
