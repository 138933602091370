import React, { useEffect, useState, FC, useRef, useContext } from "react";
import classNames from "classnames";
import { InitProdState, ScoringLevel, UUID } from "gx-npm-lib";

import { Product, Requirement } from "../../../../../../app.types";
import TableCarouselBodyRow from "../../../../../../ui/table-carousel-v2/body/table-carousel-body-row.component";

import ProductScoreCarouselColumn from "./product-score-carousel-column/product-score-carousel-column";

import ScorecardProductHead from "./scorecard-product-head/scorecard-product-head.component";
import ScorecardProductStatus from "./scorecard-product-status/scorecard-product-status.component";
import EvaluationStatusColumn from "../../evaluation-status-column/evaluation-status-column.component";

import styles from "./scorecard-product-scores.module.scss";
import { CarouselContext } from "../../carousel.context";

interface ScorecardProductsProps {
  initiativeId: UUID;
  isPopperOpen: boolean;
  displayedProducts: Product[];
  requirements: Requirement[];
  scoringLevel: ScoringLevel;
  onShowSnackBar: (type: string, message?: string) => void;
  onUpdateProductState: (productName: string, action: string) => void;
  onOpenPopper: (_isOpen: boolean) => void;
}

const ScorecardProductScores: FC<ScorecardProductsProps> = ({
  initiativeId,
  isPopperOpen = false,
  displayedProducts = [],
  requirements = [],
  scoringLevel,
  onShowSnackBar = () => {},
  onUpdateProductState = () => {},
  onOpenPopper = () => {},
}) => {
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [awardedProductDetails, setAwardedProductDetails] = useState({ name: "", imageLoc: "", id: "" });

  const { reqCatOpenIdxList } = useContext(CarouselContext);

  useEffect(() => {
    const awardedProduct = displayedProducts.find((product) => product.state === InitProdState.AWARDED);
    if (awardedProduct) {
      setAwardedProductDetails({
        name: awardedProduct.name,
        imageLoc: awardedProduct.imageLoc,
        id: awardedProduct.id,
      });
    }
  }, [displayedProducts]);

  const productScores: Array<Array<{ type: string; displayCol: React.ReactNode; lastReqRoot: boolean }>> = [];

  for (let idx = 0; idx < requirements.length; idx++) {
    productScores.push([]);
  }

  displayedProducts.forEach((product: Product) => {
    const { imageLoc, name, state, reqCategoryScoreMap } = product;
    const isScreenedOut = state === InitProdState.SCREENED_OUT;
    const prodInitId = product.id;

    requirements.forEach((requirement, requirementIndex, arr) => {
      const { id: reqCatId, items } = requirement;
      if (!reqCategoryScoreMap[reqCatId]) {
        return;
      }
      const { catScore, catScoreDisplay, reqItemScoreMap } = reqCategoryScoreMap[reqCatId];
      const scoreMapRequirementItems = reqItemScoreMap || {};
      const lastReqRoot = arr.length - 1 === requirementIndex;
      productScores[requirementIndex].push({
        lastReqRoot,
        type: "reqRoot",
        displayCol: (
          <ProductScoreCarouselColumn
            key={`product-requirements-${prodInitId}-${reqCatId}`}
            catId={reqCatId}
            catName={requirement.name}
            initId={initiativeId}
            isRequirementExpanded={reqCatOpenIdxList.includes(requirementIndex)}
            isCarouselPopperOpen={isPopperOpen}
            isScreenedOut={isScreenedOut}
            onOpenPopper={onOpenPopper}
            prodImageLoc={imageLoc}
            prodInitId={prodInitId as UUID}
            prodName={name}
            prodState={state as InitProdState}
            requirementItems={items}
            scoreCategory={catScore}
            scoreCategoryDisplay={catScoreDisplay}
            scoreMapRequirementItems={scoreMapRequirementItems}
            scoringLevel={scoringLevel}
          />
        ),
      });
    });
  });

  return (
    <>
      <tr>
        <ScorecardProductHead initiativeId={initiativeId} displayedProducts={displayedProducts} />
      </tr>

      <TableCarouselBodyRow>
        {displayedProducts.map((product: Product) => (
          <EvaluationStatusColumn
            initiativeId={initiativeId}
            prodInitId={product.id}
            state={product.state}
            productName={product.name}
            imageLoc={product.imageLoc}
            key={`vendor-eval-${product.id}`}
            awardedProductDetails={awardedProductDetails}
            updateProductState={onUpdateProductState}
            onShowSnackbar={onShowSnackBar}
          />
        ))}
      </TableCarouselBodyRow>

      <tr>
        <ScorecardProductStatus displayedProducts={displayedProducts} />
      </tr>

      {productScores.map((prodScore, prodScoreIndex) => (
        <tr
          /* todo need to switch prodScoreIndex with reqCatId to be valid key */
          key={`product-score-${prodScoreIndex}`}
          className={classNames("gx-req-root-body-row", styles.productScoreRow)}
          ref={(row) => (rowRefs.current[prodScoreIndex] = row)}
        >
          {prodScore.map((item) => item.displayCol)}
        </tr>
      ))}
    </>
  );
};

export default ScorecardProductScores;
