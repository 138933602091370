import { getAsyncRequest, useWebSocket, UUID } from "gx-npm-lib";
import { NotifyWhenStickyDetachV2Component } from "gx-npm-ui";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomAppEvent } from "../../app.constants";
import { ChecklistLoadApiResponse, ChecklistWSMessage } from "./checklist.types";
import { ChecklistWSEvent } from "./checklist.constants";
import styles from "./checklist-tab-content-v3.styles.module.scss";

const ChecklistTabContentV3Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [latestWSMessage, setLatestWSMessage] = useState<ChecklistWSMessage | null>(null);
  const reference = useRef<HTMLDivElement>(null);
  const [wsMessage] = useWebSocket<ChecklistWSMessage, unknown>();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    (async () => {
      const response: ChecklistLoadApiResponse = await getAsyncRequest(`api/v2/initiatives/${initiativeId}/checklist`);
      if (response?.status !== 200 || !response.data?.data) {
        window.dispatchEvent(new CustomEvent(CustomAppEvent.FATAL_ERROR));
      }
    })();
  }, [initiativeId]);

  useEffect(() => {
    if (!initiativeId || wsMessage?.initiativeId !== initiativeId) {
      return;
    }
    setLatestWSMessage({ ...wsMessage });
  }, [initiativeId, wsMessage]);

  useEffect(() => {
    if (!latestWSMessage || latestWSMessage.hasBeenRead) {
      return;
    }
    if (latestWSMessage.event === ChecklistWSEvent.CHECKLIST_ITEM_CREATED) {
      console.info("TODO handle ChecklistWSEvent.CHECKLIST_ITEM_CREATED");
    } else if (latestWSMessage.event === ChecklistWSEvent.CHECKLIST_ITEM_DELETED) {
      console.info("TODO handle ChecklistWSEvent.CHECKLIST_ITEM_DELETED");
    } else if (latestWSMessage.event === ChecklistWSEvent.CHECKLIST_ITEM_UPDATED) {
      console.info("TODO handle ChecklistWSEvent.CHECKLIST_ITEM_UPDATED");
    } else if (latestWSMessage.event === ChecklistWSEvent.CHECKLIST_PHASE_UPDATED) {
      console.info("TODO handle ChecklistWSEvent.CHECKLIST_PHASE_UPDATED");
    } else if (latestWSMessage.event === ChecklistWSEvent.INITIATIVE_TEAM_MEMBER_REMOVED) {
      console.info("TODO handle ChecklistWSEvent.INITIATIVE_TEAM_MEMBER_REMOVED");
    } else if (latestWSMessage.event === ChecklistWSEvent.INITIATIVE_TEAM_MEMBER_ROLE_CHANGED) {
      console.info("TODO handle ChecklistWSEvent.INITIATIVE_TEAM_MEMBER_ROLE_CHANGED");
    }
    setLatestWSMessage((prev: ChecklistWSMessage) => ({ ...prev, hasBeenRead: true }));
  }, [latestWSMessage]);

  return (
    <Fragment>
      <NotifyWhenStickyDetachV2Component dependencyData={{}} reference={reference}>
        <div className={styles.contentRoot} ref={reference} />
      </NotifyWhenStickyDetachV2Component>
    </Fragment>
  );
};

export default ChecklistTabContentV3Component;
