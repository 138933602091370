import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { colorPalette } from "gx-npm-common-styles";

const handleDragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const colorLightBerry = colorPalette.interactions.lightBerry.hex;
const colorUnset = "unset";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onUpload: PropTypes.func,
};
const FileDragAndDropUpload = ({ children, onUpload = (_files) => {} }) => {
  const [backgroundColor, setBackgroundColor] = useState();
  const dropRef = useRef(null);

  const handleDragEnter = () => setBackgroundColor(colorLightBerry);
  const handleDragExit = () => setBackgroundColor(colorUnset);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onUpload(e.dataTransfer.files);
    },
    [onUpload]
  );

  useEffect(() => {
    const dragCurrRef = dropRef.current;
    dragCurrRef.addEventListener("dragenter", handleDragEnter);
    dragCurrRef.addEventListener("dragexit", handleDragExit);
    dragCurrRef.addEventListener("dragover", handleDragOver);
    dragCurrRef.addEventListener("drop", handleDrop);
    return () => {
      dragCurrRef.removeEventListener("dragenter", handleDragEnter);
      dragCurrRef.removeEventListener("dragexit", handleDragExit);
      dragCurrRef.removeEventListener("dragover", handleDragOver);
      dragCurrRef.removeEventListener("drop", handleDrop);
    };
  }, [handleDrop]);
  return (
    <div aria-label="file drop area" ref={dropRef} style={{ backgroundColor }}>
      {children}
    </div>
  );
};

FileDragAndDropUpload.propTypes = propTypes;
export default FileDragAndDropUpload;
