import { defaultToZero, isValidResponse } from "gx-npm-lib";
import { getRequest } from "../../context/actions/apiRequests";

const computeRequirementsItemCount = (requirements = []) => {
  return requirements.reduce((acc, curr) => acc + defaultToZero(curr?.items?.length), 0) || 0;
};

const loadScorecardTabData = async (initiativeId = "", onSuccess = (_data) => {}, onError = (_error) => {}) => {
  const url = `api/v2/initiatives/${initiativeId}/scorecards`;
  const response = await getRequest(url);
  if (isValidResponse(response) && typeof response.data.data === "object") {
    onSuccess(response.data.data);
  } else {
    onError(response);
  }
};

export { computeRequirementsItemCount, loadScorecardTabData };
