import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCoal = colorPalette.neutrals.coal.hex;
const variationSemiBold = weightPalette.semiBold.variation;
const weightSemiBold = weightPalette.semiBold.amount;

const numberOfNumberV2Styles = {
  numberOfNumberRoot: {
    alignItems: "center",
    color: colorCoal,
    display: "flex",
    fontVariationSettings: variationSemiBold,
    fontWeight: weightSemiBold,
    letterSpacing: "0.25px",
  },
  numberChild: {
    display: "inline-flex",
    "&.gx-num": {
      fontSize: "18px",
      lineHeight: "25.2px",
    },
    "&.gx-of": {
      fontSize: "14px",
      lineHeight: "21px",
      margin: "auto 8px",
    },
  },
  splitter: {
    margin: "auto 8px",
    verticalAlign: "middle",
  },
};

export { numberOfNumberV2Styles };
