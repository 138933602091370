import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { TypographyComponent, XDateRangePicker } from "gx-npm-ui";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChecklistPhaseStatus } from "../checklist.constants";
import NotStartedTaskIcon from "../../../ui/icons/not-started-task.icon";
import CompletedTaskIcon from "../../../ui/icons/completed-task.icon";
import InProgressTaskIcon from "../../../ui/icons/in-progress-task.icon";
import styles from "./checklist-header-v2.styles";

type DateRange = {
  end: string;
  start: string;
};
type ChecklistHeaderV2ComponentProps = {
  dateRange: DateRange;
  initId: UUID;
  name: string;
  onUpdateDateRange: (range: DateRange | undefined) => void;
  tasksCount: number;
  tasksCompleted: number;
};
const useStyles = makeStyles(() => styles);
const ChecklistHeaderV2Component = ({
  dateRange = { end: "", start: "" },
  initId = "",
  name = "",
  onUpdateDateRange = (_range) => {},
  tasksCount = 0,
  tasksCompleted = 0,
}: ChecklistHeaderV2ComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [status, setStatus] = useState<ChecklistPhaseStatus>(ChecklistPhaseStatus.NOT_STARTED);
  const { role } = useUserInitAccess(initId);
  const isViewOnly = ![InitUserRole.OWNER, InitUserRole.CONTRIBUTOR].includes(role as InitUserRole);
  const DEFAULT_DATE = "1970-01-01";

  useEffect(() => {
    if (tasksCount === 0 || tasksCompleted === 0) {
      setStatus(ChecklistPhaseStatus.NOT_STARTED);
    } else {
      setStatus(tasksCount === tasksCompleted ? ChecklistPhaseStatus.COMPLETED : ChecklistPhaseStatus.IN_PROGRESS);
    }
  }, [tasksCount, tasksCompleted]);

  const handleDateRangeClose = (range?: DateRange) => {
    if (dateRange && range?.end === dateRange.end && range.start === dateRange.start) {
      return;
    }
    onUpdateDateRange(range);
  };

  return (
    <Fragment>
      <div className={classes.headerLeft}>
        <TypographyComponent boldness="medium" rootClassName={classes.titleName} styling="p1">
          {name}
        </TypographyComponent>
      </div>
      <div className={classNames(classes.headerMiddle, status === ChecklistPhaseStatus.COMPLETED && classes.completed)}>
        {status === ChecklistPhaseStatus.NOT_STARTED && <NotStartedTaskIcon />}
        {status === ChecklistPhaseStatus.IN_PROGRESS && <InProgressTaskIcon />}
        {status === ChecklistPhaseStatus.COMPLETED && <CompletedTaskIcon />}

        <TypographyComponent boldness="medium" rootClassName={classes.statusName} styling="p3">
          {status === ChecklistPhaseStatus.NOT_STARTED && t("Not started")}
          {status === ChecklistPhaseStatus.IN_PROGRESS && t("In progress")}
          {status === ChecklistPhaseStatus.COMPLETED && t("Completed")}
        </TypographyComponent>
      </div>
      <div className={classes.headerRight}>
        <XDateRangePicker
          disabled={isViewOnly}
          toolTipText={
            isViewOnly
              ? t("This action is only available to evaluation owners and contributors.")
              : t("Set phase date range")
          }
          toolTipPlacement={isViewOnly ? "left" : "top-start"}
          showToolTip={true}
          showClearButton={true}
          initialValue={dateRange}
          placeholder={t("Add a date range")}
          onChange={(change: { start: string; end: string }) => {
            if (change.start && change.end) {
              change.start = new Date(change.start)?.toISOString()?.split("T")[0];
              change.end = new Date(change.end)?.toISOString()?.split("T")[0];
            }
            change.start = change.start || DEFAULT_DATE;
            change.end = change.end || DEFAULT_DATE;
            handleDateRangeClose(change);
          }}
          toolTipRootClassName={classNames(isViewOnly ? classes.toolTipViewOnly : classes.toolTip)}
        />
      </div>
      <div style={{ clear: "both" }} />
    </Fragment>
  );
};

export default ChecklistHeaderV2Component;
