import { colorPalette } from "gx-npm-common-styles";

const startScorecardDialogStyles = {
  root: {
    "&.gx-score-level-change-dialog": {
      margin: "48px 32px 36px 48px",
    },
  },
  bodyContainer: {},
  subtitle: {
    marginBottom: 8,
    "& .gx-p": {
      color: colorPalette.neutrals.carbon.hex,
    },
  },
  description: {
    marginBottom: 24,
    "& .gx-p": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& button:last-child": {
      marginLeft: "12px",
    },
    paddingTop: "20px",
  },
};

export { startScorecardDialogStyles };
