// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.li5VHcpImRinjiP7al1Z{background-image:linear-gradient(to left, var(--color-primary), #011832);border-radius:8px;position:relative;height:171px;padding:24px 32px;margin-bottom:24px}.li5VHcpImRinjiP7al1Z .R8tFIgoiKGeJBk3mf1fQ,.li5VHcpImRinjiP7al1Z .BqwpTydg9MErETl2LETF,.li5VHcpImRinjiP7al1Z .bFNaurl9OnNQYKEYWgfv{position:absolute}.li5VHcpImRinjiP7al1Z .BqwpTydg9MErETl2LETF{right:17%;top:22%}.li5VHcpImRinjiP7al1Z .R8tFIgoiKGeJBk3mf1fQ{right:11%;top:20%}.li5VHcpImRinjiP7al1Z .bFNaurl9OnNQYKEYWgfv{right:316px;top:-8%}.li5VHcpImRinjiP7al1Z .I1t4N7wLpcDJm2XqTIeW{margin-top:16px;margin-bottom:8px}.li5VHcpImRinjiP7al1Z .O7AL4i8zvQCyR7kxIIW4{max-width:530px}`, "",{"version":3,"sources":["webpack://./src/sections/survey/no-recipients-banner/no-recipients-banner.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wEAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CAEA,oIACE,iBAAA,CAEF,4CACE,SAAA,CACA,OAAA,CAEF,4CACE,SAAA,CACA,OAAA,CAEF,4CACE,WAAA,CACA,OAAA,CAEF,4CACE,eAAA,CACA,iBAAA,CAEF,4CACE,eAAA","sourcesContent":[".root {\n  background-image: linear-gradient(to left, var(--color-primary), #011832);\n  border-radius: 8px;\n  position: relative;\n  height: 171px;\n  padding: 24px 32px;\n  margin-bottom: 24px;\n\n  .arrowHead, .arrowTail, .rectangle {\n    position: absolute;\n  }\n  .arrowTail {\n    right: 17%;\n    top: 22%;\n  }\n  .arrowHead {\n    right: 11%;\n    top: 20%;\n  }\n  .rectangle {\n    right: 316px;\n    top: -8%;\n  }\n  .mainHeading {\n    margin-top: 16px;\n    margin-bottom: 8px;\n  }\n  .subHeading {\n    max-width: 530px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `li5VHcpImRinjiP7al1Z`,
	"arrowHead": `R8tFIgoiKGeJBk3mf1fQ`,
	"arrowTail": `BqwpTydg9MErETl2LETF`,
	"rectangle": `bFNaurl9OnNQYKEYWgfv`,
	"mainHeading": `I1t4N7wLpcDJm2XqTIeW`,
	"subHeading": `O7AL4i8zvQCyR7kxIIW4`
};
export default ___CSS_LOADER_EXPORT___;
