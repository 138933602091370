import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import { ChecklistContextProvider } from "./checklist.context";
import ChecklistTabContentV3Component from "./checklist-tab-content-v3.component";

const ChecklistTabV3Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_CHECKLIST_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  return (
    <ChecklistContextProvider>
      <TabPanelComponent>
        <ChecklistTabContentV3Component />
      </TabPanelComponent>
    </ChecklistContextProvider>
  );
};

export default ChecklistTabV3Component;
