import React, { useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ClickAwayListener, makeStyles, Popper } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { AutocompleteStyles } from "./styles";

const propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  onClickAway: PropTypes.func,
  onInputChange: PropTypes.func,
  renderInput: PropTypes.func,
  renderOption: PropTypes.func,
  suggestions: PropTypes.array,
};
const useStyles = makeStyles(() => AutocompleteStyles);
const AutoComplete = ({ classes, onChange, onInputChange, renderInput, renderOption, suggestions, onClickAway }) => {
  const inputWrapperRef = useRef();
  const baseClasses = useStyles();
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [input, setInput] = useState("");
  const popperId = "autocomplete-popper";
  const addClassIfVendorAlreadyAdded = (suggestion) =>
    suggestion.piProductId && suggestion.isDisable && baseClasses.existingVendor;
  const handleOnInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
    setPopperAnchorEl(inputWrapperRef.current);
    handleEvent(onInputChange, e, value);
  };
  const handleOnClick = (e, selectedItem) => {
    setInput(selectedItem && selectedItem.piProductName);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setPopperAnchorEl(null);
    handleEvent(onChange, e, selectedItem);
  };
  const handleClickAway = (e) => {
    setShowSuggestions(false);
    setPopperAnchorEl(null);
    handleEvent(onClickAway, e);
  };
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      const selectedItem = suggestions && suggestions[activeSuggestionIndex];
      if (!selectedItem.isDisable) {
        handleOnClick(e, selectedItem);
      }
      handleClickAway(e);
    }
  };
  const handleClick = () => {
    setPopperAnchorEl(popperAnchorEl ? null : inputWrapperRef.current);
  };
  const handleInputKeyDown = (e) => {
    if (e.keyCode === 40) {
      if (suggestions && activeSuggestionIndex > suggestions.length) {
        setActiveSuggestionIndex(0);
      } else {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestionIndex) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      } else {
        const length = (suggestions && suggestions.length) || 0;
        setActiveSuggestionIndex(length);
      }
    }
  };
  const inputParams = {
    className: classNames(baseClasses?.input),
    value: input,
    onClick: handleClick,
    onKeyDown: handleInputKeyDown,
    InputProps: {
      onChange: handleOnInputChange,
      onKeyPress: handleOnKeyPress,
    },
  };
  const renderSuggestions = () => {
    return (
      <Popper
        open={true}
        anchorEl={popperAnchorEl}
        id={popperId}
        className={classNames(baseClasses?.popper, classes?.popper)}
      >
        <div className={classNames(baseClasses?.paper, classes?.paper)}>
          {suggestions.length && (
            <ul className={classNames(baseClasses?.listbox, classes?.listbox)}>
              {suggestions.map((suggestion, index) => {
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <li
                    className={classNames(
                      baseClasses?.option,
                      classes?.option,
                      addClassIfVendorAlreadyAdded(suggestion)
                    )}
                    key={index}
                    onClick={(e) => handleOnClick(e, suggestion)}
                    disabled={suggestion.isDisable}
                    role="option"
                    data-option-index={index}
                    data-focus={activeSuggestionIndex === index}
                    aria-selected={activeSuggestionIndex === index}
                    onMouseEnter={() => setActiveSuggestionIndex(index)}
                  >
                    {renderOption(suggestion)}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </Popper>
    );
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classNames(baseClasses?.container, classes?.container)}>
        <div ref={inputWrapperRef}>{renderInput(inputParams)}</div>
        {showSuggestions && input && renderSuggestions()}
      </div>
    </ClickAwayListener>
  );
};

AutoComplete.propTypes = propTypes;
export default AutoComplete;
