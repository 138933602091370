import { colorPalette, weightPalette } from "gx-npm-common-styles";

const evaluationPopperViewStyle = {
  sectionWrapper: {
    color: colorPalette.neutrals.carbon.hex,
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: "6px",
    overflowY: "hidden",
    position: "relative",
    width: "360px",
  },
  productWrapper: {
    display: "flex",
    padding: "20px 12px 0 20px",
    "& .gx-eval-popper-product-logo": {
      alignItems: "center",
      display: "inline-flex",
    },
  },
  productNameContainer: {
    alignItems: "center",
    display: "inline-flex",
    marginLeft: "12px",
  },
  productName: {
    display: "inline-block",
    maxWidth: "232px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  closeBtn: {
    alignItems: "center",
    display: "inline-flex",
    marginLeft: "auto",
    marginRight: "0",
    marginTop: "-8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  titleWrapper: {
    borderBottom: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    margin: "0 20px 0 20px",
    padding: "12px 0 8px 0",
    "& $title, $subTitle": {
      display: "inline-flex",
      lineHeight: "21px",
      color: colorPalette.neutrals.carbon.hex,
      fontSize: "14px",
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
      letterSpacing: "0.25px",
    },
  },
  title: {
    maxWidth: "267px",
  },
  subTitle: {
    float: "right",
  },
  listWrapper: {
    height: "100%",
    listStyle: "none",
    marginBlockEnd: 0,
    marginBlockStart: 0,
    marginLeft: "20px",
    maxHeight: "246px",
    overflowY: "auto",
    paddingInlineStart: "0",
    paddingRight: "20px",
    "& li:first-of-type": {
      paddingTop: "16px",
    },
    "& li:last-of-type": {
      paddingBottom: "12px",
    },
  },
  listFade: {
    background: `linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)`,
    bottom: "0",
    height: "24px",
    left: "0",
    position: "absolute",
    width: "100%",
    zIndex: "1001",
  },
  informativeRow: {
    lineHeight: "18px",
  },
  addTeamMemberContainer: {
    padding: "2px 0px 12px",
    teamMemberMiniButton: {
      padding: "4px 10px 4px 8px",
      fontSize: "14px",
      color: colorPalette.interactions.blueBerry.hex,
    },
  },
};

export default evaluationPopperViewStyle;
