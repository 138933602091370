// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rpF7NLqqqKGsSpVeEmB6 .gx-dialog-body{margin-left:-36px;width:600px}.isQ8zjfnxqWPPD5aFhff{padding:24px 36px 56px 36px}.nBLYCWrTvQgm96DmughJ{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.nBLYCWrTvQgm96DmughJ .O4pdieo3LPIzVLIEfYtQ{display:block}.nBLYCWrTvQgm96DmughJ .R1vJwiXUMSEAyE5ShSRk{position:relative}.nBLYCWrTvQgm96DmughJ .R1vJwiXUMSEAyE5ShSRk .mvSleVHQEvcxv79aw3UU{bottom:-74px;position:absolute;right:0}.LNmt2QIkVq8IP9NEA_MF{align-items:center;display:flex;justify-content:flex-end}.LNmt2QIkVq8IP9NEA_MF .s5BJc9Sic8UxXe8PHCHD{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `rpF7NLqqqKGsSpVeEmB6`,
	"subtitleParagraph": `isQ8zjfnxqWPPD5aFhff`,
	"remainingInitsHeading": `nBLYCWrTvQgm96DmughJ`,
	"headingSecondLine": `O4pdieo3LPIzVLIEfYtQ`,
	"cornerImgContainer": `R1vJwiXUMSEAyE5ShSRk`,
	"cornerImg": `mvSleVHQEvcxv79aw3UU`,
	"footerButtonContainer": `LNmt2QIkVq8IP9NEA_MF`,
	"cancelButton": `s5BJc9Sic8UxXe8PHCHD`
};
export default ___CSS_LOADER_EXPORT___;
