import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./filter.styles.module.scss";
import classnames from "classnames";

type FilterProps = {
  children: ReactNode | ReactNode[];
  rootClassName?: string;
};
const Filter = ({ children, rootClassName }: FilterProps) => {
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const [isFilterBarSticky, setIsFilterBarSticky] = useState(false);

  const handleScroll = () => {
    if (filterContainerRef.current) {
      const offsetTop = filterContainerRef.current.offsetTop;
      const shouldStick = window.scrollY >= offsetTop - 180;
      setIsFilterBarSticky(shouldStick);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div ref={filterContainerRef}>
      <div className={classnames(styles.root, isFilterBarSticky && styles.sticky, rootClassName)}>{children}</div>
    </div>
  );
};

export default Filter;
