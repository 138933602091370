// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.koqhgbkTUBugvPwEDlzK{margin-right:48px;width:648px;min-width:648px}.koqhgbkTUBugvPwEDlzK .MVYturAAvDjo4kBw3sqV,.koqhgbkTUBugvPwEDlzK .wH32sCzI3HVCuHuNA90Y{color:var(--color-neutrals-coal)}@media(min-width: 1440px)and (max-width: 1919px){.koqhgbkTUBugvPwEDlzK{margin-right:74px}}@media(min-width: 1240px)and (max-width: 1439px){.koqhgbkTUBugvPwEDlzK{margin-right:64px;min-width:572px;width:572px}}@media(max-width: 1239px){.koqhgbkTUBugvPwEDlzK{margin-right:64px;min-width:452px;width:452px}}.MVYturAAvDjo4kBw3sqV{font-weight:500;font-variation-settings:"wght" 500;padding-bottom:16px}.ISpUn8Z5SGmL36QCbXGX{padding-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/gartner-insights/components/technology-type/technology-type.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,eAAA,CAEA,wFACE,gCAAA,CAGF,iDATF,sBAUI,iBAAA,CAAA,CAGF,iDAbF,sBAcI,iBAAA,CACA,eAAA,CACA,WAAA,CAAA,CAGF,0BAnBF,sBAoBI,iBAAA,CACA,eAAA,CACA,WAAA,CAAA,CAIJ,sBACE,eAAA,CACA,kCAAA,CACA,mBAAA,CAGF,sBAAA,kBAAA","sourcesContent":[".root {\n  margin-right: 48px;\n  width: 648px;\n  min-width: 648px;\n\n  & .title, & .description {\n    color: var(--color-neutrals-coal);\n  }\n\n  @media (min-width: 1440px) and (max-width: 1919px) {\n    margin-right: 74px;\n  }\n\n  @media (min-width: 1240px) and (max-width: 1439px) {\n    margin-right: 64px;\n    min-width: 572px;\n    width: 572px;\n  }\n\n  @media (max-width: 1239px) {\n    margin-right: 64px;\n    min-width: 452px;\n    width: 452px;\n  }\n}\n\n.title {\n  font-weight: 500;\n  font-variation-settings: \"wght\" 500;\n  padding-bottom: 16px;\n}\n\n.name { padding-bottom: 8px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `koqhgbkTUBugvPwEDlzK`,
	"title": `MVYturAAvDjo4kBw3sqV`,
	"description": `wH32sCzI3HVCuHuNA90Y`,
	"name": `ISpUn8Z5SGmL36QCbXGX`
};
export default ___CSS_LOADER_EXPORT___;
