import { FC } from "react";
import styles from "./survey-table.styles.module.scss";
import { table } from "./mock";
import { TableComponent } from "../../../ui/table";
import { createHeaderRows } from "./utils/create-header-rows/create-header-rows";
import { Product } from "./survey-table.constants";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";

type SurveyTableProps = {
  disabled: boolean;
  initiativeId: string;
  products: Product[];
};

export const SurveyTableComponent: FC<SurveyTableProps> = ({ disabled, initiativeId, products }) => {
  const { t } = useTranslation();
  const headerRows = products.length > 0 ? createHeaderRows({ products, disabled, initiativeId, t }) : [];

  const dummyFilters = (
    <div>
      <TypographyComponent styling={"p3"} boldness={"regular"} color={"iron"}>
        {t("Filter by")} - PriorityFilterComponent / VendorFilterComponent
      </TypographyComponent>
    </div>
  );
  return (
    <div className={styles.root}>
      <TableComponent
        headerRows={headerRows}
        bodyRowSets={table.bodyRowSets}
        footerRowSet={table.footerRowSet}
        filters={dummyFilters}
      />
    </div>
  );
};
