/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from "react";
import { checklistReducer, checklistState } from "./reducers/checklistReducer";
import { requirementsReducer, requirementsState } from "./reducers/requirementsReducer";

const EvaluationStateContext = createContext();

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const initialState = {
  checklist: checklistState,
  requirements: requirementsState,
};

const rootReducer = combineReducers({
  checklist: checklistReducer,
  requirements: requirementsReducer,
});

const EvaluationStateProvider = (props) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return <EvaluationStateContext.Provider value={[state, dispatch]}>{props.children}</EvaluationStateContext.Provider>;
};

const MockEvaluationStateProvider = ({ children, mockData = {} }) => {
  const mockedState = { ...initialState, ...mockData };
  const [state, dispatch] = useReducer(rootReducer, mockedState);
  return <EvaluationStateContext.Provider value={[state, dispatch]}>{children}</EvaluationStateContext.Provider>;
};

export { EvaluationStateContext, EvaluationStateProvider, MockEvaluationStateProvider };
