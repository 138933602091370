import classNames from "classnames";
import { InitUserRole } from "gx-npm-lib";
import { TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import styles from "./team-member-list.styles.module.scss";
import { TeamMemberType } from "../../overview.types";
import React from "react";

const MAX_MEMBER_TO_DISPLAY = 2;
const TOOLTIP_MAX_LENGTH = 115;
const DISPLAY_NAME_MAX_LENGTH = 37;
const ADDITIONAL_MEMBER_THRESHOLD = 98;

type TeamMembersV2Props = {
  memberList: TeamMemberType[];
};

const TeamMemberList: React.FC<TeamMembersV2Props> = ({ memberList = [] }) => {
  const firstOwner = memberList.find((member) => member.role === InitUserRole.OWNER);
  const otherMembers = memberList.filter((member) => firstOwner && member.email !== firstOwner.email);
  const otherMemberCount = otherMembers.length;

  const additionalUserList = otherMemberCount > MAX_MEMBER_TO_DISPLAY ? otherMembers.splice(1, otherMemberCount) : [];

  const fullNameList = additionalUserList.map((user) => (user.hasViewed ? user.fullName : user.email));

  const truncateString = (str: string, maxLength: number) =>
    str.length > maxLength ? str.substring(0, maxLength).concat("...") : str;

  const additionalMemberTooltip = truncateString(fullNameList.join(", "), TOOLTIP_MAX_LENGTH);
  const additionalMember = truncateString(additionalMemberTooltip, DISPLAY_NAME_MAX_LENGTH);

  const additionalMemberCount = otherMemberCount - MAX_MEMBER_TO_DISPLAY;
  const displayTeamRow = otherMemberCount > 0;

  return (
    <div data-testid="team-members-root" className={classNames(styles.memberAvatarRoot)}>
      {firstOwner && <UsernameDisplay name={firstOwner.fullName} />}
      {displayTeamRow && (
        <div className={styles.otherMembersRow}>
          {otherMembers.slice(0, MAX_MEMBER_TO_DISPLAY).map((member) => (
            <div key={member.email}>
              <UsernameDisplay
                isUnknown={!member.hasViewed}
                name={member.hasViewed ? member.fullName : member.email}
                rootClassName={classNames(styles.userNameList)}
                showName={true}
              />
            </div>
          ))}
          {additionalMemberCount > 0 && (
            <div className={styles.additionalMemberCount}>
              <TooltipV2
                placement="top-start"
                rootClassName={styles.additionalMemberTooltip}
                PopperProps={{ modifiers: { offset: { offset: "0, 5" } } }}
                title={additionalMemberTooltip}
              >
                <span>
                  <TypographyComponent
                    rootClassName={classNames(styles.additionalMembersBadge)}
                    boldness={"medium"}
                    styling={"p1"}
                  >
                    {additionalMemberCount < ADDITIONAL_MEMBER_THRESHOLD
                      ? `+${additionalMemberCount + 1}`
                      : `${additionalMemberCount + 1}+`}
                  </TypographyComponent>
                </span>
              </TooltipV2>

              <TypographyComponent
                color={"coal"}
                rootClassName={classNames(styles.memberName)}
                styling={"p3"}
                boldness={"medium"}
              >
                {additionalMember}
              </TypographyComponent>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamMemberList;
