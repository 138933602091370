import { defaultToEmptyArray, isValidResponse } from "gx-npm-lib";
import { isAddOp, isEditOp, isReorderOp, operations } from "./operationTypes";

const isValidTransactionResponse = (response, operation) => {
  let isValid = [200, 201, 204].indexOf(response?.status) > -1;
  if (isValid && isAddOp(operation)) {
    isValid = response.data?.data?.id;
  }
  return isValid;
};

const cloneListFromState = (state, key) => {
  return [...(Array.isArray(state?.[key]?.list) ? state[key].list : [])];
};

/**
 * @param {string} id id value to put after initiatives
 * @param {string} endpoint endpoint value to put after id slash, will add empty string if null is put in with no slash
 * @param {number} apiVersion api version to use, defaults to 2
 * @returns api URL for initiatives
 */
const getApiUrl = (id, endpoint = null, apiVersion = 2) => {
  const urlEndpoint = endpoint !== null ? `/${endpoint}` : ``;
  return `api/v${apiVersion}/initiatives/${id}${urlEndpoint}`;
};

/**
 * will return GET response data from an API, or an error if one occured, along with loading flag to be spread into state
 * @param {object} response API response
 * @param {string} storedKey name of key to store data in to be spread into app state
 * @returns object with data or error, and loading flag
 */
const processGetResponse = (response, storedKey = "") => {
  const payload = {};
  if (isValidResponse(response) && typeof response?.data?.data === "object") {
    const data = response.data.data;
    const key = storedKey || "data";
    payload[key] = data;
  } else {
    payload.error = { response };
  }
  payload.isLoading = false;
  return payload;
};

/**
 * create a unique array of transaction requests where uniqueness means that no
 * two transactions are edit operations for the same exact key (matching root
 * and child id). when that occurs, the new transation replaces the item in the
 * existing transaction array - the purpose of this is to continue to reattempt
 * failed requests automatically whenever a user saves a new piece of a form
 *
 * @param {*} existingTransations existing error transactions
 * @param {*} newTransaction new error transaction to be checked if it needs to
 * replace existing item in array
 * @returns set of unquie transaction objects to be saved against API
 */
const transactionUniqueArray = (existingTransations, newTransaction) => {
  let replaceTransactionIndex = -1;
  const uniqueArray = [...defaultToEmptyArray(existingTransations)];
  if (isEditOp(newTransaction.operation)) {
    const { length } = uniqueArray;
    for (let idx = 0; idx < length; idx++) {
      if (uniqueArray[idx].operation === newTransaction.operation) {
        if (uniqueArray[idx].parentId === newTransaction.parentId) {
          if (uniqueArray[idx].childId === newTransaction.childId) {
            if (uniqueArray[idx].key === newTransaction.key) {
              replaceTransactionIndex = idx;
              break;
            }
          }
        }
      }
    }
  }
  if (replaceTransactionIndex > -1) {
    uniqueArray[replaceTransactionIndex] = newTransaction;
  } else {
    uniqueArray.push(newTransaction);
  }
  return uniqueArray;
};

const transactionPayload = (data) => {
  const payload = {};
  if (isEditOp(data.operation)) {
    payload[data.key] = data.value;
  } else if (isReorderOp(data.operation)) {
    payload.index = data.indexDest;
  }
  return payload;
};

/**
 * ops (childAdd or parentEdit): /{initId}/checklist/{phaseId}/{itemId}
 * all other: /{initId}/checklist/{phaseId}
 * @data transaction data for request
 * @returns url for request
 */
const transactionUrl = (data, section, parentName, childName) => {
  const { childId, initiativeId, operation, parentId } = data;
  const rootPath = getApiUrl(initiativeId, `${section}/${parentName}`);
  let url = "";
  switch (operation) {
    case operations.parentDelete:
    case operations.parentEdit:
      url = `${rootPath}/${parentId}`;
      break;
    case operations.childAdd:
      url = `${rootPath}/${parentId}/${childName}`;
      break;
    case operations.childDelete:
    case operations.childEdit:
    case operations.childReorder:
      url = `${rootPath}/${parentId}/${childName}/${childId}`;
      break;
    case operations.parentAdd:
    default:
      url = rootPath;
  }
  return url;
};

export {
  cloneListFromState,
  getApiUrl,
  isValidTransactionResponse,
  processGetResponse,
  transactionPayload,
  transactionUniqueArray,
  transactionUrl,
};
