import { generateUuid } from "../../lib";
import { checklistTypes } from "./actionTypes";
import {
  getApiUrl,
  isValidTransactionResponse,
  processGetResponse,
  transactionPayload,
  transactionUniqueArray,
  transactionUrl,
} from "./actionUtils";
import { deleteRequest, getRequest, postRequest } from "./apiRequests";
import { isAddOp, isDeleteOp, isEditOp, isReorderOp, operations } from "./operationTypes";

const childName = "item";
const parentName = "phase";
const section = "checklist";

const loadChecklistData = (_state, dispatch, data) => {
  async function loadData() {
    const url = getApiUrl(data?.initiativeId, section);
    const type = checklistTypes.LOAD_CHECKLIST_DATA;
    if (!data?.isReloading) {
      dispatch({ isLoading: true, type });
    }
    const response = await getRequest(url);
    const payload = processGetResponse(response, "list");
    dispatch({ ...payload, type });
  }
  loadData();
};

const getDefaultChildObject = () => {
  return {
    complete: false,
    description: "",
    id: generateUuid(),
    name: "",
    ownerName: "",
    assignedUsers: [],
    dueDate: "",
  };
};

const clearChecklistData = (_state, dispatch, _data) => {
  const type = checklistTypes.CLEAR_CHECKLIST_DATA;
  dispatch({ type });
};

const clearChecklistErrors = (state, dispatch, data) => {
  clearChecklistData(state, dispatch, data);
};

/**
 * transaction requests for checklist, based on data objects variables, will update
 * state, dispatch changes to reducer, and make API requests
 *
 * @param {*} state current state when request was made
 * @param {*} dispatch function to fire event to reducer
 * @param {*} data information for request - the transaction object
 */
const actionChecklistTransaction = (state, dispatch, data) => {
  switch (data?.operation) {
    case operations.childAdd:
      actionChecklistAddItem(state, dispatch, data);
      break;
    case operations.childDelete:
      actionChecklistDeleteItem(state, dispatch, data);
      break;
    case operations.childEdit:
    case operations.parentEdit:
      actionChecklistEditItem(state, dispatch, data);
      break;
    case operations.childReorder:
      actionChecklistReorderItem(state, dispatch, data);
      break;
    case operations.removeChecklistAssignment:
      actionChecklistRemoveAssignments(state, dispatch, data);
      break;
    default:
    // do nothing
  }
};

const actionChecklistRemoveAssignments = (state, dispatch, data) => {
  const typeRemoveUsers = checklistTypes.CHECKLIST_REMOVE_ASSIGNMENTS;
  dispatch({ type: typeRemoveUsers, ...data });
};

const actionChecklistAddItem = (state, dispatch, data) => {
  const { initiativeId, parentId, parentIndex } = data;
  const item = data.item || getDefaultChildObject();
  const typeAddChild = checklistTypes.CHECKLIST_ADD_CHILD;
  dispatch({ initiativeId, item, parentId, parentIndex, type: typeAddChild });
  actionSavePendingTransactions(state, dispatch, data);
};

const actionChecklistDeleteItem = (state, dispatch, data) => {
  const { childId, childIndex, initiativeId, parentId, parentIndex } = data;
  const typeDelChild = checklistTypes.CHECKLIST_DELETE_CHILD;
  const actionData = { childId, childIndex, parentId, parentIndex };
  dispatch({ ...actionData, initiativeId, type: typeDelChild });
  actionSavePendingTransactions(state, dispatch, data);
};

const actionChecklistEditItem = (state, dispatch, data) => {
  const { childId, childIndex, parentId, parentIndex } = data;
  const { initiativeId, key, value } = data;
  const typeEdit = childId ? checklistTypes.CHECKLIST_EDIT_CHILD : checklistTypes.CHECKLIST_EDIT_PARENT;
  const actionData = { childId, childIndex, parentId, parentIndex };
  dispatch({ ...actionData, initiativeId, key, value, type: typeEdit });
  actionSavePendingTransactions(state, dispatch, data);
};

const actionChecklistReorderItem = (state, dispatch, data) => {
  const { childId, childIndex, parentId, parentIndex } = data;
  const { indexDest, indexSource, initiativeId } = data;
  const typeReorderChild = checklistTypes.CHECKLIST_REORDER_CHILD;
  const actionData = { childId, childIndex, parentId, parentIndex };
  dispatch({
    ...actionData,
    indexDest,
    indexSource,
    initiativeId,
    type: typeReorderChild,
  });
  actionSavePendingTransactions(state, dispatch, data);
};

/**
 * creates a list of transactions based on any existing failed transactions that
 * did not return 2xx from API, removes any possible colluisions, and sends out
 * all API requests
 *
 * @param {*} state current api state
 * @param {*} dispatch function to comm with reducer
 * @param {*} data transaction information for API
 */
const actionSavePendingTransactions = (state, dispatch, data) => {
  // flag to stop transaction before saving
  if (data.noSave) {
    return null;
  }
  const transactions = transactionUniqueArray(state[section].failures, data);
  if (state[section].failures?.length > 0) {
    dispatch({ type: checklistTypes.CHECKLIST_CLEAR_FAILURES });
  }
  transactions.forEach((transaction) => {
    actionSaveTransaction(dispatch, transaction);
  });
};

/**
 * makes request to API and updates the status in the reducer
 * @param {*} dispatch function to comm with reducer
 * @param {*} data transaction information for API
 */
const actionSaveTransaction = async (dispatch, data) => {
  dispatch({ type: checklistTypes.CHECKLIST_SAVE_DATA_START });
  const url = transactionUrl(data, section, parentName, childName);
  const payload = transactionPayload(data);
  let response = null;
  if (isAddOp(data.operation) || isEditOp(data.operation) || isReorderOp(data.operation)) {
    response = await postRequest(url, payload);
  } else if (isDeleteOp(data.operation)) {
    response = await deleteRequest(url);
  }

  const isSuccess = isValidTransactionResponse(response, data.operation);
  if (isSuccess) {
    dispatch({ type: checklistTypes.CHECKLIST_SAVE_DATA_SUCCESS });
  } else if (response?.status === 401) {
    const typeError = checklistTypes.CHECKLIST_SAVE_DATA_ERROR;
    dispatch({ transaction: data, type: typeError, isNotEditable: true });
  } else {
    const typeError = checklistTypes.CHECKLIST_SAVE_DATA_ERROR;
    dispatch({ transaction: data, type: typeError });
  }
  dispatch({ type: checklistTypes.CHECKLIST_SAVE_DATA_COMPLETE });

  // update id value of adds from API response
  const addOperations = [operations.childAdd, operations.parentAdd];
  if (addOperations.indexOf(data.operation) > -1) {
    const { childIndex, initiativeId, parentId, parentIndex } = data;
    const value = response?.data?.data?.id || "";
    const typeEdit = checklistTypes.CHECKLIST_EDIT_CHILD;
    const operationData = { childIndex, initiativeId, parentId, parentIndex };
    const addData = { ...operationData, value, key: "id" };
    dispatch({ ...addData, type: typeEdit });
  }
};

export { actionChecklistTransaction, loadChecklistData, clearChecklistErrors };
