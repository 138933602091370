import { colorPalette } from "gx-npm-common-styles";

const ImportReqIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M21.7071 6.29289C21.8946 6.48043 22 6.73478 22 7L22 22C22 22.5523 21.5523 23 21 23L3 23C2.44772 23 2 22.5523 2 22L2 7C2 6.44771 2.44772 6 3 6L6 6C6.55228 6 7 6.44771 7 7C7 7.55228 6.55228 8 6 8L4 8L4 21L20 21L20 8L18 8C17.4477 8 17 7.55228 17 7C17 6.44772 17.4477 6 18 6L21 6C21.2652 6 21.5196 6.10536 21.7071 6.29289Z"
        fill={colorPalette.interactions.blueBerry.hex}
        fillRule="evenodd"
      />
      <path
        d="M13.0242 1.09052L13.0242 10.4607L14.8242 10.4607C15.2642 10.4607 15.4942 11.0007 15.1742 11.3107L12.3842 14.0907C12.1842 14.2807 11.8742 14.2807 11.6742 14.0907L9.38573 11.3107C9.06573 11.0007 8.78418 10.4607 9.23418 10.4607L11.0242 10.4607L11.0242 1.09052C11.0242 0.540517 11.4742 0.0905169 12.0242 0.0905169C12.5742 0.0905169 13.0242 0.540517 13.0242 1.09052Z"
        fill={colorPalette.interactions.blueBerry.hex}
      />
    </svg>
  );
};

export default ImportReqIcon;
