import { useTranslation } from "react-i18next";
import styles from "./technology-type.module.scss";
import { TypographyComponent } from "gx-npm-ui";

type TechnologyTypeProps = {
  templateName: string;
  templateDescription: string;
};
const TechnologyType = ({ templateName, templateDescription }: TechnologyTypeProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.root}>
        <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={styles.title}>
          {t("Gartner technology type")}
        </TypographyComponent>
        <TypographyComponent styling={"p1"} boldness={"medium"} rootClassName={styles.name}>
          {templateName}
        </TypographyComponent>
        <TypographyComponent styling={"p3"} rootClassName={styles.description}>
          {templateDescription}
        </TypographyComponent>
      </div>
    </>
  );
};

export default TechnologyType;
