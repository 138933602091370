import React, { Fragment, useState } from "react";
import { Fade } from "@material-ui/core";
import classNames from "classnames";
import classes from "./assigned-evaluator.styles.module.scss";
import { UsernameDisplay } from "../../usernameDisplay";
import { Loader } from "../../loader";
import { DialogRemoveAssignedEvaluator, Evaluator } from "../assignment-popper.types";
import DialogUserRemove from "../assignment-dialog-user-remove/dialog-user-remove.component";

type AssignedEvaluatorType = {
  dialogRemoveAssignedEvaluator?: DialogRemoveAssignedEvaluator;
  evaluator: Evaluator;
  onRemoveAssignedEvaluatorClick: (evaluator: Evaluator) => void;
  removeBtnText?: string;
  showDialogRemoveAssignedEvaluator?: boolean;
};

const AssignedEvaluator = (props: AssignedEvaluatorType) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = (selectedEvaluator: Evaluator) => {
    if (props.showDialogRemoveAssignedEvaluator) {
      setShowDialog(true);
    } else {
      if (props.onRemoveAssignedEvaluatorClick) {
        setIsLoading(true);
        props.onRemoveAssignedEvaluatorClick(selectedEvaluator);
      }
    }
  };

  const onClickDialogConfirm = (selectedEvaluator: Evaluator) => {
    setShowDialog(false);
    if (props.onRemoveAssignedEvaluatorClick) {
      setIsLoading(true);
      props.onRemoveAssignedEvaluatorClick(selectedEvaluator);
    }
  };

  return (
    <Fragment>
      <Fade key={`assigned-eval-${props.evaluator.email}`} in={true}>
        <button
          aria-label={"Remove Evaluator"}
          className={classNames(classes.gxEvaluatorBtn, classes.noPadding, isLoading && "loading")}
          onClick={() => onClick(props.evaluator)}
          disabled={isLoading}
        >
          <UsernameDisplay
            isUnknown={!props.evaluator.hasViewed}
            rootClassName={classes.gxEvaluator}
            name={props.evaluator.hasViewed ? props.evaluator.fullName : props.evaluator.email}
            isSmallerIcon={true}
          />
          <span className={classNames(classes.gxEvaluatorRemove)}>
            <span className={classes.gxPillBg}>
              <span className={classes.gxPillRemoveBg}>
                {isLoading ? (
                  <span className={classNames(classes.gxBtnLoader, classes.gxBtnLoaderRed)}>
                    <Loader size={16} variant="indeterminate" />
                  </span>
                ) : (
                  <Fragment>
                    <span className={classes.gxBtnContent}>{props.removeBtnText}</span>
                  </Fragment>
                )}
              </span>
            </span>
          </span>
        </button>
      </Fade>
      {props.showDialogRemoveAssignedEvaluator && props.dialogRemoveAssignedEvaluator && (
        <DialogUserRemove
          confirmText={props.dialogRemoveAssignedEvaluator.confirmText}
          isOpen={showDialog}
          name={props.evaluator.fullName}
          onConfirm={() => {
            onClickDialogConfirm(props.evaluator);
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
          text={props.dialogRemoveAssignedEvaluator.text}
          title={props.dialogRemoveAssignedEvaluator.title}
        />
      )}
    </Fragment>
  );
};

export default AssignedEvaluator;
