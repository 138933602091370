import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { handleEvent } from "gx-npm-lib";
import { makeStyles } from "@material-ui/core";
import { getFadeStyles } from "./styles";

const propsAreEqual = (prev, next) => {
  return (
    prev.duraction === next.duration &&
    prev.fadeIn === next.fadeIn &&
    prev.fadeOut === next.fadeOut &&
    prev.rootClassName === next.rootClassName
  );
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  duration: PropTypes.number,
  fadeIn: PropTypes.bool,
  fadeOut: PropTypes.bool,
  onExit: PropTypes.func,
  rootClassName: PropTypes.string,
};
const Fade = ({
  children = null,
  duration = 300,
  fadeIn = false,
  fadeOut = false,
  onExit = null,
  rootClassName = "",
}) => {
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    if (!timer && (fadeOut || fadeIn)) {
      const fadeTimer = setTimeout(() => {
        handleEvent(onExit);
        setTimer(0);
      }, duration);
      setTimer(fadeTimer);
    }
  }, [duration, fadeIn, fadeOut, onExit, timer]);

  const useStyles = makeStyles(() => getFadeStyles(duration));
  const classes = useStyles();
  const fadeClass = fadeOut ? classes.itemFadeout : classes.itemFadeIn;
  return (
    <div className={classNames("gx-fade-effect", (fadeIn || fadeOut) && fadeClass, rootClassName)}>{children}</div>
  );
};

Fade.propTypes = propTypes;
const MemorizedFade = memo(Fade, propsAreEqual);
export { Fade, MemorizedFade };
