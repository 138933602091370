import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { RadioButton, TypographyComponent } from "gx-npm-ui";
import styles from "./radio-button-option.styles";

const propTypes = {
  count: PropTypes.number,
  handleChange: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const RadioButtonOption = ({
  count = 0,
  handleChange = (_event) => {},
  isSelected = false,
  label = "",
  value = "",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <RadioButton checked={isSelected} label={label} onChange={handleChange} value={value} />
      <TypographyComponent rootClassName={classNames(classes.countWrapper)} styling={"p3"} boldness={"semi"}>
        {count}
        <TypographyComponent element={"span"} rootClassName={classNames(classes.countText)}>
          {t(" items to be scored")}
        </TypographyComponent>
      </TypographyComponent>
    </div>
  );
};

RadioButtonOption.propTypes = propTypes;
export default RadioButtonOption;
