import { colorPalette } from "gx-npm-common-styles";

const btnHoverBackgroundColor = colorPalette.neutrals.pearl.hex;
const btnRippleBackgroundColor = colorPalette.interactions.mildBerry.hex;
const colorNeutralsCoalHex = colorPalette.neutrals.coal.hex;
const colorNeutralsIronHex = colorPalette.neutrals.iron.hex;
const colorNeutralsSilverHex = colorPalette.neutrals.silver.hex;

const EvaluationScorecardAssignmentsAvatarStyles = {
  evaluatorAvatarsContainer: {
    marginLeft: "auto",
    order: 2,
  },
  "@global": {
    ".gx-additional-team-tooltip": {
      autoCenterOffset: "3.5",
      parentWidth: "auto",
      top: "42px !important",
      width: "max-content",
    },
  },

  evaluatorAvatarRoot: {
    "& .gx-scoring-evaluators-username-display": {
      zIndex: "2",
      position: "relative",
      "& .MuiAvatar-root": {
        height: "22px",
        width: "22px",
        fontSize: "12px",
      },
      "&.unassigned": {
        marginRight: 0,
        "& .MuiAvatar-root": {
          backgroundColor: btnHoverBackgroundColor,
          "& svg": { height: 16, width: 16, "& path": { fill: colorNeutralsIronHex } },
        },
      },
      "&.single": {
        marginRight: 0,
      },
    },
    "&.one-avatar": {
      marginRight: 10,
    },
    "& .gx-user .gx-ava-owner": {
      display: "none",
    },

    "& $evaluatorSingleWrapper, & $evaluatorsGroupWrapper": {
      alignItems: "baseline",
      display: "flex",
      "& .gx-user": {
        marginRight: "-7px",
        paddingTop: 0,
      },
      "& $additionalEvaluatorsBadge": {
        alignItems: "center",
        backgroundColor: colorNeutralsSilverHex,
        borderRadius: "50%",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        marginRight: "-7px",
        width: "24px",
        zIndex: "1",
        position: "relative",
        "&$additionalEvaluatorsCount": {
          color: colorNeutralsCoalHex,
        },
      },
      "& $groupPeople": {
        marginRight: 72,
      },
    },
  },
  evaluatorSingleWrapper: {},
  evaluatorsGroupWrapper: {
    "&:not(.disabled)": { cursor: "pointer" },
    "&.evaluators": {
      position: "relative",
      zIndex: 1,
      "&:not(.disabled)": {
        "&:before": {
          zIndex: 0,
          backgroundPosition: "center",
          borderRadius: 40,
          content: "' '",
          height: "calc(100% + 16px)",
          left: -8,
          position: "absolute",
          top: -8,
          width: "calc(100% + 23px)",
        },
        "&:hover, &:focus": {
          outline: 0,
          "&:before": {
            background: `${btnHoverBackgroundColor} radial-gradient(circle, transparent 1%, ${btnHoverBackgroundColor} 1%) center/15000%`,
            backgroundColor: btnHoverBackgroundColor,
          },
        },
      },
    },
  },

  btnAssigned: {
    "&.gx-req-root-button": {
      height: 40,
      marginRight: -8,
      width: 40,
    },
  },

  additionalEvaluatorsBadge: {},
  additionalEvaluatorsCount: {},
  groupPeople: {},
};

export { btnHoverBackgroundColor, btnRippleBackgroundColor, EvaluationScorecardAssignmentsAvatarStyles };
