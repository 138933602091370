const requirementFooterStyles = {
  footer: {
    paddingTop: "24px",
    paddingBottom: "48px",
    "& svg": {
      marginRight: "12px",
    },
  },
  tooltipWrapper: {
    display: "inline-block",
  },
};

export { requirementFooterStyles };
