import { UUID } from "gx-npm-lib";
import { useParams } from "react-router-dom";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import { OverviewContextProvider } from "./overview.context";
import OverviewTabContentV2 from "./overview-tab-content-v2.component";

const OverviewTabV2 = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  return (
    <OverviewContextProvider>
      <TabPanelComponent>
        <OverviewTabContentV2 initiativeId={initiativeId} />
      </TabPanelComponent>
    </OverviewContextProvider>
  );
};

export default OverviewTabV2;
