import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Button, TypographyComponent } from "gx-npm-ui";
import ErrorIcon from "./errorIcon";
import { processErrorStyles as styles } from "./styles";

const propTypes = { onClickBtn: PropTypes.func };
const useStyles = makeStyles(() => styles);
const ProcessUploadErrorDisplay = ({ onClickBtn = (_event) => {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const errorMessagePoints = [
    t("Check your file type (.xlsx, .xls)"),
    t("Make sure your file name is under 400 characters."),
    t("Make sure the column headers match with what's in the template."),
    t("Make sure the excel sheet name matches with what's in the template."),
    t("Check that your priorities are set to only low, medium, or high."),
    t("Check that you have only entered plain text."),
    t("Max row count is 1,000."),
  ];

  return (
    <div className={classes.errorContainer}>
      <div className={classes.errorLeft}>
        <p className={classNames("p3", "semi-bold", classes.errorTitle)}>
          <ErrorIcon />
          <TypographyComponent className={classes.errorTitleText} color={"inherit"} styling={"p3"} boldness={"medium"}>
            {t("Error - spreadsheet validation failed:")}
          </TypographyComponent>
        </p>
        <ul className={classes.errorPoints}>
          {errorMessagePoints.map((point) => {
            return (
              <li key={point}>
                <TypographyComponent color={"iron"} styling={"p4"} boldness={"medium"}>
                  {point}
                </TypographyComponent>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={classes.errorRight}>
        <Button ariaLabel={t("reset form")} onClick={onClickBtn}>
          {t("RESET")}
        </Button>
      </div>
    </div>
  );
};

ProcessUploadErrorDisplay.propTypes = propTypes;
export default ProcessUploadErrorDisplay;
