import { colorPalette } from "gx-npm-common-styles";

const NoVendorsViewStyles = {
  noVendors: {
    paddingTop: "44px",
    paddingBottom: "67px",
    backgroundColor: colorPalette.neutrals.silver.hex,
    textAlign: "center",
    borderRadius: "8px",
    "& .gx-no-vendors-msg": {
      marginBottom: "24px",
      color: colorPalette.neutrals.iron.hex,
    },
  },
  noVendorsImg: {
    marginTop: "42px",
  },
};

export default NoVendorsViewStyles;
