// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lpi8rWTXPftUSBlTmVVz{padding-left:16px}.nsClCgNV8KQZMRzx1sLQ{align-items:center;display:flex;padding:10px 16px}.nwGTpiTWPLOimf6S9g6i{max-height:24px;width:24px}.nwGTpiTWPLOimf6S9g6i img{max-height:24px;width:100%}.cECLK9fMcU9XLf2wVtUJ{width:351px}.RwQI9cu09_7smzOoLRfI{align-items:center;display:flex;margin-left:36px;width:62px}.RwQI9cu09_7smzOoLRfI .GPN8JhpWpUIR8ejaGLdI{align-items:center;display:flex;height:24px;justify-content:center;width:24px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-add-v2/products-add-dialog-v2/components/products-add-dialog-body-option-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CACA,UAAA,CAEA,0BACE,eAAA,CACA,UAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":[".title {\n  padding-left: 16px;\n}\n\n.optionsWrapper {\n  align-items: center;\n  display: flex;\n  padding: 10px 16px;\n}\n\n.logo {\n  max-height: 24px;\n  width: 24px;\n\n  & img {\n    max-height: 24px;\n    width: 100%;\n  }\n}\n\n.alreadyAdded {\n  width: 351px;\n}\n\n.addedVendor {\n  align-items: center;\n  display: flex;\n  margin-left: 36px;\n  width: 62px;\n\n  & .imageContainer {\n    align-items: center;\n    display: flex;\n    height: 24px;\n    justify-content: center;\n    width: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Lpi8rWTXPftUSBlTmVVz`,
	"optionsWrapper": `nsClCgNV8KQZMRzx1sLQ`,
	"logo": `nwGTpiTWPLOimf6S9g6i`,
	"alreadyAdded": `cECLK9fMcU9XLf2wVtUJ`,
	"addedVendor": `RwQI9cu09_7smzOoLRfI`,
	"imageContainer": `GPN8JhpWpUIR8ejaGLdI`
};
export default ___CSS_LOADER_EXPORT___;
