import { colorPalette, weightPalette } from "gx-npm-common-styles";

const deleteReqDetailsDialogEvaluatorsListStyles = {
  listedItems: {
    "& .gx-list-header-paragraph": {
      marginBottom: "12px",
    },
    "& $evaluatorContainer": {
      "&$extraEvaluators .MuiAvatar-root": {
        backgroundColor: colorPalette.neutrals.silver.hex,
        height: "32px",
        paddingTop: "1px",
        width: "32px",
      },
      "& .gx-user": {
        paddingTop: 0,
      },
      "& .MuiAvatar-root": {
        color: colorPalette.neutrals.coal.hex,
        fontSize: "12px",
        fontVariationSettings: weightPalette.medium.variation,
        fontWeight: weightPalette.medium.amount,
        height: "30px",
        letterSpacing: "0.25px",
        lineHeight: "21px",
        paddingTop: "1px",
        width: "30px",
      },
    },
  },
  evaluatorContainer: {
    display: "inline-block",
    marginRight: "12px",
  },
  extraEvaluators: {},
};

export default deleteReqDetailsDialogEvaluatorsListStyles;
