import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import { SelectionContext } from "../../../../../selection.context";
import ChecklistStatusDisplay from "./checklist-status-display.component";
import styles from "./details-checklist-section.module.scss";

const DetailsChecklistSection = () => {
  const { t } = useTranslation();
  const { checklistPhases } = useContext(SelectionContext);

  return (
    <div className={styles.checklistContainer}>
      <TypographyComponent boldness={"regular"} rootClassName={styles.checklistTitle} styling={"p4"}>
        {t("Status")}
      </TypographyComponent>
      {Array.isArray(checklistPhases) &&
        checklistPhases.map((phase) => {
          return (
            phase?.title && (
              <div key={phase.id} className={styles.checklistSection}>
                <TypographyComponent boldness={"medium"} rootClassName={styles.checklistSectionName} styling={"p3"}>
                  {phase.title}
                </TypographyComponent>
                <div className={styles.checklistSectionStatus}>
                  <ChecklistStatusDisplay status={phase.status} />
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};
export default DetailsChecklistSection;
