import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rootClassName: PropTypes.string,
};
const TableCarouselBody = ({ children, rootClassName }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child) ? React.cloneElement(child) : child;
  });

  return <tbody className={classNames(rootClassName)}>{childrenWithProps}</tbody>;
};

TableCarouselBody.propTypes = propTypes;
export default TableCarouselBody;
