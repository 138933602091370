import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const defaultColor = colorPalette.interactions.blueBerry.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

type IconProps = {
  fillPath?: string;
  height?: string;
  title?: string;
  viewBox?: string;
  width?: string;
};

export const FileIcon = ({
  fillPath = defaultColor,
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
  title = "",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M13 8.5C13 8.77614 13.2239 9 13.5 9H17.2929C17.7383 9 17.9614 8.46143 17.6464 8.14645L13.8536 4.35355C13.5386 4.03857 13 4.26165 13 4.70711V8.5ZM6 2H13.1716C13.702 2 14.2107 2.21071 14.5858 2.58579L19.4142 7.41421C19.7893 7.78929 20 8.29799 20 8.82843V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C4.89 22 4 21.1 4 20V4C4 2.89 4.89 2 6 2ZM14 19C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H7C6.44772 17 6 17.4477 6 18C6 18.5523 6.44772 19 7 19H14ZM17 15C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H17Z"
        fill={fillPath}
      />
    </svg>
  );
};
