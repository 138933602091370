import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { statusCardV2Styles as styles } from "./planning-status-card-v2.styles";

type PlanningStatusCardV2Props = {
  btnTitle: string;
  cardName: string;
  displayNumber: React.ReactNode;
  imageSrc: string;
  onNavigation: (name: string) => void;
  subTitle: string;
  title: string;
};
const useStyles = makeStyles(() => styles);
const PlanningStatusCardV2 = ({
  btnTitle,

  displayNumber,

  onNavigation,
  subTitle,
  title,
}: PlanningStatusCardV2Props) => {
  const handleNavigate = () => {
    handleEvent(onNavigation);
  };
  const classes = useStyles();

  return (
    <div className={classes.statusCardRoot}>
      <div className={classNames(classes.planningDataDisplay)}>
        <TypographyComponent
          styling={"h4"}
          boldness={"medium"}
          rootClassName={classNames("gx-planning-data-card-title")}
        >
          {title}
        </TypographyComponent>
        {displayNumber}
        <TypographyComponent
          element={"span"}
          boldness={"medium"}
          styling={"p3"}
          rootClassName={classNames(classes.statusCardSubtitle)}
        >
          {subTitle}
        </TypographyComponent>
        <div>
          <Button
            onClick={handleNavigate}
            rootClassName={classNames("gx-planning-status-btn", "btn-secondary gx-planning-status-btn-outlined")}
          >
            {btnTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PlanningStatusCardV2;
