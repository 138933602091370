import { colorPalette } from "gx-npm-common-styles";

const upNextCardV2Styles = {
  /* TODO - remove what is between this once GCOM-2654 is complete */
  root: {
    background: colorPalette.basic.white.hex,
    borderRadius: "8px",
    color: colorPalette.neutrals.iron.hex,
    marginTop: "24px",
    maxWidth: "1400px",
    minWidth: "900px",
    paddingLeft: "40px",
    paddingTop: "28px",
    "& .gx-h": {
      marginBottom: "32px",
    },
  },
  phaseDescription: {
    display: "flex",
    "@media (max-width: 1024px)": {
      gap: "32px",
      flexDirection: "column",
    },
  },
  phaseHighlights: {
    flexBasis: "374px",
    flexShrink: 0,
    margin: 0,
    marginRight: "24px",
    paddingLeft: "20px",
    "@media (max-width: 1024px)": {
      flex: "none",
      width: "600px",
    },
  },
  phaseHighlightsList: {
    marginBottom: "8px",
  },
  phaseImage: {
    alignSelf: "flex-end",
    overflow: "hidden",
    width: "100%",
  },
  /* TODO - remove what is between this once GCOM-2654 is complete */
  container: {
    background: colorPalette.basic.white.hex,
    borderRadius: "8px",
    color: colorPalette.neutrals.iron.hex,
    marginTop: "24px",
    maxWidth: "1400px",
    minWidth: "900px",
  },
  columnWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftColumn: {
    paddingLeft: "39px",
    paddingRight: "24px",
    paddingTop: "32px",
    width: "calc(33.33% + 12px)",
  },
  cards: {
    display: "flex",
    gap: "24px",
    width: "66.66%",
  },
  card: {
    width: "50%",
    "& img": {
      width: "100%",
    },
  },
  title: {
    marginBottom: "8px",
  },
  evalStartedImage: {
    alignSelf: "flex-end",
    overflow: "hidden",
    width: "100%",
    marginTop: "75px",
  },
  descriptionText: {
    "&.gx-p": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  cardDescriptionWrapper: {
    paddingRight: "16px",
  },
  cardDescription: {
    paddingTop: "4px",
    marginBottom: "40px",
  },
};

export { upNextCardV2Styles };
