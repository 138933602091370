import classNames from "classnames";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { TooltipV2, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GCOM_4297__emqUpdates } from "../../../../../../lib/feature-flags";
import EvalSummaryTableV2Component from "../../../../../../ui/eval-summary-table-v2/eval-summary-table-v2.component";
import { CommentType } from "../../../../selection.constants";
import { SelectionContext } from "../../../../selection.context";
import EvaluationComment from "../evaluation-comment/evaluation-comment.component";
import styles from "./evaluation-summary.styles.module.scss";

const EvaluationSummary = () => {
  const { t } = useTranslation();
  const { evalComment, initiativeId: contextInitId, products } = useContext(SelectionContext);
  const { initiativeId: paramsInitId = "" } = useParams<{ initiativeId: UUID }>();
  const isEMQFFOn = useFeatureFlag(GCOM_4297__emqUpdates);
  const initiativeId = isEMQFFOn ? paramsInitId : contextInitId;
  const { role } = useUserInitAccess(initiativeId);

  return (
    <div className={classNames(styles.root)}>
      <TypographyComponent styling={"p1"} boldness={"medium"}>
        {t("Evaluation summary")}
      </TypographyComponent>
      <TooltipV2
        deactivate={role === InitUserRole.OWNER}
        placement="left"
        PopperProps={{ modifiers: { offset: { offset: "0, -500px" } } }}
        title={t("This action is only available to the evaluation owners.")}
      >
        <div className={classNames(styles.comments)}>
          <EvaluationComment
            isViewOnly={role !== InitUserRole.OWNER}
            comment={{ ...evalComment, commentType: CommentType.EVAL_SUMMARY }}
          />
        </div>
      </TooltipV2>
      <EvalSummaryTableV2Component initId={initiativeId} products={products} sourcePage="selection" />
    </div>
  );
};
export default EvaluationSummary;
