import { Box } from "@material-ui/core";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./boxed-product-header.styles.module.scss";

const BoxedProductHeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <Box className={styles.header}>
      <TypographyComponent rootClassName={styles.headerText} boldness={"medium"} styling={"h3"}>
        {t("Vendor list")}
      </TypographyComponent>
    </Box>
  );
};

export default BoxedProductHeaderComponent;
