import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ReviewType } from "gx-npm-lib";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { ProductReviews } from "../../../../../../selection.types";
import ReviewItem from "./components/review-item/review-item.component";
import Stars from "./components/stars/stars.component";
import styles from "./reviews.module.scss";

type ReviewProps = {
  productReviews: ProductReviews;
  isExpanded: boolean;
};

const Review = ({ productReviews, isExpanded }: ReviewProps) => {
  const { t } = useTranslation();

  const overallRating = productReviews.overallRating;
  const reviewCount = productReviews.reviewCount;
  const reviewsURL = productReviews.reviewsURL;
  const mostFavorableReview = productReviews.mostFavorableReview;
  const mostCriticalReview = productReviews.mostCriticalReview;

  return (
    <div className={classNames(styles.root, isExpanded ? styles.isExpanded : "")}>
      <div className={styles.container}>
        <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName="section-title">
          {t("Gartner Peer Rating")}
        </TypographyComponent>

        <div className={styles.reviews}>
          {overallRating && (
            <div className={styles.overallRatingContainer}>
              <Stars rating={overallRating} isSmall={false} />
              <TypographyComponent element={"span"} styling={"h4"} boldness={"medium"} color={"gartnerBlue"}>
                {!isNaN(overallRating) && overallRating.toFixed(1)}
              </TypographyComponent>
              <span className={styles.reviewsLink}>
                <TextLink href={reviewsURL} target="_blank" text={`See ${reviewCount} review(s)`} />
              </span>
            </div>
          )}
          {mostFavorableReview.headline && <ReviewItem review={mostFavorableReview} type={ReviewType.FAVORABLE} />}
          {mostCriticalReview.headline && <ReviewItem review={mostCriticalReview} type={ReviewType.CRITICAL} />}
        </div>

        {!productReviews.mostFavorableReview.headline && !productReviews.mostCriticalReview.headline && (
          <TypographyComponent styling={"p3"} rootClassName={styles.noReviews}>
            {t("There are no reviews available for this vendor.")}
          </TypographyComponent>
        )}
      </div>
    </div>
  );
};
export default Review;
