import { deleteAsyncRequest, getAsyncRequest, postAsyncRequest, putAsyncRequest } from "gx-npm-lib";

const deleteRequest = async (url, config = {}) => {
  return deleteAsyncRequest(`/${url}`, config);
};

const getRequest = async (url, config = {}) => {
  return getAsyncRequest(`/${url}`, config);
};

const postRequest = async (url, payload, config = {}) => {
  return postAsyncRequest(`/${url}`, payload, config);
};

const putRequest = async (url, payload, config = {}) => {
  return putAsyncRequest(`/${url}`, payload, config);
};

const putUpload = async (url, payload, config = {}) => {
  return putAsyncRequest(url, payload, config);
};

export { deleteRequest, getRequest, postRequest, putRequest, putUpload };
