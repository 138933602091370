// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RrCuRQiLh57TaOF4D2SY{align-items:baseline;display:flex;justify-content:space-between;margin-bottom:24px}.RrCuRQiLh57TaOF4D2SY ._XaQsTf4nNOdwReGOiS6{align-items:center;display:flex}.RrCuRQiLh57TaOF4D2SY .kPyBmoMFFBOXIQDDbcs4{margin-inline:24px}.RrCuRQiLh57TaOF4D2SY .KUSR6qjEOWgmxxvMg_Ni{align-items:flex-end;display:flex}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/scorecard-header/scorecard-header.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,oBAAA,CACA,YAAA","sourcesContent":[".headerContainer {\n  align-items: baseline;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n\n  & .headingTitle {\n    align-items: center;\n    display: flex;\n  }\n\n  .dividerImg {\n    margin-inline: 24px;\n  }\n\n  & .headingButtons {\n    align-items: flex-end;\n    display: flex;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `RrCuRQiLh57TaOF4D2SY`,
	"headingTitle": `_XaQsTf4nNOdwReGOiS6`,
	"dividerImg": `kPyBmoMFFBOXIQDDbcs4`,
	"headingButtons": `KUSR6qjEOWgmxxvMg_Ni`
};
export default ___CSS_LOADER_EXPORT___;
