import { defaultToZero } from "gx-npm-lib";

/**
 * count the number of requirements in a list of categories
 * @param {Array<{ itemList: Array<{}>}>} list category list
 * @returns {number} number of requirements in all categories
 */
const computeRequirementsCount = (list) => {
  let total = 0;
  const length = defaultToZero(list?.length);
  for (let idx = 0; idx < length; idx++) {
    total += defaultToZero(list[idx]?.itemList?.length);
  }
  return total;
};

/**
 * count the number of additional requirements in a list of categories
 * @param {Array<{ itemList: Array<{}>}>} list category list
 * @returns {number} number of additional requirements in all categories
 */
const computeAdditionalRequirementsCount = (list) => {
  let total = 0;
  const length = defaultToZero(list?.length);
  for (let idx = 0; idx < length; idx++) {
    const lengthChild = defaultToZero(list[idx]?.itemList?.length);
    for (let idxChild = 0; idxChild < lengthChild; idxChild++) {
      total += defaultToZero(list[idx]?.itemList[idxChild]?.itemList?.length);
    }
  }
  return total;
};

export { computeRequirementsCount, computeAdditionalRequirementsCount };
