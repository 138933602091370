import { colorPalette } from "gx-npm-common-styles";

const surveyTeamMemberStyles = {
  addUserForm: {
    display: "flex",
    alignItems: "flex-start",
  },
  addUserInput: {
    "& .gx-text-field-container": {
      width: "600px",
      marginRight: "24px",
    },
  },
  errorMsg: {
    paddingTop: "4px",
    "&.gx-p": {
      color: colorPalette.status.poisonCherry.hex,
    },
  },
  error: {
    color: colorPalette.status.poisonCherry.hex,
  },
};

export default surveyTeamMemberStyles;
