import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Button, ButtonLoader } from "gx-npm-ui";
import styles from "./delete-req-details-dialog-footer.styles";

const propTypes = {
  isConfirmed: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const DeleteReqDetailsDialogFooter = ({
  isConfirmed = true,
  isLoading = false,
  isProcessing = false,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.footerRoot}>
      <Button disabled={isProcessing} onClick={onCancel}>
        {t("CANCEL")}
      </Button>
      <ButtonLoader
        btnClass="primary-destructive-btn"
        disabled={!isConfirmed || isLoading}
        isLoading={isProcessing}
        onClick={onConfirm}
      >
        {t("DELETE")}
      </ButtonLoader>
    </div>
  );
};

DeleteReqDetailsDialogFooter.propTypes = propTypes;
export default DeleteReqDetailsDialogFooter;
