import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ArrowIcon } from "gx-npm-icons";
import { ToolTipIconButton } from "gx-npm-ui";
const propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  showNextColumnLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const TableCarouselScrollRight = ({ disabled = true, onClick, showNextColumnLabel = "" }) => {
  return (
    <ToolTipIconButton
      className={classNames("gx-table-carousel-right-scroll", disabled ? "disabled" : "enabled")}
      onClick={onClick}
      clickable={!disabled}
      showTooltip={true}
      tooltipText={showNextColumnLabel}
      tooltipOptions={{ rootClassName: "gx-table-scroll-right-tooltip" }}
    >
      <ArrowIcon />
    </ToolTipIconButton>
  );
};

TableCarouselScrollRight.propTypes = propTypes;
export default TableCarouselScrollRight;
