const selectionStyles = {
  options: {
    alignItems: "flex-start",
    display: "flex",
    gap: "163px",
    "@media (max-width: 630px)": {
      gap: "90px",
    },
  },
};
export default selectionStyles;
