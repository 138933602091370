import { colorPalette, weightPalette } from "gx-npm-common-styles";

const selectionCardV2Styles = {
  selectCard: {
    background: colorPalette.basic.white.hex,
    paddingLeft: "40px",
    height: "316px",
    borderRadius: "8px",
  },
  selectedBackground: {
    borderRadius: "0 8px 8px 0",
    position: "absolute" as const,
    right: 0,
    top: 0,
  },
  flowHeaderSmallTitle: {
    paddingTop: "30px",
  },
  selectedVendorIcon: {
    display: "inline-block",
    marginRight: "12px",
  },
  flowHeaderTitle: {
    display: "flex",
    color: colorPalette.neutrals.iron.hex,
    fontSize: "12px",
    fontVariationSettings: weightPalette.medium.variation,
    fontWeight: weightPalette.medium.amount,
    letterSpacing: "0.35px",
    lineHeight: "18px",
  },
  flowCardRoot: {
    position: "relative" as const,
    maxWidth: "1400px",
    minWidth: "900px",
    overflow: "hidden",
    marginBottom: "24px",
  },
};

export { selectionCardV2Styles };
