import React, { FC, useEffect, useState } from "react";
import { Cell, RowSet, SCROLLABLE_DIV_CLASS_NAME } from "./table.constants";
import { TableHeaderComponent } from "./table-header/table-header.component";
import { TableBodyComponent } from "./table-body/table-body.component";
import { TableFooterComponent } from "./table-footer/table-footer.component";
import { TooltipProps } from "@mui/material";
import Filter from "./filter/filter.component";

/*
  headerRows[0][0]          | headerRows[0][1]          | ...
  headerRows[1][0]          | headerRows[1][1]          | ...
  ...
  bodyRowSets[0].title
  bodyRowSets[0].rows[0][0] | bodyRowSets[0].rows[0][1] |...
  bodyRowSets[0].rows[1][0] | bodyRowSets[0].rows[1][1] |...
  bodyRowSets[1].title
  bodyRowSets[1].rows[0][0] | bodyRowSets[1].rows[0][1] |...
  bodyRowSets[1].rows[1][0] | bodyRowSets[0].rows[1][1] |...
  ...
  footerRowSet.title
  footerRowSet.rows[0][0]   | footerRowSet.rows[0][1]   | ...
  footerRowSet.rows[1][0]   | footerRowSet.rows[1][1]   | ...
*/

type TableProps = {
  headerRows: Cell[][];
  bodyRowSets: RowSet[];
  footerRowSet?: RowSet;
  tooltipOptions?: {
    leftArrowTooltipOptions?: TooltipProps;
    rightArrowTooltipOptions?: TooltipProps;
    accordionArrowTooltipProps?: TooltipProps;
  };
  filters?: React.ReactNode | null;
};
export const TableComponent: FC<TableProps> = ({ headerRows, bodyRowSets, footerRowSet, tooltipOptions, filters }) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    const checkOverflow = () => {
      const scrollableElement = document.querySelectorAll(`.${SCROLLABLE_DIV_CLASS_NAME}`)[0];
      const overflowState = scrollableElement.scrollWidth > scrollableElement.clientWidth;
      setIsOverflowing(overflowState);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [headerRows]);

  return (
    <div>
      {headerRows.length > 0 && (
        <TableHeaderComponent
          leftArrowTooltipOptions={tooltipOptions?.leftArrowTooltipOptions}
          rightArrowTooltipOptions={tooltipOptions?.rightArrowTooltipOptions}
          rows={headerRows}
          isOverflowing={isOverflowing}
        />
      )}
      {filters && <Filter>{filters}</Filter>}
      {bodyRowSets.length > 0 && (
        <TableBodyComponent
          accordionArrowTooltipProps={tooltipOptions?.accordionArrowTooltipProps}
          rowSets={bodyRowSets}
          isOverflowing={isOverflowing}
        />
      )}
      {footerRowSet && <TableFooterComponent rowSet={footerRowSet} isOverflowing={isOverflowing} />}
    </div>
  );
};
