import React, { useEffect, useRef, useState } from "react";
import { Loader, TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./overview.styles.module.scss";
import * as videoPlayer from "@vidyard/embed-code";
import { useTranslation } from "react-i18next";
import { TimerIcon } from "gx-npm-icons";
import classNames from "classnames";

const OverviewComponent = () => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const player = await videoPlayer.api.renderPlayer({
          uuid: "1Tc1vBizYLLyyayDC9kMwu",
          container: videoRef.current,
        });
        player.on("ready", () => {
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [videoRef]);

  return (
    <div>
      <TypographyComponent styling={"h5"} boldness={"medium"}>
        {t("Overview")}
      </TypographyComponent>
      <div className={styles.mediaContainer}>
        <div ref={videoRef} className={styles.media}>
          {isLoading && (
            <div aria-label={"loader"} className={classNames(styles.loader)}>
              <Loader size={48} />
            </div>
          )}
        </div>
        <div className={styles.mediaDesc}>
          <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={styles.mediaContent}>
            {t("Watch a quick overview of how requirements work in BuySmart")}
          </TypographyComponent>
          <TypographyComponent rootClassName={styles.mediaTime} styling={"p3"} boldness={"regular"}>
            <TimerIcon className={styles.timerIcon} />
            1:22
          </TypographyComponent>
        </div>
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"medium"}>
          {t("Requirements are used as the basis for creating a vendor questionnaire and/or team scorecard.")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
          {t(
            "The standard list of requirements provided by Gartner can be used as-is or can be customized as much as necessary to fit your needs."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about establishing requirements")}
            target={"_blank"}
            href={"https://intercom.help/gartner-buysmart-help-center/en/articles/6260845-establishing-requirements"}
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;
