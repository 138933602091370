import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { RequirementsState } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import { BulkOperationConfirmationDialog } from "../../dialogs/bulkOperationConfirmationDialog";
import DeleteReqDetailsDialog from "../../dialogs/delete-req-details-dialog/delete-req-details-dialog.component";
import BulkOperationsBar from "./bulk-operations-bar.component";

const propTypes = {
  buttonItems: PropTypes.arrayOf(
    PropTypes.shape({
      btnLabel: PropTypes.node,
      btnVariant: PropTypes.string,
      icon: PropTypes.node,
      name: PropTypes.string,
    })
  ),
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  countSuccess: PropTypes.number,
  dialogType: PropTypes.oneOf(["delete", "move", ""]),
  initId: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  isErroredRequest: PropTypes.bool,
  isProcessingRequest: PropTypes.bool,
  isRequirementLibrary: PropTypes.bool,
  onClearError: PropTypes.func,
  onClickButtonBar: PropTypes.func,
  onClickButtonBarOption: PropTypes.func,
  onClickButtonDialogCancel: PropTypes.func,
  onClickButtonDialogConfirm: PropTypes.func,
  reqState: PropTypes.oneOf(["", ...Object.values(RequirementsState)]),
  selectedIds: PropTypes.arrayOf(PropTypes.string),
};
const BulkOperationsView = ({
  buttonItems = [],
  categoryList = [],
  countSuccess = 0,
  dialogType = "",
  initId = "",
  isDialogOpen = false,
  isErroredRequest = false,
  isProcessingRequest = false,
  onClearError = null,
  onClickButtonBar = null,
  onClickButtonBarOption = null,
  onClickButtonDialogCancel = () => {},
  onClickButtonDialogConfirm = () => {},
  selectedIds = [],
  reqState = RequirementsState.DRAFT,
  isRequirementLibrary = false,
}) => {
  const { t } = useTranslation();
  const reqPlurality = countSuccess > 1 ? t("requirements were") : t("requirement was");
  let successMessage = "";
  if (isRequirementLibrary) {
    successMessage = `${reqPlurality} ${t("successfully added.")}`;
  } else if (dialogType === "move") {
    successMessage = `${reqPlurality} ${t("successfully moved.")}`;
  } else if (dialogType === "delete") {
    successMessage = `${reqPlurality} ${t("successfully deleted.")}`;
  }

  const handleCloseDeleteDialog = (isConfirmed) => {
    if (isConfirmed) {
      onClickButtonDialogConfirm("delete");
    } else {
      onClickButtonDialogCancel();
    }
  };

  return (
    <Fragment>
      <BulkOperationsBar
        buttons={buttonItems}
        isRequirementLibrary={isRequirementLibrary}
        itemCount={selectedIds.length}
        onClickButton={onClickButtonBar}
        onClickOption={onClickButtonBarOption}
        popoverItems={categoryList}
      />
      <BulkOperationConfirmationDialog
        count={selectedIds.length}
        isProcessing={isProcessingRequest}
        isOpen={dialogType === "move" && isDialogOpen}
        onClickCancel={onClickButtonDialogCancel}
        onClickConfirm={onClickButtonDialogConfirm}
      />
      <DeleteReqDetailsDialog
        count={selectedIds.length}
        initId={initId}
        isOpen={dialogType === "delete" && isDialogOpen}
        onClose={handleCloseDeleteDialog}
        reqItemIds={selectedIds}
        reqState={reqState}
      />
      <SnackbarBanner
        autoHideDuration={3000}
        isOpen={isErroredRequest}
        isDefaultErrorMessage={true}
        setIsOpen={onClearError}
        type="ERROR"
      />
      <SnackbarBanner
        autoHideDuration={3000}
        isOpen={countSuccess > 0}
        message={`${countSuccess} ${successMessage}`}
        type="SUCCESS"
      />
    </Fragment>
  );
};

BulkOperationsView.propTypes = propTypes;
export default BulkOperationsView;
