import { getStoredJsonItem, setStoredJsonItem } from "../../ui/dragAndDropList/lib";
import { getRequest } from "../../context/actions/apiRequests";
import { processGetResponse } from "../../context/actions/actionUtils";

const fetchStoredArray = (storageId) => {
  const storedData = getStoredJsonItem(storageId);
  return Array.isArray(storedData) ? [...storedData] : [];
};

const sessionStorageKeyRoot = "initiatives-requirements-expand-collapse";
const getRequirementsStorageId = (initiativeId) => {
  return initiativeId ? `${sessionStorageKeyRoot}-${initiativeId}` : "";
};

const persistRequirementsExpandCollapse = (storageId = "", length = 0) => {
  let dataPersisted = false;
  if (!!storageId && length > 0) {
    const persistArray = fetchStoredArray(storageId);
    for (let idx = 0; idx < length; idx++) {
      if (persistArray[idx] !== true && persistArray[idx] !== false) {
        persistArray[idx] = idx === 0;
      }
    }
    setStoredJsonItem(storageId, persistArray);
    dataPersisted = true;
  }
  return dataPersisted;
};

const getSignedUrl = async (url) => {
  const response = await getRequest(url);
  const processPayload = processGetResponse(response);
  const { data } = processPayload;
  return data.signedUrl;
};

const triggerFSBulkOpEvent = (level) => {
  if (window.FS) {
    window.FS.event("Bulk-op checkbox toggle", { level });
  }
};

export { getRequirementsStorageId, persistRequirementsExpandCollapse, getSignedUrl, triggerFSBulkOpEvent };
