import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToEmptyString, isValidResponse } from "gx-npm-lib";
import { Button, Dialog, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import ImportLibraryBodyContainer from "./importLibraryBodyContainer";
import ImportLibraryList from "../importLibraryList";
import { deleteRequest, getRequest, postRequest, putUpload } from "../../../../../context/actions/apiRequests";
import { EvaluationStateContext } from "../../../../../context";
import { loadRequirementsData } from "../../../../../context/actions/requirementsActions";
import { acceptedFileTypes, processSteps } from "../lib";
import { libraryDialogStyles as styles } from "./styles";
import { getSignedUrl } from "../../../requirements.lib";

/**
 * @param {{ isOpen: boolean, initId: string }} config
 * @param {Function} onSuccess invoke when data comes back successfully
 * @param {Function} onComplete invoke when call is finished
 */
const fetchImportList = async (config = {}, onSuccess = (_list) => {}, onComplete = () => {}) => {
  if (config.isOpen && config.initId) {
    const url = `api/v2/initiatives/${config.initId}/requirements/import`;
    const response = await getRequest(url);
    if (response?.status === 200) {
      const list = Array.isArray(response?.data?.data?.importList) ? response.data.data.importList : [];
      onSuccess(list);
    }
  }
  onComplete();
};

const SIZE_LIMIT_20_MB = 209_715_200;
const propTypes = {
  initId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
const successApiResponse = [200, 201, 204];
const useStyles = makeStyles(() => styles);
const ImportLibraryBody = ({ initId = "", isOpen = false, onClose = () => {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [_state, dispatch] = useContext(EvaluationStateContext);
  const [currFileName, setCurrFileName] = useState("");
  const [isErrorMenuItem, setIsErrorMenuItem] = useState(false);
  const [isErrorUpload, setIsErrorUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [importList, setImportList] = useState([]);
  const [processStep, setProcessStep] = useState(processSteps.none);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState("");
  const [confirmDeleteFileName, setConfirmDeleteFileName] = useState("");

  useEffect(() => {
    setIsLoading(true);
    fetchImportList({ initId, isOpen }, setImportList, () => setIsLoading(false));
  }, [initId, isOpen]);

  const deleteFileCheck = (fileName, id) => {
    setConfirmDeleteDialog(true);
    setConfirmDeleteFileName(fileName);
    setConfirmDeleteId(id);
  };

  const deleteFilePermanently = async () => {
    setConfirmDeleteDialog(false);

    const deleteUrl = `api/v2/initiatives/${initId}/requirements/import/${confirmDeleteId}`;
    const urlResponse = await deleteRequest(deleteUrl);

    if (successApiResponse.includes(urlResponse.status)) {
      await fetchImportList({ initId, isOpen }, setImportList);
    } else {
      setIsErrorMenuItem(true);
    }
  };

  const handleClickMenu = async (event, importId, fileName) => {
    if (event === "ADD") {
      const urlSignedUrl = `api/v2/initiatives/${initId}/requirements/import/${importId}/add`;
      const urlResponse = await postRequest(urlSignedUrl);
      if (!isValidResponse(urlResponse)) {
        setIsErrorMenuItem(true);
        return;
      }

      const { addedCategoryList } = urlResponse.data?.data || {};
      const importedCatIds = addedCategoryList?.map((obj) => {
        return obj.categoryId;
      });
      loadRequirementsData(null, dispatch, {
        importedCatIds,
        initiativeId: initId,
        isReloading: true,
      });
      onClose();
    } else if (event === "DELETE") {
      deleteFileCheck(fileName, importId);
    } else if (event === "DOWNLOAD") {
      await handleImportClickDownload(importId);
    }
  };

  const handleClickDownload = async () => {
    const url = `api/v2/initiatives/requirements/export/template`;
    const signedUrl = await getSignedUrl(url);
    window.open(signedUrl, "_self");
  };

  const handleImportClickDownload = async (importId) => {
    const url = `api/v2/initiatives/${initId}/requirements/export/${importId}`;
    const signedUrl = await getSignedUrl(url);
    window.open(signedUrl, "_self");
  };

  const handleFileUpload = (fileObj) => {
    if (processStep !== processSteps.none) {
      return;
    }
    setProcessStep(processSteps.upload);
    setIsErrorUpload(false);

    const fileName = defaultToEmptyString(fileObj.name);
    setCurrFileName(fileName);

    // validate file type
    if (!fileName || !fileObj || fileObj.size > SIZE_LIMIT_20_MB || acceptedFileTypes.indexOf(fileObj.type) === -1) {
      setIsErrorUpload(true);
      setProcessStep(processSteps.none);
      return;
    }

    // set image to binary for upload
    try {
      let blobData = null;
      const reader = new FileReader();
      reader.onload = (progressEvent) => {
        const binary = atob(progressEvent.target.result.split(",")[1]);
        const array = [];
        for (let idx = 0; idx < binary.length; idx++) {
          array.push(binary.charCodeAt(idx));
        }
        const { type } = fileObj;
        blobData = new Blob([new Uint8Array(array)], { type });
        handleFileProcess(fileObj, blobData);
      };
      reader.readAsDataURL(fileObj);
    } catch (_ignored) {
      setIsErrorUpload(true);
      setProcessStep(processSteps.none);
    }
  };

  const handleFileProcess = async (fileObj, blobData) => {
    // fetch url to upload file
    const fileName = fileObj.name;
    const urlSignedUrl = `api/v2/initiatives/${initId}/requirements/import`;
    const urlPayload = { fileName };
    const urlResponse = await postRequest(urlSignedUrl, urlPayload);
    if (!isValidResponse(urlResponse) || !urlResponse.data?.data?.signedUrl) {
      setIsErrorUpload(true);
      setCurrFileName("");
      setProcessStep(processSteps.none);
      return;
    }

    // make upload request
    const { importId, signedUrl } = urlResponse.data.data;
    const putHeaders = { "Content-Type": fileObj.type };
    const putConfig = { headers: putHeaders };
    const putResponse = await putUpload(signedUrl, blobData, putConfig);
    if (!successApiResponse.includes(putResponse?.status)) {
      setIsErrorUpload(true);
      setCurrFileName("");
      setProcessStep(processSteps.none);
      return;
    }

    // make process upload request
    const urlProcess = `api/v2/initiatives/${initId}/requirements/import/${importId}/process`;
    setProcessStep(processSteps.process);
    const postResponse = await postRequest(urlProcess, {});
    if (!successApiResponse.includes(postResponse.status)) {
      setIsErrorUpload(true);
      setCurrFileName("");
      setProcessStep(processSteps.none);
      return;
    }

    await fetchImportList({ isOpen: true, initId }, setImportList);
    setCurrFileName("");
    setProcessStep(processSteps.none);
  };

  const handleDeleteCancel = () => {
    setConfirmDeleteDialog(false);
  };

  const handleDelete = async () => {
    await deleteFilePermanently();
  };

  const handleErrorClose = () => {
    setIsErrorMenuItem(false);
  };

  return (
    <React.Fragment>
      <ImportLibraryBodyContainer
        currentFileName={currFileName}
        isErrorUpload={isErrorUpload}
        onClickClearError={() => setIsErrorUpload(false)}
        onClickDownload={handleClickDownload}
        onFileUpload={handleFileUpload}
        processStep={processStep}
      />
      <ImportLibraryList isLoading={isLoading} list={importList} onMenuClick={handleClickMenu} />
      <SnackbarBanner
        isOpen={isErrorMenuItem}
        handleButtonClick={handleErrorClose}
        isDefaultErrorMessage={true}
        setIsOpen={handleErrorClose}
        type="ERROR"
      />
      <Dialog
        title={t("Delete file")}
        body={
          <>
            <TypographyComponent boldness="regular" styling="p2">
              {t("Are you sure you want to delete")}
              {` `}
              <TypographyComponent boldness={"medium"}>{confirmDeleteFileName}</TypographyComponent>
              {t("? This file will be permanently deleted.")}
            </TypographyComponent>
          </>
        }
        open={confirmDeleteDialog}
        footer={
          <div className={classes.confirmDelete}>
            <Button ariaLabel={t("Cancel Delete")} onClick={handleDeleteCancel}>
              {t("Cancel")}
            </Button>
            <Button ariaLabel={t("Confirm Delete")} rootClassName="primary-destructive-btn" onClick={handleDelete}>
              {t("Delete")}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  );
};

ImportLibraryBody.propTypes = propTypes;
export default ImportLibraryBody;
