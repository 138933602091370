import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  footerRowMapper: PropTypes.array,
  rows: PropTypes.number,
  tableCarouselScrollRight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
const TableCarouselFooter = ({ footerRowMapper = [], rows = 0, tableCarouselScrollRight }) => {
  const renderRowColumn = (content, idx) => {
    return (
      <tr key={idx}>
        <td>{content}</td>
      </tr>
    );
  };

  const renderBlankRows = () => {
    let blankRows = [];
    for (let i = 0; i < rows - 1; i++) {
      blankRows.push(renderRowColumn(footerRowMapper[i], i));
    }
    return blankRows;
  };

  return (
    <tfoot>
      {renderRowColumn(tableCarouselScrollRight, "right-scroll")}
      {renderBlankRows()}
    </tfoot>
  );
};

TableCarouselFooter.propTypes = propTypes;
export default TableCarouselFooter;
