import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonLoader, Dialog, TypographyComponent } from "gx-npm-ui";
import styles from "./requirements-invalid-dialog.styles";

const propTypes = { isOpen: PropTypes.bool, onCancel: PropTypes.func, onConfirm: PropTypes.func };
const useStyles = makeStyles(() => styles);
const RequirementsInvalidDialog = ({ isOpen = false, onCancel = () => {}, onConfirm = () => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      body={
        <TypographyComponent styling={"p3"} boldness={"medium"}>
          {t("Before you can start the scorecard, your total requirement category weights must equal 100%.")}
        </TypographyComponent>
      }
      footer={
        <div className={classes.buttons}>
          <Button onClick={onCancel} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <ButtonLoader btnClass="btn-primary" onClick={onConfirm}>
            {t("GO TO REQUIREMENTS")}
          </ButtonLoader>
        </div>
      }
      open={isOpen}
      title={t("Unable to start scorecard")}
    />
  );
};

RequirementsInvalidDialog.propTypes = propTypes;
export default RequirementsInvalidDialog;
