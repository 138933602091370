import { useEffect } from "react";

function useOutsideAlerter(parentRef, onClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (parentRef.current && !parentRef.current.contains(event.target)) {
        onClick(event);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClick, parentRef]);
}

export default useOutsideAlerter;
