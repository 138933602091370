import { createContext, ReactNode, useState } from "react";
import { BudgetType, OverviewContextValue, ProductType, TeamMemberType } from "./overview.types";
import { InitStatus } from "gx-npm-lib";

const OverviewContext = createContext<OverviewContextValue>({} as OverviewContextValue);

const OverviewContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [budget, setBudget] = useState<BudgetType>({ amount: 0, currency: "" });
  const [checklistCompletedCount, setChecklistCompletedCount] = useState<number>(0);
  const [checklistCount, setChecklistCount] = useState<number>(0);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [objectives, setObjectives] = useState<string>("");
  const [overviewEditCount, setOverviewEditCount] = useState<number>(0);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [requirementCount, setRequirementCount] = useState<number>(0);
  const [status, setStatus] = useState<InitStatus | "">("");
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);
  const [vendorSelectionDate, setVendorSelectionDate] = useState<string>("");

  const contextValue = {
    budget,
    checklistCompletedCount,
    checklistCount,
    isSnackbarOpen,
    objectives,
    overviewEditCount,
    products,
    requirementCount,
    status,
    teamMembers,
    vendorSelectionDate,
    setBudget,
    setChecklistCompletedCount,
    setChecklistCount,
    setObjectives,
    setOverviewEditCount,
    setIsSnackbarOpen,
    setProducts,
    setRequirementCount,
    setStatus,
    setTeamMembers,
    setVendorSelectionDate,
  };

  return <OverviewContext.Provider value={contextValue}>{props.children}</OverviewContext.Provider>;
};

export { OverviewContext, OverviewContextProvider };
