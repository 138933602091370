import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { handleEvent, ScoringLevel } from "gx-npm-lib";
import ScoringLevelOption from "./scoringLevelOption";
import { scoringSelectionsStyles as styles } from "./styles";

const propTypes = {
  countCats: PropTypes.string,
  countReqs: PropTypes.string,
  levelCurrent: PropTypes.string,
  levelSelected: PropTypes.string,
  onSelectLevel: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ScoringLevelelections = ({
  countCats = "",
  countReqs = "",
  levelCurrent = ScoringLevel.REQUIREMENT,
  levelSelected = ScoringLevel.REQUIREMENT,
  onSelectLevel = (_level) => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <section className={classes.options}>
      <ScoringLevelOption
        count={countCats}
        isCurrent={levelCurrent === ScoringLevel.CATEGORY}
        isSelected={levelSelected === ScoringLevel.CATEGORY}
        onSelect={() => handleEvent(onSelectLevel, ScoringLevel.CATEGORY)}
        textDesc={t(
          `If you want to score vendors against high level categories such as functional requirements, support and services, and commercial terms, use category scoring.`
        )}
        textTitle={t("Score categories")}
      />
      <ScoringLevelOption
        count={countReqs}
        isCurrent={levelCurrent === ScoringLevel.REQUIREMENT}
        isSelected={levelSelected === ScoringLevel.REQUIREMENT}
        onSelect={() => handleEvent(onSelectLevel, ScoringLevel.REQUIREMENT)}
        textDesc={t(
          `If you want to conduct a detailed evaluation and score vendors against each of your individual requirements, use requirement scoring.`
        )}
        textTitle={t("Score requirements")}
      />
    </section>
  );
};

ScoringLevelelections.propTypes = propTypes;
export default ScoringLevelelections;
