import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { requirementDrawerTitleStyles as styles } from "./styles";
import { TypographyComponent } from "gx-npm-ui";

const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

const useStyles = makeStyles(() => styles);
const RequirementDrawerTitle = ({ title = "", subTitle = "" }) => {
  const classes = useStyles();

  return (
    <section className={classes.titleContainer}>
      <TypographyComponent styling={"h3"} boldness={"medium"} className={classes.title}>
        {title}
      </TypographyComponent>
      <TypographyComponent styling={"p3"} color={"iron"} className={classNames(classes.subtitle)}>
        {subTitle}
      </TypographyComponent>
    </section>
  );
};

RequirementDrawerTitle.propTypes = propTypes;
export default RequirementDrawerTitle;
