// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IJtBJn9y4AwKbDVP14Ui{position:fixed;top:140px;z-index:1200;background:var(--color-neutrals-pearl);border-top:2px solid var(--color-neutrals-pearl);box-shadow:0 3px 4px -2px var(--color-neutrals-stone)}.zWFOuQuroauLgDdpiYEh{display:flex;max-width:936px}.zWFOuQuroauLgDdpiYEh>*:nth-child(even){background-color:var(--color-neutrals-pearl)}.zWFOuQuroauLgDdpiYEh>*:nth-child(odd){background-color:var(--color-white)}.xtSEAhVRw5vk3gyGx3jk{max-width:402px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-docked-req-cat-prod-scores/scorecard-docked-req-cat-prod-scores.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,SAAA,CACA,YAAA,CACA,sCAAA,CACA,gDAAA,CACA,qDAAA,CAGF,sBACE,YAAA,CACA,eAAA,CAGF,wCACE,4CAAA,CAGF,uCACE,mCAAA,CAGF,sBACE,eAAA","sourcesContent":[".dockedCategory {\n  position: fixed;\n  top: 140px;\n  z-index: 1200;\n  background: var(--color-neutrals-pearl);\n  border-top: 2px solid var(--color-neutrals-pearl);\n  box-shadow: 0 3px 4px -2px var(--color-neutrals-stone);\n}\n\n.rowWrapper {\n  display: flex;\n  max-width: 936px;\n}\n\n.rowWrapper > *:nth-child(even) {\n  background-color: var(--color-neutrals-pearl);\n}\n\n.rowWrapper > *:nth-child(odd) {\n  background-color: var(--color-white);\n}\n\n.requirementName {\n  max-width: 402px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dockedCategory": `IJtBJn9y4AwKbDVP14Ui`,
	"rowWrapper": `zWFOuQuroauLgDdpiYEh`,
	"requirementName": `xtSEAhVRw5vk3gyGx3jk`
};
export default ___CSS_LOADER_EXPORT___;
