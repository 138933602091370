import classNames from "classnames";
import { TooltipV2, UsernameDisplay } from "gx-npm-ui";
import styles from "./tool-tipped-username.styles.module.scss";

type ToolTippedUsernameProps = { showName: boolean; name: string };
const ToolTippedUsernameComponent = ({ name = "", showName = false }: ToolTippedUsernameProps) => {
  return (
    <TooltipV2
      deactivate={showName}
      placement="bottom"
      PopperProps={{ modifiers: { offset: { offset: "2px, 6px" } } }}
      title={name}
    >
      <div className={classNames(!showName && styles.hiddenUsernameWrapper)}>
        <UsernameDisplay isColorInvertedOnHover={!showName} name={name} showName={showName} />
      </div>
    </TooltipV2>
  );
};

export default ToolTippedUsernameComponent;
