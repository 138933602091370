import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { reqLibHeaderStyles as styles } from "./req-library-header.styles";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  breadcrumbText: PropTypes.string,
  categoryNum: PropTypes.number,
  requirementNum: PropTypes.number,
  breadcrumbHandleClick: PropTypes.func,
};

const useStyles = makeStyles(() => styles);
const ReqsHeader = ({ breadcrumbText = "", breadcrumbHandleClick = () => {}, categoryNum = 0, requirementNum = 0 }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TypographyComponent styling={"h5"} boldness={"medium"} rootClassName={classes.breadcrumb}>
        <span tabIndex={0} role="button" onClick={breadcrumbHandleClick} onKeyDown={breadcrumbHandleClick}>
          <TypographyComponent element={"span"} boldness={"medium"} styling={"h5"}>
            {t("Gartner Library")}
          </TypographyComponent>
        </span>
        <TypographyComponent styling={"h5"} element={"span"} boldness={"medium"} className={classes.breadcrumbDivider}>
          {" "}
          /{" "}
        </TypographyComponent>
        {breadcrumbText}
      </TypographyComponent>
      <div className={classes.bannerWrapper}>
        <TypographyComponent element={"p"}>{t("Provided by Gartner")}</TypographyComponent>
        <ul>
          <li>
            <TypographyComponent boldness={"medium"} color={colorPalette.neutrals.coal.hex} element={"span"}>
              {categoryNum}
            </TypographyComponent>{" "}
            <TypographyComponent boldness={"regular"} color={colorPalette.neutrals.coal.hex} element={"span"}>
              {categoryNum === 1 ? t("category") : t("categories")}
            </TypographyComponent>
          </li>
          <li>
            <TypographyComponent boldness={"medium"} color={colorPalette.neutrals.coal.hex} element={"span"}>
              {requirementNum}
            </TypographyComponent>{" "}
            <TypographyComponent boldness={"regular"} color={colorPalette.neutrals.coal.hex} element={"span"}>
              {t("requirement")}
              {requirementNum === 1 ? "" : "s"}
            </TypographyComponent>
          </li>
        </ul>
      </div>
    </>
  );
};

ReqsHeader.propTypes = propTypes;
export default ReqsHeader;
