import classNames from "classnames";
import {
  InitProdState,
  InitUserRole,
  postAsyncRequest,
  putAsyncRequest,
  useCaptureEventsV2,
  useUserInitAccess,
  UUID,
} from "gx-npm-lib";
import {
  AddCircledDarkIcon,
  DeleteTrashcanIcon,
  RemoveCircleIcon,
  RevertIcon,
  SelectionRibbonIcon,
} from "gx-npm-icons";
import { AwardProductDialog, DialogWithTextbox, PopoverMenu, TypographyComponent } from "gx-npm-ui";
import React, { useEffect, useState } from "react";
import { AvailableActions } from "../../../../../lib";
import { useTranslation } from "react-i18next";
import styles from "./evaluation-status-column.styles.module.scss";
import ActionDialog from "./action-dialog/action-dialog.component";
import { ClientEvent } from "../../../../../app.constants";

type EvaluationStatusMenuItem = {
  index: number;
  menuListIcon?: React.ReactElement;
  name: string;
  rootClassName?: string;
  type: string;
};

type propTypes = {
  initiativeId: string;
  prodInitId: string;
  state: string;
  key?: string;
  productName: string;
  imageLoc?: string;
  awardedProductDetails?: {
    name: string;
    imageLoc: string;
  } | null;
  updateProductState: (productName: string, action: string) => void;
  onShowSnackbar?: (type: string, message?: string) => void;
};

const EvaluationStatusColumn = ({
  initiativeId,
  prodInitId,
  state,
  key,
  productName,
  imageLoc,
  awardedProductDetails,
  updateProductState,
  onShowSnackbar,
}: propTypes) => {
  const { t } = useTranslation();
  const captureEvent = useCaptureEventsV2();
  const [menuItems, setMenuItems] = useState<EvaluationStatusMenuItem[]>([]);
  const [revertVendorDialogOpen, setRevertVendorDialogOpen] = useState(false);
  const [deleteVendorDialogOpen, setDeleteDialogOpen] = useState(false);
  const [screenOutDialogOpen, setScreenOutDialogOpen] = useState(false);
  const [selectVendorDialogOpen, setSelectVendorDialogOpen] = useState(false);
  const [addBackDialogOpen, setAddBackDialogOpen] = useState(false);
  const { role } = useUserInitAccess(initiativeId as UUID);

  useEffect(() => {
    if (state === InitProdState.AWARDED) {
      setMenuItems([
        {
          index: 0,
          name: t("Revert vendor selection"),
          type: AvailableActions.REVERT_AWARD,
          menuListIcon: <RevertIcon />,
          rootClassName: styles.revertVendorOption,
        },
        {
          index: 1,
          name: t("Remove from evaluation"),
          type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
          menuListIcon: <DeleteTrashcanIcon />,
          rootClassName: styles.deleteVendorOption,
        },
      ]);
    }

    if (state === InitProdState.IN_EVALUATION) {
      setMenuItems([
        {
          index: 0,
          name: t("Screen out vendor"),
          type: AvailableActions.SCREEN_OUT_PRODUCT,
          menuListIcon: <RemoveCircleIcon />,
          rootClassName: styles.screenOutOption,
        },
        {
          index: 1,
          name: t("Select this vendor"),
          type: AvailableActions.CHOOSE_PRODUCT,
          menuListIcon: <SelectionRibbonIcon />,
          rootClassName: styles.selectVendorOption,
        },
        {
          index: 2,
          name: t("Remove from evaluation"),
          type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
          menuListIcon: <DeleteTrashcanIcon />,
          rootClassName: styles.deleteVendorOption,
        },
      ]);
    }

    if (state === InitProdState.SCREENED_OUT) {
      setMenuItems([
        {
          index: 0,
          name: t("Add back to evaluation"),
          type: AvailableActions.ADD_PRODUCT_TO_EVAL,
          menuListIcon: <AddCircledDarkIcon width="24" height="24" viewBox="-5 -5 30 30" />,
          rootClassName: styles.addBackToEvalOption,
        },
        {
          index: 1,
          name: t("Remove from evaluation"),
          type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
          menuListIcon: <DeleteTrashcanIcon />,
          rootClassName: styles.deleteVendorOption,
        },
      ]);
    }
  }, [state, t]);

  const showVendorStatusOptions = (index: number) => {
    if (state === InitProdState.AWARDED) {
      if (index === 0) {
        setRevertVendorDialogOpen(true);
      } else if (index === 1) {
        setDeleteDialogOpen(true);
      }
    }

    if (state === InitProdState.IN_EVALUATION) {
      if (index === 0) {
        setScreenOutDialogOpen(true);
      } else if (index === 1) {
        setSelectVendorDialogOpen(true);
      } else if (index === 2) {
        setDeleteDialogOpen(true);
      }
    }

    if (state === InitProdState.SCREENED_OUT) {
      if (index === 0) {
        setAddBackDialogOpen(true);
      } else if (index === 1) {
        setDeleteDialogOpen(true);
      }
    }
  };

  const getApiUrl = (action: string) => {
    const urlPaths = {
      [AvailableActions.CHOOSE_PRODUCT]: "award",
      [AvailableActions.REMOVE_PRODUCT_FROM_EVAL]: "remove-from-eval",
      [AvailableActions.ADD_PRODUCT_TO_EVAL]: "add-to-eval",
      [AvailableActions.SCREEN_OUT_PRODUCT]: "screen-out",
      [AvailableActions.REVERT_AWARD]: "revert-award",
    };
    return `/api/v3/initiatives/${initiativeId}/products/${prodInitId}/action/${urlPaths[action]}`;
  };

  const updateSnackbar = (action: string, isError = false) => {
    if (onShowSnackbar) {
      const snackbarMsgMapping = {
        [AvailableActions.REVERT_AWARD]: `${productName} selection reverted.`,
        [AvailableActions.SCREEN_OUT_PRODUCT]: `${productName} has been screened out of your evaluation.`,
        [AvailableActions.ADD_PRODUCT_TO_EVAL]: `${productName}  has been added to your evaluation.`,
        [AvailableActions.REMOVE_PRODUCT_FROM_EVAL]: `${productName} has been removed from your evaluation.`,
      };
      if (!isError) {
        onShowSnackbar("SUCCESS", snackbarMsgMapping[action]);
      } else {
        onShowSnackbar("ERROR");
      }
    }
  };

  const hideDialog = (action: string) => {
    if (action === AvailableActions.REVERT_AWARD) {
      setRevertVendorDialogOpen(false);
    }
    if (action === AvailableActions.REMOVE_PRODUCT_FROM_EVAL) {
      setDeleteDialogOpen(false);
    }
    if (action === AvailableActions.SCREEN_OUT_PRODUCT) {
      setScreenOutDialogOpen(false);
    }
    if (action === AvailableActions.ADD_PRODUCT_TO_EVAL) {
      setAddBackDialogOpen(false);
    }
    if (action === AvailableActions.CHOOSE_PRODUCT) {
      setSelectVendorDialogOpen(false);
    }
  };

  const trackEvent = (action: string) => {
    const allEvents = {
      [AvailableActions.CHOOSE_PRODUCT]: ClientEvent.INITIATIVE_SCORECARD_SELECT_VENDOR_CLICKED,
      [AvailableActions.SCREEN_OUT_PRODUCT]: ClientEvent.INITIATIVE_SCORECARD_SCREENOUT_VENDOR_CLICKED,
      [AvailableActions.REMOVE_PRODUCT_FROM_EVAL]: ClientEvent.INITIATIVE_SCORECARD_REMOVE_VENDOR_CLICKED,
    };
    if (!allEvents[action]) {
      return;
    }
    captureEvent([
      {
        eventType: allEvents[action],
        metaData: {
          initiativeId: initiativeId,
          initProductId: prodInitId,
        },
      },
    ]);
  };

  const handleActionDialogConfirm = async (action: string) => {
    const url = getApiUrl(action);
    const res =
      action === AvailableActions.ADD_PRODUCT_TO_EVAL
        ? await putAsyncRequest(url, {})
        : await postAsyncRequest(url, {});
    if ([200, 201, 204].includes(res.status)) {
      updateProductState(prodInitId, action);
      updateSnackbar(action);
    } else {
      updateSnackbar(action, true);
    }
    hideDialog(action);
    trackEvent(action);
  };

  return (
    <>
      <td
        key={key}
        className={classNames(
          state === InitProdState.SCREENED_OUT && "gx-screened-out-bk-gnd",
          state === InitProdState.AWARDED ? "gx-awarded" : "gx-not-awarded"
        )}
      >
        <div>
          {state === InitProdState.AWARDED && <SelectionRibbonIcon />}
          <TypographyComponent styling="p3" boldness="medium" color="carbon">
            {state === InitProdState.IN_EVALUATION && t("In evaluation")}
            {state === InitProdState.AWARDED && t("Selected")}
            {state === InitProdState.SCREENED_OUT && t("Screened out")}
            {state === InitProdState.LISTED && t("Listed")}
          </TypographyComponent>
          <PopoverMenu
            disabled={role !== InitUserRole.OWNER}
            iconType="kebab"
            showTooltip={role !== InitUserRole.OWNER}
            menuItems={menuItems}
            onClick={showVendorStatusOptions}
            showSelectedOption={false}
            useIconButton={true}
            usePortal={true}
            alignmentType={"leftBottom"}
            isDynamicWidth={false}
            tooltipOptions={{
              title: t("This action is only available to evaluation owners."),
              placement: "left",
            }}
            popoverMenuClass={styles.scorecardEvalStatusPopoverMenu}
          />
        </div>
      </td>
      <ActionDialog
        isOpen={addBackDialogOpen}
        isLoading={false}
        labels={{
          title: t("Add vendor back to evaluation?"),
          body1: {
            start: t("Adding"),
            end: t(
              "back to your evaluation will permanently delete any notes that were entered about why the vendor was screened out."
            ),
          },
          body2: "",
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Add back to evaluation"),
          },
        }}
        productName={productName}
        onClose={() => setAddBackDialogOpen(false)}
        onConfirm={() => handleActionDialogConfirm(AvailableActions.ADD_PRODUCT_TO_EVAL)}
      />
      <ActionDialog
        isOpen={screenOutDialogOpen}
        isLoading={false}
        labels={{
          title: t("Screen out vendor"),
          body1: { start: t("Are you sure you want to screen out"), end: t("from your evaluation?") },
          body2: t(
            'Screened out vendors and all associated scores and comments are always accessible from the Evaluation Overview page by clicking the toggle "Show screened-out vendors."'
          ),
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Screen out"),
          },
        }}
        productName={productName}
        onClose={() => setScreenOutDialogOpen(false)}
        onConfirm={() => handleActionDialogConfirm(AvailableActions.SCREEN_OUT_PRODUCT)}
      />
      <ActionDialog
        isOpen={revertVendorDialogOpen}
        isLoading={false}
        labels={{
          title: t("Revert this vendor selection?"),
          body1: { start: "", end: t("will no longer be marked as your currently selected vendor.") },
          body2: "",
          buttons: {
            cancel: t("Cancel"),
            confirm: t("Revert selection"),
          },
        }}
        productName={productName}
        onClose={() => setRevertVendorDialogOpen(false)}
        onConfirm={() => handleActionDialogConfirm(AvailableActions.REVERT_AWARD)}
      />
      <DialogWithTextbox
        autoFocus={true}
        confirmPasscode={t("REMOVE")}
        isOpen={deleteVendorDialogOpen}
        isShowingConfirm={true}
        labelCancelBtn={t("CANCEL")}
        labelConfirmBtn={t("REMOVE VENDOR")}
        labelDescription={
          <>
            <TypographyComponent styling="p2">
              {t("Are you sure you want to remove")}
              <TypographyComponent element={"span"} boldness={"medium"}>{` ${productName} `}</TypographyComponent>
              {t("from your evaluation?")}
            </TypographyComponent>
            <TypographyComponent styling="p2">
              {t("All associated scores, comments, and questionnaire responses will be permanently deleted.")}
              {` `}
              {t(`Type "REMOVE" to confirm you want to remove this vendor.`)}
            </TypographyComponent>
          </>
        }
        labelTitle={t("Remove vendor")}
        onClickClose={() => setDeleteDialogOpen(false)}
        onClickConfirm={() => handleActionDialogConfirm(AvailableActions.REMOVE_PRODUCT_FROM_EVAL)}
      />
      <AwardProductDialog
        awardedProductImageLoc={awardedProductDetails ? awardedProductDetails.imageLoc : ""}
        awardedProductName={awardedProductDetails ? awardedProductDetails.name : ""}
        isOpen={selectVendorDialogOpen}
        isLoading={false}
        selectionImageLoc={imageLoc || ""}
        selectionName={productName}
        onClose={() => setSelectVendorDialogOpen(false)}
        onConfirm={() => handleActionDialogConfirm(AvailableActions.CHOOSE_PRODUCT)}
      />
    </>
  );
};

export default EvaluationStatusColumn;
