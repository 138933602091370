import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./evaluation-details-header.module.scss";
import { ProductLogo, TypographyComponent } from "gx-npm-ui";
import { SelectionContext } from "../../../../../../selection.context";
import { Product } from "../../../../../../selection.types";

type EvaluationDetailsHeaderProps = {
  awardedProduct: Product;
};
const EvaluationDetailsHeader = ({ awardedProduct }: EvaluationDetailsHeaderProps) => {
  const { requirementCategories, requirementItemCount } = useContext(SelectionContext);

  const { t } = useTranslation();

  return (
    <div className={styles.topContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.productContainer}>
          <div className={styles.productInfo}>
            <ProductLogo
              logo={awardedProduct.imageLoc}
              name={awardedProduct.name}
              imageWidth="32px"
              imageHeight="32px"
            />
            <TypographyComponent boldness={"medium"} styling={"h4"}>
              {awardedProduct.name}
            </TypographyComponent>
          </div>
          <div className={styles.overallScorePill}>
            <TypographyComponent boldness={"medium"} styling={"p4"} rootClassName={styles.scorePillTitle}>
              {t("Overall score")}
            </TypographyComponent>
            <TypographyComponent boldness={"medium"} styling={"h4"} rootClassName={styles.overallScore}>
              {awardedProduct.overallScoreDisplay} / 100
            </TypographyComponent>
          </div>
        </div>
        <TypographyComponent styling={"p4"} rootClassName={styles.categoryHeader}>
          {t("Category")}
        </TypographyComponent>
      </div>
      <div className={styles.categoryStats}>
        <div className={styles.categoryStat}>
          <TypographyComponent styling={"p3"} rootClassName={styles.categoryStats}>
            {t("Scored against")}{" "}
            <TypographyComponent
              boldness={"medium"}
              element={"span"}
            >{`${requirementItemCount} requirements`}</TypographyComponent>
          </TypographyComponent>
        </div>
        <div className={styles.categoryStat}>
          <TypographyComponent styling={"p3"} rootClassName={styles.categoryStats}>
            {t("across")}{" "}
            <TypographyComponent
              element={"span"}
              boldness={"medium"}
            >{`${requirementCategories.length} categories`}</TypographyComponent>
          </TypographyComponent>
        </div>
        <TypographyComponent styling={"p3"} rootClassName={styles.categoryScoreHeader}>
          {t("Category score")}
        </TypographyComponent>
      </div>
    </div>
  );
};
export default EvaluationDetailsHeader;
