const getAutoResizeTextFieldStyles = (width = 50) => {
  return {
    autoResizeContainer: {
      "& .MuiOutlinedInput-multiline, & .MuiInputBase-multiline": {
        padding: "0px",
      },
      "& .gx-autoresize-textfield textarea": {
        minWidth: width + "px",
        width: width + "px",
        overflowWrap: "break-word",
      },
      "& .gx-autoresize-textfield input": {
        minWidth: width + "px",
        width: width + "px",
      },
    },
    "@global": {
      ".gx-item-list-del": {
        width: "120px ",
        textAlign: "center",
        top: "7px !important",
        left: "-2px !important",
      },
    },
  };
};

export { getAutoResizeTextFieldStyles };
