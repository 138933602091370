import { SnackbarBanner } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EvaluationStateContext } from "../../../../context";
import { clearAllErrors } from "../../../../context/actions/sharedActions";
import { CustomAppEvent } from "../../../../app.constants";

const ChecklistErrorComponent = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(EvaluationStateContext);

  useEffect(() => {
    if (state.checklist.isErrorLoading) {
      window.dispatchEvent(new CustomEvent(CustomAppEvent.FATAL_ERROR));
      return;
    }
    let message = "";
    let open = false;
    if (state.checklist.error?.isNotEditable) {
      message = t("You don't have editing access to this initiative. Please reload the page.");
      open = true;
    } else if (state.checklist.isErrorTransaction) {
      message = t("There was an issue saving your changes.");
      open = true;
    }
    setErrorMessage(message);
    setIsOpen(open);
  }, [state.checklist, t]);

  const handleCloseSnackbar = (open: boolean) => {
    if (!open) {
      clearAllErrors(state, dispatch);
    }
    setIsOpen(open);
  };

  return <SnackbarBanner isOpen={isOpen} message={errorMessage} setIsOpen={handleCloseSnackbar} />;
};

export default ChecklistErrorComponent;
