import isString from "../isUtils/isString";
import isPositiveNumber from "../isUtils/isPositiveNumber";

/**
 * @param {*} value string to be truncated
 * @param {*} maxLength max number of letters of string
 * @param {*} addEllipsis flagg to add ellipsis when string is truncated - defaults to true
 * @returns string that if truncated, has ellipsis added depending on flag
 */
const truncateString = (value, maxLength, addEllipsis = true) => {
  let newValue = "";
  if (isString(value)) {
    if (isPositiveNumber(maxLength)) {
      const postValue = value.length > maxLength && addEllipsis ? "..." : "";
      const trimmedValue = value.substring(0, maxLength);
      newValue = trimmedValue + postValue;
    } else {
      newValue = value;
    }
  }
  return newValue;
};

export default truncateString;
