const dragAndDropRootStyles = {
  root: {
    marginBottom: 24,
    position: "relative",
  },
};

const getFadeStyles = (duration = 300) => {
  return {
    itemFadeIn: {
      opacity: 0,
      animation: `$fadein ${duration}ms`,
    },
    itemFadeout: {
      display: "flex",
      alignItems: "center",
      animation: `$fadeout ${duration}ms`,
    },
    "@keyframes fadein": {
      "0%": { opacity: 0, maxHeight: "0px" },
      "100%": { opacity: 1, maxHeight: "48px" },
    },
    "@keyframes fadeout": {
      "0%": { opacity: 1, maxHeight: "48px" },
      "100%": { opacity: 0, maxHeight: "0px" },
    },
  };
};

export { dragAndDropRootStyles, getFadeStyles };
