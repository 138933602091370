import React, { useContext } from "react";
import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { SELECTED_PRIORITY_WEIGHTS } from "../../../products.constants";
import { ProductsContext } from "../../../products.context";
import styles from "./product-list-header-v3.styles.module.scss";
import { GCOM_4297__emqUpdates } from "../../../../../lib/feature-flags";

const ProductListHeaderV3Component = ({ isEMBasedTemplate = false }: { isEMBasedTemplate?: boolean }) => {
  const { t } = useTranslation();
  const { isBaseTemplate, isMqBasedTemplate, priorities } = useContext(ProductsContext);
  const hasPrioritiesSelected = priorities.some((priority) => SELECTED_PRIORITY_WEIGHTS.includes(priority.weight));
  const hasFourColumns = !isBaseTemplate && isMqBasedTemplate && hasPrioritiesSelected;

  return (
    <div className={styles.headerWrapper}>
      <TypographyComponent boldness={"medium"} color={"iron"} rootClassName={styles.startColumn} styling={"p4"}>
        {t("Vendor")}
      </TypographyComponent>
      <div className={classNames(styles.middleColumns, hasFourColumns && styles.smallMiddleColumns)}>
        <FeatureFlagBooleanContainer flagName={GCOM_4297__emqUpdates}>
          <FeatureFlagBooleanOn>
            {(isMqBasedTemplate || isEMBasedTemplate) && (
              <TypographyComponent rootClassName={styles.column}>
                <TypographyComponent
                  element={"span"}
                  rootClassName={styles.smallMqText}
                  boldness={"medium"}
                  color={"iron"}
                  styling={"p4"}
                >
                  {isEMBasedTemplate && t("EM position")}
                  {isMqBasedTemplate && t("MQ position")}
                </TypographyComponent>
                <TypographyComponent
                  element={"span"}
                  rootClassName={styles.normalMqText}
                  boldness={"medium"}
                  color={"iron"}
                  styling={"p4"}
                >
                  {isEMBasedTemplate && t("Emerging Market Quadrant position")}
                  {isMqBasedTemplate && t("Magic Quadrant position")}
                </TypographyComponent>
              </TypographyComponent>
            )}
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            {isMqBasedTemplate && (
              <TypographyComponent rootClassName={styles.column}>
                <TypographyComponent
                  element={"span"}
                  rootClassName={styles.smallMqText}
                  boldness={"medium"}
                  color={"iron"}
                  styling={"p4"}
                >
                  {t("MQ position")}
                </TypographyComponent>
                <TypographyComponent
                  element={"span"}
                  rootClassName={styles.normalMqText}
                  boldness={"medium"}
                  color={"iron"}
                  styling={"p4"}
                >
                  {t("Magic Quadrant position")}
                </TypographyComponent>
              </TypographyComponent>
            )}
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        {!isBaseTemplate && (
          <TypographyComponent color={"iron"} boldness={"medium"} rootClassName={styles.column} styling={"p4"}>
            {t("Peer Insights")}
          </TypographyComponent>
        )}
        {hasPrioritiesSelected && (
          <TypographyComponent color={"iron"} boldness={"medium"} rootClassName={styles.column} styling={"p4"}>
            {t("Vendor fit")}
          </TypographyComponent>
        )}
      </div>
    </div>
  );
};

export default ProductListHeaderV3Component;
