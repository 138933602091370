import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { getRequest } from "../../../../../context/actions/apiRequests";
import useOutsideAlerter from "./use-outside-alerter";
import { evalPopperTypes } from "./evaluation-popper.type";
import EvaluationPopperView from "./evaluation-popper-view.component";

const propTypes = {
  initId: PropTypes.string,
  isOpen: PropTypes.bool,
  isScored: PropTypes.bool,
  onClickClose: PropTypes.func,
  prodImageLoc: PropTypes.string,
  prodInitId: PropTypes.string,
  prodName: PropTypes.string,
  reqCatId: PropTypes.string,
  reqItemId: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(evalPopperTypes)),
};
const EvaluationPopperData = ({
  initId = "",
  isOpen = false,
  isScored = false,
  onClickClose = () => {},
  prodImageLoc = "",
  prodInitId = "",
  prodName = "",
  reqCatId = "",
  reqItemId = "",
  subTitle = "",
  title = "",
  type = evalPopperTypes.NONE,
}) => {
  const [evaluatorsScores, setEvaluatorsScores] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contentRef = useRef();
  const globalClickFunction = useCallback(() => {
    if (isOpen) {
      onClickClose();
    }
  }, [isOpen, onClickClose]);
  useOutsideAlerter(contentRef, globalClickFunction);

  const globalEscFunction = useCallback(
    (event) => {
      if (isOpen && event.key === "Escape") {
        onClickClose();
      }
    },
    [isOpen, onClickClose]
  );
  useEffect(() => {
    document.addEventListener("keydown", globalEscFunction, false);
    return () => {
      document.removeEventListener("keydown", globalEscFunction, false);
    };
  }, [globalEscFunction]);

  useEffect(() => {
    if (!isOpen) {
      setEvaluatorsScores([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !initId || !prodInitId || !type) {
      return;
    }
    setEvaluatorsScores([]);
    setIsError(false);
    setIsLoading(true);
    let urlEnd = "";
    if (type === evalPopperTypes.CATEGORY_PROGRESS) {
      urlEnd = `category/${reqCatId}/progress`;
    } else if (type === evalPopperTypes.CATEGORY_SCORE) {
      urlEnd = `category/${reqCatId}/score`;
    } else if (type === evalPopperTypes.ITEM_SCORE) {
      urlEnd = `item/${reqItemId}/score`;
    }
    const url = `api/v2/initiatives/${initId}/products/${prodInitId}/evaluators/${urlEnd}`;
    const fetchData = async () => {
      const response = await getRequest(url);
      if (response?.status === 200 && Array.isArray(response.data?.data)) {
        const data = response.data.data.map((row) => {
          const { isOptedOut, name, progress, score, scoreDisplay } = row;
          return { isOptedOut, name, progress, score, scoreDisplay, id: uuidV4() };
        });
        setEvaluatorsScores(data);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [initId, isOpen, prodInitId, reqCatId, reqItemId, type]);

  return (
    <EvaluationPopperView
      evaluatorsScores={evaluatorsScores}
      forwardedRef={contentRef}
      isError={isError}
      isLoading={isLoading}
      isProgressPopper={type === evalPopperTypes.CATEGORY_PROGRESS}
      isScored={isScored}
      onClickClose={onClickClose}
      prodImageLoc={prodImageLoc}
      prodName={prodName}
      subTitle={subTitle}
      title={title}
    />
  );
};

EvaluationPopperData.propTypes = propTypes;
export default EvaluationPopperData;
