import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { planningCardV2Styles as styles } from "./planning-card-v2.styles";
import { UUID } from "gx-npm-lib";
import PlanningContentSectionV2 from "./planning-content-section-v2.component";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";

type PlanningCardV2Props = {
  initiativeId: UUID;
  isViewOnly: boolean;
};
const useStyles = makeStyles(() => styles);
const PlanningCardV2 = ({ initiativeId, isViewOnly = false }: PlanningCardV2Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.planningCardWrapper}>
      <TypographyComponent boldness={"medium"} rootClassName={classNames(classes.headerLargeTitle)} styling={"h4"}>
        {t("Evaluation details")}
      </TypographyComponent>
      <PlanningContentSectionV2 isViewOnly={isViewOnly} initiativeId={initiativeId} />
    </div>
  );
};
export default PlanningCardV2;
