// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FCibHpOkp1ZO73F1bgJr{cursor:default;display:block}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/product-list-row-v3/quadrant-cell/quadrant-cell.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,aAAA","sourcesContent":[".mqWrapper {\n  cursor: default;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mqWrapper": `FCibHpOkp1ZO73F1bgJr`
};
export default ___CSS_LOADER_EXPORT___;
