import { colorPalette } from "gx-npm-common-styles";

const selectedBodyV2Styles = {
  "@global": {
    "& .gx-selected-body-icon-vendor-name-tooltip .MuiTooltip-arrow": {
      left: "56px !important",
    },
  },
  scorePill: {
    alignItems: "center",
    backgroundColor: colorPalette.neutrals.quartz.hex,
    borderRadius: "50px",
    display: "inline-flex",
    padding: "12px 24px 12px 24px",
    zIndex: 1,
    "& $selectedVendorScore": {
      float: "none",
      marginBottom: 0,
    },
  },
  selectedBodyContainer: {
    "& $selectedCTAButton": {
      marginTop: "80px",
      "&$isWrap": {
        marginTop: "40px",
      },
    },
  },
  selectedBodyWrapper: {
    alignItems: "center",
    display: "flex",
    marginTop: "24px",
    flexWrap: "wrap" as const,
    width: "calc(100% - 255px)",
    gap: "24px",
  },
  selectedBodyLogoTitle: {
    display: "flex",
  },
  selectedBodyLogoTitleV2: {
    display: "flex",
    "&.hover": {
      cursor: "pointer",
      "& $selectedVendorName": {
        backgroundColor: `rgba(180, 205, 247, 0.60)`,
        borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
        color: colorPalette.interactions.hoverCta.hex,
        marginBottom: "-1px",
        textDecoration: "none",
      },
    },
  },
  selectedVendorName: {
    display: "flex",
    flexWrap: "wrap" as const,
    marginLeft: "12px",
    marginRight: "16px",
    zIndex: 1,
  },
  selectedVendorScore: {
    marginLeft: "12px",
  },
  selectedCTAButton: {},
  selectedBackground: {
    borderRadius: "0 8px 8px 0",
    position: "absolute" as const,
    right: 0,
    top: 0,
  },
  isWrap: {},
};

export { selectedBodyV2Styles };
