import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MiniButton, QuickStartPopperComponent } from "gx-npm-ui";
import styles from "./quickstart-guide.styles.module.scss";
import OverviewComponent from "./overview/overview.component";
import AssigningScorersComponent from "./assigning-scorers/assigning-scorers.component";
import AddingScoresComponent from "./adding-scores/adding-scores.component";
import CalculatingScoresComponent from "./calculating-scores/calculating-scores.component";
import { InfoLightIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import classNames from "classnames";
import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { ClientEvent } from "../../../../../../app.constants";
import { useParams } from "react-router-dom";

type QuickstartGuideComponentProps = {
  rootClassName?: string;
  miniBtnVariant?: "darkTheme" | "";
};
const QuickstartGuideComponent = ({ rootClassName = "", miniBtnVariant = "" }: QuickstartGuideComponentProps) => {
  const { t } = useTranslation();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const { initiativeId = "" } = useParams<{
    initiativeId: UUID;
  }>();
  const captureEvents = useCaptureEventsV2();

  const clientEvents = [
    ClientEvent.INITIATIVE_SCORECARD_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvent.INITIATIVE_SCORECARD_QUICKSTART_ASSIGNING_SCORERS_CLICKED,
    ClientEvent.INITIATIVE_SCORECARD_QUICKSTART_ADDING_SCORES_CLICKED,
    ClientEvent.INITIATIVE_SCORECARD_QUICKSTART_CALCULATING_SCORES_CLICKED,
  ];

  const metaData = { initiativeId: initiativeId || "" };

  const quickStartPopperTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Assigning scorers", content: <AssigningScorersComponent /> },
    { label: "Adding scores", content: <AddingScoresComponent /> },
    { label: "Calculating scores", content: <CalculatingScoresComponent /> },
  ];

  return (
    <div className={classNames(styles.container, rootClassName)}>
      <MiniButton
        variant={miniBtnVariant}
        onClick={() => {
          captureEvents([
            {
              eventType: ClientEvent.INITIATIVE_SCORECARD_QUICKSTART_MODAL_OPENED,
              metaData,
            },
          ]);
          setIsPopperOpen(true);
        }}
      >
        <InfoLightIcon fillPath={colorPalette.interactions.blueBerry.hex} />
        {t("How scoring works")}
      </MiniButton>
      <QuickStartPopperComponent
        title={t("Scorecard")}
        isOpen={isPopperOpen}
        onClose={() => {
          captureEvents([
            {
              eventType: ClientEvent.INITIATIVE_SCORECARD_QUICKSTART_MODAL_CLOSED,
              metaData,
            },
          ]);
          setIsPopperOpen(false);
        }}
        tabsData={quickStartPopperTabsData}
        onTabClick={(tabIndex: number) => {
          captureEvents([
            {
              eventType: clientEvents[tabIndex],
              metaData,
            },
          ]);
        }}
      />
    </div>
  );
};

export default QuickstartGuideComponent;
