import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { STICKY_HEADER_WRAPPER_CLASS, STICKY_HEADER_IGNORE_CLASS } from "gx-npm-ui";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { getListHeaderStyles } from "./styles";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  expand: PropTypes.bool,
  index: PropTypes.number,
  rootClassName: PropTypes.string,
  setExpand: PropTypes.func,
  sticky: PropTypes.number,
};
const ListHeader = ({ children, expand, index = 0, rootClassName = "", setExpand, sticky }) => {
  const styles = getListHeaderStyles(sticky, index);
  const useStyles = makeStyles(() => styles);
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const [isShadow, setIsShadow] = useState(false);
  const sectionRef = useRef(null);

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child) ? React.cloneElement(child, { expand, setExpand }) : child;
  });

  const handleMouseEvent = (type) => {
    setIsHovering("enter" === type || "move" === type);
    const currentClassList = [...(sectionRef?.current?.classList || [])];
    if (currentClassList.includes("shadow")) {
      setIsShadow(true);
    } else {
      setIsShadow(false);
    }
  };

  return (
    <section
      data-testid={"list-header-test-id"}
      className={classNames(
        "gx-list-header",
        "list-header",
        classes.listHeader,
        expand ? "expand" : "collapse",
        !expand && STICKY_HEADER_IGNORE_CLASS,
        sticky && STICKY_HEADER_WRAPPER_CLASS,
        isHovering && "hover",
        isShadow && "shadow",
        rootClassName
      )}
      ref={sectionRef}
      onBlur={() => handleMouseEvent("blur")}
      onMouseEnter={() => handleMouseEvent("enter")}
      onMouseMove={() => handleMouseEvent("move")}
      onMouseOut={() => handleMouseEvent("out")}
    >
      {childrenWithProps}
    </section>
  );
};

ListHeader.propTypes = propTypes;
export default ListHeader;
