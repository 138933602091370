// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aHaFIHuO1TLiilw0dnXD{margin-top:24px}.N1sesdfIFMLFm2pfQo0a{margin-top:32px;max-width:600px}.N1sesdfIFMLFm2pfQo0a .XbDWz7QF_ir1VSD1yMRC{color:var(--color-neutrals-carbon)}.N1sesdfIFMLFm2pfQo0a ._ZnAM4fO6YBhzDTnHC5s{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/scorecard-header/quickstart-guide/adding-scores/adding-scores.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA","sourcesContent":[".addingScoresImg {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addingScoresImg": `aHaFIHuO1TLiilw0dnXD`,
	"description": `N1sesdfIFMLFm2pfQo0a`,
	"heading": `XbDWz7QF_ir1VSD1yMRC`,
	"additionalInfo": `_ZnAM4fO6YBhzDTnHC5s`
};
export default ___CSS_LOADER_EXPORT___;
