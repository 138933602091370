import { makeStyles } from "@material-ui/styles";
import { InitUserRole, isValidResponse, useCaptureEventsV2, useUserInitAccess } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { Loader, SnackbarBanner } from "gx-npm-ui";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { ClientEvent } from "../../app.constants";
import { getApiUrl } from "../../context/actions/actionUtils";
import { getRequest } from "../../context/actions/apiRequests";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import SurveyHeader from "./header/survey-header.component";
import NoVendorsView from "./no-vendors-view/no-vendors-view.component";
import PreSurveyStartView from "./pre-survey-start-view/pre-survey-start-view.component";
import SurveyTeamManagementDrawer from "./survey-team-management/survey-team-management.component";
import VendorsSurveyListView from "./vendors-surveylist-view/vendors-surveylist-view.component";
import styles from "./survey-tab.styles";

const useStyles = makeStyles(styles);
const SurveyTab = () => {
  const classes = useStyles();
  const { initiativeId = "" } = useParams();
  const { t } = useTranslation();
  const [hasSurvey, setHasSurvey] = useState(false);
  const [surveyId, setSurveyId] = useState("");
  const [initName, setInitName] = useState("");
  const [surveyVendors, setSurveyVendors] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerVendorData, setDrawerVendorData] = useState({});
  const captureEvents = useCaptureEventsV2();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);

  const loadSurveyData = useCallback(async () => {
    const url = getApiUrl(initiativeId, "survey");
    const response = await getRequest(url);
    if (isValidResponse(response)) {
      const payload = response.data?.data;
      setHasSurvey(!!payload.surveyId);
      setSurveyId(payload.surveyId);
      setInitName(payload.initName || "");
      setSurveyVendors(payload.vendors || []);
    } else {
      setIsError(true);
    }
  }, [initiativeId]);

  useEffect(() => {
    setShowHeader(hasSurvey);
  }, [hasSurvey]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setIsLoading(true);
    loadSurveyData().finally(() => setIsLoading(false));
  }, [initiativeId, loadSurveyData]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_SURVEY_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  useEffect(() => {
    if (!window.Intercom || !hasSurvey || surveyVendors.length === 0) {
      return;
    }
    if (JSON.parse(localStorage.getItem("hasViewedQuestionnaireTabAfterInEvaluation")) === true) {
      return;
    }
    window.Intercom("startTour", 483234);
    localStorage.setItem("hasViewedQuestionnaireTabAfterInEvaluation", true);
  }, [hasSurvey, surveyVendors]);

  const handleClick = () => {
    const url = surveyId
      ? `/s/evaluation/${initiativeId}/questionnaire/setup/${surveyId}`
      : `/s/evaluation/${initiativeId}/questionnaire/setup`;
    singleSpa.navigateToUrl(url);
  };

  const handleVendorClick = (data) => {
    if (data.action === "manage") {
      setDrawerVendorData({ id: data.id, imageLoc: data.imageLoc, name: data.name });
      setIsDrawerOpen(true);
    } else if (data.action === "view") {
      updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "questionnaire" });
      singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/product/${data.id}/responses`);
    }
  };

  const handleClose = () => {
    setIsDrawerOpen(false);
    setTimeout(() => setDrawerVendorData({}), 1000);
  };

  return (
    <TabPanelComponent>
      <Fragment>
        {showHeader && (
          <SurveyHeader
            isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
            showSlideIn={surveyVendors?.length === 0}
            onSetupClick={handleClick}
            surveyId={surveyId}
            initiativeId={initiativeId}
          />
        )}
        {isLoading && (
          <div className={classes.loadingScreen}>
            <Loader size={40} variant="indeterminate" />
          </div>
        )}
        {!isLoading && !hasSurvey && (
          <PreSurveyStartView
            btnText={t("SET UP QUESTIONNAIRE")}
            onButtonClick={handleClick}
            isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
          />
        )}
        {!isLoading && hasSurvey && surveyVendors.length === 0 && <NoVendorsView />}
        {!isLoading && hasSurvey && surveyVendors.length > 0 && (
          <VendorsSurveyListView
            isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
            vendors={surveyVendors}
            onClickAction={handleVendorClick}
            initiativeId={initiativeId}
          />
        )}
        <SurveyTeamManagementDrawer
          surveyId={surveyId}
          initiativeId={initiativeId}
          initName={initName}
          isOpen={isDrawerOpen}
          onClose={handleClose}
          vendorData={drawerVendorData}
          reloadSurveyData={loadSurveyData}
        />
        <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
      </Fragment>
    </TabPanelComponent>
  );
};

export default SurveyTab;
