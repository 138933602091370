import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./survey-team-list-item.styles";
import { MiniButton, PopoverMenu, SnackbarBanner, useFeatureFlag, UsernameDisplay } from "gx-npm-ui";
import { CloseIcon, RemoveUserIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { handleEvent } from "gx-npm-lib";
import ScrollToRef from "../../../../ui/scroll-to-ref/scroll-to-ref.component";
import { GCOM_4304__manage_recipients_updates } from "../../../../lib/feature-flags";

const proptypes = {
  fullName: propTypes.string,
  email: propTypes.string,
  hasViewed: propTypes.bool,
  onRemoveTeamMember: propTypes.func,
  onResendInvite: propTypes.func,
  resendEmailSuccess: propTypes.string,
  addEmailSuccess: propTypes.string,
};

const useStyles = makeStyles(styles);

const SurveyTeamListItem = ({
  fullName = "",
  email = "",
  hasViewed = false,
  onRemoveTeamMember = () => {},
  onResendInvite = () => {},
  resendEmailSuccess = "",
  addEmailSuccess = "",
}) => {
  const isFFGCOM4304 = useFeatureFlag(GCOM_4304__manage_recipients_updates);
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const kebabMenu = [
    { index: 0, name: t("Resend questionnaire email") },
    {
      index: 1,
      name: t("Remove user"),
      rootClassName: isFFGCOM4304 ? "poison-cherry" : "remove",
    },
  ];

  useEffect(() => {
    if (resendEmailSuccess === email || addEmailSuccess === email) {
      setIsSnackbarOpen(true);
    }

    if (resendEmailSuccess === email) {
      setSnackbarMessage(t("Email resent"));
    }

    if (addEmailSuccess === email) {
      setSnackbarMessage(t("Email sent"));
    }
  }, [resendEmailSuccess, addEmailSuccess, email, t]);

  const handleSelectDropdown = (val) => {
    if (val === kebabMenu[0].index) {
      handleEvent(onResendInvite, email);
    } else if (val === kebabMenu[1].index) {
      setConfirmDelete(true);
    }
  };

  const handleRemoveUserConfirm = () => {
    handleEvent(onRemoveTeamMember, email);
    setConfirmDelete(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };
  return (
    <>
      <div className={classNames(classes.surveyTeamItem, confirmDelete && "isHighlighted")}>
        <UsernameDisplay
          email={email}
          isUnknown={!hasViewed}
          name={hasViewed ? fullName : t("Pending")}
          showEmail={true}
        />
        <div className={classes.surveyTeamSnackbarWrapper}>
          <SnackbarBanner
            autoHideDuration={2000}
            isOpen={isSnackbarOpen}
            pointerActionCursor={true}
            setIsOpen={handleSnackbarClose}
            keyName="mini-snackbar"
            message={snackbarMessage}
            transitionDuration={{ enter: 800, exit: 2000 }}
            type="MINI_SUCCESS"
          />
          <div className={classes.popoverMenu}>
            {!confirmDelete ? (
              <div className={classes.popoverMenu}>
                <PopoverMenu
                  showSelectedOption={false}
                  alignmentType="rightBottom"
                  iconType="kebab"
                  menuItems={kebabMenu}
                  useIconButton={true}
                  onClick={handleSelectDropdown}
                />
              </div>
            ) : (
              <div className={classes.actionButtons}>
                <MiniButton onClick={() => setConfirmDelete(false)}>
                  <CloseIcon fillPath={colorPalette.interactions.blueBerry.hex} /> {t("Cancel")}
                </MiniButton>
                <MiniButton onClick={handleRemoveUserConfirm} variant="destructive">
                  <RemoveUserIcon />
                  {t("Remove User")}
                </MiniButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <ScrollToRef isScrolled={email === addEmailSuccess} />
    </>
  );
};

SurveyTeamListItem.propTypes = proptypes;
export default SurveyTeamListItem;
