import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from "gx-npm-common-styles";
import { ExclamationCircledIcon } from "gx-npm-icons";
import { ButtonLoader, TooltipV2, TypographyComponent } from "gx-npm-ui";
import TriangleWarningIcon from "./triangleWarningIcon";
import { warningBannerStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";

const propTypes = {
  isButtonShown: PropTypes.bool,
  isCircleIcon: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isShadowed: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onButtonClick: PropTypes.func,
  textButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const useStyles = makeStyles(() => styles);
/* TODO - Update to use the InlineAlert component in gx-npm-ui */
/** @deprecated */
const WarningBanner = ({
  isButtonShown = false,
  isCircleIcon = true,
  isProcessing = false,
  isShadowed = true,
  isViewOnly = false,
  onButtonClick = () => {},
  textButton = "",
  textMessage = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classNames("gx-banner", classes.bnrContainer, isShadowed && "shadowed")}>
      <div className={classNames(classes.bnrIcon, classes.bnrFlexChild, isCircleIcon && "circle-icon")}>
        {isCircleIcon ? (
          <ExclamationCircledIcon fillPath={colorPalette.status.darkLemon.hex} />
        ) : (
          <TriangleWarningIcon />
        )}
      </div>
      <div className={classNames(classes.bnrFlexChild)}>
        <TypographyComponent boldness={"medium"} rootClassName={classes.bnrMessage} styling={"p2"}>
          {textMessage}
        </TypographyComponent>
      </div>
      <div className={classNames(classes.bnrButton, classes.bnrFlexChild)}>
        {isButtonShown && (
          <TooltipV2
            deactivate={!isViewOnly}
            placement="left"
            title={t("This action is only available to evaluation owners and contributors.")}
          >
            <div>
              <ButtonLoader
                btnClass="btn-tertiary"
                disabled={isViewOnly}
                isLoading={isProcessing}
                onClick={onButtonClick}
              >
                {textButton}
              </ButtonLoader>
            </div>
          </TooltipV2>
        )}
      </div>
    </div>
  );
};

WarningBanner.propTypes = propTypes;
export default WarningBanner;
