import { UUID } from "gx-npm-lib";
import { useParams } from "react-router-dom";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import SelectionTabContentV2 from "../selection-tab-v2/selection-tab-content-v2.component";
import { SelectionContextProvider } from "./selection.context";

const SelectionTabV2 = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  return (
    <SelectionContextProvider>
      <TabPanelComponent>
        <SelectionTabContentV2 initiativeId={initiativeId} />
      </TabPanelComponent>
    </SelectionContextProvider>
  );
};

export default SelectionTabV2;
