import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReqLibraryHeader from "./req-library-header.component";
import ListSelection from "../components/list-selection/list-selection.component";
import BulkOperations from "../../bulkOperations/bulk-operations.component";

const propTypes = {
  initId: PropTypes.string,
  isClosing: PropTypes.bool,
  requirements: PropTypes.arrayOf(PropTypes.object),
  reqNum: PropTypes.number,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  handleBreadcrumbClick: PropTypes.func,
  additionalReqs: PropTypes.bool,
};

const ReqLibraryContainer = ({
  initId = "",
  isClosing = false,
  requirements = [],
  reqNum = 0,
  categoryList = [],
  handleBreadcrumbClick = () => {},
  additionalReqs = false,
}) => {
  const { t } = useTranslation();

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedAllIds, setSelectedAllIds] = useState([]);

  useEffect(() => {
    if (isClosing) {
      handleDeselectAll();
    }
  }, [isClosing]);

  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectedAllIds([]);
  };

  const selectedAllCheckbox = (isSelected, catId) => {
    if (isSelected) {
      if (!selectedAllIds.includes(catId)) {
        setSelectedAllIds((prev) => [...prev, catId]);
      }
    } else {
      setSelectedAllIds((prev) => prev.filter((id) => id !== catId));
    }
  };

  const handleSelectAll = (e, index) => {
    let itemIds;

    if (additionalReqs) {
      itemIds = [];
      requirements[index].itemList.map((item) => {
        item.itemList.map((subItem) => {
          itemIds.push(subItem.id);
        });
      });
    } else {
      itemIds = requirements[index].itemList.map((item) => item.id);
    }

    if (e.target.checked) {
      for (let i = 0; i < itemIds.length; i++) {
        if (!selectedIds.includes(itemIds[i])) {
          setSelectedIds((prev) => [...prev, itemIds[i]]);
        }
      }
    } else {
      for (let i = 0; i < itemIds.length; i++) {
        setSelectedIds((prev) => prev.filter((id) => id !== itemIds[i]));
      }
    }

    selectedAllCheckbox(e.target.checked, index);
  };

  const handleChange = (isChecked, reqId) => {
    if (isChecked) {
      if (!selectedIds.includes(reqId)) {
        setSelectedIds((prev) => [...prev, reqId]);
      }
    } else {
      setSelectedIds((prev) => prev.filter((id) => id !== reqId));
    }
  };

  return (
    <>
      <ReqLibraryHeader
        breadcrumbText={additionalReqs ? t("Additional requirements") : t("Default requirements")}
        categoryNum={requirements ? requirements.length : "0"}
        requirementNum={reqNum}
        breadcrumbHandleClick={handleBreadcrumbClick}
      />
      <ListSelection
        requirements={requirements}
        selectedIds={selectedIds}
        onChange={handleChange}
        onSelectAll={handleSelectAll}
        selectedAllCheckboxId={selectedAllIds}
        additionalReqs={additionalReqs}
      />
      <BulkOperations
        bulkOperationIds={selectedIds}
        categoryList={categoryList}
        isRequirementLibrary={true}
        initiativeId={initId}
        onClearSelected={handleDeselectAll}
      />
    </>
  );
};

ReqLibraryContainer.propTypes = propTypes;
export default ReqLibraryContainer;
