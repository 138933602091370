import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "@material-ui/core";
import { WarningBanner } from "../../../../../ui/banners";
import { useTranslation } from "react-i18next";

const propTypes = { isShown: PropTypes.bool };
const ScoringLevelWarningBanner = ({ isShown }) => {
  const { t } = useTranslation();
  return (
    <Collapse in={isShown}>
      <WarningBanner
        isCircleIcon={false}
        isShadowed={false}
        textMessage={t("Warning! Switching the scoring level will delete existing scores.")}
      />
    </Collapse>
  );
};

ScoringLevelWarningBanner.propTypes = propTypes;
export default ScoringLevelWarningBanner;
