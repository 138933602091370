import { colorPalette } from "gx-npm-common-styles";

const AutocompleteStyles = {
  container: {
    position: "relative",
  },
  input: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  popper: {
    height: "auto",
    color: "red",
    backgroundColor: "var(--color-white)",
  },
  listbox: {
    margin: "0",
    overflow: "auto",
    listStyle: "none",
    maxHeight: "40vh",
    backgroundColor: "var(--color-white)",
    padding: 0,
  },
  option: {
    cursor: "pointer",
    display: "flex",
    outline: "0",
    boxSizing: "border-box",
    minHeight: "48px",
    alignItems: "center",
    padding: "6px 16px",
    justifyContent: "flex-start",
  },
  existingVendor: {
    "&[disabled]": {
      backgroundColor: colorPalette.neutrals.pearl.hex + " !important",
    },
  },
};

export { AutocompleteStyles };
