const blurRef = (ref) => {
  let hasBlurred = false;
  if (typeof ref?.current?.blur === "function") {
    ref.current.blur();
    hasBlurred = true;
  }
  return hasBlurred;
};

export default blurRef;
