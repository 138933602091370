import { colorPalette } from "gx-npm-common-styles";

const reqsPrePublishInlineAlertStyles = {
  collapseContainer: {},
  alertContainer: {
    alignItems: "center",
    backgroundColor: colorPalette.status.lightCherry.hex,
    display: "flex",
    height: "auto",
    marginBottom: "48px",
    marginTop: "-42px",
    padding: "16px 56px 16px 56px",
    width: "100%",
    "& .gx-inline-alert-message": {
      color: colorPalette.status.poisonCherry.hex,
      marginLeft: "16px",
      maxWidth: "600px",
    },
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
    marginRight: 0,
    "& .mini-btn:hover, .mini-btn:focus": {
      backgroundColor: colorPalette.status.mediumCherry.hex,
    },
  },
};

export default reqsPrePublishInlineAlertStyles;
