import { CircularProgress } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CloseIcon } from "gx-npm-icons";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { MiniButton, TooltipV2 } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./remove-product-button.styles.module.scss";

const RemoveProductButtonComponent = ({ isRemoving = false, onClick = () => {} }) => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { role } = useUserInitAccess(initiativeId);
  const isViewOnly = role !== InitUserRole.OWNER;
  return (
    <TooltipV2
      deactivate={!isViewOnly}
      placement="top"
      PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
      title={t("This action available only for the evaluation owners.")}
    >
      <div className={styles.removeBtnRoot}>
        {!isRemoving && (
          <MiniButton
            ariaLabel={t("Remove from eval")}
            disabled={isViewOnly || isRemoving}
            onClick={onClick}
            variant="destructive"
          >
            <CloseIcon fillPath={colorPalette.status.poisonCherry.hex} />
            {t("Remove from eval")}
          </MiniButton>
        )}
        {isRemoving && <CircularProgress size={24} />}
      </div>
    </TooltipV2>
  );
};

export default RemoveProductButtonComponent;
