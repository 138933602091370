import { InitState, InitStatus } from "gx-npm-lib";
import { createContext, ReactNode, useState } from "react";
import { HeaderV2ContextValue } from "./header-v2.types";

const HeaderV2Context = createContext<HeaderV2ContextValue>({} as HeaderV2ContextValue);
const HeaderV2ContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [isSavingInitName, setIsSavingInitName] = useState<boolean>(false);
  const [initName, setInitName] = useState<string>("");
  const [initState, setInitState] = useState<InitState | "">("");
  const [initStatus, setInitStatus] = useState<InitStatus | "">("");
  const [magicQuadResId, setMagicQuadResId] = useState<number>(-1);
  const [marketGuideResId, setMarketGuideResId] = useState<number>(-1);
  const [piMarketSeoName, setPiMarketSeoName] = useState<string>("");
  const [stateUpdateDate, setStateUpdateDate] = useState("");
  const [templateName, setTemplateName] = useState<string>("");

  const contextValue = {
    isSavingInitName,
    initName,
    initState,
    initStatus,
    magicQuadResId,
    marketGuideResId,
    piMarketSeoName,
    stateUpdateDate,
    templateName,
    setIsSavingInitName,
    setInitName,
    setInitState,
    setInitStatus,
    setMagicQuadResId,
    setMarketGuideResId,
    setPiMarketSeoName,
    setStateUpdateDate,
    setTemplateName,
  };
  return <HeaderV2Context.Provider value={contextValue}>{props.children}</HeaderV2Context.Provider>;
};

export { HeaderV2Context, HeaderV2ContextProvider };
