// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pTk_5WxHZEChVEuZfNKq{background-color:var(--color-white);border-radius:8px;padding:40px;transition:all .3s;width:100%}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/evaluation-details/evaluation-details.module.scss"],"names":[],"mappings":"AAAA,sBACI,mCAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".root {\n    background-color: var(--color-white);\n    border-radius: 8px;\n    padding: 40px;\n    transition: all 0.3s;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pTk_5WxHZEChVEuZfNKq`
};
export default ___CSS_LOADER_EXPORT___;
