// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WdbF4ZCSA4ZOJydQHBmw{display:flex;padding-bottom:28px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/gartner-insights/components/product-profile/product-profile.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,mBAAA","sourcesContent":[".marketInfo {\n  display: flex;\n  padding-bottom: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketInfo": `WdbF4ZCSA4ZOJydQHBmw`
};
export default ___CSS_LOADER_EXPORT___;
