import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { SlideInOverlay, SnackbarBanner } from "gx-npm-ui";
import ImportLibraryBody from "./importLibraryBody";
import RequirementDrawerTitle from "./requirementDrawerTitle";
import ImportLibraryNavBar from "./importLibraryNavBar";
import GartnerLibraryMenu from "./gartnerLibraryBody";
import { libraryDrawerStyles as styles } from "./styles";
import { computeAdditionalRequirementsCount, computeRequirementsCount, gLibraryReqLevel } from "../../../../lib";
import { getRequest } from "../../../../context/actions/apiRequests";
import ReqLibraryContainer from "./gartnerLibraryBody/req-library-container.component";
import { libraryDrawerTabIndexes } from "./lib";
import { ClientEvent } from "../../../../app.constants";

const propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.object),
  initId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tabIndexOpened: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const LibraryDrawer = ({
  initId = "",
  isOpen = false,
  onClose = () => {},
  categoryList = [],
  tabIndexOpened = libraryDrawerTabIndexes.importExport,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [additionalReqCount, setAdditionalReqCount] = useState(0);
  const [data, setData] = useState([]);
  const [defaultReqCount, setDefaultReqCount] = useState(0);
  const [gLibrarySelection, setGLibrarySelection] = useState(gLibraryReqLevel.NONE);
  const [isClosing, setIsClosing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(libraryDrawerTabIndexes.importExport);
  const [subTitle, setSubTitle] = useState("");
  const [title, setTitle] = useState("");
  const captureEvents = useCaptureEventsV2();

  const navTextItems = [t("Gartner library"), t("Imported files")];

  useEffect(() => {
    const fetchLibraryList = async () => {
      if (!isOpen || !initId || selectedIndex !== libraryDrawerTabIndexes.gartnerLibrary) {
        return;
      }

      setIsLoading(true);

      const url = `api/v2/initiatives/${initId}/requirements/library`;
      const response = await getRequest(url);

      if (response.status === 200) {
        const list = response.data?.data || [];
        setData(list);
        setAdditionalReqCount(computeAdditionalRequirementsCount(list));
        setDefaultReqCount(computeRequirementsCount(list));
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchLibraryList();
  }, [initId, isOpen, selectedIndex]);

  useEffect(() => {
    if (selectedIndex === libraryDrawerTabIndexes.gartnerLibrary) {
      setTitle(t("Requirements from Gartner"));
      setSubTitle(t("Choose from curated requirement sets built using Gartner insights."));
    } else {
      setTitle(t("Import library"));
      setSubTitle(t("Upload and manage your own requirement sets."));
    }
  }, [selectedIndex, t]);

  useEffect(() => {
    setSelectedIndex(tabIndexOpened);
  }, [tabIndexOpened]);

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setGLibrarySelection(gLibraryReqLevel.NONE);
        setIsClosing(false);
      }, 1000);
    }
  }, [isClosing]);

  const handleNavBarClick = (index) => {
    setSelectedIndex(index);
    if (index === libraryDrawerTabIndexes.gartnerLibrary) {
      captureEvents([
        {
          eventType: ClientEvent.INITIATIVE_REQUIREMENTS_LIBRARY_VIEWED,
          metaData: { initiativeId: initId },
        },
      ]);
    }
  };

  const handleDrawerSelection = (glibVal) => {
    if ([gLibraryReqLevel.DEFAULT, gLibraryReqLevel.ADDITIONAL].includes(glibVal)) {
      setGLibrarySelection(glibVal);
      captureEvents([
        {
          eventType: {
            [gLibraryReqLevel.DEFAULT]: ClientEvent.INITIATIVE_REQUIREMENTS_DEFAULT_LIBRARY_VIEWED,
            [gLibraryReqLevel.ADDITIONAL]: ClientEvent.INITIATIVE_REQUIREMENTS_ADDITIONAL_LIBRARY_VIEWED,
          }[glibVal],
          metaData: { initiativeId: initId },
        },
      ]);
    } else {
      setGLibrarySelection(gLibraryReqLevel.NONE);
    }
  };

  const handleCloseSnackbar = () => {
    setIsError(false);
  };

  return (
    <SlideInOverlay
      isOpen={isOpen}
      isOverlayNavBar={true}
      onClose={() => {
        onClose();
        setIsClosing(true);
      }}
      rootClassName={gLibrarySelection === gLibraryReqLevel.NONE ? classes.slideIn : classes.slideInSecondary}
      textBackBtn={t("requirements list")}
    >
      {gLibrarySelection === gLibraryReqLevel.NONE && (
        <>
          <ImportLibraryNavBar items={navTextItems} onClick={handleNavBarClick} selectedIndex={selectedIndex} />
          <RequirementDrawerTitle title={title} subTitle={subTitle} />

          <div className={classes.contentWrapper}>
            {selectedIndex === libraryDrawerTabIndexes.gartnerLibrary && data.length > 0 && (
              <GartnerLibraryMenu
                additionalReqCount={additionalReqCount}
                defaultReqCount={defaultReqCount}
                handleDrawerSelection={handleDrawerSelection}
                isLoading={isLoading}
              />
            )}
            {selectedIndex === libraryDrawerTabIndexes.importExport && (
              <ImportLibraryBody initId={initId} isOpen={isOpen} onClose={onClose} />
            )}
          </div>
        </>
      )}
      {gLibrarySelection === gLibraryReqLevel.DEFAULT && (
        <ReqLibraryContainer
          isClosing={isClosing}
          initId={initId}
          requirements={data}
          reqNum={defaultReqCount}
          categoryList={categoryList}
          handleBreadcrumbClick={() => handleDrawerSelection(gLibraryReqLevel.NONE)}
          additionalReqs={false}
        />
      )}
      {gLibrarySelection === gLibraryReqLevel.ADDITIONAL && (
        <ReqLibraryContainer
          isClosing={isClosing}
          initId={initId}
          requirements={data}
          reqNum={additionalReqCount}
          categoryList={categoryList}
          handleBreadcrumbClick={() => handleDrawerSelection(gLibraryReqLevel.NONE)}
          additionalReqs={true}
        />
      )}
      <SnackbarBanner
        isOpen={isError}
        message={t("Something went wrong. Please try again later.")}
        setIsOpen={handleCloseSnackbar}
      />
    </SlideInOverlay>
  );
};

LibraryDrawer.propTypes = propTypes;
export default LibraryDrawer;
