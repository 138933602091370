import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import FileDragAndDropUpload from "./fileDragAndDropUpload";
import ProcessUploadErrorDisplay from "./processUploadErrorDisplay";
import ProcessUploadIndicator from "./processUploadIndicator";
import TextLinkUpload from "./textLinkUpload";
import { processSteps } from "../lib";
import { libraryBodyStyles as styles } from "./styles";

const propTypes = {
  currentFileName: PropTypes.string,
  isErrorUpload: PropTypes.bool,
  onClickClearError: PropTypes.func,
  onClickDownload: PropTypes.func,
  onFileUpload: PropTypes.func,
  processStep: PropTypes.oneOf(Object.values(processSteps)),
};
const useStyles = makeStyles(() => styles);
const ImportLibraryBodyContainer = ({
  currentFileName = "",
  isErrorUpload = false,
  onClickClearError = (_event) => {},
  onClickDownload = (_event) => {},
  onFileUpload = (_fileObj) => {},
  processStep = processSteps.none,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleChangeFile = (files) => {
    const fileObj = files?.[0];
    if (fileObj) {
      onFileUpload(fileObj);
    }
  };
  return (
    <section className={classes.bodyContainer}>
      {!!processStep && <ProcessUploadIndicator fileName={currentFileName} processStep={processStep} />}
      {!processStep && isErrorUpload && <ProcessUploadErrorDisplay onClickBtn={onClickClearError} />}
      {!processStep && !isErrorUpload && (
        <Fragment>
          <div className={classes.bodyHalve}>
            <div className={classNames(classes.bodyTitle)}>
              <TypographyComponent rootClassName={classNames(classes.stepIcon)} boldness={"medium"} styling={"p3"}>
                {`1`}
              </TypographyComponent>
              <TypographyComponent rootClassName={classNames(classes.sectionTitle)} boldness={"medium"} styling={"p2"}>
                {t("Get started")}
              </TypographyComponent>
            </div>
            <div className={classNames(classes.bodyDesc)}>
              <TextLink
                ariaLabel="download template example"
                disabled={false}
                onClick={onClickDownload}
                text={t("Download")}
              />
              <TypographyComponent rootClassName={classNames(classes.bodyDescLeft)} styling={"p3"}>
                {t("our templated Excel file to use as a starting point.")}
              </TypographyComponent>
            </div>
          </div>
          <div className={classes.bodyHalve}>
            <div className={classNames(classes.bodyTitle)}>
              <TypographyComponent
                rootClassName={classNames(classes.stepIcon)}
                styling={"p3"}
                boldness={"medium"}
              >{`2`}</TypographyComponent>
              <TypographyComponent rootClassName={classNames(classes.sectionTitle)} styling={"p2"} boldness={"medium"}>
                {t("Upload and add to requirements")}
              </TypographyComponent>
            </div>
            <div className={classNames(classes.bodyDesc)}>
              <FileDragAndDropUpload onUpload={handleChangeFile}>
                <div className={classNames(classes.bodyDescTwo)}>
                  <div className={classNames(classes.bodyDescRight)}>
                    <TypographyComponent styling={"p3"}>{t("Drop a file here or ")}</TypographyComponent>
                    <TextLinkUpload onChangeFile={handleChangeFile} />
                  </div>
                  <TypographyComponent rootClassName={classNames(classes.bodySubDescText)} styling={"p3"}>
                    {t("(file types .xlsx, .xls)")}
                  </TypographyComponent>
                </div>
              </FileDragAndDropUpload>
            </div>
          </div>
        </Fragment>
      )}
    </section>
  );
};

ImportLibraryBodyContainer.propTypes = propTypes;
export default ImportLibraryBodyContainer;
