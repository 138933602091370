import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const SmallDateCalendarIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.333 1.99984H12.6663V1.33317C12.6663 0.966504 12.3663 0.666504 11.9997 0.666504C11.633 0.666504 11.333 0.966504 11.333 1.33317V1.99984H4.66634V1.33317C4.66634 0.966504 4.36634 0.666504 3.99967 0.666504C3.63301 0.666504 3.33301 0.966504 3.33301 1.33317V1.99984H2.66634C1.93301 1.99984 1.33301 2.59984 1.33301 3.33317V13.9998C1.33301 14.7332 1.93301 15.3332 2.66634 15.3332H13.333C14.0663 15.3332 14.6663 14.7332 14.6663 13.9998V3.33317C14.6663 2.59984 14.0663 1.99984 13.333 1.99984ZM12.6663 13.9998H3.33301C2.96634 13.9998 2.66634 13.6998 2.66634 13.3332V5.33317H13.333V13.3332C13.333 13.6998 13.033 13.9998 12.6663 13.9998Z"
        fill={colorPalette.neutrals.iron.hex}
      />
    </svg>
  );
};

export default SmallDateCalendarIcon;
