import { colorPalette } from "gx-npm-common-styles";

const CompletedTaskIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.1925 15.1925L5.6925 11.6925C5.3025 11.3025 4.6825 11.3025 4.2925 11.6925C3.9025 12.0825 3.9025 12.7025 4.2925 13.0925L8.4825 17.2825C8.8725 17.6725 9.5025 17.6725 9.8925 17.2825L20.4925 6.6925C20.8825 6.3025 20.8825 5.6825 20.4925 5.2925C20.1025 4.9025 19.4825 4.9025 19.0925 5.2925L9.1925 15.1925Z"
        fill={colorPalette.status.darkPear.hex}
      />
    </svg>
  );
};

export default CompletedTaskIcon;
