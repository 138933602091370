import { colorPalette } from "gx-npm-common-styles";

const warningBannerStyles = {
  bnrButton: {
    margin: "auto",
    marginRight: 0,
  },
  bnrContainer: {
    backgroundColor: colorPalette.status.lightLemon.hex,
    display: "flex",
    height: "48px",
    marginBottom: "12px",
    marginTop: "12px",
    width: "100%",
    "&.shadowed": {
      boxShadow: "0px 3px 3px 1px rgba(20, 19, 18, 0.1)",
    },
    "& $bnrFlexChild $bnrMessage": {
      color: colorPalette.status.darkLemon.hex,
    },
  },
  bnrFlexChild: {
    display: "inline-flex",
    marginBottom: "auto",
    marginTop: "auto",
  },
  bnrIcon: {
    marginLeft: "18px",
    marginRight: "10px",
    "&.circle-icon": {
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
  },
  bnrMessage: {},
};

export { warningBannerStyles };
