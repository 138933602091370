import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tableCarouselScrollLeft: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
const TableCarouselHeader = ({ children, tableCarouselScrollLeft }) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    let theChild = child;

    if (React.isValidElement(child)) {
      if (index === 0) {
        theChild = React.cloneElement(child, { tableCarouselScrollLeft });
      } else {
        theChild = React.cloneElement(child);
      }
    }

    return theChild;
  });
  return <thead>{childrenWithProps}</thead>;
};

TableCarouselHeader.propTypes = propTypes;
export default TableCarouselHeader;
