const checklistTypes = {
  CLEAR_CHECKLIST_DATA: "CLEAR_CHECKLIST_DATA",
  LOAD_CHECKLIST_DATA: "LOAD_CHECKLIST_DATA",
  CHECKLIST_ADD_CHILD: "CHECKLIST_ADD_CHILD",
  CHECKLIST_CLEAR_FAILURES: "CHECKLIST_CLEAR_FAILURES",
  CHECKLIST_DELETE_CHILD: "CHECKLIST_DELETE_CHILD",
  CHECKLIST_EDIT_CHILD: "CHECKLIST_EDIT_CHILD",
  CHECKLIST_EDIT_PARENT: "CHECKLIST_EDIT_PARENT",
  CHECKLIST_REORDER_CHILD: "CHECKLIST_REORDER_CHILD",
  CHECKLIST_SAVE_DATA_START: "CHECKLIST_SAVE_DATA_START",
  CHECKLIST_SAVE_DATA_SUCCESS: "CHECKLIST_SAVE_DATA_SUCCESS",
  CHECKLIST_SAVE_DATA_ERROR: "CHECKLIST_SAVE_DATA_ERROR",
  CHECKLIST_SAVE_DATA_COMPLETE: "CHECKLIST_SAVE_DATA_COMPLETE",
  CHECKLIST_REMOVE_ASSIGNMENTS: "CHECKLIST_REMOVE_ASSIGNMENTS",
};

const requirementTypes = {
  CLEAR_REQUIREMENT_DATA: "CLEAR_REQUIREMENT_DATA",
  LOAD_REQUIREMENTS_DATA: "LOAD_REQUIREMENTS_DATA",
  REQUIREMENT_ADD_CHILD: "REQUIREMENT_ADD_CHILD",
  REQUIREMENT_ADD_PARENT: "REQUIREMENT_ADD_PARENT",
  REQUIREMENT_CLEAR_FAILURES: "REQUIREMENT_CLEAR_FAILURES",
  REQUIREMENT_DELETE_CHILD: "REQUIREMENT_DELETE_CHILD",
  REQUIREMENT_DELETE_PARENT: "REQUIREMENT_DELETE_PARENT",
  REQUIREMENT_EDIT_CHILD: "REQUIREMENT_EDIT_CHILD",
  REQUIREMENT_EDIT_PARENT: "REQUIREMENT_EDIT_PARENT",
  REQUIREMENT_IMPORT_CATS_LIST_CLEAR: "REQUIREMENT_IMPORT_CATS_LIST_CLEAR",
  REQUIREMENT_IMPORT_CATS_LIST_LOAD: "REQUIREMENT_IMPORT_CATS_LIST_LOAD",
  REQUIREMENT_MOVED_CAT_ID_CLEAR: "REQUIREMENT_MOVED_CAT_ID_CLEAR",
  REQUIREMENT_MOVED_CAT_ID_LOAD: "REQUIREMENT_MOVED_CAT_ID_LOAD",
  REQUIREMENT_MOVED_ITEMS_LIST_CLEAR: "REQUIREMENT_MOVED_ITEMS_LIST_CLEAR",
  REQUIREMENT_MOVED_ITEMS_LIST_LOAD: "REQUIREMENT_MOVED_ITEMS_LIST_LOAD",
  REQUIREMENT_REORDER_CHILD: "REQUIREMENT_REORDER_CHILD",
  REQUIREMENT_SAVE_DATA_START: "REQUIREMENT_SAVE_DATA_START",
  REQUIREMENT_SAVE_DATA_SUCCESS: "REQUIREMENT_SAVE_DATA_SUCCESS",
  REQUIREMENT_SAVE_DATA_ERROR: "REQUIREMENT_SAVE_DATA_ERROR",
  REQUIREMENT_SAVE_DATA_COMPLETE: "REQUIREMENT_SAVE_DATA_COMPLETE",
  REQUIREMENT_SCORING_LEVEL_ERROR: "REQUIREMENT_SCORING_LEVEL_ERROR",
  REQUIREMENT_SCORING_LEVEL_UPDATE: "REQUIREMENT_SCORING_LEVEL_UPDATE",
  REVERT_ALL_PUBLISH_CHANGES: "REVERT_ALL_PUBLISH_CHANGES",
};

export { checklistTypes, requirementTypes };
