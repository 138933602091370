const dummyRows = [
  [
    "I'm a fixed column",
    "I'm a column",
    "I'm a column",
    "I'm a column",
    "I'm a column",
    "I'm a column",
    "I'm a column",
    "I'm a column",
    "I'm a column",
  ],
];

export const table = {
  bodyRowSets: [
    {
      title: "Functional Requirements",
      rows: [...dummyRows, ...dummyRows, ...dummyRows, ...dummyRows],
    },
    {
      title: "Technical Requirements",
      rows: [...dummyRows, ...dummyRows],
    },
  ],
  footerRowSet: {
    title: "Requested Documents",
    rows: [...dummyRows, ...dummyRows],
  },
};
