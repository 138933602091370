import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DeselectIcon, MoveToIcon, TrashcanIcon, AdditionIcon } from "gx-npm-icons";
import { useCaptureEventsV2, isValidResponse, RequirementsState } from "gx-npm-lib";
import { postRequest, putRequest } from "../../../../context/actions/apiRequests";
import BulkOperationsView from "./bulk-operations-view.component";
import { ClientEvent } from "../../../../app.constants";

const propTypes = {
  bulkOperationIds: PropTypes.arrayOf(PropTypes.string),
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  initiativeId: PropTypes.string,
  isRequirementLibrary: PropTypes.bool,
  onClearSelected: PropTypes.func,
  onSuccessUpdate: PropTypes.func,
  reqState: PropTypes.oneOf(["", ...Object.values(RequirementsState)]),
};
const BulkOperations = ({
  bulkOperationIds = [],
  categoryList = [],
  initiativeId = "",
  isRequirementLibrary = false,
  onClearSelected = () => {},
  onSuccessUpdate = (_catId, _bulkOpIds) => {},
  reqState = RequirementsState.DRAFT,
}) => {
  const [moveToCatId, setMoveToCatId] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [type, setType] = useState("");
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const buttonItems = [
    {
      btnLabel: t("Deselect all"),
      btnVariant: "darkTheme",
      hasPopover: false,
      icon: <DeselectIcon />,
      name: "deselect",
    },
    {
      btnLabel: t("Move to"),
      btnVariant: "darkTheme",
      hasPopover: true,
      icon: <MoveToIcon />,
      name: "move",
    },
    {
      btnLabel: t("Delete"),
      btnVariant: "darkThemeDestructive",
      hasPopover: false,
      icon: <TrashcanIcon />,
      name: "delete",
    },
  ];
  const buttonItemsRequirementLibrary = [
    {
      btnLabel: t("Deselect all"),
      btnVariant: "darkTheme",
      hasPopover: false,
      icon: <DeselectIcon />,
      name: "deselect",
    },
    {
      btnLabel: t("Add to"),
      btnVariant: "darkTheme",
      hasPopover: true,
      icon: <AdditionIcon />,
      name: "add",
    },
  ];

  const handleBulkOperationClick = (index) => {
    switch (index) {
      case 0:
        onClearSelected();
        break;
      case 1:
        setType("move");
        setIsDialogOpen(true);
        break;
      case 2:
        setType("delete");
        setIsDialogOpen(true);
        break;
    }
  };
  const handleReqLibBulkOperationClick = (index) => {
    if (index === 0) {
      onClearSelected();
    }
  };

  const handleBulkOperationOptionsClick = async (catId) => {
    if (isRequirementLibrary) {
      const { reqCatId, reqItemIds } = await handleBulkMove(catId);
      captureEvents([
        {
          eventType: ClientEvent.INITIATIVE_REQUIREMENTS_LIBRARY_ITEM_ADDED,
          metaData: { initiativeId, reqCatId, reqItemIds },
        },
      ]);
    } else {
      handleBulkOperationClick(1);
      setMoveToCatId(catId);
    }
  };

  const handleBulkOperationCancel = () => {
    setIsDialogOpen(false);
  };

  const handleBulkOperationConfirm = async (confirmedType) => {
    if (confirmedType === "delete") {
      await handleBulkDelete();
    } else if (confirmedType === "move") {
      await handleBulkMove();
    }
  };

  const handleBulkDelete = async () => {
    setIsProcessing(true);
    const urlDelete = `api/v2/initiatives/${initiativeId}/requirements/action/delete/items`;
    const payload = { reqItemIds: bulkOperationIds };
    const response = await putRequest(urlDelete, payload);
    processResponse(response);
    setIsProcessing(false);
  };

  const handleBulkMove = async (catId = "") => {
    setIsProcessing(true);
    let reqCatId = moveToCatId || catId;
    if (reqCatId === "NEW_CATEGORY") {
      const urlNewCat = `api/v2/initiatives/${initiativeId}/requirements/category`;
      const responseNewCat = await postRequest(urlNewCat, {});
      if (responseNewCat.status === 201 && responseNewCat.data?.data?.id) {
        reqCatId = responseNewCat.data.data.id;
        setMoveToCatId(reqCatId);
      } else {
        setIsProcessing(false);
        setHasError(true);
        setTimeout(() => {
          setHasError(false);
        }, 3000);
        return;
      }
    }
    const urlMoveReqLibrary = `api/v2/initiatives/${initiativeId}/requirements/library/add-to-requirements`;
    const urlMoveRequirementsTab = `api/v2/initiatives/${initiativeId}/requirements/action/move/items`;
    const payloadMove = { reqCatId, reqItemIds: bulkOperationIds };

    const responseMove = isRequirementLibrary
      ? await postRequest(urlMoveReqLibrary, payloadMove)
      : await putRequest(urlMoveRequirementsTab, payloadMove);
    processResponse(responseMove, reqCatId);
    setIsProcessing(false);
    return payloadMove;
  };

  const processResponse = (response, catId = "") => {
    if (isValidResponse(response)) {
      setSuccessCount(bulkOperationIds.length);
      onSuccessUpdate(catId, bulkOperationIds);
      onClearSelected();
      setIsDialogOpen(false);
      setTimeout(() => {
        setSuccessCount(0);
      }, 3000);
    } else {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
      }, 3000);
    }
  };
  return (
    <BulkOperationsView
      buttonItems={isRequirementLibrary ? buttonItemsRequirementLibrary : buttonItems}
      categoryList={categoryList}
      countSuccess={successCount}
      dialogType={type}
      initId={initiativeId}
      isDialogOpen={isDialogOpen}
      isErroredRequest={hasError}
      isProcessingRequest={isProcessing}
      isRequirementLibrary={isRequirementLibrary}
      onClearError={() => setHasError(false)}
      onClickButtonBar={isRequirementLibrary ? handleReqLibBulkOperationClick : handleBulkOperationClick}
      onClickButtonBarOption={handleBulkOperationOptionsClick}
      onClickButtonDialogCancel={handleBulkOperationCancel}
      onClickButtonDialogConfirm={handleBulkOperationConfirm}
      reqState={reqState}
      selectedIds={bulkOperationIds}
    />
  );
};

BulkOperations.propTypes = propTypes;
export default BulkOperations;
