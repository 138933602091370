// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XF3v9f0OJdWMx3GLHCJG{align-items:center;display:flex}.XF3v9f0OJdWMx3GLHCJG .gx-user{margin-right:-7px;padding-top:0}.XF3v9f0OJdWMx3GLHCJG .gx-user div.MuiAvatar-root.MuiAvatar-circle{font-size:12px;height:30px;line-height:18px;width:30px}.Z9RhCbgg8KZop03camlI{align-items:center;background-color:var(--color-neutrals-silver);border:1px solid var(--color-white);border-radius:50%;color:var(--color-neutrals-coal);display:flex;height:32px;justify-content:center;width:32px;z-index:1}`, "",{"version":3,"sources":["webpack://./src/sections/header-v2/components/active-viewer-avatars/active-viewer-avatars.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CAEA,+BACE,iBAAA,CACA,aAAA,CAGF,mEACE,cAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CAIJ,sBACE,kBAAA,CACA,6CAAA,CACA,mCAAA,CACA,iBAAA,CACA,gCAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,UAAA,CACA,SAAA","sourcesContent":[".activeViewerWrapper {\n  align-items: center;\n  display: flex;\n\n  & :global(.gx-user) {\n    margin-right: -7px;\n    padding-top: 0;\n  }\n\n  & :global(.gx-user div.MuiAvatar-root.MuiAvatar-circle) {\n    font-size: 12px;\n    height: 30px;\n    line-height: 18px;\n    width: 30px;\n  }\n}\n\n.additionalViewersAvatar {\n  align-items: center;\n  background-color: var(--color-neutrals-silver);\n  border: 1px solid var(--color-white);\n  border-radius: 50%;\n  color: var(--color-neutrals-coal);\n  display: flex;\n  height: 32px;\n  justify-content: center;\n  width: 32px;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeViewerWrapper": `XF3v9f0OJdWMx3GLHCJG`,
	"additionalViewersAvatar": `Z9RhCbgg8KZop03camlI`
};
export default ___CSS_LOADER_EXPORT___;
