const deleteReqDetailsDialogFooterStyles = {
  footerRoot: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "60px",
    "& button:last-child": {
      marginLeft: "24px",
    },
  },
};

export default deleteReqDetailsDialogFooterStyles;
