import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isValidDate, ReviewType } from "gx-npm-lib";
import { ArrowCriticalIcon, ArrowFavorableIcon } from "gx-npm-icons";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { DateTimeFormatOptions, ProductReview } from "../../../../../../../../selection.types";
import Stars from "../stars/stars.component";
import styles from "./review-item.module.scss";

type ReviewProps = { review: ProductReview; type: ReviewType };
const formatDate = (date: string) => {
  let display = "";
  if (isValidDate(date)) {
    const day: DateTimeFormatOptions = "2-digit";
    const month: DateTimeFormatOptions = "short";
    const timeZone = "UTC";
    const year: DateTimeFormatOptions = "numeric";
    const options = { day, month, timeZone, year };
    display = new Date(date).toLocaleString("en-us", options);
  }
  return display;
};

const ReviewItem = ({ review, type }: ReviewProps) => {
  const { t } = useTranslation();
  const { headline, summary, URL, rating, date } = review;
  const isReviewAvailable = !!review.headline;

  return (
    <div className={styles.reviewContainer}>
      <div className={classNames(styles.reviewHeader, isReviewAvailable && styles.reviewHeaderMarginBottom)}>
        {isReviewAvailable && (
          <>
            <Stars rating={rating} isSmall={true} />
            <span className={styles.reviewRatingText}>
              <TypographyComponent color={"gartnerBlue"} styling={"p2"} boldness={"semi"}>
                {!isNaN(rating) && rating.toFixed(1)}
              </TypographyComponent>
            </span>
            <span className={styles.reviewRatingDateWrapper}>
              <TypographyComponent styling={"p4"} rootClassName={styles.reviewRatingDate}>
                {formatDate(date)}
              </TypographyComponent>
            </span>
          </>
        )}
        {!isReviewAvailable && (
          <TypographyComponent styling={"p3"}>{t("No reviews in this category")}</TypographyComponent>
        )}
        <span className={`${styles.reviewType} ${type.toLowerCase()}`}>
          {type === ReviewType.FAVORABLE && <ArrowFavorableIcon />}
          {type === ReviewType.CRITICAL && <ArrowCriticalIcon />}

          <TypographyComponent styling={"p4"}>
            {type === ReviewType.FAVORABLE && t("Favorable review")}
            {type === ReviewType.CRITICAL && t("Critical review")}
          </TypographyComponent>
        </span>
      </div>
      {isReviewAvailable && (
        <div>
          <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={classNames(styles.headline)}>
            {headline}
          </TypographyComponent>
          <TypographyComponent styling={"p3"} rootClassName={styles.summary}>
            {summary}
          </TypographyComponent>
          <TextLink href={URL} target="_blank" text={t("Read full review")} variant="secondary" />
        </div>
      )}
    </div>
  );
};

export default ReviewItem;
