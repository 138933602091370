import * as queryString from "query-string";
import * as singleSpa from "single-spa";

/**
 * @description :: will accept two URL formats
 * 1. /s/initiatives?tab=:tab&id=:initId
 * 2. /s/initiatives?tab=:tab&id=:initId#/profile
 */
const HandleRedirects = () => {
  const defaultTab = "overview";
  const defaultRoute = "/s/evaluations";
  const { id, tab } = queryString.parse(window.location.search);
  const { hash, search } = window.location;
  let updatedTab = defaultTab;
  switch (tab) {
    case "evaluations":
      updatedTab = "scorecard";
      break;
    default:
      updatedTab = tab ? tab.toString() : defaultTab;
      break;
  }
  singleSpa.navigateToUrl(id ? `/s/evaluation/${id}/${updatedTab}${search}${hash}` : defaultRoute);
  return null;
};

export default HandleRedirects;
