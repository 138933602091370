import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import ReadOnlyEyeIcon from "./read-only-eye.icon";
import styles from "./read-only-badge.styles.module.scss";

const ReadOnlyBadgeComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.badgeWrapper}>
      <ReadOnlyEyeIcon />
      <TypographyComponent boldness={"medium"} color={"grape"} rootClassName={styles.badgeText} styling="p3">
        {t("View only")}
      </TypographyComponent>
    </div>
  );
};

export default ReadOnlyBadgeComponent;
