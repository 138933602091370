import { makeStyles } from "@material-ui/core/styles";
import { UUID } from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { evaluationHeaderV2Styles as styles } from "./evaluation-header-v2.styles";

type EvaluationHeaderV2Props = { hasVendors: boolean };
const useStyles = makeStyles(() => styles);
const EvaluationHeaderV2 = ({ hasVendors = false }: EvaluationHeaderV2Props) => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();

  const classes = useStyles();
  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/scorecard`);
  };

  return (
    <div className={classes.root}>
      <TypographyComponent styling={"h4"} boldness={"medium"}>
        {t("Top vendors in evaluation")}
      </TypographyComponent>
      {hasVendors && (
        <Button onClick={handleClick} rootClassName="btn-primary">
          {t("VIEW SCORECARD")}
        </Button>
      )}
    </div>
  );
};

export default EvaluationHeaderV2;
