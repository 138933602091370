// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ie1z1ggKm17Z2IgrbL42{align-items:center;display:flex;justify-content:space-between;padding:11px 12px 11px 24px}.UPLlMoEG30S0T2epd15f{display:inline-flex;justify-content:flex-end;margin:0 0 0 auto;width:228px}.LoyFb6_zzSW0R0xktYvz{width:281px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/weighted-priority-drawer/priorities-form/priority-row/priority-row.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,2BAAA,CAGF,sBACE,mBAAA,CACA,wBAAA,CACA,iBAAA,CACA,WAAA,CAGF,sBACE,WAAA","sourcesContent":[".row {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: 11px 12px 11px 24px;\n}\n\n.buttonsWeightSelectionWrapper {\n  display: inline-flex;\n  justify-content: flex-end;\n  margin: 0 0 0 auto;\n  width: 228px;\n}\n\n.priorityName {\n  width: 281px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `ie1z1ggKm17Z2IgrbL42`,
	"buttonsWeightSelectionWrapper": `UPLlMoEG30S0T2epd15f`,
	"priorityName": `LoyFb6_zzSW0R0xktYvz`
};
export default ___CSS_LOADER_EXPORT___;
