import { Fade } from "@material-ui/core";
import classNames from "classnames";
import classes from "./unassigned-evaluator.styles.module.scss";
import { UsernameDisplay } from "../../usernameDisplay";
import React from "react";
import { Evaluator } from "../assignment-popper.types";

type UnassignedEvaluatorType = {
  evaluator: Evaluator;
  pendingBtnText?: string;
};
const UnassignedEvaluator = (props: UnassignedEvaluatorType) => {
  return props.evaluator.hasViewed ? (
    <Fade key={`viewonly-user-${props.evaluator.email}`} in={true}>
      <div className={classNames(classes.gxEvaluatorBtn, classes.noPadding)}>
        <UsernameDisplay rootClassName={classes.gxEvaluator} name={props.evaluator.fullName} isSmallerIcon={true} />
      </div>
    </Fade>
  ) : (
    <div
      key={`pending-viewonly-user-${props.evaluator.email}`}
      className={classNames(classes.gxEvaluatorBtn, classes.noPadding)}
    >
      <UsernameDisplay
        isUnknown={!props.evaluator.hasViewed}
        rootClassName={classes.gxEvaluator}
        name={props.evaluator.email}
        isSmallerIcon={true}
      />
      <span className={classes.gxEvaluatorPending}>
        <span className={classes.gxPillBg}>
          <span className={classes.gxPillPendingBg}>{props.pendingBtnText}</span>
        </span>
      </span>
    </div>
  );
};

export default UnassignedEvaluator;
