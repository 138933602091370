import { colorPalette } from "gx-npm-common-styles";
const surveyTeamMListItemStyles = {
  surveyTeamItem: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color 0.2s ease-in-out",
    "& .gx-user": {
      padding: "12px",
    },
    "&.isHighlighted": {
      backgroundColor: colorPalette.neutrals.quartz.hex,
    },
    "& .MuiSnackbar-anchorOriginBottomLeft": {
      left: "unset",
      right: "0",
      bottom: "unset",
      position: "absolute",
      whiteSpace: "nowrap",
    },
    "& .gx-menu-list-aligned-right-bottom": { width: "auto" },
  },
  surveyTeamSnackbarWrapper: {
    position: "relative",
    "& .mini": {
      right: 0,
    },
  },
  actionButtons: {
    display: "flex",
    "& button:first-child": {
      marginRight: "12px",
    },
  },
  popoverMenu: {
    "& p.no-current-selection": {
      display: "none",
    },
  },
};

export default surveyTeamMListItemStyles;
