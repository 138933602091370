// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x4bj4y9rc5lOTdgBUU8c{margin-top:6px}`, "",{"version":3,"sources":["webpack://./src/sections/header-v2/components/file-hub-button/file-hub-button.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".documentIcon {\n  margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentIcon": `x4bj4y9rc5lOTdgBUU8c`
};
export default ___CSS_LOADER_EXPORT___;
