import { colorPalette } from "gx-npm-common-styles";

const updateReqsWithNotificationDialogStyles = {
  bodyRoot: {
    "& .gx-recommend-notify": {
      marginTop: "12px",
    },
    "& .gx-notify-switch": {
      marginBottom: "8px",
      marginTop: "24px",
    },
    "& .gx-notification-label": {
      color: `var(${colorPalette.neutrals.iron.name})`,
      marginBottom: "12px",
    },
    "& .gx-base-textarea textarea": {
      width: "100%",
      "&::placeholder": {
        color: `var(${colorPalette.neutrals.iron.name})`,
      },
    },
  },
  firstSubHeader: {
    "& .gx-first-dialog-sub-header": {
      display: "inline",
    },
  },
  btnFooterContainer: {
    display: "flex",
    "& .gx-requirements-update-notification-dialog-cancel": {
      marginLeft: "auto",
      marginRight: "12px",
    },
  },
};

export default updateReqsWithNotificationDialogStyles;
