import { colorPalette } from "gx-npm-common-styles";

const surveyTeamManagementStyles = {
  branding: {
    display: "flex",
    alignItems: "center",
    "& svg, & img": {
      maxHeight: "32px",
      marginRight: "14px",
      width: "auto",
    },
  },
  instruction: {
    paddingTop: "24px",
    paddingRight: "130px",
    paddingBottom: "40px",
    "&.gx-p": {
      color: colorPalette.neutrals.coal.hex,
    },
  },
  recipientManageOverlay: {
    "&& .gx-slidein-body": {
      paddingLeft: "4px",
      paddingRight: "4px",
      marginRight: "20%",
      marginLeft: "10%",
      width: "720px",
    },
  },
  recipientManageFooter: {
    background: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
    bottom: "0",
    height: "30px",
    position: "fixed",
    width: "100%",
    zIndex: "2000",
  },
};

export default surveyTeamManagementStyles;
