import { colorPalette } from "gx-npm-common-styles";

const listSelectionStyles = {
  reqSection: {
    marginBottom: "24px",
    backgroundColor: colorPalette.basic.white.hex,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    "&.gx-is-collapsed": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
  reqList: {
    "& .gx-category-reqs": {
      borderRadius: "8px",
    },
  },
  reqCount: {
    color: colorPalette.neutrals.coal.hex,
    fontSize: "14px",
  },
  categoryHeader: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "88px",
    alignItems: "center",
    paddingRight: "32px",
    paddingLeft: "32px",
    boxShadow: "inset 0px -1px 0px " + colorPalette.neutrals.silver.hex,
    "&.gx-is-collapsed": {
      boxShadow: "none",
    },
  },
  categoryToggle: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "600",
    "& .gx-exp-col-btn": {
      marginRight: "10px",
    },
  },
  selectAll: {
    paddingLeft: "55px",
    backgroundColor: colorPalette.interactions.paleBerry.hex,
  },
  reqItem: {
    boxShadow: "inset 0px -1px 0px " + colorPalette.neutrals.silver.hex,
    display: "flex",
    alignItems: "center",
    minHeight: "48px",
    paddingLeft: "45px",
    paddingRight: "120px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  reqItemHeader: {
    padding: "25px 31px 13px 31px",
    "& .req-title": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  reqItemName: {
    minWidth: "285px",
    fontSize: "14px",
    textAlign: "left",
    paddingRight: "24px",
    paddingLeft: "16px",
    lineHeight: "150%",
    maxWidth: "285px",
    "@media (max-width:1240px)": {
      minWidth: "194px",
    },
  },
  reqItemDesc: {
    fontSize: "14px",
    lineHeight: "150%",
    paddingRight: "24px",
    paddingTop: "15px",
    paddingBottom: "15px",
    flexGrow: "3",
  },
  reqItemPriority: {
    fontSize: "14px",
    paddingLeft: "24px",
    width: "50px",
  },
  noneFound: {
    padding: "14px 56px",
    "& p": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
};

const expandButtonStyles = {
  iconExpand: {
    float: "left",
    "& svg": {
      transform: "rotate(180deg)",
    },
    "&.gx-is-expanded svg": {
      transform: "none",
    },
    "& button": {
      bottom: 1,
    },
  },
};

export { listSelectionStyles, expandButtonStyles };
