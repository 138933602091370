// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ia7oBA0X5hO_haFm82YY{background-color:var(--color-neutrals-quartz)}.Ia7oBA0X5hO_haFm82YY.Bw_TySUR8beIzp3VE6aT{animation-duration:1.5s;animation-iteration-count:1;animation-name:SX2Q_7HK0sKB03Kw_ejN}@keyframes SX2Q_7HK0sKB03Kw_ejN{0%{opacity:1}50%{opacity:0}100%{opacity:1}}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products-tab-content-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,6CAAA,CAEA,2CACE,uBAAA,CACA,2BAAA,CACA,mCAAA,CAIJ,gCACE,GACE,SAAA,CAEF,IACE,SAAA,CAEF,KACE,SAAA,CAAA","sourcesContent":[".container {\n  background-color: var(--color-neutrals-quartz);\n\n  &.fade {\n    animation-duration: 1.5s;\n    animation-iteration-count: 1;\n    animation-name: fade-in-and-out;\n  }\n}\n\n@keyframes fade-in-and-out {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ia7oBA0X5hO_haFm82YY`,
	"fade": `Bw_TySUR8beIzp3VE6aT`,
	"fade-in-and-out": `SX2Q_7HK0sKB03Kw_ejN`
};
export default ___CSS_LOADER_EXPORT___;
