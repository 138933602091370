// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EqTbjK9bbVN1gQ0Tjfcv{margin-top:24px}.JX0xIDUZ9Y4k_WgEAk0j{margin-top:32px;max-width:600px}.JX0xIDUZ9Y4k_WgEAk0j .bfGhoi1rwYe2H0ekQwm9{color:var(--color-neutrals-carbon)}.JX0xIDUZ9Y4k_WgEAk0j .gpt_1HL0fdceAQWPELQg{margin-top:16px}.JX0xIDUZ9Y4k_WgEAk0j .gpt_1HL0fdceAQWPELQg .yUpUORdxTbMDhmWcLySL{margin:12px 0 0}.czy_JpCziW8gXCJK_GTg{margin-top:32px}.rLLZ9MTJByIqrFkWC036{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/survey/quick-start/sending-the-questionnaire/sending-the-questionnaire.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAEA,kEACE,eAAA,CAKN,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".sendQuestionnaireImageContainer {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n\n    .link {\n      margin: 12px 0 0;\n    }\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sendQuestionnaireImageContainer": `EqTbjK9bbVN1gQ0Tjfcv`,
	"description": `JX0xIDUZ9Y4k_WgEAk0j`,
	"heading": `bfGhoi1rwYe2H0ekQwm9`,
	"additionalInfo": `gpt_1HL0fdceAQWPELQg`,
	"link": `yUpUORdxTbMDhmWcLySL`,
	"helpLink": `czy_JpCziW8gXCJK_GTg`,
	"loader": `rLLZ9MTJByIqrFkWC036`
};
export default ___CSS_LOADER_EXPORT___;
