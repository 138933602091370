import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./survey-header.styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  MiniButton,
  PopoverMenu,
  QuickStartPopperComponent,
  SnackbarBanner,
  TypographyComponent,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  useFeatureFlag,
  TooltipV2,
} from "gx-npm-ui";
import { InfoLightIcon, PeopleIcon } from "gx-npm-icons";
import { getRequest } from "../../../context/actions/apiRequests";
import { processGetResponse } from "../../../context/actions/actionUtils";
import classNames from "classnames";
import { InitUserRole, useCaptureEventsV2, useUserInitAccess } from "gx-npm-lib";
import { ClientEvent } from "../../../app.constants";
import OverviewComponent from "../quick-start/overview/overview.component";
import CreatingTheQuestionnaireComponent from "../quick-start/creating-the-questionnaire/creating-the-questionnaire.component";
import SendingTheQuestionnaireComponent from "../quick-start/sending-the-questionnaire/sending-the-questionnaire.component";
import ViewingResponsesComponent from "../quick-start/viewing-responses/viewing-responses.component";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_4304__manage_recipients_updates } from "../../../lib/feature-flags";
import SurveyTeamManagementSlider from "../survey-team-management/survey-team-management-v2.component";

const useStyles = makeStyles(() => styles);
const propTypes = {
  showSlideIn: PropTypes.bool,
  onSetupClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  surveyId: PropTypes.string,
  initiativeId: PropTypes.string,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageLoc: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  reloadSurveyData: PropTypes.func,
};

const SurveyHeader = ({
  isDisabled = false,
  showSlideIn = true,
  onSetupClick = () => {},
  surveyId = "",
  initiativeId = "",
  vendors = [],
  reloadSurveyData = () => {},
}) => {
  const isFFGCOM4304 = useFeatureFlag(GCOM_4304__manage_recipients_updates);
  const [isSlideInOpen, setIsSlideInOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);
  const [isError, setIsError] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerVendorData, setDrawerVendorData] = useState({});
  const captureEventsV2 = useCaptureEventsV2();
  const clientEvents = [
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_CREATING_QUESTIONNAIRE_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_SENDING_QUESTIONNAIRE_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_VIEWING_RESPONSE_CLICKED,
  ];
  const metaData = {
    initiativeId: initiativeId,
  };
  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Creating the questionnaire", content: <CreatingTheQuestionnaireComponent /> },
    { label: "Sending the questionnaire", content: <SendingTheQuestionnaireComponent /> },
    { label: "Viewing responses", content: <ViewingResponsesComponent /> },
  ];

  const menuItems = [
    {
      index: 0,
      name: t("Edit"),
      event: "EDIT",
      disabled: hasLoadedAccess && role !== InitUserRole.OWNER,
      showTooltip: hasLoadedAccess && role !== InitUserRole.OWNER,
      tooltipOptions: {
        title: t("This action is only available to evaluation owners."),
        placement: "left",
        PopperProps: { modifiers: { offset: { offset: "0, 5px" } } },
      },
    },
    {
      index: 1,
      name: t("Preview"),
      event: "PREVIEW",
      rootClassName: classes.popoverItemClass,
    },
    {
      index: 2,
      name: t("Export questionnaire"),
      event: "EXPORT_QUESTIONNAIRE",
      disabled: hasLoadedAccess && role !== InitUserRole.OWNER,
      showTooltip: hasLoadedAccess && role !== InitUserRole.OWNER,
      tooltipOptions: {
        title: t("This action is only available to evaluation owners."),
        placement: "left",
        PopperProps: { modifiers: { offset: { offset: "0, 5px" } } },
      },
    },
    {
      index: 3,
      name: t("Export responses"),
      event: "EXPORT_RESPONSE",
    },
  ];

  const handleSlideInClose = () => {
    captureEventsV2([
      {
        eventType: ClientEvent.INITIATIVE_SURVEY_QUICKSTART_MODAL_CLOSED,
        metaData,
      },
    ]);
    setIsSlideInOpen(false);
  };

  const handleClick = () => {
    captureEventsV2([
      {
        eventType: ClientEvent.INITIATIVE_SURVEY_QUICKSTART_MODAL_OPENED,
        metaData,
      },
    ]);
    setIsSlideInOpen(true);
  };

  const getSignedUrl = async (url) => {
    try {
      const response = await getRequest(url);
      const payloadResponse = processGetResponse(response);
      const { data } = payloadResponse;
      return data.signedUrl;
    } catch (error) {
      setIsOpen(true);
    }
  };

  const handleSurveyExportClick = async () => {
    const url = `api/v2/initiatives/${initiativeId}/survey/${surveyId}/export-survey`;
    const signedUrl = await getSignedUrl(url);
    captureEventsV2([{ eventType: ClientEvent.SURVEY_EXPORTED, metaData: { initiativeId, surveyId } }]);
    window.open(signedUrl, "_self");
  };

  const handleSurveyResponseExportClick = async () => {
    const url = `api/v2/initiatives/${initiativeId}/survey/${surveyId}/export-responses`;
    const signedUrl = await getSignedUrl(url);
    captureEventsV2([{ eventType: ClientEvent.SURVEY_RESPONSES_EXPORTED, metaData: { initiativeId, surveyId } }]);
    window.open(signedUrl, "_self");
  };

  const handleMenuItemClick = async (event) => {
    const selectedItem = menuItems?.find(({ index }) => index === event);
    const action = selectedItem.event;
    if (action === "EDIT") {
      onSetupClick();
    } else if (action === "EXPORT_QUESTIONNAIRE") {
      await handleSurveyExportClick();
    } else if (action === "EXPORT_RESPONSE") {
      await handleSurveyResponseExportClick();
    } else if (action === "PREVIEW") {
      handlePreviewButtonClick();
    }
  };

  const handlePreviewButtonClick = () => {
    captureEventsV2([{ eventType: ClientEvent.SURVEY_PREVIEW_CLICKED, metaData: { initiativeId, surveyId } }]);
    window.open(`${window.location.pathname}/preview/overview`, "_blank", "noopener");
  };

  const handleCloseSnackbar = () => {
    setIsOpen(false);
  };

  const handleEventCapture = (tabIndex) => {
    captureEventsV2([
      {
        eventType: clientEvents[tabIndex],
        metaData,
      },
    ]);
  };

  const handleManageRecipientClick = () => {
    setIsDrawerOpen(true);
  };
  const handleClose = () => {
    setIsDrawerOpen(false);
    setTimeout(() => setDrawerVendorData({}), 1000);
  };

  return (
    <>
      <div className={classes.surveyHeader}>
        <div className={classes.surveyHeaderTitle}>
          <TypographyComponent color={"carbon"} boldness={"medium"} styling={"h3"}>
            {t("Questionnaire overview")}
          </TypographyComponent>
          {!showSlideIn && (
            <div className={"gx-tutorial-link"}>
              <MiniButton onClick={handleClick}>
                <InfoLightIcon data-testid="info-light-icon" fillPath={colorPalette.interactions.blueBerry.hex} />
                {t("How questionnaires work")}
              </MiniButton>
            </div>
          )}
        </div>
        <div
          className={classNames(
            classes.setupButton,
            classes.setupExportDropdown,
            isFFGCOM4304 && classes.setupButtonGCOM4304
          )}
        >
          <FeatureFlagBooleanContainer flagName={GCOM_4304__manage_recipients_updates}>
            <FeatureFlagBooleanOn>
              {vendors.length > 0 && (
                <TooltipV2
                  deactivate={!isDisabled}
                  placement="top"
                  title={t("This action is only available to evaluation owners.")}
                >
                  <div className={classes.manageSurveyButton}>
                    <MiniButton disabled={isDisabled} onClick={handleManageRecipientClick}>
                      <div className={classes.peopleIconClass}>
                        <PeopleIcon fill={colorPalette.interactions.blueBerry.hex} />
                      </div>
                      {t("Manage recipients")}
                    </MiniButton>
                  </div>
                </TooltipV2>
              )}
            </FeatureFlagBooleanOn>
          </FeatureFlagBooleanContainer>
          <div className={classes.surveyExportButton}>
            <PopoverMenu
              menuItems={menuItems}
              onClick={handleMenuItemClick}
              showSelectedOption={false}
              iconType={"arrowDynamic"}
              useIconButton={true}
              rootClassName={"gx-questionnaire-setup-button"}
              popoverMenuClass={classes.popover}
              titleText={t("Questionnaire options")}
            />
            <SnackbarBanner isOpen={isOpen} isDefaultErrorMessage={true} setIsOpen={handleCloseSnackbar} type="ERROR" />
          </div>
        </div>
      </div>
      <QuickStartPopperComponent
        title={t("Vendor questionnaire")}
        isOpen={isSlideInOpen}
        onClose={handleSlideInClose}
        tabsData={quickStartTabsData}
        onTabClick={handleEventCapture}
      />
      <FeatureFlagBooleanContainer flagName={GCOM_4304__manage_recipients_updates}>
        <FeatureFlagBooleanOn>
          <SurveyTeamManagementSlider
            isOpen={isDrawerOpen}
            onClose={handleClose}
            vendorData={drawerVendorData}
            vendors={vendors}
            initiativeId={initiativeId}
            surveyId={surveyId}
            reloadSurveyData={reloadSurveyData}
          />
          <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
        </FeatureFlagBooleanOn>
      </FeatureFlagBooleanContainer>
    </>
  );
};

SurveyHeader.propTypes = propTypes;
export default SurveyHeader;
