// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cWC6kMxv63lHIyHwUyGP{width:max-content}.cWC6kMxv63lHIyHwUyGP .btn-secondary svg{margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-add-v2/products-add-button-v2/products-add-button-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,yCACE,iBAAA","sourcesContent":[".container {\n  width: max-content;\n\n  & :global(.btn-secondary) svg {\n    margin-right: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cWC6kMxv63lHIyHwUyGP`
};
export default ___CSS_LOADER_EXPORT___;
