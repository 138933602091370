import { ReactElement } from "react";
import styles from "./tab-panel.styles.module.scss";

type TabPanelProps = { children: ReactElement | ReactElement[] };
const TabPanelComponent = ({ children }: TabPanelProps) => {
  return (
    <div className={styles.panelContainer} role="tabpanel">
      <div className={styles.panelWrapper}>{children}</div>
    </div>
  );
};

export default TabPanelComponent;
