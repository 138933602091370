import { createContext, ReactNode, useState } from "react";
import { ProductsAddContextValue, ProductsAddCustomOption } from "../../products.types";

const ProductsAddContext = createContext<ProductsAddContextValue>({} as ProductsAddContextValue);

const ProductsAddContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [productSearchValue, setProductSearchValue] = useState<string>("");
  const [productSelectedOption, setProductSelectedOption] = useState<ProductsAddCustomOption | null>(null);

  const contextValue = {
    isAddDialogOpen,
    isAdding,
    productSearchValue,
    productSelectedOption,
    setIsAddDialogOpen,
    setIsAdding,
    setProductSearchValue,
    setProductSelectedOption,
  };
  return <ProductsAddContext.Provider value={contextValue}>{props.children}</ProductsAddContext.Provider>;
};

export { ProductsAddContext, ProductsAddContextProvider };
