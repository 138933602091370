import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const SmallUsersIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.667 7.3335C11.7737 7.3335 12.6603 6.44016 12.6603 5.3335C12.6603 4.22683 11.7737 3.3335 10.667 3.3335C9.56032 3.3335 8.66699 4.22683 8.66699 5.3335C8.66699 6.44016 9.56032 7.3335 10.667 7.3335ZM5.33366 7.3335C6.44033 7.3335 7.32699 6.44016 7.32699 5.3335C7.32699 4.22683 6.44033 3.3335 5.33366 3.3335C4.22699 3.3335 3.33366 4.22683 3.33366 5.3335C3.33366 6.44016 4.22699 7.3335 5.33366 7.3335ZM5.33366 8.66683C3.78033 8.66683 0.666992 9.44683 0.666992 11.0002V12.0002C0.666992 12.3668 0.966992 12.6668 1.33366 12.6668H9.33366C9.70033 12.6668 10.0003 12.3668 10.0003 12.0002V11.0002C10.0003 9.44683 6.88699 8.66683 5.33366 8.66683ZM10.667 8.66683C10.4737 8.66683 10.2537 8.68016 10.0203 8.70016C10.0337 8.70683 10.0403 8.72016 10.047 8.72683C10.807 9.28016 11.3337 10.0202 11.3337 11.0002V12.0002C11.3337 12.2335 11.287 12.4602 11.2137 12.6668H14.667C15.0337 12.6668 15.3337 12.3668 15.3337 12.0002V11.0002C15.3337 9.44683 12.2203 8.66683 10.667 8.66683Z"
        fill={colorPalette.neutrals.iron.hex}
      />
    </svg>
  );
};

export default SmallUsersIcon;
