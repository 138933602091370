import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { TooltipV2, SplitButton } from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { actionRequirementsTransaction } from "../../../context/actions/requirementsActions";
import { operations } from "../../../context/actions/operationTypes";
import { setExpandCollapse } from "../../../ui/dragAndDropList/lib";
import { getRequirementsStorageId } from "../requirements.lib";
import { generateUuid } from "../../../lib";
import { requirementFooterStyles as styles } from "./requirements-footer.styles";

const propTypes = {
  initiativeId: PropTypes.string,
  isViewOnly: PropTypes.bool,
  setCategoryAdded: PropTypes.func,
  addFromLibraryBtnClick: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const RequirementsFooter = ({
  initiativeId = null,
  isViewOnly = false,
  setCategoryAdded = null,
  addFromLibraryBtnClick = () => {},
}) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [sessionStorageId, setSessionStorageId] = useState("");
  const [state, dispatch] = useContext(EvaluationStateContext);

  useEffect(() => {
    setIsDisabled(!state.requirements.isSuccessfullyLoaded);
  }, [state.requirements]);

  useEffect(() => {
    const id = getRequirementsStorageId(initiativeId);
    setSessionStorageId(id);
  }, [initiativeId]);

  const createScrollToTimer = () => {
    const timer = setTimeout(() => {
      addAnchor?.current?.scrollIntoView({ behavior: "smooth" });
      clearTimeout(timer);
    });
  };

  const handleAddCategoryClick = () => {
    const id = generateUuid();
    const operation = operations.parentAdd;
    const parentIndex = state.requirements.list?.length || 0;
    const data = { id, initiativeId, operation, parentIndex, save: true };
    setExpandCollapse(sessionStorageId, state.requirements.list?.length || -1, true);
    actionRequirementsTransaction(state, dispatch, data);
    handleEvent(setCategoryAdded, true);
    createScrollToTimer();
  };

  const addAnchor = useRef();
  const classes = useStyles();
  const menuItems = [
    { index: 0, name: t("Add new category") },
    { index: 1, name: t("Add from library") },
  ];
  const handleClickMenu = (index) => {
    if (index === 0) {
      handleAddCategoryClick();
    } else if (index === 1) {
      addFromLibraryBtnClick();
    }
  };
  return (
    <div className={classNames("gx-req-footer", classes.footer)}>
      <TooltipV2
        deactivate={!isViewOnly}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 12" } } }}
        title={t("This action is only available to evaluation owners and contributors.")}
      >
        <div className={classes.tooltipWrapper}>
          <SplitButton
            defaultTitle={t("ADD NEW CATEGORY")}
            disabled={isDisabled || isViewOnly}
            iconType={"arrowDynamic"}
            menuItems={menuItems}
            onClickButton={handleAddCategoryClick}
            onClickMenu={handleClickMenu}
          />
        </div>
      </TooltipV2>
      <div className={"gx-add-req-anchor"} ref={addAnchor} />
    </div>
  );
};

RequirementsFooter.propTypes = propTypes;
export { RequirementsFooter };
