export type DialogRemoveAssignedEvaluator = {
  confirmText: string;
  text: string;
  title: string;
};

export type Evaluator = {
  email: string;
  fullName: string;
  hasViewed: boolean;
};

type ReferenceObject = {
  clientHeight: number;
  clientWidth: number;
  referenceNode?: Node;
  getBoundingClientRect(): DOMRect;
};

export enum AssignmentPopperPosition {
  TOP = "top",
  LEFT = "left",
  BOTTOM = "bottom",
  RIGHT = "right",
}

export type AssigmentPopperType = {
  categoryName: string;
  dialogRemoveAssignedEvaluator?: DialogRemoveAssignedEvaluator;
  evaluatorsAssignedList: Evaluator[];
  evaluatorsAvailableList: Evaluator[];
  viewOnlyUsersList: Evaluator[];
  open: boolean;
  anchor?: ReferenceObject;
  position: AssignmentPopperPosition;
  offset?: string;
  showAddAllEvaluatorsBtn?: boolean;
  showAddTeamMemberBtn?: boolean;
  showDialogRemoveAssignedEvaluator?: boolean;
  onAddAllEvaluatorsClick?: () => void;
  onRemoveAssignedEvaluatorClick: (evaluator: Evaluator) => void;
  onAssignEvaluatorClick: (evaluator: Evaluator) => void;
  onAddTeamMemberClick?: () => void;
  onClose: () => void;
  addTeamMemberButtonText?: string;
  noTeamMembersAssignedText?: string;
  allTeamMembersAssignedText?: string;
  allMembersText?: string;
  availableTeamMembersText?: string;
  addBtnText?: string;
  removeBtnText?: string;
  rootClassName?: string;
  assignedText?: string;
  pendingBtnText?: string;
  viewOnlyTeamMembersText?: string;
  onClickAway?: (event: MouseEvent | TouchEvent) => void;
};
