import { colorPalette } from "gx-npm-common-styles";

const barHeight = 72;

const bulkOpBarStyles = {
  container: {
    backgroundColor: colorPalette.interactions.hoverCta.hex,
    bottom: 0,
    left: "76px",
    minWidth: "950px",
    padding: "0 62px",
    position: "fixed",
    width: "calc(100% - 76px)",
    zIndex: 1100,
    transition: "bottom 500ms",
    "&[aria-hidden=true].gx-requirements-library-bulkbar": {
      visibility: "hidden",
    },
    "&.gx-requirements-library-bulkbar": {
      position: "fixed",
      left: "auto",
      right: 0,
      bottom: 0,
      width: "85%",
      maxWidth: "1285px",
      maxHeight: "72px",
      minHeight: "72px",
      paddingTop: "20px",
      "& .MuiBox-root": {
        color: colorPalette.basic.white.hex,
        fontSize: 14,
        lineHeight: "21px",
        letterspacing: ".25px",
      },
    },
  },
  containerOpen: {
    "&.gx-requirements-library-bulkbar": { paddingTop: 0 },
    bottom: 0,
    "& $wrapper": {
      maxHeight: `${barHeight}px`,
      minHeight: `${barHeight}px`,
    },
  },
  containerClosed: {
    bottom: `-${barHeight}px`,
  },
  actionButtons: {
    display: "flex",
    "& :last-child": {
      marginRight: 0,
    },
    "& button": {
      marginLeft: "16px",
      marginRight: "16px",
    },
    // popover overrides
    "& .gx-popover-menu-root.select": {
      border: "none",
      height: "32px",
      marginLeft: "16px",
      padding: 0,
      "& .mini-btn": {
        margin: 0,
      },
      "& .gx-popover-menu-list": {
        width: "350px",
        left: "-250px",
        "& ul": {
          display: "inline-block",
          maxHeight: "200px",
          overflowY: "auto",
          width: "100%",
          "& a": {
            overflowX: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
    },
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "1400px",
    minHeight: "0",
  },
  divider: {
    backgroundColor: colorPalette.interactions.berry.hex,
    height: "24px",
    marginTop: "5px",
    width: "1px",
  },
};

const bulkOpButtonStyles = {
  popoverFooter: {
    backgroundColor: colorPalette.neutrals.tin.hex,
    padding: "8px 10px",
    width: "100%",
  },
  popoverHeader: {
    borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
    color: colorPalette.neutrals.carbon.hex,
    cursor: "default",
    padding: "6px 0 7px 16px",
    width: "100%",
  },
};

export { bulkOpBarStyles, bulkOpButtonStyles };
