import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextLink } from "gx-npm-ui";

const propTypes = { onChangeFile: PropTypes.func };
const TextLinkUpload = ({ onChangeFile = (_files) => {} }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const handleFileChange = (e) => {
    onChangeFile(e.target.files);
  };

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  return (
    <Fragment>
      <input onChange={handleFileChange} ref={inputRef} style={{ display: "none" }} type="file" />
      <TextLink
        ariaLabel={t("select file to import")}
        disabled={false}
        onClick={handleClickUpload}
        text={t("choose a file")}
      />
    </Fragment>
  );
};

TextLinkUpload.propTypes = propTypes;
export default TextLinkUpload;
