import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import CheckListSection from "./check-list-section.component";
import ChecklistErrorComponent from "./components/checklist-error/checklist-error.component";

const ChecklistV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_CHECKLIST_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  return (
    <TabPanelComponent>
      <CheckListSection initiativeId={initiativeId} />
      <ChecklistErrorComponent />
    </TabPanelComponent>
  );
};

export default ChecklistV2Component;
