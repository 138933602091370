import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { handleEvent, isValidNumber } from "gx-npm-lib";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core";
import { listItemsContainerStyles as styles } from "./styles";

const propTypes = {
  children: PropTypes.any, // issue with children propType being node when using in TS components
  draggable: PropTypes.bool,
  itemList: PropTypes.array,
  onDragComplete: PropTypes.func,
  onDragFinish: PropTypes.func,
  onDragStart: PropTypes.func,
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
/**
 * callback props passed into container
 * @param {*} children - list items to be rendered
 * @param {*} draggable - boolean to determine if list items are draggable
 * @param {*} itemList - array of items to be rendered
 * @param {*} onDragComplete - fires after a drag event has completed and any changes have already been emitted
 * @param {*} onDragFinish - fires when a drag event finishes with changes in order of list
 * @param {*} onDragStart - fires when a drag even begins
 * @param {*} rootClassName - root class name for container
 * @returns
 */
const ListItemsContainer = ({
  children,
  draggable,
  itemList,
  onDragComplete,
  onDragFinish,
  onDragStart,
  rootClassName = "",
}) => {
  const classes = useStyles();

  const handleOnDragEnd = (result) => {
    if (
      Array.isArray(itemList) &&
      isValidNumber(result?.destination?.index) &&
      isValidNumber(result?.source?.index) &&
      result?.destination?.index !== result?.source?.index
    ) {
      const items = Array.from(itemList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      handleEvent(onDragFinish, items, result);
    }
    handleEvent(onDragComplete, result);
  };

  const handleOnDragStart = (event) => {
    handleEvent(onDragStart, event);
  };

  const renderListItem = (provided) => {
    return (
      <ul
        className={classNames("gx-dnd-unordered-dropzone", classes.taskList)}
        {...(provided
          ? {
              ref: provided.innerRef,
              ...provided.draggableProps,
            }
          : {})}
      >
        {children}
        {provided && provided.placeholder}
      </ul>
    );
  };

  const renderDraggable = () => {
    return (
      <DragDropContext onDragEnd={(event) => handleOnDragEnd(event)} onDragStart={(event) => handleOnDragStart(event)}>
        <Droppable droppableId="itemList">{(provided) => renderListItem(provided)}</Droppable>
      </DragDropContext>
    );
  };

  return (
    <section className={classNames("gx-dnd-dropzone-root", rootClassName)}>
      {draggable ? renderDraggable() : renderListItem()}
    </section>
  );
};

ListItemsContainer.propTypes = propTypes;
export default ListItemsContainer;
