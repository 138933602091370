import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorBlueBerry = colorPalette.interactions.blueBerry.hex;
const variationSemiBold = weightPalette.semiBold.variation;
const weightSemiBold = weightPalette.semiBold.amount;

const toggleTextStyles = {
  toggleTextCollapsed: {
    "&$toggleTextDisplay": {
      boxOrient: "vertical",
      display: "box",
      lineClamp: 5,
      textOverflow: "ellipsis",
    },
  },
  toggleTextDisplay: {
    margin: "20px 0",
    overflow: "hidden",
    transition: "height 0.5s ease-in-out",
    whiteSpace: "pre-wrap",
  },
  toggleTextLink: {
    "& button": {
      alignItems: "center",
      backgroundColor: "unset",
      border: "none",
      color: `${colorBlueBerry} !important`,
      display: "flex",
      flexBasis: "unset",
      flexGrow: "unset",
      fontSize: "14px !important",
      fontVariationSettings: variationSemiBold,
      fontWeight: weightSemiBold,
      letterSpacing: "0.25px",
      lineHeight: "21px !important",
      marginBottom: "17.5px",
      marginTop: "-11.5px",
      padding: "0 !important",
      "&:hover": {
        backgroundColor: "unset !important",
        cursor: "pointer",
      },
    },
  },
  toggleTextArrow: {
    marginLeft: "12px",
  },
  toggleInvertedArrow: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
};

export { toggleTextStyles };
