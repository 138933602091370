import styles from "./no-recipients-banner.styles.module.scss";
import ArrowHeadSVG from "../../../assets/images/no-recipients-banner/arrow-head.svg";
import ArrowTailSVG from "../../../assets/images/no-recipients-banner/arrow-tail.svg";
import RectangleSVG from "../../../assets/images/no-recipients-banner/rectangle.svg";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";

const NoRecipientsBannerComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <TypographyComponent styling={"p4"} color={"skyBrand"} boldness={"medium"}>
        {t("Setup is done, next up ...")}
      </TypographyComponent>
      <TypographyComponent styling={"h4"} color={"white"} rootClassName={styles.mainHeading}>
        {t("Send the questionnaire to vendors you're engaged with.")}
      </TypographyComponent>
      <TypographyComponent styling={"p3"} color={"silver"} rootClassName={styles.subHeading}>
        {t(
          "Add recipients so they can access and respond to your questionnaire. All the responses will show up here for your review."
        )}
      </TypographyComponent>
      <img alt="banner-tip" src={RectangleSVG} className={styles.rectangle} />
      <img alt="arrow-tail" src={ArrowTailSVG} className={styles.arrowTail} />
      <img alt="arrow-head" src={ArrowHeadSVG} className={styles.arrowHead} />
    </div>
  );
};

export default NoRecipientsBannerComponent;
