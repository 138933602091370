import { weightPalette } from "gx-npm-common-styles";

const deleteReqDetailsDialogBodyStyles = {
  bodyTop: {
    marginTop: "23px",
    marginBottom: "20px",
  },
  highlightedBold: {
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
  },
  confirmationContainer: {
    marginTop: "40px",
    "& .gx-delete-textbox-header": {
      marginBottom: "20px",
    },
  },
  spacer: {
    height: "24px",
  },
};

export default deleteReqDetailsDialogBodyStyles;
