// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZOIb8Wdzz1TkCaF1GbkB{align-items:center;display:flex}.ZOIb8Wdzz1TkCaF1GbkB .MuiLinearProgress-root{border-radius:5px;width:86px;height:12px}.ZOIb8Wdzz1TkCaF1GbkB .MuiLinearProgress-colorPrimary{background-color:var(--color-neutrals-silver)}.ZOIb8Wdzz1TkCaF1GbkB .MuiLinearProgress-bar{background:var(--color-neutrals-iron) linear-gradient(to right, var(--color-accent-sour-kiwi) 25%, var(--color-accent-kiwi) 75%);border-radius:5px 0 0 5px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/product-list-row-v3/cells/priority-score-cell/priority-score-cell.styles.module.scss"],"names":[],"mappings":"AACA,sBACE,kBAAA,CACA,YAAA,CAEA,8CACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEF,sDACE,6CAAA,CAEF,6CACE,gIAAA,CACA,yBAAA","sourcesContent":["\n.root {\n  align-items: center;\n  display: flex;\n\n  & :global(.MuiLinearProgress-root ) {\n    border-radius: 5px;\n    width: 86px;\n    height: 12px;\n  }\n  & :global(.MuiLinearProgress-colorPrimary ) {\n    background-color: var(--color-neutrals-silver)\n  }\n  & :global(.MuiLinearProgress-bar ) {\n    background: var(--color-neutrals-iron) linear-gradient(to right, var(--color-accent-sour-kiwi) 25%, var(--color-accent-kiwi) 75%);\n    border-radius: 5px 0 0 5px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ZOIb8Wdzz1TkCaF1GbkB`
};
export default ___CSS_LOADER_EXPORT___;
