import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from "gx-npm-common-styles";
import { InfoLightIcon } from "gx-npm-icons";
import { MiniButton, QuickStartPopperComponent, TypographyComponent } from "gx-npm-ui";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import DividerSVG from "../../../assets/images/divider.svg";
import { titleTopRowStyles as styles } from "./styles";
import OverviewComponent from "./quick-start/overview/overview.component";
import ScoringWeightComponent from "./quick-start/scoring-weights/scoring-weights.component";
import ImportingAndExportingComponent from "./quick-start/importing-exporting/importing-exporting.component";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../../app.constants";
import { useParams } from "react-router-dom";

const propTypes = {};
const useStyles = makeStyles(() => styles);
const TitleTopRow = () => {
  const { t } = useTranslation();
  const { initiativeId } = useParams();
  const classes = useStyles();
  const [isRequirementsTutorialDialogOpen, setIsRequirementsTutorialDialogOpen] = useState(false);
  const captureEventsV2 = useCaptureEventsV2();
  const clientEvents = [
    ClientEvent.INITIATIVE_REQUIREMENTS_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvent.INITIATIVE_REQUIREMENTS_QUICKSTART_SCORING_WEIGHTS_CLICKED,
    ClientEvent.INITIATIVE_REQUIREMENTS_QUICKSTART_IMPORTING_EXPORTING_CLICKED,
  ];
  const metaData = {
    initiativeId: initiativeId,
  };
  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Scoring weights", content: <ScoringWeightComponent /> },
    { label: "Importing / exporting", content: <ImportingAndExportingComponent /> },
  ];

  const handleEventCapture = (tabIndex) => {
    captureEventsV2([
      {
        eventType: clientEvents[tabIndex],
        metaData,
      },
    ]);
  };

  return (
    <Fragment>
      <div className={classes.topRowContainerV2}>
        <TypographyComponent color="carbon" boldness={"medium"} rootClassName={classes.titleText} styling="h3">
          {t("Requirements list")}
        </TypographyComponent>

        <img alt="" src={DividerSVG} />
        <div className={"gx-requirements-tutorial-link"}>
          <MiniButton
            onClick={() => {
              captureEventsV2([
                {
                  eventType: ClientEvent.INITIATIVE_REQUIREMENTS_QUICKSTART_MODAL_OPENED,
                  metaData,
                },
              ]);
              setIsRequirementsTutorialDialogOpen(true);
            }}
          >
            <InfoLightIcon fillPath={colorPalette.interactions.blueBerry.hex} />
            {t("How requirements work")}
          </MiniButton>
        </div>

        <QuickStartPopperComponent
          title={t("Requirements")}
          isOpen={isRequirementsTutorialDialogOpen}
          onClose={() => {
            captureEventsV2([
              {
                eventType: ClientEvent.INITIATIVE_REQUIREMENTS_QUICKSTART_MODAL_CLOSED,
                metaData,
              },
            ]);
            setIsRequirementsTutorialDialogOpen(false);
          }}
          tabsData={quickStartTabsData}
          onTabClick={handleEventCapture}
        />
      </div>
    </Fragment>
  );
};

TitleTopRow.propTypes = propTypes;
export default TitleTopRow;
