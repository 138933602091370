import { defaultToEmptyArray, defaultToEmptyObject, defaultToEmptyString, defaultToZero } from "gx-npm-lib";
import { requirementTypes } from "../actions/actionTypes";

/**
 * @var importedCatIds list of cat ids just added through import function
 * @var failures array of API save transactions objects that failed when on last attempt to save
 * @var isErrorLoading indicates an error occured either on load
 * @var isErrorTransaction indicates a failure has occured either on save - cleared after user is informed
 * @var isProcessing indicates waiting on a publish request
 * @var lastPublishedDate last date requirements was published
 * @var list existing requirements categories/items
 * @var movedCatId id of category items were just moved to
 * @var movedItemIds list of items just moved
 * @var savingCount current number of API save requests waiting for response
 * @var state current state for requirements - DRAFT or PUBLISHED
 * @var success flag for successfully publishing requirements
 * @var reverted flag for successfully reverting requirements
 * legacy
 * @var isLoading: false,
 * @var isOutOfSync: false,
 */
const requirementsState = {
  failures: [],
  hasScorecard: false,
  hasSurvey: false,
  hasSurveyRecipientViewed: false,
  importedCatIds: [],
  isErrorLoading: false,
  isErrorScoringLevel: false,
  isErrorTransaction: false,
  isLoading: false,
  isOutOfSync: false,
  isProcessing: false,
  lastPublishedDate: "",
  list: [],
  movedCatId: "",
  movedItemIds: [],
  savingCount: 0,
  scoringLevel: "",
  state: "",
  success: null,
  reverted: false,
};

const root = "requirements";
const requirementsReducer = (state, action = null) => {
  let newState;
  let temp = null;
  switch (action?.type) {
    case requirementTypes.CLEAR_REQUIREMENT_DATA:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].error = null;
      newState[root].failures = [];
      newState[root].isErrorScoringLevel = false;
      newState[root].isErrorTransaction = false;
      newState[root].success = null;
      break;
    case requirementTypes.LOAD_REQUIREMENTS_DATA:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].error = action.error ? { loading: true, ...defaultToEmptyObject(action.error) } : null;
      newState[root].hasScorecard = !!action.hasScorecard;
      newState[root].hasSurvey = !!action.hasSurvey;
      newState[root].hasSurveyRecipientViewed = !!action.hasSurveyRecipientViewed;
      newState[root].isErrorLoading = !!action.error;
      newState[root].isLoading = !!action.isLoading;
      newState[root].isOutOfSync = false;
      newState[root].isSuccessfullyLoaded = !action.error && !action.isLoading && !!action.state;
      newState[root].lastPublishedDate = defaultToEmptyString(action.lastPublishedDate);
      newState[root].list = [...defaultToEmptyArray(action.list)];
      newState[root].scoringLevel = defaultToEmptyString(action.scoringLevel || newState[root].scoringLevel);
      newState[root].state = defaultToEmptyString(action.state);
      break;
    case requirementTypes.REVERT_ALL_PUBLISH_CHANGES:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].error = action.error ? { ...defaultToEmptyObject(action.error) } : null;
      newState[root].isProcessing = !!action.isProcessing;
      newState[root].reverted = !!action.success;
      newState[root].success = action.success ? { ...defaultToEmptyObject(action.success) } : null;
      break;
    case requirementTypes.REQUIREMENT_ADD_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[action.parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[action.parentIndex]),
      };
      newState[root].list[action.parentIndex].itemList = defaultToEmptyArray(
        newState[root].list[action.parentIndex]?.itemList
      );
      newState[root].list[action.parentIndex].itemList.push(action.item);
      newState[root].list[action.parentIndex].itemList[action.childIndex].isModified = !!action.isModified;
      newState[root].list[action.parentIndex].isModified = !!action.isModified;
      break;
    case requirementTypes.REQUIREMENT_ADD_PARENT:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[action.parentIndex] = defaultToEmptyObject(action.item);
      newState[root].list[action.parentIndex].isModified = !!action.isModified;
      break;
    case requirementTypes.REQUIREMENT_DELETE_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[action.parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[action.parentIndex]),
      };
      newState[root].list[action.parentIndex].itemList = defaultToEmptyArray(
        newState[root].list[action.parentIndex]?.itemList
      );
      newState[root].list[action.parentIndex].itemList.splice(action.childIndex, 1);
      newState[root].list[action.parentIndex].isModified = !!action.isModified;
      break;
    case requirementTypes.REQUIREMENT_DELETE_PARENT:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list.splice(action.parentIndex, 1);
      break;
    case requirementTypes.REQUIREMENT_EDIT_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[action.parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[action.parentIndex]),
      };
      newState[root].list[action.parentIndex].itemList = defaultToEmptyArray(
        newState[root].list[action.parentIndex]?.itemList
      );
      newState[root].list[action.parentIndex].itemList[action.childIndex] = defaultToEmptyObject(
        newState[root].list[action.parentIndex].itemList[action.childIndex]
      );
      newState[root].list[action.parentIndex].itemList[action.childIndex][action.key] = action.value;
      newState[root].list[action.parentIndex].itemList[action.childIndex].isModified = !!action.isModified;
      newState[root].list[action.parentIndex].isModified = !!action.isModified;
      break;
    case requirementTypes.REQUIREMENT_EDIT_PARENT:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[action.parentIndex] = defaultToEmptyObject(newState[root].list[action.parentIndex]);
      newState[root].list[action.parentIndex][action.key] = action.value;
      newState[root].list[action.parentIndex].isModified = !!action.isModified;
      break;
    case requirementTypes.REQUIREMENT_REORDER_CHILD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].state = defaultToEmptyString(action.state || newState[root].state);
      newState[root].list = [...defaultToEmptyArray(newState[root].list)];
      newState[root].list[action.parentIndex] = {
        ...defaultToEmptyObject(newState[root].list[action.parentIndex]),
      };
      newState[root].list[action.parentIndex].itemList = defaultToEmptyArray(
        newState[root].list[action.parentIndex]?.itemList
      );
      temp = newState[root].list[action.parentIndex].itemList.splice(action.indexSource, 1);
      newState[root].list[action.parentIndex].itemList.splice(action.indexDest, 0, temp[0]);
      newState[root].list[action.parentIndex].itemList[action.indexDest] = defaultToEmptyObject(
        newState[root].list[action.parentIndex].itemList[action.indexDest]
      );
      newState[root].list[action.parentIndex].itemList[action.indexDest].isModified = !!action.isModified;
      newState[root].list[action.parentIndex].isModified = !!action.isModified;
      break;
    // granular saving types
    case requirementTypes.REQUIREMENT_SAVE_DATA_START:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].savingCount = defaultToZero(newState[root].savingCount) + 1;
      break;
    case requirementTypes.REQUIREMENT_SAVE_DATA_SUCCESS:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].isErrorTransaction = newState[root].failures?.length > 0;
      break;
    case requirementTypes.REQUIREMENT_SAVE_DATA_ERROR:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].failures = defaultToEmptyArray(newState[root].failures);
      newState[root].failures.push(action.transaction);
      newState[root].isErrorTransaction = true;
      newState[root].error = { isNotEditable: action.isNotEditable };
      break;
    case requirementTypes.REQUIREMENT_SAVE_DATA_COMPLETE:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].savingCount = defaultToZero(newState[root].savingCount) - 1;
      break;
    case requirementTypes.REQUIREMENT_CLEAR_FAILURES:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].failures = [];
      newState[root].isErrorScoringLevel = false;
      newState[root].isErrorTransaction = false;
      newState[root].error = null;
      break;
    // scoring level types
    case requirementTypes.REQUIREMENT_SCORING_LEVEL_ERROR:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].isErrorScoringLevel = true;
      break;
    case requirementTypes.REQUIREMENT_SCORING_LEVEL_UPDATE:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].scoringLevel = action.scoringLevel;
      break;
    case requirementTypes.REQUIREMENT_IMPORT_CATS_LIST_CLEAR:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].importedCatIds = [];
      break;
    case requirementTypes.REQUIREMENT_IMPORT_CATS_LIST_LOAD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].importedCatIds = defaultToEmptyArray(action.catIds);
      break;
    case requirementTypes.REQUIREMENT_MOVED_CAT_ID_CLEAR:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].movedCatId = "";
      break;
    case requirementTypes.REQUIREMENT_MOVED_CAT_ID_LOAD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].movedCatId = action.catId;
      break;
    case requirementTypes.REQUIREMENT_MOVED_ITEMS_LIST_CLEAR:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].movedItemIds = [];
      break;
    case requirementTypes.REQUIREMENT_MOVED_ITEMS_LIST_LOAD:
      newState = { ...defaultToEmptyObject(state) };
      newState[root] = { ...defaultToEmptyObject(newState[root]) };
      newState[root].movedItemIds = defaultToEmptyArray(action.itemIds);
      break;
    default:
      newState = state;
  }
  return newState;
};

export { requirementsState, requirementsReducer };
