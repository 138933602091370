import classNames from "classnames";
import { FreeTrialRole, getAsyncRequest, useUserState } from "gx-npm-lib";
import { MiniButton, TooltipV2 } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import ExportScorecardIcon from "./export-scorecard.icon";

type ExportScorecardApiResponse = { status: number; data: { data: { signedUrl: string } } };
type ExportScorecardButtonProps = { isViewer?: boolean; initiativeId?: string; onError?: () => void };
const ExportScorecardButton = ({
  isViewer = false,
  initiativeId = "",
  onError = () => {},
}: ExportScorecardButtonProps) => {
  const { t } = useTranslation();
  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  const handleExportButtonClick = async () => {
    try {
      const res: ExportScorecardApiResponse = await getAsyncRequest(
        `api/v3/initiatives/${initiativeId}/scorecards/export`
      );
      if (!(res.status === 200 && res.data?.data)) {
        throw new Error();
      }
      const { signedUrl } = res.data.data;
      window.open(signedUrl, "_self");
    } catch (err) {
      onError();
    }
  };

  const tooltipTitle = isTrialUser
    ? t("Exporting is not available in the Gartner BuySmart free trial.")
    : t("This action is only available to evaluation owners and contributors.");
  return (
    <TooltipV2
      deactivate={!isViewer && !isTrialUser}
      placement="top"
      PopperProps={{ modifiers: { offset: { offset: "0, 12" } } }}
      title={tooltipTitle}
    >
      <div>
        <MiniButton
          rootClassName={classNames("gx-export-scorecard-button")}
          onClick={handleExportButtonClick}
          variant="default"
          disabled={isViewer || isTrialUser}
        >
          <ExportScorecardIcon />
          {t("Export")}
        </MiniButton>
      </div>
    </TooltipV2>
  );
};

export default ExportScorecardButton;
