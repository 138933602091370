import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToEmptyArray } from "gx-npm-lib";
import { NotifyWhenStickyDetachV2Component } from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { operations } from "../../../context/actions/operationTypes";
import { actionRequirementsTransaction } from "../../../context/actions/requirementsActions";
import { DragAndDropList } from "../../../ui/dragAndDropList";
import {
  getExpandCollapse,
  getStoredJsonItem,
  setExpandCollapse,
  setStoredJsonItem,
} from "../../../ui/dragAndDropList/lib";
import { getRequirementsStorageId, persistRequirementsExpandCollapse } from "../requirements.lib";
import { RequirementListContent } from "./rows";
import { RequirementListFooter } from "./footer";
import { RequirementListHeader } from "./header";
import ScrollToRef from "../../../ui/scroll-to-ref/scroll-to-ref.component";
import { requirementListStyles as styles } from "./styles";
import classNames from "classnames";

const PAGE_HEADER_HEIGHT_IN_PX = 48;
const REQ_ITEM_COUNT_PERFORMANCE_THRESH0LD = 100;
const REQ_ITEM_ADD_DELAY_IN_MS = 400;

const propTypes = {
  categoryAdded: PropTypes.bool,
  initiativeId: PropTypes.string,
  expandedCatId: PropTypes.string,
  highlightedItemsIds: PropTypes.arrayOf(PropTypes.string),
  isValidWeight: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onWeightChange: PropTypes.func,
  reqCount: PropTypes.number,
  scrollToBotId: PropTypes.string,
  scrollToTopId: PropTypes.string,
  setCategoryAdded: PropTypes.func,
  selectedReq: PropTypes.func,
  selectedBulkOpIds: PropTypes.array,
  selectedAllCheckbox: PropTypes.func,
  selectedAllCheckboxId: PropTypes.array,
  addFromLibraryBtnClick: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const RequirementsLists = ({
  categoryAdded = false,
  expandedCatId = "",
  highlightedItemsIds = [],
  initiativeId = "",
  isValidWeight = true,
  isViewOnly = false,
  onWeightChange = (_value, _index) => {},
  reqCount = -1,
  scrollToBotId = "",
  scrollToTopId = "",
  setCategoryAdded = null,
  selectedReq = null,
  selectedBulkOpIds = [],
  selectedAllCheckbox = null,
  selectedAllCheckboxId = [],
  addFromLibraryBtnClick = null,
}) => {
  const classes = useStyles();
  const contentRef = useRef();
  const [addIndex, setAddIndex] = useState(-1);
  const [dataPersisted, setDataPersisted] = useState(false);
  const [deleteKey, setDeleteKey] = useState(-1);
  const [deletedKey, setDeletedKey] = useState(-1);
  const [expandedIds, setExpandedIds] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [newReqIndex, setNewReqIndex] = useState(-1);
  const [sessionStorageId, setSessionStorageId] = useState("");
  const [state, dispatch] = useContext(EvaluationStateContext);

  const importedIds = defaultToEmptyArray(state.requirements.importedCatIds);
  const isAboveReqCountThreshold = reqCount > REQ_ITEM_COUNT_PERFORMANCE_THRESH0LD;

  useEffect(() => {
    const id = getRequirementsStorageId(initiativeId);
    setSessionStorageId(id);
  }, [initiativeId]);

  useEffect(() => {
    const idx = state.requirements.list.findIndex((req) => {
      return expandedCatId && req.id === expandedCatId;
    });
    if (idx === -1) {
      return;
    }
    setExpandCollapse(sessionStorageId, idx, true);
    setExpandedIds((prev) => {
      const ids = { ...prev, [expandedCatId]: true };
      setStoredJsonItem(sessionStorageId + "-ids", ids);
      return ids;
    });
  }, [expandedCatId, sessionStorageId, state.requirements.list]);

  const handleExpandCollapseClick = (value, index) => {
    setExpandCollapse(sessionStorageId, index, value);
    setExpandedIds((prev) => {
      const id = state.requirements.list?.[index]?.id;
      const ids = { ...prev, [id]: value };
      setStoredJsonItem(sessionStorageId + "-ids", ids);
      return ids;
    });
  };

  const handleRequirementAddClick = (index) => {
    const childCnt = state.requirements.list[index]?.itemList?.length;
    actionRequirementsTransaction(state, dispatch, {
      childIndex: childCnt,
      initiativeId,
      operation: operations.childAdd,
      parentId: state.requirements.list[index]?.id,
      parentIndex: index,
    });
    setIsAdding(true);
    setNewReqIndex(childCnt);
    setTimeout(() => {
      setIsAdding(false);
      setNewReqIndex(-1);
    }, REQ_ITEM_ADD_DELAY_IN_MS);
    setAddIndex(index);
  };

  const handleWeightChange = (value, index) => {
    onWeightChange(value, index);
  };

  return (
    <NotifyWhenStickyDetachV2Component
      dependencyData={[...state.requirements.list, ...Object.keys(expandedIds)]}
      reference={contentRef}
      topOffsetInPx={PAGE_HEADER_HEIGHT_IN_PX}
    >
      <div className={classNames(classes.content)} ref={contentRef}>
        {Array.isArray(state.requirements.list) &&
          state.requirements.list.map((req, idx) => {
            const listLength = state.requirements.list.length;
            if (!dataPersisted && !!sessionStorageId) {
              persistRequirementsExpandCollapse(sessionStorageId, listLength || 0);
              setExpandedIds((prev) => {
                const storedArray = getStoredJsonItem(sessionStorageId);
                const length = storedArray?.length || 0;
                const ids = { ...prev };
                for (let storedIdx = 0; storedIdx < length; storedIdx++) {
                  const id = state.requirements.list[storedIdx]?.id;
                  ids[id] = storedArray[storedIdx];
                }
                setStoredJsonItem(sessionStorageId + "-ids", ids);
                return ids;
              });
              setDataPersisted(true);
            }
            const catId = req?.id;
            const currId = state.requirements.list?.[idx]?.id;
            const isDeleting = deleteKey === currId;
            const isExpanded = expandedIds[state.requirements.list?.[idx]?.id];
            const hasDeleted = deletedKey === currId;
            return (
              !!req &&
              !hasDeleted && (
                <DragAndDropList
                  defaultExpand={getExpandCollapse(sessionStorageId, idx)}
                  handleExpand={(value) => handleExpandCollapseClick(value, idx)}
                  isIdExpanded={isExpanded}
                  key={state.requirements.list?.[idx]?.id}
                  rootClassName={isDeleting ? "deleting" : ""}
                  useExpandedId={true}
                >
                  <RequirementListHeader
                    autoFocus={categoryAdded && idx + 1 === state.requirements.list.length}
                    index={idx}
                    initiativeId={initiativeId}
                    isImportedCat={importedIds.indexOf(currId) > -1}
                    isValidWeight={isValidWeight}
                    isViewOnly={isViewOnly}
                    name={req.name}
                    onWeightChange={(value) => handleWeightChange(value, idx)}
                    setCategoryAdded={setCategoryAdded}
                    setDeleteKey={setDeleteKey}
                    setDeletedKey={setDeletedKey}
                    weight={req.weight}
                  />
                  <ScrollToRef isScrolled={catId && catId === scrollToTopId} />
                  {isExpanded && !isDeleting && !hasDeleted && (
                    <Fragment>
                      <RequirementListContent
                        addIndex={addIndex}
                        highlightedItemsIds={highlightedItemsIds}
                        index={idx}
                        initiativeId={initiativeId}
                        isAboveReqCountThreshold={isAboveReqCountThreshold}
                        isExpanded={isExpanded}
                        isViewOnly={isViewOnly}
                        newReqIndex={newReqIndex}
                        setAddIndex={setAddIndex}
                        selectedReq={selectedReq}
                        selectedBulkOpIds={selectedBulkOpIds}
                        selectedAllCheckbox={selectedAllCheckbox}
                        selectedAllCheckboxId={selectedAllCheckboxId}
                      />
                      <RequirementListFooter
                        isAdding={isAdding}
                        isViewOnly={isViewOnly}
                        onHandleRequirementAddClick={() => handleRequirementAddClick(idx)}
                        onHandleAddFromLibraryClick={addFromLibraryBtnClick}
                      />
                    </Fragment>
                  )}
                  <ScrollToRef isScrolled={catId && catId === scrollToBotId} />
                </DragAndDropList>
              )
            );
          })}
      </div>
    </NotifyWhenStickyDetachV2Component>
  );
};

RequirementsLists.propTypes = propTypes;
export { RequirementsLists };
