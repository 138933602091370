import { AdditionCircledIcon } from "gx-npm-icons";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { MiniButton, TooltipV2 } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const AddProductButtonComponent = ({ isAdding = false, onClick = () => {} }) => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { role } = useUserInitAccess(initiativeId);
  return (
    <TooltipV2
      deactivate={role === InitUserRole.OWNER}
      placement="top"
      PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
      title={t("This action is only available to evaluation owners.")}
    >
      <div>
        <MiniButton onClick={onClick} variant="default" disabled={role !== InitUserRole.OWNER || isAdding}>
          <AdditionCircledIcon />
          {t("Add to eval")}
        </MiniButton>
      </div>
    </TooltipV2>
  );
};

export default AddProductButtonComponent;
