// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lnMY_eU78WlnUS3sFxr7{background-color:var(--color-neutrals-quartz);margin-top:0;width:100%}._7fcw1vsveE_bJYzdiseA{background-color:var(--color-neutrals-quartz);padding:48px 56px;transition:padding .5s}@media(max-width: 1239px){._7fcw1vsveE_bJYzdiseA{padding:48px 24px}}@media(min-width: 1440px){._7fcw1vsveE_bJYzdiseA{max-width:1512px;margin:0 auto}}`, "",{"version":3,"sources":["webpack://./src/ui/tab-panel/tab-panel.styles.module.scss"],"names":[],"mappings":"AACA,sBACE,6CAAA,CACA,YAAA,CACA,UAAA,CAGF,uBACE,6CAAA,CACA,iBAAA,CACA,sBAAA,CAEA,0BALF,uBAMI,iBAAA,CAAA,CAGF,0BATF,uBAUI,gBAAA,CACA,aAAA,CAAA","sourcesContent":["\n.panelContainer {\n  background-color: var(--color-neutrals-quartz);\n  margin-top: 0;\n  width: 100%;\n}\n\n.panelWrapper {\n  background-color: var(--color-neutrals-quartz);\n  padding: 48px 56px;\n  transition: padding 0.5s;\n\n  @media (max-width:1239px) {\n    padding: 48px 24px;\n  }\n\n  @media (min-width:1440px) {\n    max-width: 1512px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelContainer": `lnMY_eU78WlnUS3sFxr7`,
	"panelWrapper": `_7fcw1vsveE_bJYzdiseA`
};
export default ___CSS_LOADER_EXPORT___;
