import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { SelectedVendorIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import SelectedBodyV2 from "./selected-body-v2.component";
import { selectionCardV2Styles as styles } from "./selection-card-v2.styles";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const SelectionCardV2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classNames("gx-flowcard", classes.flowCardRoot)}>
      <div className={classNames("gx-select-card", classes.selectCard)}>
        <div className={classNames(classes.flowHeaderTitle)}>
          <div className={classes.selectedVendorIcon}>
            <SelectedVendorIcon />
          </div>
          <div className={classes.flowHeaderSmallTitle}>
            <TypographyComponent styling={"p4"} color={"iron"} boldness={"medium"}>
              {t("Selected vendor")}
            </TypographyComponent>
          </div>
        </div>
        <SelectedBodyV2 />
      </div>
    </div>
  );
};

export default SelectionCardV2;
