import { UUID } from "gx-npm-lib";
import { Product } from "../../../../../../selection.types";

const topThreeProductListByCatScore = (reqId: UUID, inEvalProducts: Product[], awardedProduct: Product) => {
  const pattern = [...(Array.isArray(inEvalProducts) ? inEvalProducts : [])];
  let topThreeProductList = [] as Product[];
  let asterisk = false;

  if (!pattern.length) {
    topThreeProductList = [awardedProduct];
    return {
      topThreeProductList,
      asterisk,
    };
  }

  pattern.sort((a, b) => {
    const categoryScoreA = a.scorecardCategories?.find((category) => category.id === reqId)?.scoreDisplay || 0;
    const categoryScoreB = b.scorecardCategories?.find((category) => category.id === reqId)?.scoreDisplay || 0;
    return Number(categoryScoreB) - Number(categoryScoreA);
  });

  const topThreeNotAwarded = pattern.slice(0, 3);
  topThreeNotAwarded.push(awardedProduct);

  if (topThreeNotAwarded.length > 3) {
    const categoryScore4 =
      topThreeNotAwarded[3].scorecardCategories?.find((category) => category.id === reqId)?.scoreDisplay || "0";
    const categoryScore3 =
      topThreeNotAwarded[2].scorecardCategories?.find((category) => category.id === reqId)?.scoreDisplay || "0";

    if (Number(categoryScore4) < Number(categoryScore3)) {
      asterisk = true;
    }

    topThreeNotAwarded[2] = awardedProduct;
    topThreeProductList = [...topThreeNotAwarded.slice(0, 3)];
  } else {
    topThreeProductList = [...topThreeNotAwarded];
  }

  topThreeProductList = [
    ...topThreeProductList.sort((a, b) => {
      const categoryScoreA = a.scorecardCategories?.find((category) => category.id === reqId)?.scoreDisplay || 0;
      const categoryScoreB = b.scorecardCategories?.find((category) => category.id === reqId)?.scoreDisplay || 0;
      return Number(categoryScoreB) - Number(categoryScoreA);
    }),
  ];

  return {
    topThreeProductList,
    asterisk,
  };
};
export { topThreeProductListByCatScore };
