import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { TextField as GXTextField } from "gx-npm-ui";
import { handleEvent, truncateString } from "gx-npm-lib";
import { getElemRefresh } from "../../lib";

const propTypes = {
  autoFocus: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleAutoSave: PropTypes.func,
  inputProps: PropTypes.object,
  maxDisplayChars: PropTypes.number,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  placeHolder: PropTypes.string,
  rootClassName: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
};

/**
 * @param {*} props.disabled - flag to disable field, defaults to false
 * @param {*} props.handleAutoSave - callback for fired to automatically save on value change
 * @param {*} props.maxDisplayChars - maximum number of characters allowed to be display in the field, will truncate value of field when unfocused,
 * and show full value when user refocuses. will apply whenever the value is greater than 0, DEFAULTS to 0
 * @param {*} props.multiline - flag to enable multiline, defaults to false
 * @param {*} props.onBlur - NOT REQUIRED: additional parent callback for onblur events outside of default behavior
 * @param {*} props.onChange - NOT REQUIRED: additional parent callback for onchange events outside of default behavior
 * @param {*} props.onClick - NOT REQUIRED: additional parent callback for onclick events outside of default behavior
 * @param {*} props.onKeyPress - NOT REQUIRED: additional parent callback for onKeyPress events outside of default behavior
 * @param {*} props.onFocus - NOT REQUIRED: additional parent callback for onfocus events outside of default behavior
 * @param {*} props.setValue - REQUIRED: setter hook used by parent to track value, required in order to keep value up to date
 * @param {*} props.value - REQUIRED: getter hook used by parent to track value, required in order to keep value up to date
 * @returns MUI wrapped text field component
 */
const TextField = ({
  autoFocus = false,
  autoHighlight = true,
  className = "",
  disabled = false,
  fullWidth = false,
  handleAutoSave = null,
  inputProps = {},
  maxDisplayChars = 0,
  multiline = false,
  onBlur = null,
  onChange = null,
  onClick = null,
  onFocus = null,
  onKeyPress = null,
  placeHolder = "",
  rootClassName = "",
  setValue = null,
  value = "",
  variant = "outlined",
}) => {
  const [display, setDisplay] = useState("");
  const [isEditting, setIsEditting] = useState(false);
  const [initValue, setInitValue] = useState("");
  const [isClickable, setIsClickable] = useState(true);

  const truncateDisplayValue = useCallback(() => {
    if (maxDisplayChars > 0) {
      const truncValue = truncateString(value, maxDisplayChars);
      setDisplay(truncValue);
    } else {
      setDisplay(value);
    }
  }, [maxDisplayChars, value]);

  useEffect(() => {
    if (!isEditting) {
      truncateDisplayValue();
    }
  }, [truncateDisplayValue, isEditting]);

  const handleKeyPress = (event) => {
    handleEvent(onKeyPress, event);
  };

  const handleBlur = (event) => {
    setIsClickable(true);
    const newValue = event?.target?.value || "";
    if (event && initValue !== newValue) {
      handleEvent(handleAutoSave, newValue);
    }
    truncateDisplayValue();
    handleEvent(onBlur, event);
    setIsEditting(false);
  };

  const handleChange = (event) => {
    if (event && event.target) {
      const eventValue = event.target.value;
      handleEvent(setValue, eventValue);
      setDisplay(eventValue);
    }
    handleEvent(onChange, event);
  };

  const handleClick = (event) => {
    if (!disabled) {
      setIsClickable(false);
      handleEvent(onClick, event);
    }
  };

  const handleFocus = (event) => {
    setIsEditting(true);
    setDisplay(value);
    if (autoHighlight && event) {
      const { target } = event;
      getElemRefresh(target, function () {
        target.select();
      });
    }
    setInitValue(value);
    handleEvent(onFocus, event);
  };

  return (
    <GXTextField
      autoFocus={autoFocus}
      rootClassName={classNames(className, rootClassName, !isClickable && "click")}
      disabled={disabled}
      fullWidth={fullWidth}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      onFocus={handleFocus}
      onKeyPress={handleKeyPress}
      placeholder={placeHolder}
      value={display}
      variant={variant}
      inputProps={inputProps}
      multiline={multiline}
    />
  );
};

TextField.propTypes = propTypes;
export default TextField;
