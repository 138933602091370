import { isValidNumber } from "../isUtils";

/**
 * sum all the key values of each object in an array where the key name is passed as key; i.e. if
 * key value is sent as "dog", and array is sent as [{ dog: 5 }, { cat: 2, dog: 4}], the return
 * value would be 9
 * @param {*} array array with objects
 * @param {*} key key in each object to sum
 * @returns value of all keys in array of object with numberic values
 */
const sumArrayObjects = (array = [], key = "") => {
  let sum = 0;
  const length = !!key && array?.length;
  for (let idx = 0; idx < length; idx++) {
    if (isValidNumber(array?.[idx]?.[key])) {
      sum += array[idx][key];
    }
  }
  return sum;
};

export default sumArrayObjects;
