const requirementListFooterStyles = {
  addRequirementTooltip: {
    width: 264,
  },
  addLibraryTooltip: {
    width: 264,
  },
};

export { requirementListFooterStyles };
