import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RadioButtonOption from "../../../../ui/radio-button-option/radio-button-option.component";
import styles from "./start-scorecard-dialog-selection.styles";
import { ScoringLevel } from "gx-npm-lib";

const propTypes = {
  countCats: PropTypes.number,
  countReqs: PropTypes.number,
  setSelectedLevel: PropTypes.func,
  selectedLevel: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const StartScorecardDialogSelection = ({
  countCats = "",
  countReqs = "",
  setSelectedLevel = (_level) => {},
  selectedLevel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedLevel(selectedLevel);
  }, [selectedLevel, setSelectedLevel]);

  const handleChange = (e) => {
    if ([ScoringLevel.CATEGORY, ScoringLevel.REQUIREMENT].includes(e.target.value)) {
      setSelectedLevel(e.target.value);
    }
  };
  return (
    <section className={classes.options}>
      <RadioButtonOption
        count={countReqs}
        handleChange={handleChange}
        isSelected={selectedLevel === ScoringLevel.REQUIREMENT}
        label={t("Requirements")}
        value={ScoringLevel.REQUIREMENT}
      />
      <RadioButtonOption
        count={countCats}
        handleChange={handleChange}
        isSelected={selectedLevel === ScoringLevel.CATEGORY}
        label={t("Categories")}
        value={ScoringLevel.CATEGORY}
      />
    </section>
  );
};

StartScorecardDialogSelection.propTypes = propTypes;
export default StartScorecardDialogSelection;
