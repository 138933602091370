import React from "react";
import PropTypes from "prop-types";
import { ProductLogo, TooltipV2 } from "gx-npm-ui";

const propTypes = { logo: PropTypes.string, name: PropTypes.string };
const DeleteReqDetailsLogo = ({ logo = "", name = "" }) => {
  return (
    <TooltipV2 PopperProps={{ modifiers: { offset: { offset: "0, 8" } } }} title={name}>
      <div>
        <ProductLogo imageHeight="32px" imageWidth="32px" logo={logo} name={name} />
      </div>
    </TooltipV2>
  );
};

DeleteReqDetailsLogo.propTypes = propTypes;
export default DeleteReqDetailsLogo;
