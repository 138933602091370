import { ChangeEvent, useContext } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { TextField, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { budgetTextFieldV2Styles as styles } from "./budget-text-field-v2.styles";
import { OverviewContext } from "../../overview.context";
import { displayBudget, parseStringToInt } from "../../../../lib";
import { putAsyncRequest, UUID } from "gx-npm-lib";
import { updateEditCount } from "./planning-card-lib";

/**
 * create a textField that is stylized for BuySmart
 * @param {*} props.disabled - passes disable value on to Mui component
 * @param {*} props.initiativeId - uses initiativeId to make api call
 */

type BudgetTextFieldV2Props = {
  disabled: boolean;
  initiativeId: UUID;
};

const useStyles = makeStyles(() => styles);
const BudgetTextFieldV2 = ({ disabled = false, initiativeId }: BudgetTextFieldV2Props) => {
  const { budget, setBudget, setIsSnackbarOpen, setOverviewEditCount } = useContext(OverviewContext);
  const { t } = useTranslation();

  const budgetDisplayValue = displayBudget(budget).substring(1);
  const updateUrl = `api/v3/initiatives/${initiativeId}`;
  const handleBudgetChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount = parseStringToInt(e.target.value);
    if (amount) {
      setBudget({ amount, currency: budget.currency });
    } else {
      setBudget({ amount: "", currency: budget.currency });
    }
  };

  const handleBudgetBlur = async (e: ChangeEvent<HTMLInputElement>) => {
    const amount = parseStringToInt(e.target.value);
    setBudget({ amount, currency: budget.currency });
    if (window.FS) {
      window.FS.event("Budget Update Event", {
        "Budget Amount": amount,
        "Budget Currency": budget.currency,
        "Initiative ID": initiativeId,
      });
    }
    try {
      updateEditCount(setOverviewEditCount, 1);
      const putResponse = await putAsyncRequest(updateUrl, { budget: { amount, currency: budget.currency } });
      if (putResponse.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      setIsSnackbarOpen(true);
    } finally {
      updateEditCount(setOverviewEditCount, -1);
    }
  };

  const classes = useStyles();

  const newInputProps = {
    ...(parseStringToInt(budgetDisplayValue) > 0 && {
      pattern: "[0-9,]*",
      startAdornment: (
        <InputAdornment position="start">
          <TypographyComponent styling="p3" color="coal">
            $
          </TypographyComponent>
        </InputAdornment>
      ),
    }),
  };

  return (
    <TooltipV2
      deactivate={!disabled}
      rootClassName="gx-budget-tooltip"
      PopperProps={{
        modifiers: { offset: { offset: "-50, 0" } },
      }}
      placement="top"
      title={t("This action is only available to evaluation owners.")}
    >
      <div aria-label="textbox" className={classnames("Budget-Field", classes.container)}>
        <TextField
          rootClassName="gx-budget-field"
          InputProps={newInputProps}
          value={Number(budget.amount) > 0 ? budgetDisplayValue : ""}
          type="text"
          pattern="[0-9,]*"
          min="1"
          step="1"
          inputProps={{ maxLength: 15 }}
          size="small"
          onBlur={handleBudgetBlur}
          onChange={handleBudgetChange}
          placeholder={"Enter amount"}
          fullWidth
          disabled={disabled}
        />
      </div>
    </TooltipV2>
  );
};

export default BudgetTextFieldV2;
