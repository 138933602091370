// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viEg7h0FYVhgSs5Tr8eb .save-indicator-icon{height:unset;margin-left:8px;width:unset}`, "",{"version":3,"sources":["webpack://./src/sections/header-v2/components/save-indicator/save-indicator.styles.module.scss"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".saveIndicator :global(.save-indicator-icon) {\n  height: unset;\n  margin-left: 8px;\n  width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveIndicator": `viEg7h0FYVhgSs5Tr8eb`
};
export default ___CSS_LOADER_EXPORT___;
