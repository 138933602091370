import { StarSmallIcon } from "gx-npm-icons";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./peer-insights-cell.styles.module.scss";

const PeerInsightsCellComponent = ({ rating = 0, reviewCount = 0 }) => {
  const { t } = useTranslation();
  const hasReviews = reviewCount > 0;
  return (
    <div className={styles.root}>
      <TooltipV2
        deactivate={hasReviews}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
        title={t("There are no user reviews for this product in this market, so we are unable to provide a rating.")}
      >
        <div aria-label={hasReviews ? "peer review rating" : "no peer reviews"} className={styles.piRating}>
          {hasReviews && (
            <Fragment>
              <StarSmallIcon />
              <TypographyComponent boldness={"medium"} styling={"p1"}>
                {(Math.floor(rating * 10) / 10 || 0).toFixed(1)}
              </TypographyComponent>
              <TypographyComponent boldness={"regular"} rootClassName={styles.piDenominator} styling={"p4"}>
                {" / 5"}
              </TypographyComponent>
            </Fragment>
          )}
          {!hasReviews && (
            <TypographyComponent boldness={"medium"} rootClassName={styles.piEmpty} styling={"p4"}>
              {t("No rating")}
            </TypographyComponent>
          )}
        </div>
      </TooltipV2>
      <div aria-label="peer review count">
        <TypographyComponent boldness={"medium"} color={"iron"} styling={"p4"}>
          {`${reviewCount || "0"} ${t("reviews")}`}
        </TypographyComponent>
      </div>
    </div>
  );
};

export default PeerInsightsCellComponent;
