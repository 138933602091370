import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const variationSemiBold = weightPalette.semiBold.variation;
const weightSemiBold = weightPalette.semiBold.amount;

const setUpCardV2Styles = {
  statusViewRoot: {
    color: colorCarbon,
    display: "block",
    marginBottom: "24px",
    width: "100%",
    "&.gx-planning-view-root": {
      "& .gx-num": {
        fontSize: "24px",
        letterSpacing: "0.15px",
        lineHeight: "31.2px",
      },
      "& .gx-of": {
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "21px",
      },
      "& .gx-num, .gx-of": {
        color: colorCarbon,
        fontVariationSettings: variationSemiBold,
        fontWeight: weightSemiBold,
      },
    },
    "@media (max-width: 1239px)": {
      minWidth: "820px",
    },
  },
};

export default setUpCardV2Styles;
