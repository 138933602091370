import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { BaseTextArea } from "gx-npm-ui";
import { blurRef } from "../../../lib";
import { getTextAreaStyles } from "./styles";

const propTypes = {
  autoFocus: PropTypes.bool,
  handleAutoSave: PropTypes.func,
  isDisabled: PropTypes.bool,
  isDragging: PropTypes.bool,
  isIndexMenuOpen: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  rootBaseClassName: PropTypes.string,
  rootClassName: PropTypes.string,
  setEdit: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
};
const ListItemTextArea = ({
  autoFocus = false,
  handleAutoSave = (_event) => {},
  isDisabled = false,
  isDragging = false,
  isIndexMenuOpen = false,
  isMenuOpen = false,
  isViewOnly = false,
  onBlur = (_event) => {},
  onFocus = (_event) => {},
  placeholder = "Enter value",
  rootBaseClassName = "",
  rootClassName = "",
  setEdit = (_isEdit) => {},
  type = "",
  value = "",
}) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused && (isDragging || isMenuOpen)) {
      blurRef(areaRef);
      if (isMenuOpen && isIndexMenuOpen) {
        handleEvent(setEdit, true);
      }
      setIsFocused(false);
    }
  }, [isDragging, isFocused, isIndexMenuOpen, isMenuOpen, setEdit]);

  const handleBlur = (event) => {
    setIsFocused(false);
    handleEvent(setEdit, false);
    handleEvent(onBlur, event);
  };

  const handleFocus = (event) => {
    setIsFocused(true);
    handleEvent(setEdit, true);
    handleEvent(onFocus, event);
  };

  const useStyles = makeStyles(() => getTextAreaStyles(type));
  const classes = useStyles();
  const areaRef = useRef();
  return (
    <div className={classNames(rootClassName, classes.root, isViewOnly && "gx-read-only")}>
      <BaseTextArea
        areaRef={areaRef}
        autoFocus={autoFocus}
        disabled={isDisabled || isViewOnly}
        flavor="BLUEBERRY"
        handleAutoSave={(event) => handleEvent(handleAutoSave, event)}
        onBlur={(event) => handleBlur(event)}
        onFocus={(event) => handleFocus(event)}
        placeholder={placeholder}
        rootClassName={rootBaseClassName}
        value={value}
      />
    </div>
  );
};

ListItemTextArea.propTypes = propTypes;
export default ListItemTextArea;
