import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { ArrowCircledIcon } from "gx-npm-icons";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import { listHeaderExpandButtonStyles as styles } from "./styles";

const propTypes = {
  expand: PropTypes.bool,
  setExpand: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ListHeaderExpandButtonV2 = ({ expand = false, setExpand = (_isExpanded) => {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClick = () => {
    setExpand(!expand);
  };

  const tooltipText = expand ? t("Collapse section") : t("Expand section");
  return (
    <div className={classNames("gx-exp-col-btn", classes.iconExpand, expand && "gx-is-expanded")}>
      <TooltipV2
        interactive={false}
        placement="bottom"
        PopperProps={{ modifiers: { offset: { offset: "0, 12" } } }}
        title={tooltipText}
      >
        <div>
          <IconButton onClick={handleClick}>
            <ArrowCircledIcon />
          </IconButton>
        </div>
      </TooltipV2>
    </div>
  );
};

ListHeaderExpandButtonV2.propTypes = propTypes;
export default ListHeaderExpandButtonV2;
