// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z7iBvCEAV_nT5cDdNNqD{display:flex}.ptvpY_QY0avv4pKO0lFe{margin-right:24px}.WbmsAJEeSVqsva8gjgNT{width:24px}.WlkdOVRaJ9sy4NgoBVdI{margin-left:-7px;padding-top:20px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/initiative-details-card/team-members-v2/team-members-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,UAAA,CAGF,sBACE,gBAAA,CACA,gBAAA","sourcesContent":[".teamMembersRoot {\n  display: flex;\n}\n\n.primeOwnerWrapper {\n  margin-right: 24px;\n}\n\n.additionalMemberPadding {\n  width: 24px;\n}\n\n.avatarCountWrapper {\n  margin-left: -7px;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamMembersRoot": `Z7iBvCEAV_nT5cDdNNqD`,
	"primeOwnerWrapper": `ptvpY_QY0avv4pKO0lFe`,
	"additionalMemberPadding": `WbmsAJEeSVqsva8gjgNT`,
	"avatarCountWrapper": `WlkdOVRaJ9sy4NgoBVdI`
};
export default ___CSS_LOADER_EXPORT___;
