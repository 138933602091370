import { makeStyles } from "@material-ui/core";
import { UUID } from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import EmptyVendors from "../../../../assets/images/overview/evaluation/emptyVendors.svg";
import { emptyVendorListV2Styles as styles } from "./empty-vendor-list-v2.styles";

const useStyles = makeStyles(() => styles);
const EmptyVendorListV2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{
    initiativeId: UUID;
  }>();
  const [isImgError, setIsImgError] = useState(false);

  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/products`);
  };
  return (
    <div className={classes.root}>
      {!isImgError && <img alt="no vendors in evaluation" onError={() => setIsImgError(true)} src={EmptyVendors} />}
      <TypographyComponent boldness={"medium"} color={"iron"} rootClassName={classes.emptyText} styling={"p1"}>
        {t("It looks like there are no active vendors in your evaluation.")}
      </TypographyComponent>
      <Button onClick={handleClick} rootClassName="btn-tertiary">
        {t("GO TO VENDOR LIST")}
      </Button>
    </div>
  );
};
export default EmptyVendorListV2;
