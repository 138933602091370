import { colorPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;

const libraryListStyles = {
  listContainer: {
    paddingTop: "16px",
    color: colorIron,
    "& .gx-popover-menu-list": {
      left: "-148px",
    },
  },
  listTitle: {
    paddingBottom: "28px",
  },
};

const libraryListRowStyles = {
  libListRowWrap: {
    alignItems: "center",
    borderBottom: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    display: "flex",
    width: "100%",
    "&.hover": {
      backgroundColor: colorPalette.neutrals.pearl.hex,
    },
  },
  libListRowIcon: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "12px",
    maxWidth: "48px",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  libListRowTitle: {
    paddingLeft: "16px",
    width: "100%",
  },
  libListRowCount: {
    maxWidth: "140px",
    width: "100%",
  },
  libListRowAvatarDate: {
    alignItems: "center",
    color: colorIron,
    display: "inline-flex",
    maxWidth: "148px",
    width: "100%",
    "& .gx-user": {
      padding: "16px 10px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .tool-tip": {
      width: "unset",
    },
  },
  libListRowPopover: {
    maxWidth: "68px",
    width: "100%",
    "& ul li:last-child a": {
      color: colorPalette.status.cherry.hex,
    },
  },
};

export { libraryListRowStyles, libraryListStyles };
