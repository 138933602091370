import React, { FC, useEffect, useCallback, useState, useRef } from "react";
import styles from "./table-header.styles.module.scss";
import classNames from "classnames";
import { ArrowIcon } from "gx-npm-icons";
import { debounce, TooltipProps, useMediaQuery } from "@mui/material";
import { Cell, LAST_TABLE_COLUMN_CLASS_NAME, SCROLLABLE_DIV_CLASS_NAME } from "../table.constants";
import { ToolTipIconButton } from "gx-npm-ui";

type TableHeaderProps = {
  rows: Cell[][];
  isOverflowing: boolean;
  leftArrowTooltipOptions?: TooltipProps;
  rightArrowTooltipOptions?: TooltipProps;
};

export const TableHeaderComponent: FC<TableHeaderProps> = ({
  rows,
  isOverflowing,
  leftArrowTooltipOptions,
  rightArrowTooltipOptions,
}) => {
  const areThreeColumnsVisible = useMediaQuery("(max-width:1240px)");
  const areTwoColumnsVisible = useMediaQuery("(max-width:1024px)");
  const tableDivisionFactor = areTwoColumnsVisible ? 2 : areThreeColumnsVisible ? 3 : 4;
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isNavInProgress, setIsNavInProgress] = useState(false);

  const handleScroll = debounce(() => {
    if (stickyRef.current) {
      const offsetTop = stickyRef.current.offsetTop;
      setIsSticky(window.scrollY >= offsetTop - 42);
    }
  }, 100);

  const [arrowVisibility, setArrowVisibility] = useState<{
    left: boolean;
    right: boolean;
  }>({
    left: false,
    right: true,
  });

  const updateArrowVisibility = useCallback(() => {
    const scrollableElement = document.getElementsByClassName(SCROLLABLE_DIV_CLASS_NAME)[0];
    const isAtStart = scrollableElement.scrollLeft === 0;
    const isAtEnd = scrollableElement.scrollLeft + scrollableElement.clientWidth >= scrollableElement.scrollWidth - 10;
    setArrowVisibility({ left: !isAtStart, right: !isAtEnd });
  }, []);

  const updateLastColumnsColor = () => {
    const lastColumns = document.getElementsByClassName(LAST_TABLE_COLUMN_CLASS_NAME);
    Array.from(lastColumns).forEach((column: HTMLDivElement) => {
      const currentBackgroundColor = getComputedStyle(column).getPropertyValue("background-color");
      const stoneColor = "rgb(250, 250, 250)";
      const whiteColor = "rgb(255, 255, 255)";

      if (currentBackgroundColor === whiteColor) {
        column.style.backgroundColor = stoneColor;
      } else {
        column.style.backgroundColor = whiteColor;
      }
    });
  };

  const resetLastColumnsColor = () => {
    const lastColumns = document.getElementsByClassName(LAST_TABLE_COLUMN_CLASS_NAME);
    Array.from(lastColumns).forEach((column: HTMLDivElement) => {
      column.style.backgroundColor = "";
    });
  };

  const scrollToStart = useCallback(() => {
    const scrollableElements = document.getElementsByClassName(SCROLLABLE_DIV_CLASS_NAME);
    Array.from(scrollableElements).forEach((element) => {
      element.scrollTo({ left: 0, behavior: "smooth" });
    });
    updateArrowVisibility();
  }, [updateArrowVisibility]);

  useEffect(() => {
    const handleResize = () => {
      scrollToStart();
      updateArrowVisibility();
      resetLastColumnsColor();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollToStart, updateArrowVisibility]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleNavClick = useCallback(
    (direction: "left" | "right" = "left") => {
      if (isNavInProgress) {
        return;
      }

      setIsNavInProgress(true);
      const scrollableElements = document.getElementsByClassName(SCROLLABLE_DIV_CLASS_NAME);
      Array.from(scrollableElements).forEach((element) => {
        const scrollAmount = Number((element.getBoundingClientRect().width / tableDivisionFactor).toFixed(1));
        element.scrollTo({
          left: direction === "left" ? element.scrollLeft - scrollAmount : element.scrollLeft + scrollAmount,
          behavior: "smooth",
        });
      });

      updateLastColumnsColor();

      setTimeout(() => {
        updateArrowVisibility();
        setIsNavInProgress(false);
      }, 400);
    },
    [tableDivisionFactor, updateArrowVisibility, isNavInProgress]
  );

  return (
    <div className={classNames(styles.root, isSticky && styles.stickyShadow)} ref={stickyRef}>
      {rows.map((row, rowIndex) => (
        <div
          className={classNames(styles.row, {
            [styles.firstRow]: rowIndex === 0,
          })}
          key={`row-${rowIndex}`}
        >
          <div
            className={classNames({
              [styles.firstColumn]: true,
            })}
          >
            <div>{row[0]}</div>
            {rowIndex === 0 && arrowVisibility?.left && (
              <div className={styles.scrollLeftContainer}>
                <ToolTipIconButton
                  ariaLabel={"table-header-left-arrow"}
                  onClick={() => handleNavClick("left")}
                  {...leftArrowTooltipOptions}
                >
                  <ArrowIcon />
                </ToolTipIconButton>
              </div>
            )}
          </div>
          <div
            className={classNames({
              [styles.columnContainer]: true,
              [SCROLLABLE_DIV_CLASS_NAME]: true,
              [styles.roundedTopRightBorder]: rowIndex === 0,
              [styles.roundedBottomRightBorder]: rowIndex === rows.length - 1 && !isOverflowing,
            })}
          >
            {row.slice(1).map((column, columnIndex) => (
              <div
                className={classNames(styles.column, {
                  [styles.darkColumn]: rowIndex !== 0 && columnIndex % 2 === 0,
                  [styles.responsiveColumns]: row.length >= 4,
                  [styles.moreThanFourColumns]: row.length >= 5,
                })}
                key={`column-${columnIndex + 1}`}
              >
                {column}
              </div>
            ))}
          </div>
          {isOverflowing && (
            <div
              className={classNames({
                [styles.lastColumn]: true,
                [styles.roundedTopRightBorder]: rowIndex === 0,
                [styles.roundedBottomRightBorder]: rowIndex === rows.length - 1,
                [LAST_TABLE_COLUMN_CLASS_NAME]: rowIndex !== 0,
              })}
            >
              {rowIndex === 0 && arrowVisibility?.right && (
                <div className={styles.scrollRightContainer}>
                  <ToolTipIconButton
                    ariaLabel={"table-header-right-arrow"}
                    onClick={() => handleNavClick("right")}
                    {...rightArrowTooltipOptions}
                  >
                    <ArrowIcon />
                  </ToolTipIconButton>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
