// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KZNd_K5NmCOrThv9yYHQ{margin-left:-7px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/initiative-details-card/team-members-v2/tool-tipped-username/tool-tipped-username.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".hiddenUsernameWrapper {\n  margin-left: -7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hiddenUsernameWrapper": `KZNd_K5NmCOrThv9yYHQ`
};
export default ___CSS_LOADER_EXPORT___;
