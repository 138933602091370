const evaluationHeaderV2Styles = {
  root: {
    display: "flex",
    "& .btn-primary": {
      marginLeft: "auto",
      marginRight: 0,
    },
  },
};

export { evaluationHeaderV2Styles };
