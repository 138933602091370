import React from "react";
import { Product, SurveyStatus } from "../../survey-table.constants";
import styles from "./create-header-rows.styles.module.scss";
import { MiniButton, ProductLogo, TextLinkV2, TypographyComponent } from "gx-npm-ui";
import { AdditionCircledIcon } from "gx-npm-icons";
import { navigateToUrl } from "single-spa";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";

type CreateHeaderRowsProps = {
  products: Product[];
  disabled: boolean;
  initiativeId: string;
  t: (value: string) => string;
};

export const createHeaderRows = ({ products, disabled, initiativeId, t }: CreateHeaderRowsProps) => {
  const handleNavigation = (url: string) => {
    navigateToUrl(url);
  };

  const getSurveyStatus = (product: Product) => {
    const statusMap = {
      [SurveyStatus.DEFAULT]: t("Not yet sent"),
      [SurveyStatus.IN_PROGRESS]: t("In Progress"),
      [SurveyStatus.NOT_STARTED]: t("Not Started"),
      [SurveyStatus.SUBMITTED]: t("Responses Shared"),
    };

    const statusText = statusMap[product.surveyStatus as SurveyStatus] || "";
    const statusClass = classNames(styles.surveyStatus, {
      [styles.default]: product.surveyStatus === SurveyStatus.DEFAULT,
      [styles.inProgress]: product.surveyStatus === SurveyStatus.IN_PROGRESS,
      [styles.notStarted]: product.surveyStatus === SurveyStatus.NOT_STARTED,
      [styles.submitted]: product.surveyStatus === SurveyStatus.SUBMITTED,
    });

    return (
      <TypographyComponent key={product.id} rootClassName={statusClass} styling="p3" boldness="medium">
        {statusText}
      </TypographyComponent>
    );
  };

  const renderProductInfo = (product: Product) => (
    <div key={product.id} className={styles.centeredColumns}>
      <div
        aria-label="Vendor logo and name"
        role="button"
        onClick={() => handleNavigation(`/s/evaluation/${initiativeId}/product/${product.id}/profile`)}
        onKeyDown={(e) =>
          e.key === "Enter" && handleNavigation(`/s/evaluation/${initiativeId}/product/${product.id}/profile`)
        }
        tabIndex={0}
        className={styles.logoContainer}
      >
        <ProductLogo hasAltText={true} name={product.name} logo={product.imageLoc} rootClassName={styles.logo} />
      </div>
      <TypographyComponent styling="p3" boldness="medium">
        <TextLinkV2
          href={""}
          rootClassName={styles.productLink}
          variant="secondary"
          text={product.name}
          onClick={() => handleNavigation(`/s/evaluation/${initiativeId}/product/${product.id}/profile`)}
        />
      </TypographyComponent>
    </div>
  );

  const renderFirstRow = () => (
    <div key="product-count" className={styles.staticFirstColumn}>
      <TypographyComponent styling="p2" boldness="medium">
        {t("Vendors")} ({products.length})
      </TypographyComponent>
      <MiniButton disabled={disabled} onClick={() => handleNavigation(`/s/evaluation/${initiativeId}/products`)}>
        <AdditionCircledIcon fillPath={colorPalette.interactions.defaultCta.hex} />
        {t("Add vendor")}
      </MiniButton>
    </div>
  );

  const firstRow = [renderFirstRow(), ...products.map(renderProductInfo)];

  const secondRow = [
    <TypographyComponent key="questionnaire-status" styling="p2" boldness="medium">
      {t("Questionnaire Status")}
    </TypographyComponent>,
    ...products.map((product) => (
      <div key={product.id} className={styles.surveyStatus}>
        {getSurveyStatus(product)}
      </div>
    )),
  ];

  return [firstRow, secondRow];
};
