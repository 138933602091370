import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { SELECTED_PRIORITY_WEIGHTS } from "../../../products.constants";
import { ProductsContext } from "../../../products.context";
import styles from "./product-list-header-v3.styles.module.scss";

const ProductListHeaderV3Component = () => {
  const { t } = useTranslation();
  const { isBaseTemplate, isMqBasedTemplate, priorities } = useContext(ProductsContext);
  const hasPrioritiesSelected = priorities.some((priority) => SELECTED_PRIORITY_WEIGHTS.includes(priority.weight));
  const hasFourColumns = !isBaseTemplate && isMqBasedTemplate && hasPrioritiesSelected;
  return (
    <div className={styles.headerWrapper}>
      <TypographyComponent boldness={"medium"} color={"iron"} rootClassName={styles.startColumn} styling={"p4"}>
        {t("Vendor")}
      </TypographyComponent>
      <div className={classNames(styles.middleColumns, hasFourColumns && styles.smallMiddleColumns)}>
        {isMqBasedTemplate && (
          <TypographyComponent rootClassName={styles.column}>
            <TypographyComponent
              element={"span"}
              rootClassName={styles.smallMqText}
              boldness={"medium"}
              color={"iron"}
              styling={"p4"}
            >
              {t("MQ position")}
            </TypographyComponent>
            <TypographyComponent
              element={"span"}
              rootClassName={styles.normalMqText}
              boldness={"medium"}
              color={"iron"}
              styling={"p4"}
            >
              {t("Magic Quadrant position")}
            </TypographyComponent>
          </TypographyComponent>
        )}
        {!isBaseTemplate && (
          <TypographyComponent color={"iron"} boldness={"medium"} rootClassName={styles.column} styling={"p4"}>
            {t("Peer Insights")}
          </TypographyComponent>
        )}
        {hasPrioritiesSelected && (
          <TypographyComponent color={"iron"} boldness={"medium"} rootClassName={styles.column} styling={"p4"}>
            {t("Vendor fit")}
          </TypographyComponent>
        )}
      </div>
    </div>
  );
};

export default ProductListHeaderV3Component;
