// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Huj_HfzVh52Fb52ohi1l{color:var(--color-neutrals-iron)}.GJDWANdvMo27zW51uQel{align-items:baseline;display:flex;justify-content:space-between}.GJDWANdvMo27zW51uQel button:last-child{margin-left:24px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-add-v2/products-add-dialog-v2/components/products-add-dialog-footer-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CAGF,sBACE,oBAAA,CACA,YAAA,CACA,6BAAA,CAEA,wCACE,gBAAA","sourcesContent":[".requiredLabel {\n  color: var(--color-neutrals-iron);\n}\n\n.footer {\n  align-items: baseline;\n  display: flex;\n  justify-content: space-between;\n\n  & button:last-child {\n    margin-left: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requiredLabel": `Huj_HfzVh52Fb52ohi1l`,
	"footer": `GJDWANdvMo27zW51uQel`
};
export default ___CSS_LOADER_EXPORT___;
