import { colorPalette } from "gx-npm-common-styles";

const overviewTabContentV2Styles = {
  overviewRoot: {
    gap: "24px",
  },
  evaluationSummary: {
    background: colorPalette.basic.white.hex,
    borderRadius: "8px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "40px",
    paddingTop: "32px",
    marginBottom: "24px",
  },
  headerTitle: {
    color: colorPalette.neutrals.carbon.hex,
    paddingBottom: "8px",
  },
  planningContainer: {
    display: "flex",
    marginBottom: 24,
  },
  teamManagementCard: {
    display: "flex",
  },
};

export { overviewTabContentV2Styles };
