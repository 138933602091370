import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const propTypes = { isScrolled: PropTypes.bool };
const ScrollToRef = ({ isScrolled = false }) => {
  const ref = useRef();
  useEffect(() => {
    if (!isScrolled || !ref || !ref.current) {
      return;
    }
    const options = { behavior: "smooth", block: "center" };
    ref.current.scrollIntoView(options);
  }, [isScrolled]);
  return <div ref={ref} style={{ height: 0 }} />;
};

ScrollToRef.propTypes = propTypes;
export default ScrollToRef;
