import { useWebSocket, UUID } from "gx-npm-lib";
import { Button, Dialog, TypographyComponent } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { WebSocketMessageEvent } from "../../../../app.constants";

type WsInMsgDeleteInit = {
  event: WebSocketMessageEvent;
  initiativeId: UUID;
  name: string;
};

const InitDeletedDialogComponent = () => {
  const { initiativeId } = useParams();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [wsMessage] = useWebSocket<WsInMsgDeleteInit, unknown>();

  useEffect(() => {
    if (!initiativeId || !wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.INITIATIVE_DELETED) {
      return;
    }
    setIsOpen(true);
    setUsername(wsMessage.name);
  }, [initiativeId, wsMessage]);

  const handleClick = () => {
    setIsOpen(false);
    singleSpa.navigateToUrl("/s/evaluations");
  };
  return (
    <Dialog
      body={
        <TypographyComponent styling="p3">
          {username}
          {` `}
          {t("has deleted this evaluation and you no longer can view it.")}
        </TypographyComponent>
      }
      footer={
        <Button rootClassName="btn-primary" onClick={handleClick}>
          {t("BACK TO MY EVALUATIONS")}
        </Button>
      }
      open={isOpen}
      title={t("This evaluation has been deleted")}
      variant="dialog"
    />
  );
};

export default InitDeletedDialogComponent;
