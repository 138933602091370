import React, { useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { ScoringMode } from "gx-npm-lib";

import TableCarouselHeaderRowCol from "../../../../../../ui/table-carousel-v2/header/table-carousel-header-row-col.component";
import ScorecardReqCatItems from "./scorecard-req-cat-items/scorecard-req-cat-items.component";
import { Requirement, Product } from "../../../../../../app.types";
import styles from "./scorecard-requirements.module.scss";
import ScorecardReqCat from "./scorecard-req-cat/scorecard-req-cat.component";
import { CarouselContext } from "../../carousel.context";

type ScorecardRequirementsProps = {
  buffer: number;
  visibilityDelayMs: number;
  requirements: Requirement[];
  onAvatarClick: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
  displayedProducts: Product[];
};

const ScorecardRequirementsComponent = ({
  buffer = 0,
  visibilityDelayMs = 500,
  requirements,
  onAvatarClick,
  isOwner,
  scoringMode,
  displayedProducts = [],
}: ScorecardRequirementsProps) => {
  const reqCatRowRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const displayedProductCount = displayedProducts.length;

  const { setDockedReqCatIdx } = useContext(CarouselContext);

  useEffect(() => {
    const handleScroll = () => {
      setDockedReqCatIdx(-1);

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        let closestIdx = -1;
        let closestDistance = Infinity;

        reqCatRowRefs.current.forEach((row, index) => {
          if (row) {
            const { top } = row.getBoundingClientRect();
            const distance = Math.abs(top - buffer);

            if (distance < closestDistance && top <= buffer) {
              closestDistance = distance;
              closestIdx = index;
            }
          }
        });

        if (closestIdx !== -1) {
          setDockedReqCatIdx(closestIdx);
        }
      }, visibilityDelayMs);
    };

    const handleScrollWithRAF = () => {
      requestAnimationFrame(handleScroll);
    };
    window.addEventListener("scroll", handleScrollWithRAF, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScrollWithRAF);
    };
  }, [visibilityDelayMs, buffer, setDockedReqCatIdx]);

  return (
    <>
      {requirements.map((requirement, index, arr) => {
        const { id, items } = requirement;

        return (
          <TableCarouselHeaderRowCol
            key={id.toString() || index.toString()}
            rootClass={classNames(
              "gx-req-root-head-row",
              styles.reqRootHeadRow,
              styles.reqScoringAssignment,
              arr.length - 1 === index && "gx-last-req-root"
            )}
          >
            <div>
              <div ref={(el) => (reqCatRowRefs.current[index] = el)}>
                <ScorecardReqCat
                  reqCat={requirement}
                  reqCatCount={requirements.length}
                  index={index}
                  isOwner={isOwner}
                  scoringMode={scoringMode}
                  onAvatarClick={onAvatarClick}
                  displayedProductsCount={displayedProductCount}
                />
              </div>
              <ScorecardReqCatItems index={index} reqItems={items} displayProductsCount={displayedProductCount} />
              {index === 0 && displayedProductCount === 0 && <div className={styles.rightBorderTop} />}
              {index === requirements.length - 1 && displayedProductCount === 0 && (
                <div className={styles.rightBorderBottom} />
              )}
            </div>
          </TableCarouselHeaderRowCol>
        );
      })}
    </>
  );
};

export default ScorecardRequirementsComponent;
