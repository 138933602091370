import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowIcon } from "gx-npm-icons";
import { defaultToZero } from "gx-npm-lib";
import { toggleTextStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";

const selector = "gx-toggle-text";
const propTypes = {
  baseLabel: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const ToggleText = ({ rootClassName = "", text = "" }) => {
  const [heightCurrent, setHeightCurrent] = useState(0);
  const [heightMax, setHeightMax] = useState(0);
  const [heightMin, setHeightMin] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const element = document.querySelector(`.${selector}`);
    const heightClient = defaultToZero(element?.clientHeight);
    const scrollClient = defaultToZero(element?.scrollHeight);
    if (heightClient !== scrollClient) {
      setIsOverflow(true);
      setHeightMax(scrollClient);
      setHeightMin(heightClient);
      setHeightCurrent(heightClient);
    }
  }, [text]);

  const handleClickBtn = () => {
    setHeightCurrent(isExpanded ? heightMin : heightMax);
    setIsExpanded((prev) => !prev);
  };

  const expandLabel = isExpanded ? t("See less") : t("See more");
  const classes = useStyles();
  return (
    <div className={classNames(rootClassName)}>
      <div
        className={classNames(
          text ? selector : "gx-toggle-no-text",
          classes.toggleTextDisplay,
          !isExpanded && classes.toggleTextCollapsed
        )}
        style={heightCurrent ? { height: `${heightCurrent}px` } : {}}
      >
        {!text && t("No objectives set.")}
        {!!text && text}
      </div>
      {isOverflow && (
        <div className={classNames(classes.toggleTextLink)}>
          <button className="btn-toggle" onClick={handleClickBtn}>
            {expandLabel}
            <span className={classNames(classes.toggleTextArrow, isExpanded && classes.toggleInvertedArrow)}>
              <ArrowIcon fillPath={colorPalette.interactions.blueBerry.hex} />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

ToggleText.propTypes = propTypes;
export { ToggleText };
