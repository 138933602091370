import { KeyboardEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductLogo, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { updateStateSubject } from "gx-npm-messenger-util";
import { SelectionContext } from "../../../../selection.context";
import * as singleSpa from "single-spa";
import classNames from "classnames";
import styles from "./selected-vendor.module.scss";
import { SelectedVendorIcon } from "gx-npm-icons";

const SelectedVendor = () => {
  const { t } = useTranslation();
  const { initiativeId, awardedProduct } = useContext(SelectionContext);

  const { id, name, imageLoc, overallScoreDisplay } = awardedProduct;
  const navigateToVendorProfile = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/product/${id}/profile`);
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "selection" });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      navigateToVendorProfile();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.selectedVendorTitle}>
          {t("Selected vendor")}
        </TypographyComponent>
      </div>
      <div className={classNames(styles.productContainer)}>
        <TooltipV2
          title={t("Click to view profile")}
          placement="top-start"
          PopperProps={{ modifiers: { offset: { offset: "-50, 12" } } }}
          enterDelay={1500}
          enterNextDelay={1500}
          rootClassName={"gx-selected-vendor-tooltip"}
        >
          <div
            aria-label={t("Vendor logo and name")}
            className={styles.productInfo}
            onClick={navigateToVendorProfile}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            <div className={styles.selectedVendorLogo}>
              <ProductLogo logo={imageLoc} name={name} imageWidth="48px" imageHeight="48px" />
            </div>
            <div className={classNames(styles.selectedVendorName)}>
              <TypographyComponent styling={"p1"} color={"carbon"} boldness={"medium"}>
                {name}
              </TypographyComponent>
            </div>
          </div>
        </TooltipV2>
        <div className={styles.overallScorePill}>
          <TypographyComponent boldness={"medium"} styling={"p4"} color={"coal"}>
            {t("Overall score")}
          </TypographyComponent>
          <TypographyComponent
            styling={"h4"}
            boldness={"medium"}
            color={"carbon"}
          >{`${overallScoreDisplay} / 100`}</TypographyComponent>
        </div>
      </div>
      <div className={styles.selectedTag}>
        <SelectedVendorIcon />
      </div>
    </div>
  );
};

export default SelectedVendor;
