import { colorPalette, weightPalette } from "gx-npm-common-styles";
const styles = {
  infoBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 36px 20px 40px",
    height: "72px",
    backgroundColor: "rgba(221, 221, 221, 0.7)",
    borderRadius: "8px",
    marginBottom: "24px",
    "&.gx-info-bar-disabled": {
      display: "none",
    },
  },
  infoBarLeft: {
    display: "flex",
    alignItems: "center",
  },
  infoBarLeftItem: {
    marginRight: "24px",
  },
  exportImportWrapper: {
    display: "flex",
  },
  infoBarItemText: {
    "&.p3": {
      color: colorPalette.neutrals.iron.hex,
    },
    "& span": {
      color: colorPalette.neutrals.carbon.hex,
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
    },
  },
  requirementsSpanMargin: {
    marginLeft: 16,
  },
  infoBarRight: {
    display: "flex",
    alignItems: "center",
  },
  infoBarRightItem: {
    marginRight: "24px",
  },
  infoBarRightItemVerticalLine: {
    borderLeft: `1px solid ${colorPalette.neutrals.stone.hex}`,
    height: "30px",
  },
  exportImportButtonsVerticalLine: {
    borderLeft: `1px solid ${colorPalette.neutrals.stone.hex}`,
    height: "30px",
    marginLeft: 24,
    marginRight: 24,
  },
};
export { styles };
