import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import RequirementsErrorComponent from "./requirements-error/requirements-error.component";
import { Requirements } from "./requirements.component";

const RequirementsV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_REQUIREMENTS_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  return (
    <TabPanelComponent>
      <Requirements initiativeId={initiativeId} />
      <RequirementsErrorComponent />
    </TabPanelComponent>
  );
};

export default RequirementsV2Component;
