import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { FileTypeXlsIcon, FileTypeXlsxIcon } from "gx-npm-icons";
import { PopoverMenu, TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import { fileTypes } from "../lib";
import { timeFromDateFormat } from "gx-npm-lib";
import { libraryListRowStyles as styles } from "./styles";

const propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  id: PropTypes.string,
  onMenuClick: PropTypes.func,
  reqCount: PropTypes.number,
  uploadBy: PropTypes.string,
  uploadDate: PropTypes.string,
};
const menuItems = [
  { index: 0, name: "Add file to requirements", event: "ADD" },
  { index: 1, name: "Download", event: "DOWNLOAD" },
  { index: 2, name: "Delete", event: "DELETE" },
];
const useStyles = makeStyles(() => styles);
const ImportLibraryListRow = ({
  fileName = "",
  fileType = "",
  id = "",
  onMenuClick = (_event, _id, _fileName) => {},
  reqCount = 0,
  uploadBy = "",
  uploadDate = "",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();
  const handleClick = (event) => {
    onMenuClick(menuItems[event].event, id, fileName);
  };

  return (
    <div
      className={classNames(classes.libListRowWrap, isHovered && "hover")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.libListRowIcon}>
        {[fileTypes.xls, fileTypes.xlsx].indexOf(fileType) > -1 && (
          <TooltipV2
            placement="top"
            PopperProps={{ modifiers: { offset: { offset: "3px, 10px" } } }}
            title={`.${fileType.toLocaleLowerCase()} file`}
          >
            <div>
              {fileType === fileTypes.xls && <FileTypeXlsIcon />}
              {fileType === fileTypes.xlsx && <FileTypeXlsxIcon />}
            </div>
          </TooltipV2>
        )}
      </div>

      <TypographyComponent
        boldness={"medium"}
        styling={"p3"}
        color={"carbon"}
        rootClassName={classNames(classes.libListRowTitle)}
      >
        {fileName}
      </TypographyComponent>
      <TypographyComponent styling={"p4"} color={"iron"} rootClassName={classNames(classes.libListRowCount)}>
        <TypographyComponent boldness={"semi"}>{reqCount}</TypographyComponent>
        <TypographyComponent>{` requirements`}</TypographyComponent>
      </TypographyComponent>
      <div className={classes.libListRowAvatarDate}>
        <TooltipV2 title={uploadBy} placement="top">
          <div>
            <UsernameDisplay isColorInvertedOnHover={true} name={uploadBy} showName={false} />
          </div>
        </TooltipV2>
        <TypographyComponent styling={"p4"}>{timeFromDateFormat(uploadDate)}</TypographyComponent>
      </div>
      <div className={classes.libListRowPopover}>
        <PopoverMenu
          iconType="kebab"
          showSelectedOption={false}
          useIconButton={true}
          menuItems={menuItems}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

ImportLibraryListRow.propTypes = propTypes;
export default ImportLibraryListRow;
