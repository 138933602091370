import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import ImportLibraryListRow from "./importLibraryListRow";
import { libraryListStyles as styles } from "./styles";
import { TypographyComponent } from "gx-npm-ui";

const propTypes = {
  isLoading: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      fileExt: PropTypes.string,
      fileLabel: PropTypes.string,
      id: PropTypes.string,
      processedDate: PropTypes.string,
      requirementCount: PropTypes.number,
      user: PropTypes.string,
    })
  ),
  onMenuClick: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ImportLibraryList = ({ isLoading = false, list = [], onMenuClick = (_event, _id, _fileName) => {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const listCount = Array.isArray(list) && list.length;
  return (
    <section className={classes.listContainer}>
      <TypographyComponent styling={"p4"} color={"iron"} className={classNames(classes.listTitle)}>
        {isLoading && t("Files loading...")}
        {!isLoading && (
          <Fragment>
            {listCount || t("No")}
            {t(" files uploaded")}
          </Fragment>
        )}
      </TypographyComponent>
      {!!listCount &&
        list.map((item) => {
          return (
            <ImportLibraryListRow
              key={item.id}
              fileName={item.fileName}
              fileType={item.fileExtension}
              id={item.id}
              onMenuClick={onMenuClick}
              reqCount={item.requirementCount}
              uploadBy={item.user}
              uploadDate={item.processedDate}
            />
          );
        })}
    </section>
  );
};

ImportLibraryList.propTypes = propTypes;
export default ImportLibraryList;
