import React from "react";
import PropTypes from "prop-types";
import { listSelectionStyles as styles } from "./list-selection.styles";
import { makeStyles } from "@material-ui/core/styles";
import { TypographyComponent } from "gx-npm-ui";
import SelectionListItem from "./selection-list-item.component";

const propTypes = {
  requirements: PropTypes.array,
  onChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  additionalReqs: PropTypes.bool,
};

const useStyles = makeStyles(() => styles);
const ReqListSection = ({ requirements = [], onChange = () => {}, selectedIds = [], additionalReqs = false }) => {
  const classes = useStyles();

  return (
    <>
      {requirements.map((item, reqIndex) => {
        if (additionalReqs) {
          if (item.itemList.length > 0) {
            return (
              <div key={reqIndex}>
                <div className={classes.reqItemHeader}>
                  <TypographyComponent rootClassName={"req-title"} styling={"p4"}>
                    {item.name} ({item.itemList.length})
                  </TypographyComponent>
                </div>

                {item.itemList.map((subItem, subReqIndex) => (
                  <SelectionListItem
                    requirement={subItem}
                    onChange={onChange}
                    selectedIds={selectedIds}
                    key={subReqIndex}
                  />
                ))}
              </div>
            );
          }
        } else {
          return <SelectionListItem requirement={item} onChange={onChange} selectedIds={selectedIds} key={reqIndex} />;
        }
      })}
    </>
  );
};

ReqListSection.propTypes = propTypes;
export default ReqListSection;
