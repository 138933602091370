// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O6tLFdF7cYEELSY74qCO{margin-top:24px}.sbGBClMyPWa9PLX6ymt4{margin-top:32px;max-width:600px}.sbGBClMyPWa9PLX6ymt4 .tDjjr0FgLidd25RtmFC3{color:var(--color-neutrals-carbon)}.sbGBClMyPWa9PLX6ymt4 .VJajgxL9TxTOS1qzwzTs{margin-top:16px}.sbGBClMyPWa9PLX6ymt4 .VJajgxL9TxTOS1qzwzTs .y33kjgphbBFJx6gxmow5{margin:12px 0 0}.kMikJbrJjPjSu1SY9ftL{margin-top:32px}.kDo9igTOnGWlgY8YYx26{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/survey/quick-start/viewing-responses/viewing-responses.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAEA,kEACE,eAAA,CAKN,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".viewResponsesImageContainer {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n\n    .link {\n      margin: 12px 0 0;\n    }\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewResponsesImageContainer": `O6tLFdF7cYEELSY74qCO`,
	"description": `sbGBClMyPWa9PLX6ymt4`,
	"heading": `tDjjr0FgLidd25RtmFC3`,
	"additionalInfo": `VJajgxL9TxTOS1qzwzTs`,
	"link": `y33kjgphbBFJx6gxmow5`,
	"helpLink": `kMikJbrJjPjSu1SY9ftL`,
	"loader": `kDo9igTOnGWlgY8YYx26`
};
export default ___CSS_LOADER_EXPORT___;
