import { MiniButton } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import styles from "./file-hub-button.styles.module.scss";
import { UUID } from "gx-npm-lib";
import { FileIcon } from "./file.icon";
import { TabName } from "../../../../app.constants";

const FileHubButtonComponent = () => {
  const { t } = useTranslation();
  const { initiativeId = "", tab = "" } = useParams<{ initiativeId: UUID; tab: TabName | "" }>();

  const handleClick = () => {
    sessionStorage.setItem(`file-hub-source-page-${initiativeId}`, tab);
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/files/all`);
  };

  return (
    <MiniButton onClick={handleClick}>
      <div className={styles.documentIcon}>
        <FileIcon />
      </div>
      {t("Evaluation files")}
    </MiniButton>
  );
};

export default FileHubButtonComponent;
