import HeaderContentV2Component from "./header-content-v2.component";
import { HeaderV2ContextProvider } from "./header-v2.context";

const HeaderV2Component = () => {
  return (
    <HeaderV2ContextProvider>
      <HeaderContentV2Component />
    </HeaderV2ContextProvider>
  );
};

export default HeaderV2Component;
