import { postAsyncRequest, PriorityWeightOption, UUID } from "gx-npm-lib";
import {
  DrawerCloseAction,
  ScoredProduct,
  SnackbarBanner,
  WeightedPriority,
  WeightedPriorityDrawerComponent,
} from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductsContext } from "../../products.context";
import { InitiativeProduct } from "../../products.types";
import { WeightedDrawerLocation } from "../../products.constants";

const InitiativeWeightedPrioritiesDrawerComponent = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [currentPriorities, setCurrentPriorities] = useState<WeightedPriority[]>([]);
  const [currentProducts, setCurrentProducts] = useState<ScoredProduct[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [updateUrl, setUpdateUrl] = useState<string>("");
  const {
    gcomCcResId,
    isWeightedPriorityDrawerOpen,
    priorities,
    products,
    setIsWeightedPriorityDrawerOpen,
    setPriorities,
    setProducts,
  } = useContext(ProductsContext);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setUpdateUrl(`/api/v6/initiatives/${initiativeId}/products-ranked`);
  }, [initiativeId]);

  useEffect(() => {
    if (!isWeightedPriorityDrawerOpen || priorities.length === 0) {
      return;
    }
    setCurrentPriorities(priorities.map((p) => ({ ...p })));
  }, [isWeightedPriorityDrawerOpen, priorities]);

  useEffect(() => {
    if (!isWeightedPriorityDrawerOpen || products.length === 0) {
      return;
    }
    setCurrentProducts(products.map((p) => ({ ...p })).filter((p) => !p.addedByUser));
  }, [isWeightedPriorityDrawerOpen, products]);

  const handleClose = async (
    action: DrawerCloseAction,
    params: { priorities: WeightedPriority[]; products: ScoredProduct[] }
  ) => {
    setIsWeightedPriorityDrawerOpen(false);
    if (action !== DrawerCloseAction.SAVE_CHANGES) {
      return;
    }
    setProducts([
      ...params.products.map((p) => {
        const product = products.find((prod) => prod.id === p.id) || {};
        return { ...product, ...p } as InitiativeProduct;
      }),
      ...products.filter((p) => p.addedByUser),
    ]);
    const weightedPriorities = priorities.map((priority) => {
      const weight = params.priorities.find((p) => p.id === priority.id)?.weight || PriorityWeightOption.NONE;
      return { ...priority, weight };
    });
    setPriorities(weightedPriorities);
    const url = `/api/v7/initiatives/${initiativeId}/products/priorities`;
    const response = await postAsyncRequest(url, { weightedPriorities });
    if (response.status !== 201) {
      setHasError(true);
    }
  };

  return (
    <Fragment>
      <WeightedPriorityDrawerComponent
        isOpen={isWeightedPriorityDrawerOpen}
        onClose={handleClose}
        priorities={currentPriorities}
        products={currentProducts}
        updateScoresUrl={updateUrl}
        gcomCcResId={gcomCcResId}
        drawerLocation={WeightedDrawerLocation.INITIATIVE_VENDOR_TAB}
        initiativeId={initiativeId}
      />
      <SnackbarBanner
        isDefaultErrorMessage={true}
        isOpen={hasError}
        setIsOpen={() => setHasError(false)}
        type="ERROR"
      />
    </Fragment>
  );
};

export default InitiativeWeightedPrioritiesDrawerComponent;
