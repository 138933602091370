// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hJ3IgNIs3j0Jmv7zaFIg{align-items:center;display:flex;flex-direction:column;text-align:center;width:100%}.hJ3IgNIs3j0Jmv7zaFIg .YwPiplpDB1s7MFpeyDFu{margin-bottom:36px;margin-top:20px}.hJ3IgNIs3j0Jmv7zaFIg .gsp2_PPksUtkmJGSgBWC{height:166px;margin-bottom:40px;width:193px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/no-products-in-evaluation/no-products-in-evaluation.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CAEA,4CACE,kBAAA,CACA,eAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":[".noProductsWrapper {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  width: 100%;\n\n  & .noProductsButton {\n    margin-bottom: 36px;\n    margin-top: 20px;\n  }\n\n  & .noProductsImg {\n    height: 166px;\n    margin-bottom: 40px;\n    width: 193px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noProductsWrapper": `hJ3IgNIs3j0Jmv7zaFIg`,
	"noProductsButton": `YwPiplpDB1s7MFpeyDFu`,
	"noProductsImg": `gsp2_PPksUtkmJGSgBWC`
};
export default ___CSS_LOADER_EXPORT___;
