import { colorPalette } from "gx-npm-common-styles";

const tabSectionLoaderStyles = {
  loading: {
    backgroundColor: colorPalette.basic.white.hex,
    height: "150px",
    margin: "30px auto",
    width: "100%",
  },
};

export { tabSectionLoaderStyles };
