import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Loader, ProductLogo, SlideInOverlay, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./survey-team-management.styles";
import AddSurveyTeamMemberForm from "./components/add-survey-team-member-form.component";
import SurveyTeamList from "./components/survey-team-list.component";
import { getApiUrl } from "../../../context/actions/actionUtils";
import { getRequest, postRequest } from "../../../context/actions/apiRequests";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../../app.constants";

const useStyles = makeStyles(() => styles);

const proptypes = {
  initiativeId: propTypes.string,
  initName: propTypes.string,
  surveyId: propTypes.string,
  isOpen: propTypes.bool,
  onClose: propTypes.func,
  vendorData: propTypes.shape({ id: propTypes.string, imageLoc: propTypes.string, name: propTypes.string }),
  reloadSurveyData: propTypes.func,
};
const SurveyTeamManagement = ({
  initiativeId,
  initName,
  surveyId,
  isOpen,
  onClose = () => {},
  vendorData,
  reloadSurveyData = () => {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isError, setIsError] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [resendEmailSuccess, setResendEmailSuccess] = useState("");
  const [addMemberSuccess, setAddMemberSuccess] = useState("");
  const [loadList, setLoadList] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setLoadList(true);
  }, [isOpen]);

  useEffect(() => {
    if (!initiativeId || !surveyId || !vendorData.id || !loadList) {
      return;
    }

    setIsLoadingList(true);

    const loadSurveyTeamData = async () => {
      const url = getApiUrl(initiativeId, "survey-team/" + surveyId + "/" + vendorData.id);
      const response = await getRequest(url);
      if (response.status === 200) {
        setRecipients(response.data?.data);
      } else {
        setIsError(true);
        setRecipients([]);
      }
    };

    loadSurveyTeamData().then(() => {
      setLoadList(false);
      setIsLoadingList(false);
    });
  }, [initiativeId, surveyId, vendorData.id, loadList]);

  const handleRemoveTeamMember = async (email) => {
    setIsLoadingAction(true);
    const url = getApiUrl(initiativeId, "survey-team/" + surveyId + "/" + vendorData.id + "/remove");
    const response = await postRequest(url, { email });

    if (response.status === 201) {
      setIsLoadingAction(false);
      setLoadList(true);
      reloadSurveyData();
    } else {
      setIsLoadingAction(false);
      setIsError(true);
    }
  };

  const handleResendInvite = async (email) => {
    setIsLoadingAction(true);
    const url = getApiUrl(initiativeId, "survey-team/" + surveyId + "/" + vendorData.id + "/resend-notification");
    const response = await postRequest(url, { email });

    if (response.status === 201) {
      setIsLoadingAction(false);
      setResendEmailSuccess(email);
      setTimeout(() => {
        setResendEmailSuccess("");
      }, 3000);
    } else {
      setIsLoadingAction(false);
      setIsError(true);
    }
  };

  const handleAddMember = async (email) => {
    setIsLoadingAction(true);
    const url = getApiUrl(initiativeId, "survey-team/" + surveyId + "/" + vendorData.id + "/add", 3);
    const response = await postRequest(url, { email });

    if (response.status === 201) {
      setAddMemberSuccess(email);
      setLoadList(true);
      reloadSurveyData();

      setTimeout(() => {
        setAddMemberSuccess("");
      }, 3000);
    } else {
      if (response.status === 500) {
        setIsError(true);
        setAddMemberSuccess(false);
      } else {
        setErrorMsg(response?.data?.message);

        setTimeout(() => {
          setErrorMsg("");
        }, 3000);
      }
    }

    setIsLoadingAction(false);
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_SURVEY_SENT,
        metaData: { initiativeId, surveyId, initProductId: vendorData.id, productName: vendorData.name },
      },
    ]);
  };

  return (
    <SlideInOverlay
      rootClassName={classes.recipientManageOverlay}
      isOpen={isOpen}
      isOverlayNavBar={true}
      onClose={onClose}
      textBackBtn="questionnaire"
      textTitle={initName === "" ? t("Untitled Initiative") : initName}
    >
      <div className={classes.recipientManageHeader}>
        <div className={classes.branding}>
          <ProductLogo logo={vendorData.imageLoc} />
          <h3>{vendorData.name}</h3>
        </div>
        <TypographyComponent rootClassName={classes.instruction} styling={"p2"}>
          {t(
            "Add email addresses for recipients that you would like to complete the questionnaire. You can add or remove contacts at any time."
          )}
        </TypographyComponent>
        <AddSurveyTeamMemberForm
          recipients={recipients}
          isLoading={isLoadingAction}
          onButtonClick={handleAddMember}
          addMemberSuccessful={addMemberSuccess}
          addMemberFailure={errorMsg}
        />
        {isLoadingList && (
          <div>
            <Loader size={42} variant="indeterminate" />
          </div>
        )}
        {!isLoadingList && (
          <SurveyTeamList
            recipients={recipients}
            onRemoveTeamMember={handleRemoveTeamMember}
            onResendInvite={handleResendInvite}
            resendEmailSuccess={resendEmailSuccess}
            addEmailSuccess={addMemberSuccess}
          />
        )}
      </div>
      <div className={classes.recipientManageFooter} />
      <SnackbarBanner isOpen={isError} isDefaultErrorMessage={true} type="ERROR" setIsOpen={setIsError} />
    </SlideInOverlay>
  );
};

SurveyTeamManagement.propTypes = proptypes;
export default SurveyTeamManagement;
