import { Avatar } from "@mui/material";
import { commonTheme } from "gx-npm-common-styles";
import { InitUserRole } from "gx-npm-lib";
import { TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { SelectionContext } from "../../../../../selection.context";
import { TeamMember } from "../../../../../selection.types";
import ToolTippedUsernameComponent from "./tool-tipped-username/tool-tipped-username.component";
import styles from "./team-members-v2.styles.module.scss";

const MAX_MEMBER_DISPLAY = 3;

const TeamMembersV2Component = () => {
  const { teamMembers } = useContext(SelectionContext);
  const [isWindowWidthXl, setIsWindowWidthXl] = useState(false);
  const [members, setMembers] = useState<TeamMember[]>([]);
  const [primeOwner, setPrimeOwner] = useState<TeamMember | null>(null);
  const [showMemberFullName, setShowMemberFullName] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWindowWidthXl(window.innerWidth >= commonTheme.breakpoints.values.xl);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const viewedMember = teamMembers.filter((member) => member?.hasViewed);
    const owner = viewedMember.find((member) => member.role === InitUserRole.OWNER) || null;
    setPrimeOwner(owner);
    setMembers(viewedMember.filter((member) => member.email !== owner?.email));
  }, [teamMembers]);

  useEffect(() => {
    setShowMemberFullName(isWindowWidthXl && members.length < MAX_MEMBER_DISPLAY);
  }, [members, isWindowWidthXl]);

  return (
    <div className={styles.teamMembersRoot}>
      {primeOwner && (
        <div className={styles.primeOwnerWrapper}>
          <UsernameDisplay name={primeOwner.fullName} />
        </div>
      )}
      {members.length > 0 && <ToolTippedUsernameComponent name={members[0].fullName} showName={showMemberFullName} />}
      {members.length === 2 && showMemberFullName && <div className={styles.additionalMemberPadding} />}
      {members.length > 1 && <ToolTippedUsernameComponent name={members[1].fullName} showName={showMemberFullName} />}
      {members.length > 2 && <ToolTippedUsernameComponent name={members[2].fullName} showName={showMemberFullName} />}
      {members.length > MAX_MEMBER_DISPLAY && (
        <TooltipV2
          placement="bottom"
          PopperProps={{ modifiers: { offset: { offset: "2px, 6px" } } }}
          title={members.map((member, idx) => {
            return idx < MAX_MEMBER_DISPLAY ? null : (
              <span key={member.email}>
                {`${member.fullName} `}
                <br />
              </span>
            );
          })}
        >
          <div className={styles.avatarCountWrapper}>
            <Avatar>
              <TypographyComponent boldness="semi" color="coal" styling="p3">
                +{members.length - MAX_MEMBER_DISPLAY}
              </TypographyComponent>
            </Avatar>
          </div>
        </TooltipV2>
      )}
    </div>
  );
};

export default TeamMembersV2Component;
