import { colorPalette } from "gx-npm-common-styles";

const selectionAnimationStyles = {
  animationCopy: {
    color: colorPalette.basic.black.hex,
    textAlign: "center" as const,
    paddingBottom: "50px",
    paddingTop: "75px",
  },
};

export default selectionAnimationStyles;
