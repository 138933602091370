// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Km8m3VNr1PNxLS7H4c4o{margin-top:0}.LqCmbPUCG0nHIzn5oPyt{margin-top:-50px;position:relative;z-index:3}.B9LGM1fePZrksG5kJdAw{color:var(--color-black)}.B9LGM1fePZrksG5kJdAw .aV2_wMvPUk_KyUI7nSqR{margin-bottom:36px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/products-list-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,gBAAA,CACA,iBAAA,CACA,SAAA,CAGF,sBACE,wBAAA,CAEA,4CACE,kBAAA","sourcesContent":[".productWrapper {\n  margin-top: 0;\n}\n\n.productWrapperFTUX {\n  margin-top: -50px;\n  position: relative;\n  z-index: 3;\n}\n\n.content {\n  color: var(--color-black);\n\n  & .dndRoot {\n    margin-bottom: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productWrapper": `Km8m3VNr1PNxLS7H4c4o`,
	"productWrapperFTUX": `LqCmbPUCG0nHIzn5oPyt`,
	"content": `B9LGM1fePZrksG5kJdAw`,
	"dndRoot": `aV2_wMvPUk_KyUI7nSqR`
};
export default ___CSS_LOADER_EXPORT___;
