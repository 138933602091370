import { actionChecklistTransaction } from "../../context/actions/checklistActions";
import { operations } from "../../context/actions/operationTypes";
import { InitUserRole } from "gx-npm-lib";

const interpretChecklistWsMessages = (state, dispatch, wsMessage) => {
  if (wsMessage.event === "CHECKLIST_PHASE_UPDATED") {
    const data = {
      initiativeId: wsMessage.initiativeId,
      key: wsMessage.fieldName,
      noSave: true,
      operation: operations.parentEdit,
      parentId: wsMessage.phaseId,
      parentIndex: findParentIndex(state, wsMessage.phaseId),
      value: wsMessage.fieldValue,
    };
    actionChecklistTransaction(null, dispatch, data);
  } else if (wsMessage.event === "CHECKLIST_ITEM_CREATED") {
    const childIndex = findChildIndex(state, wsMessage.phaseId, wsMessage.itemId);
    if (childIndex >= 0) {
      // avoid a 2nd add for the user who performed the action
      return;
    }
    const data = {
      childId: wsMessage.itemId,
      childIndex: childIndex,
      initiativeId: wsMessage.initiativeId,
      item: { id: wsMessage.itemId },
      noSave: true,
      operation: operations.childAdd,
      parentId: wsMessage.phaseId,
      parentIndex: findParentIndex(state, wsMessage.phaseId),
    };
    actionChecklistTransaction(null, dispatch, data);
  } else if (wsMessage.event === "CHECKLIST_ITEM_DELETED") {
    const childIndex = findChildIndex(state, wsMessage.phaseId, wsMessage.itemId);
    if (childIndex < 0) {
      // avoid a 2nd delete for the user who performed the action
      return;
    }
    const data = {
      childId: wsMessage.itemId,
      childIndex: childIndex,
      initiativeId: wsMessage.initiativeId,
      noSave: true,
      operation: operations.childDelete,
      parentId: wsMessage.phaseId,
      parentIndex: findParentIndex(state, wsMessage.phaseId),
    };
    actionChecklistTransaction(null, dispatch, data);
  } else if (wsMessage.event === "CHECKLIST_ITEM_UPDATED") {
    const parentIndex = findParentIndex(state, wsMessage.phaseId);
    const childIndex = findChildIndex(state, wsMessage.phaseId, wsMessage.itemId);
    const data = {
      childId: wsMessage.itemId,
      childIndex: childIndex,
      initiativeId: wsMessage.initiativeId,
      noSave: true,
      parentId: wsMessage.phaseId,
      parentIndex: parentIndex,
    };
    if (wsMessage.fieldName === "index") {
      data.indexDest = wsMessage.fieldValue;
      data.indexSource = wsMessage.childIndex;
      data.operation = operations.childReorder;
    } else {
      data.key = wsMessage.fieldName;
      data.operation = operations.childEdit;
      data.value = wsMessage.fieldValue;
    }
    actionChecklistTransaction(null, dispatch, data);
  } else if (wsMessage.event === "INITIATIVE_TEAM_MEMBER_ROLE_CHANGED") {
    if (wsMessage.role === InitUserRole.VIEWER) {
      const data = {
        value: wsMessage.email,
        initiativeId: wsMessage.initiativeId,
        operation: operations.removeChecklistAssignment,
      };
      actionChecklistTransaction(state, dispatch, data);
    }
  } else if (wsMessage.event === "INITIATIVE_TEAM_MEMBER_REMOVED") {
    const data = {
      value: wsMessage.email,
      initiativeId: wsMessage.initiativeId,
      operation: operations.removeChecklistAssignment,
    };
    actionChecklistTransaction(state, dispatch, data);
  }
};

const findParentIndex = (state, parentId) => {
  return state.checklist.list.findIndex((x) => x.id === parentId);
};

const findChildIndex = (state, parentId, childId) => {
  const parentIndex = findParentIndex(state, parentId);
  return parentIndex < 0 ? -1 : state.checklist.list[parentIndex].taskList.findIndex((x) => x.id === childId);
};

export default interpretChecklistWsMessages;
