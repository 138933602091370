/**
 * Gets the respective pixel value break point when given a string
 * @param {string} breakPoint value to search for (sm, md, lg, xlg)
 * @returns { integer } integer value of respective key
 */
const getBreakPoint = (breakPoint = "") => {
  const breakPoints = { sm: 1024, md: 1240, lg: 1440, xlg: 1600 };
  return breakPoints[breakPoint] || -1;
};

/**
 * Gets the maximum number of allowable columns at each break point
 * @returns { integer } integer from 1-4
 */

const getMaxCols = () => {
  const width = window?.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth;

  let maxCols = 4;
  if (width >= getBreakPoint("md") && width < getBreakPoint("lg")) {
    maxCols = 3;
  } else if (width >= getBreakPoint("sm") && width < getBreakPoint("md")) {
    maxCols = 2;
  } else if (width < getBreakPoint("sm")) {
    maxCols = 1;
  }
  return maxCols;
};

/**
 * Sets scroll position of table body
 * @param {*} tableRef - the table ref for DOM calculations
 * @param {*} direction - direction for scroll movement. Valid options are left, right, and reset
 * @param {*} currentPosition - the current position where 0 is the starting point
 * @param {*} setCurrentPosition - change the current position state of the parent component
 */

const handleScroll = ({
  tableRef = {},
  direction = "reset",
  currentPosition = 0,
  setCurrentPosition = function () {},
} = {}) => {
  const table = tableRef?.current;

  const getColumnWidth = () => {
    const tBodyCols = table?.querySelector("tbody");
    const col = tBodyCols?.querySelector("td");
    return col?.clientWidth || 1;
  };

  const getNumberVisibleCols = () => {
    const tBodyCols = table?.querySelector("tbody");
    const row = tBodyCols?.querySelector("tr");
    const col = tBodyCols?.querySelector("td");
    return row && col ? Math.round(row?.clientWidth / col?.clientWidth) : 0;
  };

  const getTotalCols = () => {
    const tBodyCols = table?.querySelector("tbody tr");
    return tBodyCols?.querySelectorAll("td")?.length;
  };

  const setScrollPosition = (_direction, newPosition) => {
    const endX = newPosition * getColumnWidth();
    const tableBodyRows = table?.querySelectorAll("tbody tr");

    const scrollDelay = (ms) => {
      return new Promise((res) => setTimeout(res, ms));
    };

    const scrollTableBody = async (start, distance, duration, interval, _directionBody) => {
      const time = duration / interval;
      const amountMove = distance / time;

      for (let x = 0; x <= distance; x += amountMove) {
        for (let j = 0; j < tableBodyRows?.length; j++) {
          tableBodyRows[j].scroll({
            left: direction === "right" ? start + x : start - x,
          });
        }
        await scrollDelay(time);
      }

      for (let j = 0; j < tableBodyRows?.length; j++) {
        tableBodyRows[j].scroll({
          left: endX - 1,
        });
      }
    };

    if (tableBodyRows?.length > 0) {
      scrollTableBody(
        tableBodyRows[0].scrollLeft,
        Math.abs(tableBodyRows[0].scrollLeft - (endX - 1)),
        500,
        30,
        direction
      ).catch((_ignored) => {});
    }

    setCurrentPosition(newPosition);
  };

  if (direction === "right") {
    if (currentPosition < getTotalCols() - getNumberVisibleCols()) {
      setScrollPosition("right", currentPosition + 1);
    }
  } else if (direction === "left") {
    if (currentPosition !== 0) {
      setScrollPosition("left", currentPosition - 1);
    }
  } else if (direction === "reset") {
    setScrollPosition("reset", currentPosition);
  }
};

export { getBreakPoint, getMaxCols, handleScroll };
