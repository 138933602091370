import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { InitProdState } from "gx-npm-lib";
import { Button, MiniButton, TooltipV2, TypographyComponent } from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { surveyStatuses } from "../../../lib";
import ManageSurvey from "./peopleSurvey.svg";
import SendSurvey from "./sendSurvey.svg";
import styles from "./vendors-surveylist-view.styles";
import VendorIconAndTextLink from "./vendor-icon-and-text-link/vendor-icon-and-text-link.component";

const useStyles = makeStyles(() => styles);
const propTypes = {
  initiativeId: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClickAction: PropTypes.func,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      hasRecipients: PropTypes.bool,
      id: PropTypes.string,
      imageLoc: PropTypes.string,
      name: PropTypes.string,
      surveyStatus: PropTypes.oneOf(Object.values(surveyStatuses)),
    })
  ),
};
const VendorsSurveyListView = ({
  isDisabled = false,
  onClickAction = (_data = { action: "", id: "" }) => {},
  vendors = [],
  initiativeId = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.surveyList}>
      <div className={classes.surveyListHeader}>
        <div className={classes.productNameCol}>
          <TypographyComponent styling={"p4"} boldness={"medium"}>
            {t("Vendor")}
          </TypographyComponent>
        </div>
        <div className={classNames(classes.surveyDataTitle, classes.headerAdjustment)}>
          <TypographyComponent rootClassName={"gx-survey-list-access-header"} styling={"p4"} boldness={"medium"}>
            {t("Access")}
          </TypographyComponent>
        </div>
        <div className={classes.surveyDataTitle}>
          <TypographyComponent rootClassName={"gx-survey-list-status-header"} styling={"p4"} boldness={"medium"}>
            {t("Status")}
          </TypographyComponent>
        </div>
      </div>

      {vendors.map((item, index) => {
        const { hasRecipients, id, imageLoc, name, surveyStatus } = item;
        const isScreenedOut = item.productState === InitProdState.SCREENED_OUT;
        return (
          <div
            key={id}
            className={classNames(
              classes.surveyListItem,
              isScreenedOut && classes.surveyListItemScreenedOut,
              index === vendors.length - 1 && "gx-last-survey-product"
            )}
          >
            <div className={classes.productNameCol}>
              <VendorIconAndTextLink
                initiativeId={initiativeId}
                id={id}
                imageLoc={imageLoc}
                name={name}
                isScreenedOut={isScreenedOut}
              />
            </div>
            <div className={classes.surveyData}>
              <TooltipV2
                deactivate={!isDisabled}
                placement="left"
                title={t("This action is only available to evaluation owners.")}
              >
                <div>
                  <MiniButton
                    disabled={isDisabled}
                    onClick={() => onClickAction({ action: "manage", id, imageLoc, name })}
                  >
                    <img alt="" src={hasRecipients ? ManageSurvey : SendSurvey} />
                    {` ${hasRecipients ? t("Manage recipients") : t("Send questionnaire")}`}
                  </MiniButton>
                </div>
              </TooltipV2>
            </div>
            <div className={classes.surveyData}>
              {surveyStatus === surveyStatuses.NONE && (
                <TypographyComponent boldness={"medium"} styling={"p3"}>
                  &mdash;
                </TypographyComponent>
              )}
              {surveyStatus === surveyStatuses.NOT_STARTED && (
                <TypographyComponent boldness={"medium"} styling={"p3"}>
                  {t("Not started")}
                </TypographyComponent>
              )}
              {surveyStatus === surveyStatuses.IN_PROGRESS && (
                <TypographyComponent boldness={"medium"} styling={"p3"}>
                  {t("In progress")}
                </TypographyComponent>
              )}
              {surveyStatus === surveyStatuses.SUBMITTED && (
                <Button
                  onClick={() => onClickAction({ action: "view", id })}
                  rootClassName="btn-secondary gx-view-responses-btn"
                >
                  {t("View responses")}
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

VendorsSurveyListView.propTypes = propTypes;
export default VendorsSurveyListView;
