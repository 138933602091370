import { colorPalette } from "gx-npm-common-styles";

const requirementStyles = {
  container: {
    backgroundColor: colorPalette.neutrals.quartz.hex,
    height: "100%",
    left: 76,
    marginTop: "-6px",
    padding: 0,
    "& .bulkCheckbox ": {
      visibility: "hidden",
    },
    "& .hover .bulkCheckbox": {
      visibility: "visible",
    },
    "&.isBulkOp .bulkCheckbox": {
      visibility: "visible",
    },
    "&.isBulkOp .gx-req-delete-button, &.isBulkOp .gx-req-cat-delete-icon, &.isBulkOp .gx-dnd-icon": {
      visibility: "hidden",
    },
    "&.isBulkOp .gx-req-footer": {
      paddingBottom: "48px",
    },
  },
};

export { requirementStyles };
