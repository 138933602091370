import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { handleEvent } from "gx-npm-lib";
import { Button, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { ListFooter } from "../../../../ui/dragAndDropList/footer";
import { PlusIcon } from "gx-npm-icons";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { requirementListFooterStyles as styles } from "./styles";
import { makeStyles } from "@material-ui/core";

const propTypes = {
  isAdding: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onHandleRequirementAddClick: PropTypes.func,
  onHandleAddFromLibraryClick: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const RequirementListFooter = ({
  isAdding = false,
  isViewOnly = false,
  onHandleRequirementAddClick = null,
  onHandleAddFromLibraryClick = null,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let plusIconFillPathColor = colorPalette.interactions.defaultCta.hex;
  if (isAdding || isViewOnly) {
    plusIconFillPathColor = colorPalette.neutrals.iron.hex;
  }

  return (
    <ListFooter>
      <TooltipV2
        title={t("This action is only available to evaluation owners and contributors.")}
        placement="top"
        deactivate={!isViewOnly}
        rootClassName={classes.addRequirementTooltip}
      >
        <div>
          <Button disabled={isAdding || isViewOnly} onClick={() => handleEvent(onHandleRequirementAddClick)}>
            <PlusIcon fillPath={plusIconFillPathColor} />
            <TypographyComponent element={"span"} boldness={"semi"} color={"inherit"}>
              {t("ADD NEW REQUIREMENT")}
            </TypographyComponent>
          </Button>
        </div>
      </TooltipV2>
      <div className="gx-list-footer-btn-add-vertical-line" />
      <TooltipV2
        title={t("This action is only available to evaluation owners and contributors.")}
        placement="top"
        deactivate={!isViewOnly}
        rootClassName={classes.addLibraryTooltip}
      >
        <div>
          <Button
            rootClassName={classNames("gx-secondary-requirement-library-button", "btn-tertiary")}
            disabled={isAdding || isViewOnly}
            onClick={onHandleAddFromLibraryClick}
          >
            <PlusIcon fillPath={plusIconFillPathColor} />
            <TypographyComponent element={"span"} boldness={"semi"} color={"inherit"}>
              {t("ADD FROM LIBRARY")}
            </TypographyComponent>
          </Button>
        </div>
      </TooltipV2>
    </ListFooter>
  );
};

RequirementListFooter.propTypes = propTypes;
export { RequirementListFooter };
