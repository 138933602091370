import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { listSelectionStyles as styles } from "./list-selection.styles";
import { Checkbox, TypographyComponent } from "gx-npm-ui";
import ExpandButton from "./expand-button";
import { Collapse } from "@material-ui/core";
import ReqListSection from "./req-list-section.component";

const propTypes = {
  requirements: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedAllCheckboxId: PropTypes.arrayOf(PropTypes.number),
  additionalReqs: PropTypes.bool,
};

const useStyles = makeStyles(() => styles);
const ListSelection = ({
  requirements = {},
  selectedIds = [],
  onChange = () => {},
  onSelectAll = () => {},
  selectedAllCheckboxId = [],
  additionalReqs = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [collapsedIndexes, setCollapsedIndexes] = useState([]);

  const onExpandClick = (index) => {
    if (!collapsedIndexes.includes(index)) {
      setCollapsedIndexes((prev) => [...prev, index]);
    } else {
      setCollapsedIndexes((prev) => prev.filter((i) => i !== index));
    }
  };

  const isCollapsed = (index) => {
    return collapsedIndexes.includes(index);
  };

  const renderReqCount = (requirement) => {
    if (additionalReqs) {
      let additionalCount = 0;
      requirement.itemList.forEach((item) => {
        additionalCount = item.itemList.length + additionalCount;
      });

      return additionalCount;
    }
    return requirement.itemList.length;
  };

  return (
    <>
      {requirements.length > 0 && (
        <div className={classes.reqList}>
          <section className="gx-category-reqs">
            {requirements.map((requirement, index) => (
              <div className={classnames(classes.reqSection, isCollapsed(index) ? "gx-is-collapsed" : "")} key={index}>
                <div className={classnames(classes.categoryHeader, isCollapsed(index) && "gx-is-collapsed")}>
                  <div className={classes.categoryToggle}>
                    <ExpandButton expand={!isCollapsed(index)} setExpand={() => onExpandClick(index)} />

                    <TypographyComponent styling={"p1"} boldness={"medium"}>
                      {requirement.name}
                    </TypographyComponent>
                  </div>
                  <div className={classes.reqCount}>
                    <TypographyComponent styling={"p3"} color={"coal"} boldness={"regular"}>
                      <TypographyComponent styling={"span"} boldness={"medium"}>
                        {renderReqCount(requirement)}
                      </TypographyComponent>{" "}
                      requirement
                      {renderReqCount(requirement) === 1 ? "" : "s"}
                    </TypographyComponent>
                  </div>
                </div>
                <Collapse timeout={0} in={!collapsedIndexes.includes(index)}>
                  {renderReqCount(requirement) > 0 && (
                    <div className={classes.selectAll}>
                      <Checkbox
                        inputProps={{ "aria-label": "" }}
                        label={t("Select all")}
                        rootClassName="select-all"
                        onChange={(e) => onSelectAll(e, index)}
                        checked={selectedAllCheckboxId.includes(index)}
                      />
                    </div>
                  )}
                  <ReqListSection
                    onChange={onChange}
                    selectedIds={selectedIds}
                    requirements={requirement.itemList}
                    additionalReqs={additionalReqs}
                  />
                  {renderReqCount(requirement) === 0 && (
                    <div className={classes.noneFound}>
                      <TypographyComponent styling={"p3"}>
                        {t("There are no requirements in this category.")}
                      </TypographyComponent>
                    </div>
                  )}
                </Collapse>
              </div>
            ))}
          </section>
        </div>
      )}
    </>
  );
};

ListSelection.propTypes = propTypes;
export default ListSelection;
