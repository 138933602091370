import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { handleEvent } from "gx-npm-lib";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "./textField";
import { getAutoResizeTextFieldStyles } from "./styles";
import DOMPurify from "dompurify";

const propTypes = {
  maxWidth: PropTypes.number,
  minEditWidth: PropTypes.number,
  minWidth: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  padding: PropTypes.number,
  rootClassName: PropTypes.string,
  value: PropTypes.string,
};

/**
 * creates a hidden element in DOM to determine the size of a text string styled
 * @param {*} value text being measured
 * @returns number value of total DOM width being measured
 */
const calculateDomTextLength = (value) => {
  let width = 0;
  if (value?.length > 0) {
    const text = document.createElement("span");
    document.body.appendChild(text);
    text.style.visibility = "hidden";
    text.style.fontSize = "20px";
    text.style.fontWeight = "600";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.style.width = "auto";
    text.innerHTML = DOMPurify.sanitize(value);
    // when no width return null
    width = text?.clientWidth ? Math.ceil(text.clientWidth) : null;
    document.body.removeChild(text);
  }
  return width;
};

/**
 * calculate what the width of an input field should be based on values
 * @param {*} text length of text in input field that was calculated
 * @param {*} min minimum width of input field
 * @param {*} max maximum width of input field
 * @param {*} pad padding to the right of input text
 */
const calculateInputWidth = (text = 0, min = 50, max = 500, pad = 12) => {
  let width = text + pad;
  if (width < min) {
    width = min;
  } else if (width > max) {
    width = max;
  }
  return width;
};

const AutoResizeTextField = ({
  minEditWidth = 50,
  maxWidth = 512,
  minWidth = 172,
  onBlur,
  onChange,
  padding = 12,
  rootClassName,
  value,
  ...props
}) => {
  const [currValue, setCurrValue] = useState(value);
  const [width, setWidth] = useState(minWidth);

  useEffect(() => {
    const textWidth = calculateDomTextLength(currValue);
    const inputWidth = calculateInputWidth(textWidth, textWidth ? minEditWidth : minWidth, maxWidth, padding);
    setWidth(inputWidth);
  }, [currValue, minEditWidth, minWidth, maxWidth, padding]);

  const handleBlur = (event) => {
    setCurrValue(event?.target?.value);
    handleEvent(onBlur, event);
  };

  const handleChange = (event) => {
    setCurrValue(event?.target?.value);
    handleEvent(onChange, event);
  };

  const useStyles = makeStyles(() => getAutoResizeTextFieldStyles(width));
  const textClasses = useStyles();
  return (
    <div className={classNames(rootClassName, textClasses?.autoResizeContainer)}>
      <TextField
        onBlur={(event) => handleBlur(event)}
        onChange={(event) => handleChange(event)}
        rootClassName="gx-autoresize-textfield"
        value={value}
        {...props}
      />
    </div>
  );
};

AutoResizeTextField.propTypes = propTypes;
export default AutoResizeTextField;
