import { createContext, ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { CarouselContextValue } from "../../../../app.types";
import { getStoredJsonItem, setStoredJsonItem } from "../../../../ui/dragAndDropList/lib";

const CarouselContext = createContext<CarouselContextValue>({} as CarouselContextValue);

const CarouselContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [dockedReqCatIdx, setDockedReqCatIdx] = useState<number>(-1);
  const [reqCatOpenIdxList, setReqCatOpenIdxList] = useState<number[]>([]);

  const { initiativeId = "" } = useParams();

  const updateSessionStorage = (openCopy: number[]) => {
    const sessionStorageId = `initiatives-evaluation-${initiativeId}`;
    const sessionData = getStoredJsonItem(sessionStorageId);
    setStoredJsonItem(sessionStorageId, {
      ...sessionData,
      catReqOpenIdxList: openCopy,
    });
  };

  const onExpandToggleReqCatClick = (clickIndex: number) => {
    const openCopy = reqCatOpenIdxList.includes(clickIndex)
      ? reqCatOpenIdxList.filter((index) => index !== clickIndex)
      : [...reqCatOpenIdxList, clickIndex];

    setReqCatOpenIdxList(openCopy);
    updateSessionStorage(openCopy);
  };

  const contextValue = {
    dockedReqCatIdx,
    reqCatOpenIdxList,
    setDockedReqCatIdx,
    setReqCatOpenIdxList,
    onExpandToggleReqCatClick,
  };

  return <CarouselContext.Provider value={contextValue}>{props.children}</CarouselContext.Provider>;
};

export { CarouselContext, CarouselContextProvider };
