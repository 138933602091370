import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { isValidResponse, ScoringLevel } from "gx-npm-lib";
import { SlideInOverlay, SnackbarBanner } from "gx-npm-ui";
import { putRequest } from "../../../../../context/actions/apiRequests";
import { getApiUrl } from "../../../../../context/actions/actionUtils";
import ScoringLevelFooter from "./scoringLevelFooter";
import ScoringLevelSelections from "./scoringLevelSelections";
import ScoringLevelTitle from "./scoringLevelTitle";
import ScoringLevelWarningBanner from "./scoringLevelWarningBanner";
import { scoringContainerStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";

const propTypes = {
  countCats: PropTypes.string,
  countReqs: PropTypes.string,
  initiativeId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  scoringLevel: PropTypes.string,
  requirements: PropTypes.array,
};
const useStyles = makeStyles(() => styles);
const ScoringLevelDrawer = ({
  initiativeId = "",
  isOpen = false,
  onClose = (_level) => {},
  onError = () => {},
  scoringLevel = ScoringLevel.REQUIREMENT,
  requirements = [],
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(ScoringLevel.REQUIREMENT);
  const [isOpenSuccessLevel, setIsOpenSuccessLevel] = useState(false);
  const [countCategories, setCountCategories] = useState("");
  const [countRequirements, setCountRequirements] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (Array.isArray(requirements)) {
      setCountCategories(`${requirements.length}`);
      setCountRequirements(`${requirements.reduce((acc, curr) => acc + (curr.items?.length || 0), 0)}`);
    }
  }, [requirements]);

  useEffect(() => {
    setSelectedLevel(scoringLevel);
  }, [scoringLevel]);

  const handleClickSave = async () => {
    setIsSaving(true);
    const section = "requirements/scoring-level";
    const url = getApiUrl(initiativeId, section, 3);
    const payload = { scoringLevel: selectedLevel };
    const response = await putRequest(url, payload);
    if (isValidResponse(response)) {
      onClose(selectedLevel);
      setIsOpenSuccessLevel(true);
    } else {
      onError();
    }
    setIsSaving(false);
  };

  const handleSelectLevel = (level) => {
    if (ScoringLevel[level]) {
      setSelectedLevel(level);
    }
  };

  const handleCloseCancel = () => {
    setIsSaving(false);
    setSelectedLevel(scoringLevel);
    onClose();
  };
  const classes = useStyles();
  return (
    <Fragment>
      <SlideInOverlay isOpen={isOpen} onClose={handleCloseCancel} textBackBtn={"scorecard"}>
        <section className={classNames(classes.container)}>
          <ScoringLevelTitle />
          <ScoringLevelWarningBanner isShown={scoringLevel !== selectedLevel} />
          <ScoringLevelSelections
            countCats={countCategories}
            countReqs={countRequirements}
            levelCurrent={scoringLevel}
            levelSelected={selectedLevel}
            onSelectLevel={handleSelectLevel}
          />
          <ScoringLevelFooter
            disabled={scoringLevel === selectedLevel}
            isSaving={isSaving}
            onClickCancel={handleCloseCancel}
            onClickSave={handleClickSave}
          />
        </section>
      </SlideInOverlay>
      <SnackbarBanner
        autoHideDuration={3000}
        isOpen={isOpenSuccessLevel}
        message={t("Successfully updated scoring level.")}
        setIsOpen={setIsOpenSuccessLevel}
        type="SUCCESS"
      />
    </Fragment>
  );
};

ScoringLevelDrawer.propTypes = propTypes;
export default ScoringLevelDrawer;
