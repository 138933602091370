import classNames from "classnames";
import { ProductLogo, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import statusCompletedIcon from "../../../../../../assets/images/statusCompleted.svg";
import { ProductsAddCustomOption } from "../../../../products.types";
import styles from "./products-add-dialog-body-option-v2.styles.module.scss";

const ProductsAddDialogBodyOptionV2Component = ({
  imageLoc,
  isInEvaluation,
  isLogoDisplayed,
  message,
  piProductName,
}: ProductsAddCustomOption) => {
  const { t } = useTranslation();

  return (
    <div className={styles.optionsWrapper}>
      {isLogoDisplayed && (
        <div className={styles.logo}>
          <ProductLogo imageHeight="24px" imageWidth="24px" logo={imageLoc} name={piProductName} />
        </div>
      )}

      <TypographyComponent
        rootClassName={classNames(isLogoDisplayed && styles.title, isInEvaluation && styles.alreadyAdded)}
        element={"p"}
      >
        {piProductName || message}
      </TypographyComponent>

      {isInEvaluation && (
        <div className={styles.addedVendor}>
          <TypographyComponent styling={"p4"}>{t("Added")}</TypographyComponent>
          <div className={styles.imageContainer}>
            <img src={statusCompletedIcon} alt={t("vendor added icon")} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsAddDialogBodyOptionV2Component;
