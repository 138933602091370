import { makeStyles } from "@material-ui/core/styles";
import { Button, TypographyComponent } from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import NoVendorsImage from "../../../assets/images/survey/no-vendors.svg";
import styles from "./no-vendors-view.styles";

const propTypes = { onNavigation: PropTypes.func };
const useStyles = makeStyles(() => styles);

const NoVendorsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams();

  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/products`);
  };

  return (
    <div className={classes.noVendors}>
      <TypographyComponent rootClassName={"gx-no-vendors-msg"} boldness={"medium"} styling={"p2"}>
        {t("It looks like there are no active vendors in your eval.")}
      </TypographyComponent>
      <Button onClick={handleClick} rootClassName="btn-secondary">
        {t("Go to vendor list")}
      </Button>
      <div className={classes.noVendorsImg}>
        <img alt="" src={NoVendorsImage} />
      </div>
    </div>
  );
};

NoVendorsView.propTypes = propTypes;
export default NoVendorsView;
