import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { colorPalette } from "gx-npm-common-styles";
import { makeStyles } from "@material-ui/core/styles";
import { TrashcanIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import { listDeleteButtonStyles as styles } from "./styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorPoisonCherry = colorPalette.status.poisonCherry.hex;

const propTypes = {
  alwaysDisplay: PropTypes.bool,
  disabled: PropTypes.bool,
  hover: PropTypes.bool,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  tooltipLeft: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltipTop: PropTypes.string,
  tooltipWidth: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const ListItemButtonDelete = ({
  alwaysDisplay = false,
  disabled = false,
  hover,
  onClick,
  rootClassName = "",
  tooltipText = "Delete",
}) => {
  const classes = useStyles();
  const isVisible = hover || alwaysDisplay;
  return (
    <div className={classNames("gx-dlt-btn", rootClassName, classes.root)}>
      <TooltipV2 title={tooltipText} placement="top">
        <div>
          <IconButton
            disabled={disabled}
            hoverFill={colorPoisonCherry}
            onClick={(e) => handleEvent(onClick, e)}
            transparentBackground
          >
            <TrashcanIcon fillPath={isVisible ? colorIron : "none"} />
          </IconButton>
        </div>
      </TooltipV2>
    </div>
  );
};

ListItemButtonDelete.propTypes = propTypes;
export default ListItemButtonDelete;
