import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MQ } from "../../../../../../selection.types";
import styles from "./magic-quadrant.module.scss";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import QuadrantBox from "./components/quadrant-box/quadrant-box.component";

type MagicQuadrantProps = {
  mq: MQ;
  isExpanded: boolean;
};

const MagicQuadrant = ({ mq, isExpanded }: MagicQuadrantProps) => {
  const { t } = useTranslation();
  const qdPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];
  const noPosition = "NONE";

  const { qdPosition, docURL, qdDesc } = mq;

  if (qdPosition === "") {
    return null;
  }

  return (
    <div className={classNames(styles.root, isExpanded ? styles.isExpanded : "")}>
      <div className={styles.section}>
        <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={styles.sectionTitle}>
          {t("Gartner Magic Quadrant")}
        </TypographyComponent>
      </div>
      <div className={styles.qdWrapper}>
        {qdPosition !== noPosition ? (
          <TypographyComponent styling={"h4"} boldness={"medium"} color={"gartnerBlue"}>
            {qdPosition}
          </TypographyComponent>
        ) : (
          <TypographyComponent styling={"p3"} rootClassName={styles.noMq}>
            {t("There is no Magic Quadrant for this technology.")}
          </TypographyComponent>
        )}
        <div className={styles.qdDetails}>
          <div className={styles.qdBox}>
            {qdPosition !== noPosition &&
              qdPositions.map((position) => <QuadrantBox key={position} position={position} qdPosition={qdPosition} />)}
          </div>
          <div className={styles.qdDescWrapper}>
            {qdDesc && (
              <TypographyComponent styling={"p4"} rootClassName={styles.qdDesc}>
                {qdDesc}
              </TypographyComponent>
            )}
            {docURL && (
              <>
                <TextLink href={docURL} target="_blank" text={t("Learn more about this Magic Quadrant")} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagicQuadrant;
