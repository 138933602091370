import { TypographyComponent } from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import NoProductsImage from "../../../../assets/images/vendors/noProducts.svg";
import ProductsAddV2Component from "../products-add-v2/products-add-v2.component";
import styles from "./products-empty-view-v2.styles.module.scss";

const ProductsEmptyViewV2Component = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={styles.noProducts}>
        <TypographyComponent rootClassName={styles.title} boldness={"medium"} styling={"h4"}>
          {t("Get started by adding vendors you're considering")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.description} element={"p"}>
          {t(
            "Once you have your initial consideration set, you can finalize who is in your shortlist for a deeper evaluation."
          )}
        </TypographyComponent>
        <img alt={t("no products")} src={NoProductsImage} />
      </div>
      <div className={styles.addProductWrapper}>
        <ProductsAddV2Component />
      </div>
    </Fragment>
  );
};

export default ProductsEmptyViewV2Component;
