import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import { ScoringMode } from "gx-npm-lib";

import { ListHeaderExpandButton } from "../../../../../../../ui/dragAndDropList/header";
import EvaluationScorecardAssignmentsAvatar from "../../../evaluation-scorecard-assignments-avatar.component";
import { Requirement } from "../../../../../../../app.types";
import styles from "./scorecard-req-cat.module.scss";
import { CarouselContext } from "../../../carousel.context";

type ScorecardReqCatProps = {
  reqCat: Requirement;
  reqCatCount: number;
  index: number;
  displayedProductsCount: number;
  onAvatarClick?: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
  docked?: boolean;
};

const ScorecardReqCat = ({
  reqCat,
  reqCatCount,
  index,
  displayedProductsCount,
  onAvatarClick,
  isOwner,
  scoringMode,
  docked = false,
}: ScorecardReqCatProps) => {
  const { t } = useTranslation();
  const { onExpandToggleReqCatClick, reqCatOpenIdxList } = useContext(CarouselContext);

  if (!reqCat) {
    return null;
  }

  return (
    <div
      className={classNames(
        "gx-get-element-height",
        displayedProductsCount === 0 && styles.rightBorder,
        (index === 0 || index === reqCatCount - 1) && styles.noRightBorder,
        reqCatOpenIdxList.includes(index) && styles.reqRootCategoryOpen,
        styles.reqRootCategory,
        docked && styles.docked
      )}
    >
      <ListHeaderExpandButton
        expand={reqCatOpenIdxList.includes(index)}
        setExpand={() => onExpandToggleReqCatClick(index)}
      />
      <div className={styles.reqRootCategoryNameWeightWrapper}>
        <TypographyComponent boldness={"medium"} rootClassName={styles.reqRootCategoryName} styling={"p2"}>
          {reqCat.name || <span>{t("Untitled category")}</span>}
        </TypographyComponent>
        <TypographyComponent boldness={"semi"} rootClassName={styles.reqRootCategoryWeight} styling={"p4"}>
          {reqCat.weight}%
        </TypographyComponent>
      </div>
      <EvaluationScorecardAssignmentsAvatar
        categoryEvaluators={reqCat.evaluators}
        id={reqCat.id}
        isOwner={isOwner}
        mode={scoringMode}
        onClick={onAvatarClick}
      />
    </div>
  );
};

export default ScorecardReqCat;
