import { UUID } from "gx-npm-lib";
import { useParams } from "react-router-dom";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import { ProductsContextProvider } from "./products.context";
import ProductsTabContentV2Component from "./products-tab-content-v2.component";

const ProductsTabV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  return (
    <ProductsContextProvider>
      <TabPanelComponent>
        <ProductsTabContentV2Component initiativeId={initiativeId} />
      </TabPanelComponent>
    </ProductsContextProvider>
  );
};

export default ProductsTabV2Component;
