import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorLightBerry = colorPalette.interactions.lightBerry.hex;
const colorWhite = colorPalette.basic.white.hex;

const listContentStyles = {
  root: {
    "& .gx-popover-menu-root.gx-req-popover-menu": {
      height: "unset",
    },
    "& .root-list-item": {
      position: "relative",
      width: "100%",
      "&.highlighted-item, &.highlighted-item textarea": {
        animationDuration: "4s",
        animationIterationCount: "1",
        animationName: "$movedBackground",
        animationTimingFunction: "ease-in-out",
      },
      "& .gx-item-modified": {
        background: colorPalette.status.lemon.hex,
        display: "block",
        height: "100%",
        position: "absolute",
        width: "4px",
        top: 0,
      },
    },
    "& .root-list-item.gx-dnd-item.root-list-item.gx-is-draggable": {
      alignItems: "start",
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& .bulkCheckbox": {
      marginTop: 3,
      marginRight: 2,
    },
    "& .button-filler": {
      order: 2,
      "&:after": {
        content: '""',
        display: "block",
        height: "48px",
        width: "48px",
      },
    },
  },
  emptyContent: {
    color: colorPalette.neutrals.iron.hex,
    height: "58px",
    paddingBottom: "13px",
    paddingLeft: "64px",
    paddingTop: "14px",
  },
  flexChild: {
    display: "inline-flex",
  },
  deleteButton: { marginLeft: "auto", order: 2 },
  selectionBox: {
    backgroundColor: colorPalette.interactions.paleBerry.hex,
    paddingLeft: "58px",
    "& .select-all": {
      "& .gx-checkbox-label": {
        color: colorPalette.interactions.defaultCta.hex,
        fontVariationSettings: weightPalette.bold.variation,
        fontWeight: weightPalette.bold.amount,
      },
    },
  },
  skeletonClass: {
    background: colorPalette.basic.white.hex,
    borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
    height: "52.5px",
    padding: "0 12px",
    width: "100%",
  },
  "@keyframes movedBackground": {
    "0%": { backgroundColor: colorLightBerry },
    "75.0%": { backgroundColor: colorLightBerry },
    "100.0%": { backgroundColor: colorWhite },
  },
};
export { listContentStyles };
