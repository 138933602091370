import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkCircledIcon, CheckmarkDottedCircledOutlineIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { IconButton } from "gx-npm-ui";
const darkPear = colorPalette.status.darkPear.hex;
const propTypes = {
  isComplete: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onClick: PropTypes.func,
  taskId: PropTypes.string,
};
const useStyles = makeStyles(() => ({
  status: {
    float: "left",
    marginLeft: 4,
    "&.complete": {
      "& button:disabled": {
        "& svg path": {
          fill: darkPear,
        },
      },
    },
  },
  icons: {
    alignItems: "center",
    display: "flex",
    pointerEvents: "none",
  },
}));
const ListButtonStatus = ({ isComplete, onClick, isViewOnly = false, taskId = "" }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ariaLabel = isComplete ? t("Checklist item marked complete") : t("Checklist item marked incomplete");
  return (
    <div data-testid={`${taskId}-status`} className={classNames(classes.status, isComplete && "complete")}>
      <IconButton
        ariaLabel={ariaLabel}
        disabled={isViewOnly}
        onClick={(e) => {
          e.preventDefault();
          handleEvent(onClick, e);
        }}
      >
        <div className={classes.icons}>
          {isComplete ? (
            <CheckmarkCircledIcon fillPath={darkPear} title="" />
          ) : (
            <CheckmarkDottedCircledOutlineIcon title="" />
          )}
        </div>
      </IconButton>
    </div>
  );
};

ListButtonStatus.propTypes = propTypes;
export default ListButtonStatus;
