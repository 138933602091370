// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pMy2pRxBTU2MqdwnuWy5{margin-top:40px;display:flex;align-items:center;height:32px}.pMy2pRxBTU2MqdwnuWy5.GatyfLqaBVTlnyswoFSh{height:48px;background-color:var(--color-white);border:1.5px solid var(--color-neutrals-silver);border-radius:4px;box-shadow:0 0 12px 0 rgba(20,19,18,.16);padding:8px 20px;position:fixed;bottom:0;margin-bottom:20px;z-index:1;width:100%;min-width:900px;max-width:1400px}@media(max-width: 1600px){.pMy2pRxBTU2MqdwnuWy5.GatyfLqaBVTlnyswoFSh{width:calc(100% - 186px)}}@media(max-width: 1239px){.pMy2pRxBTU2MqdwnuWy5.GatyfLqaBVTlnyswoFSh{width:calc(100% - 122px)}}`, "",{"version":3,"sources":["webpack://./src/ui/table/filter/filter.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CAEA,2CACE,WAAA,CACA,mCAAA,CACA,+CAAA,CACA,iBAAA,CACA,wCAAA,CACA,gBAAA,CACA,cAAA,CACA,QAAA,CACA,kBAAA,CACA,SAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA,CAGF,0BACE,2CACE,wBAAA,CAAA,CAGJ,0BACE,2CACE,wBAAA,CAAA","sourcesContent":[".root {\n  margin-top: 40px;\n  display: flex;\n  align-items: center;\n  height: 32px;\n\n  &.sticky {\n    height: 48px;\n    background-color: var(--color-white);\n    border: 1.5px solid var(--color-neutrals-silver);\n    border-radius: 4px;\n    box-shadow: 0 0 12px 0 rgba(20, 19, 18, 0.16);\n    padding: 8px 20px;\n    position: fixed;\n    bottom: 0;\n    margin-bottom: 20px;\n    z-index: 1;\n    width: 100%;\n    min-width: 900px;\n    max-width: 1400px;\n  }\n\n  @media (max-width: 1600px) {\n    &.sticky {\n      width: calc(100% - 186px);\n    }\n  }\n  @media (max-width: 1239px) {\n    &.sticky {\n      width: calc(100% - 122px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pMy2pRxBTU2MqdwnuWy5`,
	"sticky": `GatyfLqaBVTlnyswoFSh`
};
export default ___CSS_LOADER_EXPORT___;
