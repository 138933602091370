import { colorPalette, weightPalette } from "gx-npm-common-styles";

const deleteReqDetailsDialogProductsListStyles = {
  listedItems: {
    "& $productsList $iconContainer .MuiAvatar-root": {
      backgroundColor: colorPalette.neutrals.silver.hex,
      color: colorPalette.neutrals.coal.hex,
      fontSize: "12px",
      fontVariationSettings: weightPalette.medium.variation,
      fontWeight: weightPalette.medium.amount,
      height: "32px",
      letterSpacing: "0.25px",
      lineHeight: "21px",
      paddingTop: "1px",
      width: "32px",
    },
  },
  productsList: {
    display: "flex",
    marginTop: "12px",
  },
  iconContainer: {
    display: "inline-flex",
    marginRight: "12px",
  },
};

export default deleteReqDetailsDialogProductsListStyles;
