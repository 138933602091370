// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cHJl4XzDH0dm0flak3rQ{margin-top:24px}.ZF3gRY3CSY2UVMIyDjBb{margin-top:32px;max-width:600px}.ZF3gRY3CSY2UVMIyDjBb .GUxYwcP_pbHzZV3qd2P9{color:var(--color-neutrals-carbon)}.ZF3gRY3CSY2UVMIyDjBb .ZT4kojLsKtmWkEZhT1mz{margin-top:16px}.dg9y5bY4fHSau9nOspQz{margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/scorecard-header/quickstart-guide/assigning-scorers/assigning-scorers.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAIJ,sBACE,eAAA","sourcesContent":[".assigningScorersImg {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assigningScorersImg": `cHJl4XzDH0dm0flak3rQ`,
	"description": `ZF3gRY3CSY2UVMIyDjBb`,
	"heading": `GUxYwcP_pbHzZV3qd2P9`,
	"additionalInfo": `ZT4kojLsKtmWkEZhT1mz`,
	"helpLink": `dg9y5bY4fHSau9nOspQz`
};
export default ___CSS_LOADER_EXPORT___;
