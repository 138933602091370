import { PlusIcon } from "gx-npm-icons";
import { InitUserRole, useUserInitAccess } from "gx-npm-lib";
import { Button, TooltipV2 } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductsContext } from "../../../products.context";
import { ProductsAddContext } from "../products-add.context";
import styles from "./products-add-button-v2.styles.module.scss";
import { colorPalette } from "gx-npm-common-styles";

const ProductsAddButtonV2Component = () => {
  const { t } = useTranslation();
  const { setIsAddDialogOpen } = useContext(ProductsAddContext);
  const { initId } = useContext(ProductsContext);
  const { role } = useUserInitAccess(initId);

  return (
    <div className={styles.container}>
      <TooltipV2
        deactivate={role === InitUserRole.OWNER}
        PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
        title={t("This action is only available to evaluation owners.")}
      >
        <div>
          <Button
            ariaLabel={t("add vendor")}
            disabled={role !== InitUserRole.OWNER}
            onClick={() => setIsAddDialogOpen(true)}
            rootClassName="btn-secondary"
          >
            <PlusIcon fillPath={colorPalette.interactions.defaultCta.hex} />
            {t("add vendor")}
          </Button>
        </div>
      </TooltipV2>
    </div>
  );
};

export default ProductsAddButtonV2Component;
