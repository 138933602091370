import classNames from "classnames";
import { PeopleIcon } from "gx-npm-icons";
import { updateStateSubject } from "gx-npm-messenger-util";
import { MiniButton, TooltipV2, useFeatureFlag } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import styles from "./share-button.styles.module.scss";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3787__fileHub } from "../../../../lib/feature-flags";

const ShareButtonComponent = () => {
  const { initiativeId, tab } = useParams();
  const { t } = useTranslation();
  const { role } = useUserInitAccess(initiativeId as UUID);
  const isOwner = role === InitUserRole.OWNER;
  const ffGCOM3787 = useFeatureFlag(GCOM_3787__fileHub);

  const handleClickShare = () => {
    if (window.Intercom) {
      const metadata = { event: "User clicked Share button" };
      window.Intercom("trackEvent", "share-button", metadata);
    }
    updateStateSubject("EVAL_HEADER_SOURCE_PAGE", { sourcePage: "eval" });
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/${tab}/team-management`);
  };

  return (
    <TooltipV2 deactivate={isOwner} placement="left" title={t("This action is only available to evaluation owners.")}>
      <div
        className={classNames(
          styles.shareButton,
          ffGCOM3787 && styles.shareButtonGCOM3787,
          "gx-initiative-share",
          "gx-intercom-initiative-share"
        )}
      >
        <MiniButton disabled={!isOwner} onClick={handleClickShare}>
          <div className={styles.peopleIconClass}>
            <PeopleIcon fill={colorPalette.interactions.blueBerry.hex} />
          </div>
          {t("Manage team")}
        </MiniButton>
      </div>
    </TooltipV2>
  );
};

export default ShareButtonComponent;
