import { FreeTrialRole, useUserState } from "gx-npm-lib";
import { MiniButton, TooltipV2 } from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { getSignedUrl } from "../requirements.lib";
import ExportReqIcon from "./export-req.icon";

const propTypes = { isViewOnly: PropTypes.bool, initiativeId: PropTypes.string, disabled: PropTypes.bool };
const ExportReqButton = ({ isViewOnly = false, initiativeId = "", disabled = false }) => {
  const { t } = useTranslation();
  const { freeTrialRole } = useUserState();
  const isTrialUser = Object.values(FreeTrialRole).includes(freeTrialRole);

  const handleExportReqClick = async () => {
    const url = `api/v2/initiatives/${initiativeId}/requirements/export/draft`;
    const signedUrl = await getSignedUrl(url);
    window.open(signedUrl, "_self");
  };

  const tooltipTitle = isTrialUser
    ? t("Exporting is not available in the Gartner BuySmart free trial.")
    : t("This action is only available to evaluation owners and contributors.");
  return (
    <TooltipV2
      deactivate={!isViewOnly && !isTrialUser}
      placement="top"
      PopperProps={{ modifiers: { offset: { offset: "0, 12" } } }}
      title={tooltipTitle}
    >
      <div>
        <MiniButton
          disabled={disabled || isTrialUser}
          rootClassName="gx-export-req-button"
          onClick={handleExportReqClick}
          variant="default"
        >
          <ExportReqIcon />
          {t("Export")}
        </MiniButton>
      </div>
    </TooltipV2>
  );
};

ExportReqButton.propTypes = propTypes;

export default ExportReqButton;
