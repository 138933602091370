const surveyTabStyles = {
  loadingScreen: {
    paddingTop: "44px",
    paddingBottom: "67px",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
};

export default surveyTabStyles;
