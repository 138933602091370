import React, { Fragment, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TypographyComponent, XDatePicker } from "gx-npm-ui";
import { putAsyncRequest, UUID } from "gx-npm-lib";
import { planningContentSectionV2Styles as styles } from "./planning-content-section-v2.styles";
import { makeStyles } from "@material-ui/core";
import { OverviewContext } from "../../overview.context";
import BudgetTextFieldV2 from "./budget-text-field-v2.component";
import ObjectivesTextFieldV2 from "./objectives-text-field-v2.component";
import { updateEditCount } from "./planning-card-lib";

type PlanningContentSectionV2Props = {
  initiativeId: UUID;
  isViewOnly: boolean;
};

const useStyles = makeStyles(() => styles);
const PlanningContentSectionV2 = ({ initiativeId, isViewOnly = false }: PlanningContentSectionV2Props) => {
  const { vendorSelectionDate, setIsSnackbarOpen, setOverviewEditCount, setVendorSelectionDate } =
    useContext(OverviewContext);
  const updateUrl = `api/v3/initiatives/${initiativeId}`;
  const DEFAULT_DATE = "1970-01-01";
  const { t } = useTranslation();

  const handleDatePickerChange = async (value: string) => {
    try {
      updateEditCount(setOverviewEditCount, 1);
      const putResponse = await putAsyncRequest(updateUrl, { vendorSelectionDate: value });
      if (putResponse.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      setIsSnackbarOpen(true);
    } finally {
      updateEditCount(setOverviewEditCount, -1);
    }
  };

  const classes = useStyles();
  return (
    <Fragment>
      <div className={classNames(classes.planContentRoot)}>
        <div className={classNames(classes.planContent, classes.planContentObjectives)}>
          <div className={classes.objectivesWrapper}>
            <TypographyComponent color={"carbon"} boldness={"semi"}>
              {t("Objectives")}
            </TypographyComponent>
            <ObjectivesTextFieldV2 disabled={isViewOnly} initiativeId={initiativeId} />
          </div>
          <div className={classNames(classes.budgetAndDatePickerWrapper)}>
            <div className={classNames(classes.planBudgetSpacer)}>
              <TypographyComponent color={"carbon"} boldness={"semi"}>
                {t("Budget")}
              </TypographyComponent>
              <div>
                <BudgetTextFieldV2 disabled={isViewOnly} initiativeId={initiativeId} />
              </div>
            </div>
            <div className={classNames(classes.datePickerWrapper)}>
              <TypographyComponent boldness={"semi"} color={"carbon"}>
                {t("Vendor selection date")}
              </TypographyComponent>
              <div className={classes.calendar}>
                <XDatePicker
                  showClearButton={true}
                  value={vendorSelectionDate === DEFAULT_DATE ? null : vendorSelectionDate}
                  placeholder={"Set date"}
                  disabled={isViewOnly}
                  toolTipPlacement={"bottom"}
                  onChange={(value: { isValid: () => never; toISOString: () => never }) => {
                    const formattedDate = value && value.isValid() ? value.toISOString() : DEFAULT_DATE;
                    setVendorSelectionDate(formattedDate);
                    handleDatePickerChange(formattedDate);
                  }}
                  toolTipText={t("This action is only available to evaluation owners.")}
                  showToolTip={isViewOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PlanningContentSectionV2;
