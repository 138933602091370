import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TextField, TypographyComponent } from "gx-npm-ui";
import styles from "./delete-req-details-dialog-body.styles";
import DeleteReqDetailsDialogEvaluatorsList from "./delete-req-details-dialog-evaluators-list.component";
import DeleteReqDetailsDialogProductsList from "./delete-req-details-dialog-products-list.component";
import { RequirementsState } from "gx-npm-lib";

const propTypes = {
  count: PropTypes.number,
  dialogType: PropTypes.oneOf(["category", "items"]),
  evaluators: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  name: PropTypes.string,
  onConfirmationUpdate: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({ imageLoc: PropTypes.string, name: PropTypes.string })),
  reqState: PropTypes.oneOf(["", ...Object.values(RequirementsState)]),
};
const useStyles = makeStyles(() => styles);
const DeleteReqDetailsDialogBody = ({
  count = 0,
  dialogType = "items",
  evaluators = [],
  name = "",
  onConfirmationUpdate = (_isConfirmed) => {},
  products = [],
  reqState = RequirementsState.DRAFT,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    const requiresConfirmation = [RequirementsState.INEDIT, RequirementsState.PUBLISHED].includes(reqState);
    const isValidConfirmationText = confirmText?.toLocaleLowerCase() === "delete";
    onConfirmationUpdate(!requiresConfirmation || isValidConfirmationText);
  }, [confirmText, onConfirmationUpdate, reqState]);

  return (
    <Fragment>
      <div className={classes.bodyTop} role="complementary">
        {dialogType === "category" && (
          <TypographyComponent styling={"p2"}>
            {t("Are you sure you want to delete")}
            <TypographyComponent
              element={"span"}
              className={classes.highlightedBold}
            >{` ${name}? `}</TypographyComponent>
            {t("This will permanently delete this entire category, including all requirements.")}
          </TypographyComponent>
        )}
        {dialogType === "items" && (
          <TypographyComponent styling={"p2"}>
            {t("Are you sure you want to discard the")}
            <TypographyComponent element={"span"} boldness={"semi"}>
              {` ${count} `}
              {count > 1 ? t("requirements") : t("requirement")}
              {`? `}
            </TypographyComponent>
            {count > 1
              ? t("These requirements will be permanently deleted.")
              : t("This requirement will be permanently deleted.")}
          </TypographyComponent>
        )}
      </div>
      <DeleteReqDetailsDialogProductsList products={products} />
      {products.length > 0 && evaluators.length > 0 && <div className={classes.spacer} />}
      <DeleteReqDetailsDialogEvaluatorsList evaluators={evaluators} />
      {[RequirementsState.INEDIT, RequirementsState.PUBLISHED].includes(reqState) && (
        <div className={classes.confirmationContainer}>
          <TypographyComponent rootClassName="gx-delete-textbox-header" styling="p2">
            {`${t("Type “DELETE“ to confirm you want to delete")} ${
              count > 1 ? t("these requirements") : t("this requirement")
            }.`}
          </TypographyComponent>
          <TextField
            autoFocus={true}
            fullWidth={true}
            InputProps={{ "aria-label": t("confirm delete textbox") }}
            label={t(`Type “DELETE“ to confirm`)}
            onChange={(event) => setConfirmText(event.target.value)}
            value={confirmText}
          />
        </div>
      )}
    </Fragment>
  );
};

DeleteReqDetailsDialogBody.propTypes = propTypes;
export default DeleteReqDetailsDialogBody;
