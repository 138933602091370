import { useEffect, useState } from "react";

const currentTimestamp = () => {
  return new Date(Date.now()).getTime();
};

/**
 * returns a random number anytime a scroll event occurs in order to force re-renders in components
 * @returns {number}
 * @deprecated use hook from gx-npm-ui
 */
const useScrollListener = () => {
  const [scrollModulo, setScrollModulo] = useState(0);

  useEffect(() => {
    const delay = 100;
    let startTime = null;
    let timeoutId = null;
    const scrollListener = () => {
      if (startTime === null) {
        startTime = currentTimestamp();
        const timeoutFunction = () => {
          const diff = currentTimestamp() - startTime;
          if (diff >= delay) {
            startTime = null;
            setScrollModulo((prev) => {
              return (prev + 1) % 541 || 1;
            });
          } else {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(timeoutFunction, delay);
          }
        };
        clearTimeout(timeoutId);
        timeoutId = setTimeout(timeoutFunction, delay);
      } else {
        startTime = currentTimestamp();
      }
    };
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);
  return scrollModulo;
};

export default useScrollListener;
