import classNames from "classnames";
import { putAsyncRequest, useUserInitAccess, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { SnackbarBanner, TooltipV2 } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TextField } from "../../../../ui/textFields";
import styles from "./init-title-text-field.styles.module.scss";
import { HeaderV2Context } from "../../header-v2.context";

const CURRENT_INIT_STATE_NAME = "CURRENT_INITIATIVE";
const MAX_TITLE_LENGTH = 60;
const InitTitleTextFieldComponent = () => {
  const { initiativeId } = useParams();
  const { t } = useTranslation();
  const [hasSaveError, setHasSaveError] = useState(false);
  const { initName, setInitName, setIsSavingInitName } = useContext(HeaderV2Context);
  const { role } = useUserInitAccess(initiativeId as UUID);
  const isOwner = role === "OWNER";

  useEffect(() => {
    updateStateSubject(CURRENT_INIT_STATE_NAME, { name: initName || t("Untitled evaluation") });
    return () => {
      updateStateSubject(CURRENT_INIT_STATE_NAME, { name: null });
    };
  }, [initName, t]);

  const handleAutoSave = async (name = "") => {
    setInitName(name);
    setIsSavingInitName(true);
    const response = await putAsyncRequest(`api/v3/initiatives/${initiativeId}`, { name });
    if (response.status !== 200) {
      setHasSaveError(true);
    }
    setIsSavingInitName(false);
  };

  return (
    <Fragment>
      <TooltipV2
        deactivate={isOwner}
        placement="bottom"
        title={t("This action is only available to evaluation owners.")}
      >
        <div className={styles.textFieldWrapper}>
          <TextField
            disabled={!isOwner}
            handleAutoSave={handleAutoSave}
            inputProps={{ maxLength: MAX_TITLE_LENGTH }}
            maxDisplayChars={MAX_TITLE_LENGTH}
            placeHolder={initName ? undefined : t("Untitled evaluation")}
            rootClassName={classNames(styles.textFieldRoot, !isOwner && styles.disabledTextField)}
            setValue={setInitName}
            value={initName}
          />
        </div>
      </TooltipV2>
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasSaveError} setIsOpen={setHasSaveError} type="ERROR" />
    </Fragment>
  );
};

export default InitTitleTextFieldComponent;
