import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./requirement-categories.module.scss";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import { InitProdState, InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import EvaluationComment from "../../../evaluation-comment/evaluation-comment.component";
import { RequirementCategoryType } from "../../../../../../selection.types";
import { SelectionContext } from "../../../../../../selection.context";
import ProductScoreSheet from "./product-scoresheet.component";
import { topThreeProductListByCatScore } from "./requirement-categories-lib";
import classNames from "classnames";
import { CommentType } from "../../../../../../../../app.constants";

type RequirementCategoriesProps = {
  requirementCategories: Readonly<RequirementCategoryType[]>;
};

const RequirementCategories = ({ requirementCategories }: RequirementCategoriesProps) => {
  const { t } = useTranslation();
  const { initiativeId, awardedProduct, products } = useContext(SelectionContext);
  const { role } = useUserInitAccess(initiativeId);
  const isViewOnly = role !== InitUserRole.OWNER;
  const inEvalProducts = products.filter((product) => product.state === InitProdState.IN_EVALUATION);
  let isAsterisk = false;

  return (
    <>
      {requirementCategories.map((reqCategory, reqIdx) => {
        const { topThreeProductList, asterisk } = topThreeProductListByCatScore(
          reqCategory.id as UUID,
          inEvalProducts,
          awardedProduct
        );

        const comment = {
          id: reqCategory.comment.id,
          description: reqCategory.comment.description || "",
          commentType: CommentType.REQUIREMENT_SUMMARY,
          parentId: reqCategory.id as UUID,
        };

        if (asterisk) {
          isAsterisk = true;
        }

        return (
          <div className={styles.categories} key={reqCategory.id || reqIdx}>
            <div className={styles.categoryDetails}>
              <div className={styles.evalRequirement}>
                <TypographyComponent styling={"h5"} boldness={"medium"}>
                  {reqCategory.title}
                </TypographyComponent>
                <TypographyComponent
                  styling={"p3"}
                  color={"coal"}
                  rootClassName={styles.evalRequirementWeight}
                >{`${reqCategory.weight}%`}</TypographyComponent>
              </div>
              <TooltipV2
                deactivate={!isViewOnly}
                placement="left"
                PopperProps={{ modifiers: { offset: { offset: "0, -450px" } } }}
                title={t("This action is only available to the evaluation owners.")}
              >
                <div className={styles.comments}>
                  <EvaluationComment comment={comment} isViewOnly={isViewOnly} />
                </div>
              </TooltipV2>
            </div>
            <div className={styles.categoryTotals}>
              <div className={styles.vendorBox}>
                <ProductScoreSheet
                  asterisk={asterisk}
                  reqCategoryId={reqCategory.id as UUID}
                  products={topThreeProductList}
                />
              </div>
            </div>
          </div>
        );
      })}
      {isAsterisk && (
        <div className={classNames(styles.topContainer, "gx-footer")}>
          <div className={styles.leftContainer} />
          <div className={classNames(styles.categoryStats, "gx-footer")}>
            <TypographyComponent styling={"p3"} rootClassName={"gx-category-stats"}>
              * {awardedProduct.name} {t("was not in the top three vendors for this category.")}
            </TypographyComponent>
          </div>
        </div>
      )}
    </>
  );
};

export default RequirementCategories;
