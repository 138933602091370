const transitionHeightSpeed = "0.75s";

const requirementsTitleStyles = {
  banner: {
    height: "0px",
    maxHeight: "0px",
    transition: `max-height ${transitionHeightSpeed} ease-out`,
    visibility: "hidden",
  },
  bannerV2: {
    height: "0px",
    maxHeight: "0px",
    visibility: "hidden",
    "& button": { visibility: "hidden" },
  },
  bannerShown: {
    "&$banner": {
      height: "unset",
      maxHeight: "100px",
      transition: `max-height ${transitionHeightSpeed} ease-in`,
      visibility: "visible",
    },
  },
  bannerShownV2: {
    "&$bannerV2": {
      height: "unset",
      maxHeight: "100px",
      position: "relative",
      top: "-54px",
      visibility: "visible",
      "& button": {
        visibility: "visible",
      },
    },
  },
  titleRow: {
    display: "inline-block",
  },
};

export default requirementsTitleStyles;
