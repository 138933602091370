// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RaScypeBDlp4XTeo8KdH{margin-bottom:12px}.SMg3TkqxhYLK8QZuaKck .yuCj7s14yCFQ9G3r1TYo{padding-bottom:40px;padding-inline-start:24px}.HX4pbs8TiPR0CFXT73Rg{float:right}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-info-modal/free-trial-info-modal.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,4CACE,mBAAA,CACA,yBAAA,CAGF,sBACE,WAAA","sourcesContent":[".sentenceBottomBreak {\n  margin-bottom: 12px;\n}\n\n.listContainer .list {\n  padding-bottom: 40px;\n  padding-inline-start: 24px;\n}\n\n.footerButton {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentenceBottomBreak": `RaScypeBDlp4XTeo8KdH`,
	"listContainer": `SMg3TkqxhYLK8QZuaKck`,
	"list": `yuCj7s14yCFQ9G3r1TYo`,
	"footerButton": `HX4pbs8TiPR0CFXT73Rg`
};
export default ___CSS_LOADER_EXPORT___;
