import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./animation-selection-view.styles";
import classNames from "classnames";
import { Player } from "@lottiefiles/react-lottie-player";
import selectionAnimation from "../../../../assets/lottie/selectionAnimation.json";
import React, { useContext } from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3755__vendorSelections } from "../../../../lib/feature-flags";
import { SelectionContext } from "../../selection.context";
import Lottie from "react-lottie-player";

const useStyles = makeStyles(() => styles);

type AnimationSelectionViewProps = { onComplete: () => void };
const AnimationSelectionView = ({ onComplete }: AnimationSelectionViewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showAnimation } = useContext(SelectionContext);
  const handleEvent = (event: string) => {
    if (event === "complete") {
      setInterval(onComplete, 1000);
    }
  };

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3755__vendorSelections}>
        <FeatureFlagBooleanOn>
          {showAnimation && (
            <TypographyComponent rootClassName={classNames(classes.animationCopy)}>
              {t("We're gathering your selection summary ...")}
            </TypographyComponent>
          )}
          <Lottie
            play={showAnimation}
            loop={false}
            onComplete={onComplete}
            style={{ height: "437px", width: "349px", margin: "0 auto", display: showAnimation ? "block" : "none" }}
            animationData={selectionAnimation}
          />
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent rootClassName={classNames(classes.animationCopy)}>
            {t("We're gathering your selection summary ...")}
          </TypographyComponent>
          <Player
            autoplay={true}
            onEvent={handleEvent}
            src={selectionAnimation}
            style={{ height: "437px", width: "349px" }}
          />
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default AnimationSelectionView;
