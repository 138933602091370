import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToZero } from "gx-npm-lib";
import { numberOfNumberV2Styles as styles } from "./number-of-number-v2.styles";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";

type NumberOfNumberProps = {
  numOne: number;
  numTwo: number;
  rootClassName?: string;
};

const useStyles = makeStyles(() => styles);
const NumberOfNumberV2 = ({ numOne, numTwo, rootClassName }: NumberOfNumberProps) => {
  const { t } = useTranslation();
  const one = defaultToZero(numOne);
  const two = defaultToZero(numTwo);
  const classes = useStyles();
  return (
    <div className={classNames(classes.numberOfNumberRoot, rootClassName)}>
      <TypographyComponent styling={"h4"}>
        <TypographyComponent boldness={"medium"} element={"span"} styling={"h4"}>
          {one}
        </TypographyComponent>
        <TypographyComponent boldness={"semi"} rootClassName={classes.splitter} element={"span"} styling={"p3"}>
          {t("of")}
        </TypographyComponent>
        <TypographyComponent boldness={"medium"} element={"span"} styling={"h4"}>
          {two}
        </TypographyComponent>
      </TypographyComponent>
    </div>
  );
};

export default NumberOfNumberV2;
