import { colorPalette } from "gx-npm-common-styles";

const colorIronVar = `var(${colorPalette.neutrals.iron.name})`;
const colorQuartzVar = `var(${colorPalette.neutrals.quartz.name})`;
const colorWhiteVar = `var(${colorPalette.basic.white.name})`;

const colorNeutralsStoneRgb = colorPalette.neutrals.stone.rgb;
const screenedOutBgLines = `rgba(${colorNeutralsStoneRgb}, 0.7)`;

const vendorsSurveyListStyles = {
  surveyList: {
    backgroundColor: colorWhiteVar,
    borderRadius: "8px",
  },
  surveyListHeader: {
    display: "flex",
    padding: "16px 40px",
    "&& p": {
      color: colorIronVar,
    },
  },
  surveyListItem: {
    alignItems: "center",
    borderTop: `2px solid ${colorQuartzVar}`,
    color: colorIronVar,
    display: "flex",
    padding: "46px 40px",
  },
  surveyListItemScreenedOut: {
    alignItems: "center",
    backgroundImage: `linear-gradient(135deg, transparent 42.86%, ${screenedOutBgLines} 42.86%, ${screenedOutBgLines} 50%, transparent 50%, transparent 92.86%, ${screenedOutBgLines} 92.86%, ${screenedOutBgLines} 100%)`,
    backgroundSize: "9.90px 9.90px",
    borderTop: `2px solid ${colorQuartzVar}`,
    color: colorIronVar,
    display: "flex",
    padding: "46px 40px",
    "&.gx-last-survey-product": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    "& img.gx-survey-product-logo": {
      filter: "grayscale(100%)",
    },
    "& .mini-btn": {
      backgroundColor: colorWhiteVar,
    },
  },
  headerAdjustment: {
    marginLeft: "16px",
  },
  surveyDataTitle: {
    flex: "1",
    "& img": {
      marginRight: "6px",
    },
  },
  surveyData: {
    flex: "1",
    paddingLeft: "16px",
    whiteSpace: "nowrap",
    "& img": {
      marginRight: "6px",
    },
    "& .Mui-disabled": {
      "& img": {
        filter: "grayscale(100%)",
      },
    },
  },
  productNameCol: {
    flex: "3",
  },
  productBrand: {
    alignItems: "center",
    display: "flex",
    "& img, & svg": {
      marginRight: "24px",
      maxHeight: "32px",
      width: "auto",
    },
    "& .gx-survey-screened-out": {
      color: colorIronVar,
    },
    "&.hover": {
      cursor: "pointer",
      "& p": {
        color: colorPalette.interactions.blackBerry.hex,
        backgroundColor: `rgba(180, 205, 247, 0.60)`,
        borderBottom: `1px solid ${colorPalette.interactions.blackBerry.hex}`,
        textDecoration: "none",
      },
    },
  },
  tooltipRecipients: {
    width: "240px",
  },
};

export default vendorsSurveyListStyles;
