import { useUserInitAccess, useWebSocket, UUID } from "gx-npm-lib";
import { ErrorOverlayV2, FeatureFlagsAvailableContainer, GartnerFooter, useFeatureFlag } from "gx-npm-ui";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GCOM_2831_GCOM_3998__checklistTabReduxRemoval } from "./lib/feature-flags";
import ChecklistV2Component from "./sections/checklist/checklist-v2.component";
import ChecklistTabV3Component from "./sections/checklist-tab-v3/checklist-tab-v3.component";
import HeaderV2Component from "./sections/header-v2/header-v2.component";
import OverviewTabV2 from "./sections/overview-tab-v2/overview-tab-v2.component";
import ProductsTabV2Component from "./sections/products-tab-v2/products-tab-v2.component";
import RequirementsV2Component from "./sections/requirements/requirements-v2.component";
import ScorecardTab from "./sections/scorecard-tab/scorecard-tab.component";
import SelectionTabV2 from "./sections/selection-tab-v2/selection-tab-v2.component";
import SurveyTab from "./sections/survey/survey-tab.component";
import { CustomAppEvent, TabIndex, TabName, WebSocketMessageAction, WebSocketMessageEvent } from "./app.constants";
import styles from "./app.styles.module.scss";

type WebSocketPublishMessage = {
  action: string;
  data: { event: WebSocketMessageEvent; initiativeId: UUID; isViewing: boolean };
};
const AppComponent = () => {
  const { initiativeId = "", tab = "" } = useParams<{ initiativeId: UUID; tab: TabName | "" }>();
  const { hasLoadedAccess } = useUserInitAccess(initiativeId as UUID);
  const [fatalError, setFatalError] = useState(false);
  const [tabIndex, setTabIndex] = useState(TabIndex.OVERVIEW);
  const [wsMessage, wsPublish] = useWebSocket<{ event: WebSocketMessageEvent }, WebSocketPublishMessage>();
  const isChecklistV3FFOn = useFeatureFlag(GCOM_2831_GCOM_3998__checklistTabReduxRemoval);

  useEffect(() => {
    if (tab.toLocaleLowerCase() === "overview") {
      setTabIndex(TabIndex.OVERVIEW);
    } else if (tab.toLocaleLowerCase() === "checklist") {
      setTabIndex(TabIndex.CHECKLIST);
    } else if (tab.toLocaleLowerCase() === "requirements") {
      setTabIndex(TabIndex.REQUIREMENTS);
    } else if (tab.toLocaleLowerCase() === "products") {
      setTabIndex(TabIndex.PRODUCTS);
    } else if (tab.toLocaleLowerCase() === "questionnaire") {
      setTabIndex(TabIndex.SURVEY);
    } else if (tab.toLocaleLowerCase() === "scorecard") {
      setTabIndex(TabIndex.SCORECARD);
    } else if (tab.toLocaleLowerCase() === "selection") {
      setTabIndex(TabIndex.SELECTION);
    }
  }, [tab]);

  const handleUserViewingStatusChange = useCallback(
    (isViewing: boolean) => {
      if (!(initiativeId && hasLoadedAccess)) {
        return;
      }
      const event = WebSocketMessageEvent.VIEWING_INITIATIVE;
      const data = { event, initiativeId: initiativeId as UUID, isViewing };
      wsPublish({ action: WebSocketMessageAction.MESSAGE, data });
    },
    [hasLoadedAccess, initiativeId, wsPublish]
  );

  useEffect(() => {
    const listener = () => setFatalError(true);
    window.addEventListener(CustomAppEvent.FATAL_ERROR, listener);
    return () => {
      window.removeEventListener(CustomAppEvent.FATAL_ERROR, listener);
    };
  }, []);

  useEffect(() => {
    handleUserViewingStatusChange(true);
    return () => {
      handleUserViewingStatusChange(false);
    };
  }, [handleUserViewingStatusChange]);

  useEffect(() => {
    if (!wsMessage || wsMessage.event !== WebSocketMessageEvent.CONNECT) {
      return;
    }
    handleUserViewingStatusChange(true);
  }, [handleUserViewingStatusChange, wsMessage]);

  return (
    <Fragment>
      {!fatalError && (
        <Fragment>
          <div className={styles.appRoot}>
            <HeaderV2Component />
            <FeatureFlagsAvailableContainer>
              {tabIndex === TabIndex.OVERVIEW && <OverviewTabV2 />}
              {tabIndex === TabIndex.CHECKLIST && !isChecklistV3FFOn && <ChecklistV2Component />}
              {tabIndex === TabIndex.CHECKLIST && isChecklistV3FFOn && <ChecklistTabV3Component />}
              {tabIndex === TabIndex.REQUIREMENTS && <RequirementsV2Component />}
              {tabIndex === TabIndex.PRODUCTS && <ProductsTabV2Component />}
              {tabIndex === TabIndex.SURVEY && <SurveyTab />}
              {tabIndex === TabIndex.SCORECARD && <ScorecardTab />}
              {tabIndex === TabIndex.SELECTION && <SelectionTabV2 />}
            </FeatureFlagsAvailableContainer>
          </div>
          <GartnerFooter />
        </Fragment>
      )}
      {fatalError && <ErrorOverlayV2 statusCode={0} />}
    </Fragment>
  );
};

export default AppComponent;
