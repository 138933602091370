import { colorPalette } from "gx-npm-common-styles";

const requirementDrawerTitleStyles = {
  titleContainer: {
    borderBottom: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    paddingBottom: "24px",
    position: "sticky",
    width: "100%",
  },
  title: {
    color: colorPalette.neutrals.carbon.hex,
  },
  subtitle: {
    color: colorPalette.neutrals.iron.hex,
    paddingTop: "8px",
  },
  navContainer: {
    left: "56px",
    paddingBottom: "24px",
    position: "absolute",
    width: "100%",
  },
  libraryNavItem: {
    borderLeft: `solid 4px ${colorPalette.neutrals.silver.hex}`,
    color: colorPalette.neutrals.carbon.hex,
    cursor: "pointer",
    height: "40px",
    paddingLeft: "24px",
    paddingTop: "8px",
    width: "260px",
    "&$isSelected": {
      borderLeft: `solid 4px ${colorPalette.brand.yam.hex}`,
      color: colorPalette.brand.yam.hex,
    },
    "&$isDisabled": {
      color: colorPalette.neutrals.pewter.hex,
      cursor: "default",
    },
  },
  libraryNavAnchor: {
    background: "none",
    border: "none",
    display: "block",
    padding: 0,
    textAlign: "left",
  },
  isDisabled: {},
  isSelected: {},
};

const libraryDrawerStyles = {
  contentWrapper: {
    // 288px = 72px (grey header) 56px x2 (content top/bottom padding) 104px (title header)
    height: "calc(100vh - 288px)",
    overflowY: "auto",
    position: "relative",
  },
  "@global": {
    ".gx-req-lib-slidein .gx-slidein-body": {
      overflow: "hidden",
    },
    ".gx-slidein-body": {
      "& .gx-requirements-library-bulkbar": {
        "& .gx-popover-menu": {
          "& .gx-popover-menu-list": {
            left: "-260px",
            top: "auto",
            bottom: "52px",
          },
        },
      },
    },
  },
  slideIn: {
    "& .gx-slidein-body": {
      transition: "none",
    },
  },
  slideInSecondary: {
    "& .gx-slidein-header": {
      boxShadow: "inset 0px -1px 0px rgba(187, 185, 184, 0.5)",
    },
    "& .gx-slidein-body": {
      backgroundColor: colorPalette.neutrals.quartz.hex,
    },
    "&& .gx-slidein-body": {
      padding: "56px",
      transition: "none",
      minWidth: "870px",
    },
  },
};

export { requirementDrawerTitleStyles, libraryDrawerStyles };
