// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CuKZlHL3KsZ1eKQ3O8xN{background-color:var(--color-white);border-radius:8px;padding:40px;transition:width .5s;width:100%}.SorjbSRl43MMa9FsjXDJ{padding-bottom:20px}.SorjbSRl43MMa9FsjXDJ.DWkV_ldJV3YMHW90l2ob{padding-bottom:0}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/gartner-insights/gartner-insights.module.scss"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACA,iBAAA,CACA,YAAA,CACA,oBAAA,CACA,UAAA,CAGF,sBACE,mBAAA,CAEA,2CACE,gBAAA","sourcesContent":[".root {\n  background-color: var(--color-white);\n  border-radius: 8px;\n  padding: 40px;\n  transition: width 0.5s;\n  width: 100%;\n}\n\n.productInfoContainer {\n  padding-bottom: 20px;\n\n  &.no-technology-type {\n    padding-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CuKZlHL3KsZ1eKQ3O8xN`,
	"productInfoContainer": `SorjbSRl43MMa9FsjXDJ`,
	"no-technology-type": `DWkV_ldJV3YMHW90l2ob`
};
export default ___CSS_LOADER_EXPORT___;
