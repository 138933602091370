import { ResearchRibbonV2Component } from "gx-npm-ui";
import { useContext } from "react";
import { HeaderV2Context } from "../../header-v2.context";

const InitResearchRibbonComponent = () => {
  const { magicQuadResId, marketGuideResId, piMarketSeoName, templateName } = useContext(HeaderV2Context);
  const showAnimation = JSON.parse(localStorage.getItem("showRibbonAnimation") || "false");
  return (
    <ResearchRibbonV2Component
      autoExpand={showAnimation}
      magicQuadrantResId={magicQuadResId}
      marketGuideResId={marketGuideResId}
      piMarketSeoName={piMarketSeoName}
      templateName={templateName}
    />
  );
};

export default InitResearchRibbonComponent;
