import { InitProdState, UUID } from "gx-npm-lib";
import classNames from "classnames";
import ProductScoreBoxComponent from "./product-score-box.component";
import styles from "./eval-summary-table-v2.styles.module.scss";

type ProductSummary = { id: UUID; imageLoc: string; name: string; overallScoreDisplay: string; state: InitProdState };
type EvalSummaryTableV2Props = { initId: UUID; products: ProductSummary[]; sourcePage: "overview" | "selection" };
const EvalSummaryTableV2Component = ({
  initId = "",
  products = [],
  sourcePage = "overview",
}: EvalSummaryTableV2Props) => {
  const awardedProduct = products.find((product) => product.state === InitProdState.AWARDED);
  const inEvalProducts = products.filter((product) => product.state === InitProdState.IN_EVALUATION);

  const awardedMinWidth = 14.285;
  const awardedColWidth = Math.max(awardedMinWidth, 100 / (inEvalProducts.length + 1));

  return (
    <div className={styles.tableContainer}>
      {awardedProduct && (
        <div className={styles.awardedProduct} style={{ width: `${awardedColWidth}%` }}>
          <ProductScoreBoxComponent
            imageLoc={awardedProduct.imageLoc}
            initId={initId}
            initProdId={awardedProduct.id}
            name={awardedProduct.name}
            scoreDisplay={awardedProduct.overallScoreDisplay}
            source={sourcePage}
            state={awardedProduct.state}
            inEvalProductCount={0}
          />
        </div>
      )}
      <div className={classNames(styles.otherProducts, "vendors-" + inEvalProducts.length)}>
        {products
          .filter((product) => [InitProdState.IN_EVALUATION, InitProdState.SCREENED_OUT].indexOf(product.state) > -1)
          .map((product) => {
            return (
              <ProductScoreBoxComponent
                key={product.id}
                imageLoc={product.imageLoc}
                initId={initId}
                initProdId={product.id}
                name={product.name}
                scoreDisplay={product.overallScoreDisplay}
                source={sourcePage}
                state={product.state}
                inEvalProductCount={inEvalProducts.length}
              />
            );
          })}
      </div>
    </div>
  );
};

export default EvalSummaryTableV2Component;
