import blurRef from "./blurRef";
import { computeRequirementsCount, computeAdditionalRequirementsCount } from "./computeRequirementsCount";
import displayBudget from "./displayBudget";
import generateUuid from "./generateUuid";

const parseStringToInt = (value) => {
  if (value) {
    return parseInt(value.replace(/,/g, ""));
  }
  return 0;
};
const getElemRefresh = (target, callback) => {
  if (target) {
    const observer = new MutationObserver(function (_mutations) {
      if (document.contains(target)) {
        if (callback) {
          callback();
        }
        observer.disconnect();
      }
    });
    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });
  }
};

/**
 * takes search result vendors and flags any that are already in initiative
 * @param {Array<{ piProductId: string, piProductName: string }>} vendorsFromSearch list of vendors from search
 * @param {Array<{ name: string,piProductId: string }>} vendorsFromInitiatives list of existing vendors in initiative
 * @returns array of vendorsFromSearch with any matching from vendorsFromInitiative as disabled
 */
const markExistingVendorsDisabled = (vendorsFromSearch, vendorsFromInitiatives) => {
  if (!Array.isArray(vendorsFromSearch)) {
    return [];
  }
  if (!Array.isArray(vendorsFromInitiatives)) {
    return vendorsFromSearch;
  }
  return vendorsFromSearch.map((searchItem) => {
    const isExisitngVendor = vendorsFromInitiatives.some((initItem) => {
      return (
        initItem.piProductId === searchItem.piProductId ||
        initItem.name?.toLowerCase() === searchItem.piProductName?.toLowerCase()
      );
    });

    if (searchItem.piProductId && isExisitngVendor) {
      searchItem.isDisable = true;
    }
    return searchItem;
  });
};

const surveyStatuses = {
  NONE: "",
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  SUBMITTED: "SUBMITTED",
};

const gLibraryReqLevel = {
  DEFAULT: "DEFAULT",
  ADDITIONAL: "ADDITIONAL",
  NONE: "",
};

const customEvents = {
  overviewEditCount: "OVERVIEW_EDIT_COUNT",
  selectionEditCount: "SELECTION_EDIT_COUNT",
  initiativeStatusUpdate: "INITIATIVE_STATUS_UPDATE",
};

const AvailableActions = {
  ADD_PRODUCT_TO_EVAL: "ADD_PRODUCT_TO_EVAL",
  CHOOSE_PRODUCT: "CHOOSE_PRODUCT",
  REMOVE_PRODUCT_FROM_EVAL: "REMOVE_PRODUCT_FROM_EVAL",
  SCREEN_OUT_PRODUCT: "SCREEN_OUT_PRODUCT",
  REVERT_AWARD: "REVERT_AWARD",
};

export {
  customEvents,
  parseStringToInt,
  blurRef,
  computeRequirementsCount,
  computeAdditionalRequirementsCount,
  displayBudget,
  generateUuid,
  getElemRefresh,
  markExistingVendorsDisabled,
  gLibraryReqLevel,
  surveyStatuses,
  AvailableActions,
};
