import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  InitUserRole,
  RequirementsState,
  sumArrayObjects,
  useUserInitAccess,
  useWebSocket,
  useCaptureEventsV2,
} from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import { EvaluationStateContext } from "../../context";
import {
  clearRequirementsSuccesses,
  loadRequirementsData,
  revertAllInEditPublishChanges,
} from "../../context/actions/requirementsActions";
import { TabSectionLoader } from "../../ui/loader";
import BulkOperations from "./drawers/bulkOperations/bulk-operations.component";
import { RequirementsFooter } from "./footer/requirements-footer.component";
import { RequirementsLists } from "./lists";
import { RequirementsTitle } from "./title/requirements-title.component";
import { requirementStyles } from "./requirements.styles";
import { interpretRequirementWsMessages } from "./requirements-ws-interpreter.lib";
import { computeRequirementsCount } from "../../lib";
import RequirementsInfoBar from "./infoBar/requirements-info-bar.component";
import LibraryDrawer from "./drawers/libraryDrawer";
import { libraryDrawerTabIndexes } from "./drawers/libraryDrawer/lib";
import { ClientEvent } from "../../app.constants";

const validWeightAmount = 100;
const determineValidWeight = (weight, list) => {
  return weight === validWeightAmount || (list?.length || 0) === 0;
};

const propTypes = { initiativeId: PropTypes.string };
const useStyles = makeStyles(() => requirementStyles);
const Requirements = ({ initiativeId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [wsMessage] = useWebSocket();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const [bulkOperationIds, setBulkOperationIds] = useState([]);
  const [catCount, setCatCount] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryAdded, setCategoryAdded] = useState(false);
  const [expandedCatId, setExpandedCatId] = useState("");
  const [highlightedItemsIds, setHighlightedItemsIds] = useState([]);
  const [isLibraryDrawerOpen, setIsLibraryDrawerOpen] = useState(false);
  const [isValidWeight, setIsValidWeight] = useState(true);
  const [isPriorityError, setIsPriorityError] = useState(false);
  const [latestWSMessage, setLatestWSMessage] = useState({});
  const [libraryDrawerIndex, setLibraryDrawerIndex] = useState(libraryDrawerTabIndexes.none);
  const [reqCount, setReqCount] = useState(0);
  const [scrollToBotId, setScrollToBotId] = useState("");
  const [scrollToTopId, setScrollToTopId] = useState("");
  const [selectedAllIds, setSelectedAllIds] = useState([]);
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    setCatCount(state.requirements.list.length);
    const cnt = computeRequirementsCount(state.requirements.list);
    setReqCount(cnt);
  }, [state.requirements.list]);

  const handleSnackbarClose = useCallback(() => {
    clearRequirementsSuccesses(null, dispatch);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleSnackbarClose();
    };
  }, [handleSnackbarClose]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    const data = { initiativeId };
    loadRequirementsData(null, dispatch, data);
  }, [dispatch, initiativeId]);

  useEffect(() => {
    if (!state.requirements.reverted) {
      return;
    }
    const data = { initiativeId };
    loadRequirementsData(null, dispatch, data);
  }, [dispatch, initiativeId, state.requirements.reverted]);

  useEffect(() => {
    const weight = Number.parseFloat(sumArrayObjects(state.requirements.list, "weight").toFixed(1));
    setIsValidWeight(determineValidWeight(weight, state.requirements.list));
    const catList = state.requirements.list.map((cat, index) => {
      const name = cat.name || "Untitled Category";
      return { id: cat.id, index, name };
    });
    setCategoryList(catList);
  }, [state.requirements.list]);

  useEffect(() => {
    if (!wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    setLatestWSMessage({ ...wsMessage });
  }, [initiativeId, wsMessage]);

  useEffect(() => {
    if (latestWSMessage.hasBeenInterpreted) {
      return;
    }
    interpretRequirementWsMessages(state, dispatch, latestWSMessage);
    setLatestWSMessage((prev) => {
      return { ...prev, hasBeenInterpreted: true };
    });
  }, [dispatch, initiativeId, latestWSMessage, state]);

  useEffect(() => {
    const id = state.requirements.importedCatIds?.length > 0 ? state.requirements.importedCatIds[0] : "";
    setScrollToTopId(id);
  }, [state.requirements.importedCatIds]);

  useEffect(() => {
    const id = state.requirements.movedCatId || "";
    setScrollToBotId(id);
  }, [state.requirements.movedCatId]);

  useEffect(() => {
    const ids = state.requirements.movedItemIds?.length > 0 ? state.requirements.movedItemIds : [];
    setHighlightedItemsIds(ids);
  }, [state.requirements.movedItemIds]);

  const handleImportExportLibraryDrawerOpen = () => {
    setIsLibraryDrawerOpen(true);
    setLibraryDrawerIndex(libraryDrawerTabIndexes.importExport);
  };

  const handleWeightChange = (value, index) => {
    const sum = sumArrayObjects(state.requirements.list, "weight");
    const currWeight = state.requirements.list?.[index]?.weight || 0;
    let weight = sum - currWeight + (value || 0);
    weight = Number.parseFloat(weight.toFixed(1));
    setIsValidWeight(determineValidWeight(weight, state.requirements.list));
  };

  const handlePublishFinish = (isSuccessful) => {
    if (!isSuccessful && !state.requirements.success) {
      return;
    }
    const data = { initiativeId };
    loadRequirementsData(null, dispatch, data);
  };

  const handleRevertButtonClick = () => {
    if (state.requirements.state !== RequirementsState.INEDIT) {
      return;
    }
    const data = { initiativeId };
    revertAllInEditPublishChanges(state, dispatch, data);
  };

  const selectedReq = (isSelected, operationId) => {
    if (isSelected) {
      if (!bulkOperationIds.includes(operationId)) {
        setBulkOperationIds((prev) => [...prev, operationId]);
      }
    } else {
      setBulkOperationIds((prev) => prev.filter((id) => id !== operationId));
    }
  };

  const selectedAllCheckbox = (isSelected, catId) => {
    if (isSelected) {
      if (!selectedAllIds.includes(catId)) {
        setSelectedAllIds((prev) => [...prev, catId]);
      }
    } else {
      setSelectedAllIds((prev) => prev.filter((id) => id !== catId));
    }
  };

  const handleDeselectAll = () => {
    setBulkOperationIds([]);
    setSelectedAllIds([]);
  };
  const handleSuccessfulBulkOps = (catId = "", ids = []) => {
    setExpandedCatId(catId);
    const data = {
      initiativeId,
      isReloading: true,
      movedCatId: catId,
      movedItemsIds: ids,
    };
    loadRequirementsData(null, dispatch, data);
    setTimeout(() => setExpandedCatId(""), 0);
  };

  const handleAddFromLibraryDrawerOpen = () => {
    setIsLibraryDrawerOpen(true);
    setLibraryDrawerIndex(libraryDrawerTabIndexes.gartnerLibrary);
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_REQUIREMENTS_LIBRARY_VIEWED,
        metaData: { initiativeId },
      },
    ]);
  };
  const handleLibraryDrawerClose = () => {
    setIsLibraryDrawerOpen(false);
    setLibraryDrawerIndex(libraryDrawerTabIndexes.none);
  };
  return (
    <div className={classNames(classes.container, bulkOperationIds.length > 0 && "isBulkOp")}>
      <RequirementsTitle
        hasScorecard={state.requirements.hasScorecard}
        hasSurvey={state.requirements.hasSurvey}
        hasSurveyRecipientViewed={state.requirements.hasSurveyRecipientViewed}
        initiativeId={initiativeId}
        isLoaded={state.requirements.isSuccessfullyLoaded}
        isProcessing={state.requirements.isProcessing}
        isPublishInEdit={state.requirements.state === RequirementsState.INEDIT}
        isValidWeight={isValidWeight}
        onPublishFinish={handlePublishFinish}
        onRevertButtonClick={handleRevertButtonClick}
        role={role}
      />
      <RequirementsInfoBar
        isLoaded={state.requirements.isSuccessfullyLoaded}
        isProcessing={state.requirements.isProcessing}
        catCount={catCount}
        reqCount={reqCount}
        isViewOnly={hasLoadedAccess && role === InitUserRole.VIEWER}
        addFromLibraryBtnClick={handleAddFromLibraryDrawerOpen}
        initiativeId={initiativeId}
        onLibraryDrawerOpen={handleImportExportLibraryDrawerOpen}
      />
      <LibraryDrawer
        categoryList={categoryList}
        initId={initiativeId}
        isOpen={isLibraryDrawerOpen}
        onClose={handleLibraryDrawerClose}
        tabIndexOpened={libraryDrawerIndex}
      />
      {state.requirements.isLoading && <TabSectionLoader />}
      {!state.requirements.isLoading && (
        <RequirementsLists
          categoryAdded={categoryAdded}
          expandedCatId={expandedCatId}
          highlightedItemsIds={highlightedItemsIds}
          initiativeId={initiativeId}
          isValidWeight={isValidWeight}
          isViewOnly={hasLoadedAccess && role === InitUserRole.VIEWER}
          onWeightChange={(value, index) => handleWeightChange(value, index)}
          reqCount={reqCount}
          scrollToBotId={scrollToBotId}
          scrollToTopId={scrollToTopId}
          setCategoryAdded={setCategoryAdded}
          selectedReq={selectedReq}
          selectedBulkOpIds={bulkOperationIds}
          selectedAllCheckbox={selectedAllCheckbox}
          selectedAllCheckboxId={selectedAllIds}
          addFromLibraryBtnClick={handleAddFromLibraryDrawerOpen}
        />
      )}
      <BulkOperations
        bulkOperationIds={bulkOperationIds}
        categoryList={categoryList}
        initiativeId={initiativeId}
        onClearSelected={handleDeselectAll}
        onSuccessUpdate={handleSuccessfulBulkOps}
        reqState={state.requirements.state}
      />
      <RequirementsFooter
        initiativeId={initiativeId}
        isViewOnly={hasLoadedAccess && role === InitUserRole.VIEWER}
        setCategoryAdded={setCategoryAdded}
        addFromLibraryBtnClick={handleAddFromLibraryDrawerOpen}
      />
      <SnackbarBanner
        autoHideDuration={3000}
        isOpen={!!state.requirements.importedCatIds?.length}
        message={`${state.requirements.importedCatIds?.length} ${t("Categories successfully added.")}`}
        type="SUCCESS"
      />
      <SnackbarBanner
        isOpen={isPriorityError}
        setIsOpen={setIsPriorityError}
        type="ERROR"
        isDefaultErrorMessage={true}
      />
    </div>
  );
};

Requirements.propTypes = propTypes;
export { Requirements };
