import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Button, ButtonLoader } from "gx-npm-ui";
import { scoringFooterStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const propTypes = {
  disabled: PropTypes.bool,
  isSaving: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickSave: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ScoringLevelFooter = ({ disabled = false, isSaving = false, onClickCancel = null, onClickSave = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.footer}>
      <div className={classes.footerWrapper}>
        <Button
          disabled={isSaving}
          onClick={onClickCancel}
          rootClassName={classNames(classes.cancelBtn, "btn-tertiary")}
        >
          {t("CANCEL")}
        </Button>
        <ButtonLoader
          btnClass="btn-primary"
          disabled={disabled}
          handleButtonClick={onClickSave}
          isLoading={isSaving}
          rootClassName="btn-block"
        >
          {t("SAVE SCORING LEVEL")}
        </ButtonLoader>
      </div>
    </div>
  );
};

ScoringLevelFooter.propTypes = propTypes;
export default ScoringLevelFooter;
