import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { AdditionIcon } from "gx-npm-icons";
import { listButtenAddStyles as styles } from "./styles";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
};

const useStyles = makeStyles(() => styles);
const ListFooterButtonAdd = ({ disabled = false, label = "", onClick = null, rootClassName = "" }) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      disabled={disabled}
      onClick={(e) => handleEvent(onClick, e)}
      rootClassName={classNames("btn-tertiary", "gx-add-btn", rootClassName)}
    >
      <span className={classNames(classes.btnRoot, disabled && "disabled")}>
        <AdditionIcon fillPath={colorPalette.interactions.defaultCta.hex} />

        <TypographyComponent
          boldness={"semi"}
          color={"inherit"}
          rootClassName={classNames("gx-btn-label", classes.btnLabel)}
        >
          {label}
        </TypographyComponent>
      </span>
    </Button>
  );
};

ListFooterButtonAdd.propTypes = propTypes;
export default ListFooterButtonAdd;
