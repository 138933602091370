import { makeStyles } from "@material-ui/core/styles";
import { InitProdState, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { useContext } from "react";
import * as singleSpa from "single-spa";
import { evaluationCardV2Styles as styles } from "./evaluation-card-v2.styles";
import { OverviewContext } from "../../overview.context";
import EvaluationHeaderV2 from "./evaluation-header-v2.component";
import EmptyVendorListV2 from "./empty-vendor-list-v2.component";
import TopVendorsV2 from "./top-vendors-v2.component";
import AdditionalVendorsV2 from "./additional-vendors-v2.component";

const SHOWN_VENDOR_COUNT = 3;
type EvaluationCardV2Props = { initiativeId: UUID; isViewOnly: boolean };
const useStyles = makeStyles(() => styles);
const EvaluationCardV2 = ({ initiativeId, isViewOnly = false }: EvaluationCardV2Props) => {
  const classes = useStyles();
  const { products } = useContext(OverviewContext);
  const vendors = products.filter((product) => product.state === InitProdState.IN_EVALUATION);
  const hasVendors = vendors.length > 0;
  const handleNavigateToWorkspace = (pid: UUID, destination = "profile") => {
    const url =
      destination === "scoresheet"
        ? `/s/evaluation/${initiativeId}/product/${pid}/scoresheet`
        : `/s/evaluation/${initiativeId}/product/${pid}/profile`;

    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "overview" });
    singleSpa.navigateToUrl(url);
  };
  return (
    <section className={classes.root}>
      <EvaluationHeaderV2 hasVendors={hasVendors} />
      {!hasVendors && <EmptyVendorListV2 />}
      {hasVendors && (
        <TopVendorsV2
          isViewOnly={isViewOnly}
          onClickEvalLink={handleNavigateToWorkspace}
          vendors={vendors.slice(0, SHOWN_VENDOR_COUNT)}
        />
      )}
      {vendors.length > SHOWN_VENDOR_COUNT && (
        <AdditionalVendorsV2
          onClickEvalLink={handleNavigateToWorkspace}
          vendors={vendors.slice(SHOWN_VENDOR_COUNT - vendors.length)}
        />
      )}
    </section>
  );
};
export default EvaluationCardV2;
