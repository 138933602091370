import { colorPalette } from "gx-npm-common-styles";

const DeleteProductButtonIcon = ({ isHighlighted }: { isHighlighted: boolean }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V10C18 8.9 17.1 8 16 8H8C6.9 8 6 8.9 6 10V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z"
        fill={isHighlighted ? colorPalette.status.poisonCherry.hex : colorPalette.neutrals.iron.hex}
      />
    </svg>
  );
};

export default DeleteProductButtonIcon;
