import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Avatar, makeStyles } from "@material-ui/core";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import DeleteReqDetailsLogo from "./delete-req-details-logo.component";
import styles from "./delete-req-details-dialog-products-list.styles";

const propTypes = { products: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })) };
const useStyles = makeStyles(() => styles);
const DeleteReqDetailsDialogProductsList = ({ products = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.listedItems}>
      {!!products.length && (
        <TypographyComponent boldness={"medium"} styling={"p4"}>
          {t("Vendors that have shared questionnaire responses")}
        </TypographyComponent>
      )}
      {!!products.length && (
        <div className={classes.productsList}>
          {products.slice(0, 3).map((product) => {
            return (
              <div key={product.name} className={classes.iconContainer}>
                <DeleteReqDetailsLogo logo={product.imageLoc} name={product.name} />
              </div>
            );
          })}
          {products.length > 3 && (
            <div className={classes.iconContainer}>
              <TooltipV2
                PopperProps={{ modifiers: { offset: { offset: "0, 8" } } }}
                title={products.slice(3).map((extraProduct, extraIndex) => (
                  <span key={`extra-${extraProduct.name}`}>
                    {extraProduct.name}
                    {products.length - 4 !== extraIndex && `,`}
                    <br />
                  </span>
                ))}
              >
                <Avatar>{`+${products.length - 3}`}</Avatar>
              </TooltipV2>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DeleteReqDetailsDialogProductsList.propTypes = propTypes;
export default DeleteReqDetailsDialogProductsList;
