// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cBGdXtjadOALpP0A8KQK{margin-top:24px}.j9hkJ8_XVMI1V3JjGxeQ{margin-top:32px;max-width:600px}.j9hkJ8_XVMI1V3JjGxeQ .WsPPbPHBc98PrmvA6XZS{color:var(--color-neutrals-carbon)}.j9hkJ8_XVMI1V3JjGxeQ .peok9ez6rmgLcDfjev0G{margin-top:16px}.euBt4FTgW5C0TZQVXf4e{margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/scorecard-header/quickstart-guide/calculating-scores/calculating-scores.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAIJ,sBACE,eAAA","sourcesContent":[".calculatingScoresImg {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calculatingScoresImg": `cBGdXtjadOALpP0A8KQK`,
	"description": `j9hkJ8_XVMI1V3JjGxeQ`,
	"heading": `WsPPbPHBc98PrmvA6XZS`,
	"additionalInfo": `peok9ez6rmgLcDfjev0G`,
	"helpLink": `euBt4FTgW5C0TZQVXf4e`
};
export default ___CSS_LOADER_EXPORT___;
