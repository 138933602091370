import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import ExportSpinnerIcon from "./spinnerIcon.icon";
import { processSteps } from "../lib";
import { processIndicatorStyles as styles } from "./styles";
import { TypographyComponent } from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  fileName: PropTypes.string,
  processStep: PropTypes.oneOf(Object.values(processSteps)),
};
const useStyles = makeStyles(() => styles);
const ProcessUploadIndicator = ({ fileName = "", processStep = processSteps.none }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.indicatorContainer} role="progressbar" aria-label={`${t("Step")}-${processStep}`}>
      <TypographyComponent boldness={"medium"} styling={"p3"} element={"p"}>
        {`${t("Step")} ${processStep} ${t("of 2")}`}
      </TypographyComponent>
      <TypographyComponent rootClassName={classNames(classes.divider)} styling={"p3"}>
        {`|`}
      </TypographyComponent>
      <TypographyComponent boldness={"medium"} styling={"p3"}>
        {processStep === processSteps.upload && t("Uploading")}
        {processStep !== processSteps.upload && t("Validating")}
        {` ${fileName} ...`}
      </TypographyComponent>
      <span className={classes.spinIcon}>
        <ExportSpinnerIcon fillPath={colorPalette.interactions.defaultCta.hex} />
      </span>
    </div>
  );
};

ProcessUploadIndicator.propTypes = propTypes;
export default ProcessUploadIndicator;
