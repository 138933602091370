const displayBudget = (budget) => {
  let display = "";
  if (!!budget?.amount || budget?.amount === 0) {
    const style = "currency";
    const currency = budget.currency || "USD";
    const config = {
      currency,
      style,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    };
    const formatter = new Intl.NumberFormat("en-US", config);
    display = formatter.format(budget.amount);
  }
  return display;
};

export default displayBudget;
