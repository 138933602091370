import { colorPalette } from "gx-npm-common-styles";
import React from "react";
import PropTypes from "prop-types";

const propTypes = { title: PropTypes.string };
const TriangleWarningIcon = ({ title }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {title && <title>{title}</title>}
      <path
        d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 16.9997C13.0001 17.552 12.5524 17.9997 12.0001 17.9997C11.4478 17.9997 11.0001 17.552 11.0001 16.9997C11.0001 16.4474 11.4478 15.9997 12.0001 15.9997C12.5524 15.9997 13.0001 16.4474 13.0001 16.9997Z"
        fill={colorPalette.status.darkLemon.hex}
      />
    </svg>
  );
};

TriangleWarningIcon.propTypes = propTypes;
export default TriangleWarningIcon;
