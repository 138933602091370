import { Button, ButtonLoader, Dialog, TypographyComponent } from "gx-npm-ui";
import styles from "./action-dialog.styles.module.scss";

type ActionDialogProps = {
  isLoading: boolean;
  isOpen: boolean;
  labels: {
    title: string;
    body1: { start: string; end: string };
    body2?: string;
    buttons: { cancel: string; confirm: string };
  };
  onClose: () => void;
  onConfirm: () => void;
  productName: string;
};

const ActionDialog = ({
  isLoading = false,
  isOpen = false,
  labels = { title: "", body1: { start: "", end: "" }, buttons: { cancel: "Cancel", confirm: "Confirm" } },
  onClose = () => {},
  onConfirm = () => {},
  productName = "",
}: ActionDialogProps) => {
  const { title, body1, body2, buttons } = labels;

  return (
    <Dialog
      open={isOpen}
      handleClose={onClose}
      title={title}
      rootClassName={styles.root}
      body={
        <div className={styles.body}>
          <div className={styles.bodyFirst}>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {body1.start}
            </TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"medium"}>
              {` ${productName} `}
            </TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {body1.end}
            </TypographyComponent>
          </div>
          <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
            {body2}
          </TypographyComponent>
        </div>
      }
      footer={
        <div className={styles.footer}>
          {!!onClose && (
            <Button onClick={onClose} rootClassName="btn-tertiary">
              {buttons.cancel}
            </Button>
          )}
          {!!onConfirm && (
            <div className={styles.confirm}>
              <ButtonLoader btnClass="primary-destructive-btn" handleButtonClick={onConfirm} isLoading={isLoading}>
                {buttons.confirm}
              </ButtonLoader>
            </div>
          )}
        </div>
      }
    />
  );
};

export default ActionDialog;
