import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Popper } from "@material-ui/core";
import EvaluationPopperData from "./evaluation-popper-data.component";
import { evalPopperTypes } from "./evaluation-popper.type";
import styles from "./evaluation-popper.style";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../../../../app.constants";

const useStyles = makeStyles(() => styles);
const propTypes = {
  anchorEl: PropTypes.any,
  children: PropTypes.any,
  initId: PropTypes.string,
  isOpen: PropTypes.bool,
  isScored: PropTypes.bool,
  onClose: PropTypes.func,
  prodImageLoc: PropTypes.string,
  prodInitId: PropTypes.string,
  prodName: PropTypes.string,
  reqCatId: PropTypes.string,
  reqItemId: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["", ...Object.values(evalPopperTypes)]),
};
const EvaluationPopper = ({
  anchorEl = null,
  initId = "",
  isOpen = false,
  isScored = false,
  onClose = () => {},
  prodImageLoc = "",
  prodInitId = "",
  prodName = "",
  reqCatId = "",
  reqItemId = "",
  subTitle = "",
  title = "",
  type = evalPopperTypes.NONE,
}) => {
  const [arrowRef, setArrowRef] = useState(null);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initId || !isOpen || !prodInitId) {
      return;
    }
    let eventType;
    let metaData;
    if (type === evalPopperTypes.CATEGORY_PROGRESS) {
      eventType = ClientEvent.INITIATIVE_SCORECARD_PRODUCT_CATEGORY_PROGRESS_POPPER_VIEWED;
      metaData = { initiativeId: initId, initProductId: prodInitId, categoryId: reqCatId };
    } else if (type === evalPopperTypes.CATEGORY_SCORE) {
      eventType = ClientEvent.INITIATIVE_SCORECARD_PRODUCT_CATEGORY_SCORE_POPPER_VIEWED;
      metaData = { initiativeId: initId, initProductId: prodInitId, categoryId: reqCatId };
    } else if (type === evalPopperTypes.ITEM_SCORE) {
      eventType = ClientEvent.INITIATIVE_SCORECARD_PRODUCT_ITEM_SCORE_POPPER_VIEWED;
      metaData = { initiativeId: initId, initProductId: prodInitId, categoryId: reqCatId, itemId: reqItemId };
    } else {
      return;
    }

    captureEvents([
      {
        eventType,
        metaData,
      },
    ]);
  }, [captureEvents, initId, isOpen, prodInitId, reqCatId, reqItemId, type]);
  const classes = useStyles();
  const handleRef = (ref) => {
    setArrowRef(ref);
  };
  const offset = type === evalPopperTypes.ITEM_SCORE ? "0, 8" : "0, -4";
  return (
    <Popper
      anchorEl={anchorEl}
      className={classes.root}
      modifiers={{
        arrow: { enabled: true, element: arrowRef },
        flip: { enabled: false },
        offset: { offset },
        preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
      }}
      open={isOpen}
      placement="bottom"
    >
      <EvaluationPopperData
        initId={initId}
        isOpen={isOpen}
        isScored={isScored}
        onClickClose={onClose}
        prodImageLoc={prodImageLoc}
        prodInitId={prodInitId}
        prodName={prodName}
        reqCatId={reqCatId}
        reqItemId={reqItemId}
        subTitle={subTitle}
        title={title}
        type={type}
      />
      <span className="gx-popper-arrow" ref={handleRef} />
    </Popper>
  );
};

EvaluationPopper.propTypes = propTypes;
export default EvaluationPopper;
