import { colorPalette, weightPalette } from "gx-npm-common-styles";

const preSurveyStartViewStyles = {
  root: {
    color: colorPalette.neutrals.carbon.hex,
    display: "flex",
    minWidth: "1024px",
    "& .btn-primary": {
      width: "auto",
    },
  },
  descriptionTab: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    paddingRight: "64px",
    "@media (min-width: 1240px)": {
      width: "39%",
    },
  },
  videoTab: {
    display: "flex",
    flexDirection: "column",
    width: "calc(55% - 64px)",
    justifyContent: "center",
    alignItems: "center",
    "@media (min-width: 1240px)": {
      width: "61%",
      paddingRight: "0",
    },
    "&.isLoaded": {
      alignItems: "inherit",
    },
  },
  loaderWrapper: { position: "absolute" },
  headerBox: {
    maxWidth: "492px",
  },
  orangeText: {
    color: colorPalette.brand.yam.hex,
  },
  bulletList: {
    listStyle: "none",
    paddingLeft: "16px",
    "& li": {
      display: "flex",
      paddingBottom: "16px",
      "&::before": {
        color: colorPalette.brand.yam.hex,
        content: '"\\2022"',
        display: "inline-block",
        fontWeight: weightPalette.bold.amount,
        marginLeft: "-1em",
        width: "1em",
        marginTop: "2px",
      },
      "& .gx-p": {
        color: colorPalette.neutrals.coal.hex,
      },
    },
  },
  btnTooltipWrapper: {
    display: "block",
  },
  btnWrapper: {
    display: "inline",
  },
  tutorialLinkBtn: {
    marginTop: "24px",
  },
};

export default preSurveyStartViewStyles;
