import classNames from "classnames";
import { postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { TextField, useFeatureFlag } from "gx-npm-ui";
import { FormEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CustomAppEvent } from "../../../../../../app.constants";
import { GCOM_4297__emqUpdates } from "../../../../../../lib/feature-flags";
import { CommentType } from "../../../../selection.constants";
import { SelectionContext } from "../../../../selection.context";
import { Comment } from "../../../../selection.types";
import styles from "./evaluation-comment.styles.module.scss";

type EvaluationCommentProps = { comment: Comment; isViewOnly: boolean };
const EvaluationComment = ({ comment, isViewOnly }: EvaluationCommentProps) => {
  const [value, setValue] = useState(comment);
  const { t } = useTranslation();
  const {
    initiativeId: contextInitId,
    awardedProduct,
    setSelectionEditCount,
    setRequirementComment,
  } = useContext(SelectionContext);
  const { initiativeId: paramsInitiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const isEMQFFOn = useFeatureFlag(GCOM_4297__emqUpdates);
  const initiativeId = isEMQFFOn ? paramsInitiativeId : contextInitId;

  const handleCommentUpdate = async (commentValue: {
    id: UUID;
    description: string;
    commentType: CommentType;
    parentId: UUID;
  }) => {
    if (isEMQFFOn) {
      window.dispatchEvent(new CustomEvent(CustomAppEvent.SELECTION_EDIT_COUNT, { detail: { count: 1 } }));
    } else {
      setSelectionEditCount(1);
      setRequirementComment({
        description: value.description,
        id: commentValue.id,
        parentId: commentValue.parentId,
        commentType: CommentType.REQUIREMENT_SUMMARY,
      });
    }
    let payload;
    if (commentValue.commentType === CommentType.EVAL_SUMMARY) {
      payload = { description: commentValue.description };
    } else if (commentValue.commentType === CommentType.REQUIREMENT_SUMMARY) {
      payload = { description: commentValue.description, parentId: commentValue.parentId };
    }
    const url = `api/v3/initiatives/${initiativeId}/products/${awardedProduct?.id}/comments/${commentValue.commentType}/${commentValue.id}`;
    const response = await putAsyncRequest(url, payload);
    if (response.status !== 200) {
      console.error("Error updating comment", response);
    }

    if (isEMQFFOn) {
      window.dispatchEvent(new CustomEvent(CustomAppEvent.SELECTION_EDIT_COUNT, { detail: { count: -1 } }));
    } else {
      setSelectionEditCount(-1);
    }
  };

  const handleCommentCreate = async (commentValue: {
    description: string;
    commentType: CommentType;
    parentId: UUID;
  }) => {
    if (isEMQFFOn) {
      window.dispatchEvent(new CustomEvent(CustomAppEvent.SELECTION_EDIT_COUNT, { detail: { count: 1 } }));
    } else {
      setSelectionEditCount(1);
    }
    let payload;
    if (commentValue.commentType === CommentType.EVAL_SUMMARY) {
      payload = { description: commentValue.description };
    } else if (commentValue.commentType === CommentType.REQUIREMENT_SUMMARY) {
      payload = { description: commentValue.description, parentId: commentValue.parentId };
    }
    const url = `api/v3/initiatives/${initiativeId}/products/${awardedProduct?.id}/comments/${commentValue.commentType}`;
    const response = await postAsyncRequest(url, payload);

    if (response.status === 201) {
      setValue({ ...value, id: response.data.data.id });
    } else {
      console.error("Error creating comment", response);
    }
    if (isEMQFFOn) {
      window.dispatchEvent(new CustomEvent(CustomAppEvent.SELECTION_EDIT_COUNT, { detail: { count: -1 } }));
    } else {
      setSelectionEditCount(-1);
    }
  };

  const handleCommentChange = async (commentValue: {
    id: UUID;
    description: string;
    commentType: CommentType;
    parentId: UUID;
  }) => {
    if (value.id) {
      //update comment
      await handleCommentUpdate({
        id: value.id,
        description: commentValue.description,
        commentType: commentValue.commentType,
        parentId: commentValue.parentId,
      });
    } else {
      //create comment if record does not exist and comment is added
      if (commentValue.description) {
        await handleCommentCreate({
          description: commentValue.description,
          commentType: commentValue.commentType,
          parentId: commentValue.parentId,
        });
      }
    }
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    if (event && event.target) {
      const description = (event.target as HTMLInputElement).value;
      setValue({ ...value, description });
    }
  };

  const handleBlur = async (_event: FocusEvent) => {
    //call change method only when comment is modified
    if (value.description !== comment.description) {
      await handleCommentChange({
        id: comment.id,
        description: value.description,
        commentType: value.commentType,
        parentId: comment.parentId,
      });
    }
  };

  return (
    <TextField
      disabled={isViewOnly}
      fullWidth
      multiline
      multilinev2={true}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={t("Add comments...")}
      rootClassName={classNames(styles.root, styles.inlineTextRoot, isViewOnly && styles.viewOnly)}
      value={value.description}
    />
  );
};

export default EvaluationComment;
