import { isValidResponse } from "gx-npm-lib";
import { postRequest } from "../../../../context/actions/apiRequests";

/**
 * loads delete requirement details
 * @param config {*} expected to always have initId and either reqCatId or reqItemIds
 * @param onSuccess {*} invoked with successful api response with data passed
 */
const loadDeleteReqDialogDetails = async (
  config = { initId: "", reqCatId: "", reqItemIds: [] },
  onSuccess = (_data) => {}
) => {
  const { initId, reqCatId, reqItemIds } = config;
  const reqBody = reqCatId ? { reqCatId } : { reqItemIds };
  const reqType = reqCatId ? "category" : "items";
  const url = `api/v2/initiatives/${initId}/requirements/delete-details/${reqType}`;
  const response = await postRequest(url, reqBody);
  if (isValidResponse(response) && typeof response.data.data === "object") {
    onSuccess({
      evaluators: response.data.data.evaluators || [],
      products: response.data.data.products || [],
    });
  }
};

export { loadDeleteReqDialogDetails };
