import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider, FeatureFlagsAvailableContainer } from "gx-npm-ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { EvaluationStateProvider } from "./context";
import AppComponent from "./app.component";
import HandleRedirects from "./handle-redirect";

const generateClassName = createGenerateClassName({ seed: "Initiatives-App" });
export default function Root() {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <EvaluationStateProvider>
        <StylesProvider generateClassName={generateClassName}>
          <FeatureFlagsAvailableContainer>
            <BrowserRouter>
              <Routes>
                <Route path={"/s/evaluation/:initiativeId"} element={<AppComponent />} />
                <Route path={"/s/evaluation/:initiativeId/:tab"} element={<AppComponent />} />
                <Route path={"/s/evaluation/:initiativeId/:tab/team-management"} element={<AppComponent />} />
                <Route path={"*"} element={<HandleRedirects />} />
              </Routes>
            </BrowserRouter>
          </FeatureFlagsAvailableContainer>
        </StylesProvider>
      </EvaluationStateProvider>
    </FeatureFlagProvider>
  );
}
