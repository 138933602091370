import React, { useState } from "react";
import { Collapse } from "@material-ui/core";
import classNames from "classnames";
import { UUID, ScoringLevel, InitProdState } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";

import EvaluationPopper from "../../../evaluation-popper/evaluation-popper.component";
import { evalPopperTypes } from "../../../evaluation-popper/evaluation-popper.type";
import { ReqItemScoreMap, RequirementItem } from "../../../../../../../app.types";
import styles from "./product-score-carousel-column.module.scss";

type ProductScoreCarouselColumnProps = {
  catId: string;
  catName: string;
  initId: UUID;
  isCarouselPopperOpen: boolean;
  isRequirementExpanded: boolean;
  isScreenedOut: boolean;
  onOpenPopper?: (_isOpen: boolean) => void;
  prodImageLoc: string;
  prodInitId: UUID;
  prodName: string;
  prodState: InitProdState;
  requirementItems?: RequirementItem[];
  scoreCategory: number;
  scoreCategoryDisplay: string;
  scoreMapRequirementItems: ReqItemScoreMap;
  scoringLevel: ScoringLevel;
};

const ProductScoreCarouselColumn = ({
  catId = "",
  catName = "",
  initId = "",
  isCarouselPopperOpen = false,
  isRequirementExpanded = false,
  isScreenedOut = false,
  onOpenPopper = (_isOpen: boolean) => {},
  prodImageLoc = "",
  prodInitId = "",
  prodName = "",
  prodState,
  requirementItems = [],
  scoreCategory = 0,
  scoreCategoryDisplay = "",
  scoreMapRequirementItems,
  scoringLevel = ScoringLevel.REQUIREMENT,
}: ProductScoreCarouselColumnProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoveredCellId, setHoveredCellId] = useState<string>("");
  const [isCatCellHovered, setIsCatCellHovered] = useState<boolean>(false);
  const [isCatPopperOpen, setIsCatPopperOpen] = useState<boolean>(false);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [isScored, setIsScored] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [popperSubTitle, setPopperSubTitle] = useState<string>("");
  const [popperTitle, setPopperTitle] = useState<string>("");
  const [popperType, setPopperType] = useState<string>("");

  const isAwarded = prodState === InitProdState.AWARDED;

  const handleClosePopper = () => {
    onOpenPopper(false);
    setIsCatPopperOpen(false);
    setIsPopperOpen(false);
    setIsScored(false);
    setAnchorEl(null);
    setItemId("");
    setPopperType("");
    setPopperSubTitle("");
    setPopperTitle("");
  };

  const handleHoverEnterItemCell = (cellId: string) => {
    if (!isCarouselPopperOpen) {
      setHoveredCellId(cellId);
    }
  };

  const handleHoverExitItemCell = () => {
    setHoveredCellId("");
  };

  const handleHoverEnterCategoryCell = () => {
    setIsCatCellHovered(!isCarouselPopperOpen);
  };
  const handleHoverExitCategoryCell = () => {
    setIsCatCellHovered(false);
  };

  const handleKeyDownCategoryPopper = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      handleOpenCategoryPopper(event);
    }
  };

  const handleOpenCategoryPopper = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (isCarouselPopperOpen) {
      return;
    }
    let display = "";
    let type = evalPopperTypes.CATEGORY_PROGRESS;
    if (scoringLevel === ScoringLevel.CATEGORY) {
      type = evalPopperTypes.CATEGORY_SCORE;
      display =
        !isNaN(Number(scoreCategoryDisplay)) && scoreCategoryDisplay.indexOf(".") === -1
          ? `${scoreCategoryDisplay}.0`
          : scoreCategoryDisplay;
      setIsScored(scoreCategory > 0);
    }
    setIsCatPopperOpen(true);
    setPopperTitle(catName);
    setPopperSubTitle(display);
    setPopperType(type);
    handleOpenPopper(event);
  };

  const handleKeyDownItemPopper = (id: string, name: string, event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      handleOpenItemPopper(id, name, event);
    }
  };

  const handleOpenItemPopper = (
    id: string,
    name: string,
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    if (isCarouselPopperOpen || scoringLevel !== ScoringLevel.REQUIREMENT) {
      return;
    }
    setPopperType(evalPopperTypes.ITEM_SCORE);
    setPopperTitle(name);
    setItemId(id);
    const { itemScore, itemScoreDisplay } = scoreMapRequirementItems[id] || {};
    setIsScored(itemScore > 0);
    setPopperSubTitle(itemScoreDisplay);
    handleOpenPopper(event);
  };

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (event.currentTarget instanceof HTMLElement) {
      onOpenPopper(true);
      setIsPopperOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const cursorClassName = !isCarouselPopperOpen && "gx-cursor-pointer";
  const wrapperDivClassName = classNames(
    styles.reqCatColumn,
    styles.reqRootCategoryScore,
    "gx-set-element-height",
    isCatPopperOpen && styles.selected,
    isCatCellHovered && !isCatPopperOpen && styles.hovered,
    cursorClassName
  );
  const getLiClassName = (id: string) => {
    return classNames(
      styles.reqItemCol,
      styles.reqItemTd,
      "gx-set-element-height",
      scoringLevel === ScoringLevel.REQUIREMENT && cursorClassName,
      id === itemId && styles.selected,
      id === hoveredCellId && id !== itemId && styles.hovered
    );
  };
  return (
    <td
      className={classNames(isAwarded ? styles.awarded : styles.notAwarded, isScreenedOut && styles.screenedOutBkGnd)}
    >
      <React.Fragment>
        <div
          className={wrapperDivClassName}
          onBlur={handleHoverExitCategoryCell}
          onClick={handleOpenCategoryPopper}
          onFocus={handleHoverEnterCategoryCell}
          onKeyDown={handleKeyDownCategoryPopper}
          onMouseEnter={handleHoverEnterCategoryCell}
          onMouseOut={handleHoverExitCategoryCell}
          role="button"
          tabIndex={0}
        >
          <TypographyComponent boldness={"medium"}>{scoreCategoryDisplay}</TypographyComponent>
        </div>

        {requirementItems?.length > 0 && (
          <Collapse className="gx-req-item-score-container" in={isRequirementExpanded}>
            <ul className="gx-req-item-list-td">
              {requirementItems.map((item) => (
                <li key={item.id} className={getLiClassName(item.id)}>
                  <div
                    aria-disabled={scoringLevel !== ScoringLevel.REQUIREMENT}
                    className="gx-req-item-col-child"
                    onBlur={handleHoverExitItemCell}
                    onClick={(event) => handleOpenItemPopper(item.id, item.name, event)}
                    onFocus={() => handleHoverEnterItemCell(item.id)}
                    onKeyDown={(event) => handleKeyDownItemPopper(item.id, item.name, event)}
                    onMouseEnter={() => handleHoverEnterItemCell(item.id)}
                    onMouseOut={handleHoverExitItemCell}
                    role="button"
                    tabIndex={0}
                  >
                    <TypographyComponent styling={"p1"} boldness={"medium"} color={"coal"}>
                      {scoreMapRequirementItems[item.id]?.itemScoreDisplay}
                    </TypographyComponent>
                  </div>
                </li>
              ))}
            </ul>
          </Collapse>
        )}
        <EvaluationPopper
          anchorEl={anchorEl as unknown as null}
          /* TODO: Update EvaluationPopper to use TS and avoid forced type */
          initId={initId}
          isOpen={isPopperOpen}
          isScored={isScored}
          onClose={handleClosePopper}
          prodImageLoc={prodImageLoc}
          prodInitId={prodInitId}
          prodName={prodName}
          reqCatId={catId}
          reqItemId={itemId}
          subTitle={popperSubTitle}
          title={popperTitle}
          type={popperType}
        />
      </React.Fragment>
    </td>
  );
};

export default ProductScoreCarouselColumn;
