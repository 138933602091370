import { StarV2Icon, StarSmallIcon, HalfStarV2Icon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import classNames from "classnames";
import styles from "./stars.module.scss";

type StarsProps = {
  rating: number;
  isSmall: boolean;
};
const Stars = ({ rating, isSmall = false }: StarsProps) => {
  const iconHalfStarProps = { fillPath: colorPalette.brand.gartnerBlue.hex };
  const iconPropsActive = { fillPath: colorPalette.brand.gartnerBlue.hex, fillSvg: "none", opacity: "1" };
  const iconPropsInactive = { fillPath: colorPalette.neutrals.stone.hex, fillSvg: "none", opacity: "1" };

  const starIconInactive = isSmall ? <StarSmallIcon {...iconPropsInactive} /> : <StarV2Icon {...iconPropsInactive} />;
  const starIconActive = isSmall ? <StarSmallIcon {...iconPropsActive} /> : <StarV2Icon {...iconPropsActive} />;

  return (
    <div className={classNames(styles.starsContainer, isSmall && styles.small)}>
      <div className={classNames(styles.star1, rating < 1 && rating > 0 && styles.half)}>
        {rating === 0 && starIconInactive}
        {rating < 1 && rating > 0 && <HalfStarV2Icon {...iconHalfStarProps} />}
        {rating >= 1 && starIconActive}
      </div>

      <div className={classNames(styles.star2, rating < 2 && rating > 1 && styles.half)}>
        {rating < 2 && rating <= 1 && starIconInactive}
        {rating < 2 && rating > 1 && <HalfStarV2Icon {...iconHalfStarProps} />}
        {rating >= 2 && starIconActive}
      </div>

      <div className={classNames(styles.star3, rating < 3 && rating > 2 && styles.half)}>
        {rating < 3 && rating <= 2 && starIconInactive}
        {rating < 3 && rating > 2 && <HalfStarV2Icon {...iconHalfStarProps} />}
        {rating >= 3 && starIconActive}
      </div>

      <div className={classNames(styles.star4, rating < 4 && rating > 3 && styles.half)}>
        {rating < 4 && rating <= 3 && starIconInactive}
        {rating < 4 && rating > 3 && <HalfStarV2Icon {...iconHalfStarProps} />}
        {rating >= 4 && starIconActive}
      </div>

      <div className={classNames(styles.star5, rating < 5 && rating > 4 && styles.half)}>
        {rating < 5 && rating <= 4 && starIconInactive}
        {rating < 5 && rating > 4 && <HalfStarV2Icon {...iconHalfStarProps} />}
        {rating === 5 && starIconActive}
      </div>
    </div>
  );
};

export default Stars;
