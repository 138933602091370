import { colorPalette } from "gx-npm-common-styles";

const displayInlineBlock = "inline-block";
const transitionHeightSpeed = "0.75s";

const titleStyles = {
  banner: {
    height: "0px",
    maxHeight: "0px",
    transition: `max-height ${transitionHeightSpeed} ease-out`,
    visibility: "hidden",
  },
  bannerV2: {
    height: "0px",
    maxHeight: "0px",
    visibility: "hidden",
    "& button": { visibility: "hidden" },
  },

  bannerShown: {
    "&$banner": {
      height: "unset",
      maxHeight: "100px",
      transition: `max-height ${transitionHeightSpeed} ease-in`,
      visibility: "visible",
    },
  },
  bannerShownV2: {
    "&$bannerV2": {
      height: "unset",
      maxHeight: "100px",
      position: "relative",
      top: "-54px",
      visibility: "visible",
      "& button": {
        visibility: "visible",
      },
    },
  },
  titleRow: {
    display: displayInlineBlock,
  },
};

const titleTopRowStyles = {
  topRowContainer: {
    alignItems: "baseline",
    display: "flex",
    marginBottom: "24px",
    width: "100%",
    "& $categoryWeight.p2": {
      color: colorPalette.neutrals.iron.hex,
      flexGrow: "1",
      "&$invalidCategoryWeight": {
        color: colorPalette.status.poisonCherry.hex,
      },
    },
    "& $titleText": {
      display: displayInlineBlock,
      marginBottom: "0",
    },
    "& p": {
      display: displayInlineBlock,
    },
  },
  topRowContainerV2: {
    alignItems: "center",
    display: "flex",
    marginBottom: "24px",
    width: "100%",
    "& $titleText": {
      display: displayInlineBlock,
      marginBottom: "0",
      marginRight: "24px",
    },
    "& p": {
      display: displayInlineBlock,
    },
    "& button": {
      marginLeft: "24px",
    },
  },
  categoryWeight: {
    display: displayInlineBlock,
    padding: "0 24px",
  },
  headerButton: {
    display: "inline-flex",
    "& .btn-primary": {
      marginLeft: "24px",
      "& svg": {
        marginRight: "12px",
      },
    },
    "& .gx-popover-menu-list": {
      left: -151,
      top: 48,
      "& .gx-popover-menu-item a": {
        paddingLeft: 0,
      },
    },
  },
  titleText: {},
  invalidCategoryWeight: {},
};

const overviewBannerStyles = {
  overviewWrapper: {
    alignItems: "center",
    backgroundColor: colorPalette.neutrals.silver.hex,
    borderRadius: "8px",
    display: "flex",
    marginBottom: "24px",
    padding: "12px 40px 12px 16px",
    position: "relative",
    width: "100%",
    zIndex: 1000,
    "& p": {
      color: colorPalette.neutrals.coal.hex,
    },
  },
  separator: {
    borderLeft: `1px solid ${colorPalette.neutrals.stone.hex}`,
    display: "inline-block",
    height: "30px",
    paddingRight: "24px",
    verticalAlign: "middle",
    width: "0px",
  },
  separatorLeft: {
    paddingRight: "24px",
  },
  countContainer: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "0px",
    order: 2,
  },
  cntDispaly: {
    paddingRight: "4px",
  },
  scoreLevel: {
    display: "flex",
    paddingLeft: "8px",
  },
  scoreLevelDisplay: {
    paddingLeft: "4px",
    textTransform: "capitalize",
  },
};

export { overviewBannerStyles, titleStyles, titleTopRowStyles };
