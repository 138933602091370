const requirementsInvalidDialogStyles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "20px",
    "& button:last-child": {
      marginLeft: "24px",
    },
  },
};

export default requirementsInvalidDialogStyles;
