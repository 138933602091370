import { Dialog } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductsAddContext } from "../products-add.context";
import ProductsAddDialogBodyV2Component from "./components/products-add-dialog-body-v2.component";
import ProductsAddDialogFooterV2Component from "./components/products-add-dialog-footer-v2.component";

const ProductsAddDialogV2Component = () => {
  const { t } = useTranslation();
  const { isAddDialogOpen, setIsAddDialogOpen, setProductSelectedOption } = useContext(ProductsAddContext);
  const handleClose = () => {
    setIsAddDialogOpen(false);
    setProductSelectedOption(null);
  };

  return (
    <Dialog
      body={<ProductsAddDialogBodyV2Component />}
      footer={<ProductsAddDialogFooterV2Component />}
      onClose={handleClose}
      open={isAddDialogOpen}
      title={t("Add vendor")}
    />
  );
};

export default ProductsAddDialogV2Component;
