import { colorPalette } from "gx-npm-common-styles";

const evaluationPopperStyle = {
  root: {
    border: `solid 2px ${colorPalette.neutrals.silver.hex}`,
    borderRadius: "8px",
    zIndex: "1000",
    "& .gx-popper-arrow": {
      boxSizing: "border-box",
      height: "0.71em",
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      "&::before": {
        backgroundColor: colorPalette.basic.white.hex,
        border: `solid 2px ${colorPalette.neutrals.silver.hex}`,
        borderRadius: "2px",
        content: '""',
        display: "block",
        height: "100%",
        margin: "auto",
        transform: "rotate(45deg)",
        width: "100%",
        zIndex: "1001",
      },
    },
    // reference for styles: https://stackblitz.com/edit/poppers-with-arrows?file=index.js
    '&[x-placement*="bottom"] .gx-popper-arrow': {
      left: "0",
      marginLeft: "4px",
      marginRight: "4px",
      marginTop: "-0.71em",
      top: "0",
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
  },
};

export default evaluationPopperStyle;
