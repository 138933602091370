import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { RequirementsState } from "gx-npm-lib";
import { Dialog } from "gx-npm-ui";
import DeleteReqDetailsDialogBody from "./body/delete-req-details-dialog-body.component";
import { loadDeleteReqDialogDetails } from "./delete-req-details-dialog.lib";
import DeleteReqDetailsDialogFooter from "./delete-req-details-dialog-footer.component";

const propTypes = {
  initId: PropTypes.string,
  isOpen: PropTypes.bool,
  isProcessing: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func,
  reqCatId: PropTypes.string,
  reqItemIds: PropTypes.arrayOf(PropTypes.string),
  reqState: PropTypes.oneOf(["", ...Object.values(RequirementsState)]),
};
const DeleteReqDetailsDialog = ({
  initId = "",
  isOpen = false,
  isProcessing = false,
  name = "",
  onClose = (_isConfirmed) => {},
  reqCatId = "",
  reqItemIds = [],
  reqState = RequirementsState.DRAFT,
}) => {
  const { t } = useTranslation();
  const [evaluators, setEvaluators] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setIsConfirmed(![RequirementsState.INEDIT, RequirementsState.PUBLISHED].includes(reqState));
  }, [reqState]);

  useEffect(() => {
    if (reqCatId) {
      setTitle(t("Delete category"));
    } else if (reqItemIds.length > 1) {
      setTitle(t("Delete requirements"));
    } else if (reqItemIds.length === 1) {
      setTitle(t("Delete requirement"));
    } else {
      setTitle("");
    }
  }, [reqCatId, reqItemIds, t]);

  useEffect(() => {
    if (isOpen || !hasLoaded) {
      return;
    }
    setHasLoaded(false);
  }, [hasLoaded, isOpen]);

  useEffect(() => {
    if (isOpen || !evaluators.length) {
      return;
    }
    setEvaluators([]);
  }, [evaluators, isOpen]);

  useEffect(() => {
    if (isOpen || !products.length) {
      return;
    }
    setProducts([]);
  }, [isOpen, products]);

  useEffect(() => {
    if (!isOpen || hasLoaded) {
      return;
    }
    if (!initId || ![RequirementsState.INEDIT, RequirementsState.PUBLISHED].includes(reqState)) {
      return;
    }
    if (!reqCatId && !reqItemIds.length) {
      return;
    }
    setHasLoaded(true);
    setIsLoading(true);
    const config = { initId, reqCatId, reqItemIds };
    loadDeleteReqDialogDetails(config, handleLoadDetailsSuccess).finally(() => setIsLoading(false));
  }, [initId, hasLoaded, isOpen, reqCatId, reqItemIds, reqState]);

  const handleLoadDetailsSuccess = (data) => {
    setEvaluators(data.evaluators);
    setProducts(data.products);
  };

  return (
    <Dialog
      body={
        <DeleteReqDetailsDialogBody
          count={reqItemIds.length}
          dialogType={reqCatId ? "category" : "items"}
          evaluators={evaluators}
          name={name}
          onConfirmationUpdate={setIsConfirmed}
          products={products}
          reqState={reqState}
        />
      }
      closeIcon={true}
      footer={
        <DeleteReqDetailsDialogFooter
          isConfirmed={isConfirmed}
          isLoading={isLoading}
          isProcessing={isProcessing}
          onCancel={() => onClose(false)}
          onConfirm={() => onClose(true)}
        />
      }
      onClose={() => onClose(false)}
      open={isOpen}
      rootClassName="gx-delete-dialog"
      title={title}
    />
  );
};

DeleteReqDetailsDialog.propTypes = propTypes;
export default DeleteReqDetailsDialog;
