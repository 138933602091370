import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { ArrowCircledIcon } from "gx-npm-icons";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { listHeaderExpandButtonStyles as styles } from "./styles";

const propTypes = {
  expand: PropTypes.bool,
  onExpand: PropTypes.func,
  rootClassName: PropTypes.string,
  setExpand: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ListHeaderExpandButton = ({
  expand = false,
  onExpand = (_expand) => {},
  rootClassName = "",
  setExpand = (_expand) => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const title = expand ? t("Collapse section") : t("Expand section");

  const handleClick = (event) => {
    event.preventDefault();
    setExpand(!expand);
    onExpand(!expand);
  };

  return (
    <div className={classNames("gx-exp-col-btn", classes.iconExpand, rootClassName, expand && "gx-is-expanded")}>
      <TooltipV2 placement="bottom" PopperProps={{ modifiers: { offset: { offset: "0px, 12px" } } }} title={title}>
        <div>
          <IconButton ariaLabel={title} onClick={handleClick}>
            <ArrowCircledIcon />
          </IconButton>
        </div>
      </TooltipV2>
    </div>
  );
};

ListHeaderExpandButton.propTypes = propTypes;
export default ListHeaderExpandButton;
