import { LinearProgress } from "@material-ui/core";
import classNames from "classnames";
import { useContext } from "react";
import { ProductsContext } from "../../../../../products.context";
import styles from "./priority-score-cell.styles.module.scss";

type PriorityFitCellProps = { priorityScore: number; name: string; rank: number };
const PriorityScoreCellComponent = ({ priorityScore = 0, name = "", rank = 0 }: PriorityFitCellProps) => {
  const { products } = useContext(ProductsContext);
  return (
    <div className={classNames(styles.root)}>
      <LinearProgress
        aria-label={`${name} has a priority fit of ${rank} out of ${products.length} vendors`}
        variant="determinate"
        value={priorityScore}
      />
    </div>
  );
};

export default PriorityScoreCellComponent;
