import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { GroupAddIcon, GroupPeople } from "gx-npm-icons";
import { handleEvent, ScoringMode } from "gx-npm-lib";
import { IconButton, TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import PropTypes, { string } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  btnHoverBackgroundColor,
  btnRippleBackgroundColor,
  EvaluationScorecardAssignmentsAvatarStyles as styles,
} from "./evaluation-scorecard-assignments-avatar.styles";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  categoriesV2: PropTypes.arrayOf(PropTypes.shape({ evaluators: PropTypes.arrayOf(string), id: PropTypes.string })),
  id: PropTypes.string,
  isOwner: PropTypes.bool,
  mode: PropTypes.string,
  onClick: PropTypes.func,
};

const useStyles = makeStyles(() => styles);
const EvaluationScorecardAssignmentsAvatar = ({
  categoriesV2 = [],
  id = "",
  isOwner = false,
  mode = "",
  onClick = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    const { code } = e;
    if (code === "Enter" || code === "Space") {
      handleEvent(onClick);
    }
  };

  const renderAuto = () => {
    return (
      <TooltipV2
        PopperProps={{ modifiers: { offset: { offset: "0, 8px" } } }}
        title={t("Entire team scores")}
        placement="bottom"
      >
        <div>
          <IconButton
            ariaLabel={
              isOwner ? t("open manage scoring for auto assignment") : t("This action is only available to the owner.")
            }
            backgroundColor={"transparent"}
            borderRadius={"50%"}
            className={classNames(classes.btnAssigned, "gx-req-root-button")}
            disabled={!isOwner}
            hoverBackgroundColor={btnHoverBackgroundColor}
            onClick={onClick}
            rippleBackgroundColor={btnRippleBackgroundColor}
          >
            <GroupPeople />
          </IconButton>
        </div>
      </TooltipV2>
    );
  };

  const renderManual = () => {
    const targetCategory = categoriesV2.find(function (category) {
      return category.id === id;
    });

    if (!targetCategory) {
      return;
    }

    const { evaluators } = targetCategory;
    const evaluatorCount = evaluators.length;
    const maxEvaluatorAdditional = 2;
    const maxEvaluatorDisplay =
      evaluatorCount > maxEvaluatorAdditional ? maxEvaluatorAdditional - 1 : maxEvaluatorAdditional;
    let avatars = "";

    const renderAdditionalAvatar = () => {
      const additionalEvaluatorCount = evaluatorCount - maxEvaluatorDisplay;
      if (additionalEvaluatorCount > 0) {
        const additionalEvaluatorsTooTipText = evaluators.slice(maxEvaluatorDisplay).map((evaluator) => (
          <span key={evaluator}>
            {evaluator}
            <br />
          </span>
        ));

        return (
          <span aria-label="additional">
            <TooltipV2
              title={additionalEvaluatorsTooTipText}
              placement="bottom"
              PopperProps={{ modifiers: { offset: { offset: "0, 15px" } } }}
            >
              <div>
                <TypographyComponent
                  boldness={"medium"}
                  rootClassName={classNames(classes.additionalEvaluatorsBadge, "additional")}
                  color={"coal"}
                  styling={"p4"}
                >
                  {`+${additionalEvaluatorCount}`}
                </TypographyComponent>
              </div>
            </TooltipV2>
          </span>
        );
      }
    };

    if (evaluatorCount > 0) {
      const displayEvaluatorRow = evaluatorCount > maxEvaluatorDisplay - 1;
      const visibleAvatars = evaluators.slice(0, maxEvaluatorDisplay).map((evaluator, index) => {
        return (
          <TooltipV2
            key={index}
            PopperProps={{ modifiers: { offset: { offset: evaluatorCount > 1 ? "0, 15px" : "0, 8px" } } }}
            placement="bottom"
            title={evaluator}
          >
            <div>
              {evaluatorCount > 1 ? (
                <UsernameDisplay
                  ariaLabel="evaluator"
                  name={evaluator}
                  rootClassName={"gx-scoring-evaluators-username-display"}
                  showName={!displayEvaluatorRow}
                />
              ) : (
                <IconButton
                  ariaLabel={
                    isOwner
                      ? t("open manage scoring for manual assignment")
                      : t("This action is only available to the owner.")
                  }
                  backgroundColor={"transparent"}
                  borderRadius={"50%"}
                  className={classNames(classes.btnAssigned, "gx-req-root-button")}
                  disabled={!isOwner}
                  hoverBackgroundColor={btnHoverBackgroundColor}
                  onClick={() => {
                    handleEvent(onClick);
                  }}
                  rippleBackgroundColor={btnRippleBackgroundColor}
                >
                  <UsernameDisplay
                    ariaLabel="evaluator"
                    name={evaluator}
                    rootClassName={classNames("gx-scoring-evaluators-username-display", "single")}
                    showName={!displayEvaluatorRow}
                  />
                </IconButton>
              )}
            </div>
          </TooltipV2>
        );
      });
      avatars = (
        <React.Fragment>
          {visibleAvatars}
          {renderAdditionalAvatar()}
        </React.Fragment>
      );
    } else {
      avatars = (
        <TooltipV2
          PopperProps={{ modifiers: { offset: { offset: "0, 8px" } } }}
          placement="bottom"
          title={t("No team members have been assigned to score.")}
        >
          <div>
            <IconButton
              ariaLabel={
                isOwner
                  ? t("open manage scoring for manual assignment")
                  : t("This action is only available to the owner.")
              }
              backgroundColor={"transparent"}
              borderRadius={"50%"}
              className={classNames(classes.btnAssigned, "gx-req-root-button")}
              disabled={!isOwner}
              hoverBackgroundColor={btnHoverBackgroundColor}
              onClick={() => {
                handleEvent(onClick);
              }}
              rippleBackgroundColor={btnRippleBackgroundColor}
            >
              <GroupAddIcon fillPath={colorPalette.neutrals.iron.hex} width={"20px"} height={"20px"} />
            </IconButton>
          </div>
        </TooltipV2>
      );
    }

    const renderGroupAvatars = () => {
      return (
        <div className={classes.evaluatorAvatarRoot}>
          <div
            aria-label={
              isOwner
                ? t("open manage scoring for manual assignment")
                : t("This action is only available to the owner.")
            }
            className={classNames(
              classes.evaluatorsGroupWrapper,
              classes.additionalEvaluatorsCount,
              "additional",
              "evaluators",
              !isOwner && "disabled"
            )}
            onClick={() => {
              if (isOwner) {
                handleEvent(onClick);
              }
            }}
            onKeyDown={(e) => {
              if (isOwner) {
                handleKeyDown(e);
              }
            }}
            role="button"
            tabIndex="0"
          >
            {avatars}
          </div>
        </div>
      );
    };

    const renderSingleAvatar = () => {
      return (
        <div className={classNames(classes.evaluatorAvatarRoot, "single-avatar")}>
          <div className={classNames(classes.evaluatorSingleWrapper)}>{avatars}</div>
        </div>
      );
    };

    return <React.Fragment>{evaluatorCount > 1 ? renderGroupAvatars() : renderSingleAvatar()}</React.Fragment>;
  };

  return (
    <div
      aria-label={mode === ScoringMode.AUTO_ASSIGNMENT ? t("auto assigned") : t("manual assigned")}
      className={classes.evaluatorAvatarsContainer}
    >
      {mode === ScoringMode.AUTO_ASSIGNMENT && renderAuto()}
      {mode === ScoringMode.MANUAL_ASSIGNMENT && renderManual()}
    </div>
  );
};

EvaluationScorecardAssignmentsAvatar.propTypes = propTypes;
export default EvaluationScorecardAssignmentsAvatar;
