// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KZJqzu7GY7ah1S1hGpLa{display:flex;justify-content:center}.a1RUe54mnGPMvPaa0QuQ{color:var(--color-neutrals-iron);height:48px;padding-bottom:13px;padding-left:64px;padding-top:14px}.VrQAiCbPGtOf3G03ZzlA{height:100%;margin:0 auto;padding-top:65px;text-align:center;width:500px}.VrQAiCbPGtOf3G03ZzlA img{padding-bottom:56px}.oIgS8YqmKjYgHeNB4V2d{color:var(--color-neutrals-carbon);padding-bottom:12px}.KWRJ9fU9cXnPGBkOYbNU{color:var(--color-neutrals-coal);padding-bottom:56px}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-empty-view-v2/products-empty-view-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,gCAAA,CACA,WAAA,CACA,mBAAA,CACA,iBAAA,CACA,gBAAA,CAGF,sBACE,WAAA,CACA,aAAA,CACA,gBAAA,CACA,iBAAA,CACA,WAAA,CAEA,0BACE,mBAAA,CAIJ,sBACE,kCAAA,CACA,mBAAA,CAGF,sBACE,gCAAA,CACA,mBAAA","sourcesContent":[".addProductWrapper {\n  display: flex;\n  justify-content: center;\n}\n\n.emptyContent {\n  color: var(--color-neutrals-iron);\n  height: 48px;\n  padding-bottom: 13px;\n  padding-left: 64px;\n  padding-top: 14px;\n}\n\n.noProducts {\n  height: 100%;\n  margin: 0 auto;\n  padding-top: 65px;\n  text-align: center;\n  width: 500px;\n\n  & img {\n    padding-bottom: 56px;\n  }\n}\n\n.title {\n  color: var(--color-neutrals-carbon);\n  padding-bottom: 12px;\n}\n\n.description {\n  color: var(--color-neutrals-coal);\n  padding-bottom: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addProductWrapper": `KZJqzu7GY7ah1S1hGpLa`,
	"emptyContent": `a1RUe54mnGPMvPaa0QuQ`,
	"noProducts": `VrQAiCbPGtOf3G03ZzlA`,
	"title": `oIgS8YqmKjYgHeNB4V2d`,
	"description": `KWRJ9fU9cXnPGBkOYbNU`
};
export default ___CSS_LOADER_EXPORT___;
