import { clearRequirementsErrors } from "./requirementsActions";
import { clearChecklistErrors } from "./checklistActions";

const clearAllErrors = (state, dispatch) => {
  if (state?.checklist?.failures?.length > 0) {
    clearChecklistErrors(state, dispatch);
  }
  if (state?.requirements?.failures?.length > 0) {
    clearRequirementsErrors(state, dispatch);
  }
};

export { clearAllErrors };
