import { PriorityWeightOption } from "gx-npm-lib";

export enum ProductDataSource {
  PEER_INSIGHTS = "PEER_INSIGHTS",
  UNLISTED = "UNLISTED",
}

export enum WeightedDrawerLocation {
  INITIATIVE_VENDOR_TAB = "INITIATIVE_VENDOR_TAB",
}

export enum ProductWSEvent {
  PRODUCT_CUSTOM_ADDED = "PRODUCT_CUSTOM_ADDED",
  PRODUCT_CUSTOM_DELETED = "PRODUCT_CUSTOM_DELETED",
  RELOAD_PRODUCT_DATA = "RELOAD_PRODUCT_DATA",
  UPDATE_PRODUCT_POSITION = "UPDATE_PRODUCT_POSITION",
  UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE",
}

export enum ProductWSStateUpdate {
  ADD_TO_EVAL = "ADD_TO_EVAL",
  AWARD = "AWARD",
  REMOVE_FROM_EVAL = "REMOVE_FROM_EVAL",
  REVERT_AWARD = "REVERT_AWARD",
  SCREEN_OUT = "SCREEN_OUT",
}

export const SELECTED_PRIORITY_WEIGHTS: Readonly<PriorityWeightOption[]> = Object.freeze([
  PriorityWeightOption.LOW,
  PriorityWeightOption.MEDIUM,
  PriorityWeightOption.HIGH,
]);
