import React, { Fragment, useState } from "react";
import { Fade } from "@material-ui/core";
import classNames from "classnames";
import classes from "./available-evaluator.styles.module.scss";
import { UsernameDisplay } from "../../usernameDisplay";
import { Loader } from "../../loader";
import { ArrowForwardIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { Evaluator } from "../assignment-popper.types";

type AvailableEvaluator = {
  evaluator: Evaluator;
  onAssignEvaluatorClick: (evaluator: Evaluator) => void;
  addBtnText?: string;
};

const AvailableEvaluator = (props: AvailableEvaluator) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = (selectedEvaluator: Evaluator) => {
    setIsLoading(true);
    if (props.onAssignEvaluatorClick) {
      props.onAssignEvaluatorClick(selectedEvaluator);
    }
  };
  return (
    <Fade key={`available-eval-${props.evaluator.email}`} in={true}>
      <button
        aria-label={"Add Evaluator"}
        className={classNames(classes.gxEvaluatorBtn, classes.noPadding)}
        onClick={() => onClick(props.evaluator)}
        disabled={isLoading}
      >
        <UsernameDisplay
          isUnknown={!props.evaluator.hasViewed}
          rootClassName={classes.gxEvaluator}
          name={props.evaluator.hasViewed ? props.evaluator.fullName : props.evaluator.email}
          isSmallerIcon={true}
        />
        <div className={classNames(classes.gxEvaluatorAdd)}>
          <span className={classes.gxPillBg}>
            <span className={classes.gxPillAddBg}>
              <span className={classes.gxBtnContent}>
                {isLoading ? (
                  <span className={classes.gxBtnLoader}>
                    <Loader size={16} variant="indeterminate" />
                  </span>
                ) : (
                  <Fragment>
                    {props.addBtnText} <ArrowForwardIcon fillColor={colorPalette.neutrals.pearl.hex} />
                  </Fragment>
                )}
              </span>
            </span>
          </span>
        </div>
      </button>
    </Fade>
  );
};

export default AvailableEvaluator;
