import { colorPalette } from "gx-npm-common-styles";

const topVendorV2Styles = {
  logoTitleButton: {
    textAlign: "center" as const,
    "& img": {
      verticalAlign: "middle",
    },
    "&.hover": {
      cursor: "pointer",
      "& $vendorName": {
        "&.primary": {
          backgroundColor: `rgba(180, 205, 247, 0.60)`,
          borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
          textDecoration: "none",
          "& p": {
            color: colorPalette.interactions.hoverCta.hex,
          },
        },
      },
    },
    "& $vendorName": {
      "&.primary": {
        borderBottom: `1px solid transparent`,
        "& p": {
          color: colorPalette.neutrals.carbon.hex,
        },
      },
    },
  },
  toolTip: {
    "& .MuiTooltip-arrow": {
      left: `60px !important`,
      "@media (max-width: 1240px)": {
        left: "unset",
      },
    },
  },
  vendorNameContainer: {
    display: "inline-block",
    "@media (max-width: 1240px)": {
      display: "block",
      marginTop: 16,
    },
  },
  vendorName: {
    marginLeft: 11,
    "@media (max-width: 1240px)": {
      marginLeft: 0,
    },
  },
};

export { topVendorV2Styles };
