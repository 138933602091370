import { postAsyncRequest } from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductDataSource } from "../../../../products.constants";
import { ProductsContext } from "../../../../products.context";
import { ProductsAddContext } from "../../products-add.context";
import styles from "./products-add-dialog-footer-v2.styles.module.scss";

const ProductsAddDialogFooterV2Component = () => {
  const { t } = useTranslation();
  const { initId, setProducts, setProductsErrorMessage, setProductsSuccessMessage } = useContext(ProductsContext);
  const {
    isAdding,
    productSearchValue,
    productSelectedOption,
    setIsAddDialogOpen,
    setIsAdding,
    setProductSearchValue,
    setProductSelectedOption,
  } = useContext(ProductsAddContext);

  const handleClickAdd = async () => {
    setIsAdding(true);
    const url = `api/v3/initiatives/${initId}/products`;
    const config = { productName: productSearchValue, source: ProductDataSource.UNLISTED, piProductId: -1 };
    if (productSelectedOption) {
      config.piProductId = productSelectedOption.piProductId;
      config.productName = productSelectedOption.piProductName;
      config.source = ProductDataSource.PEER_INSIGHTS;
    }
    const response = await postAsyncRequest(url, config);
    if (response?.status === 201 && response.data?.data) {
      setProducts((prevProducts) => {
        if (prevProducts.findIndex((product) => product.id === response.data.data.id) !== -1) {
          return prevProducts;
        }
        return [response.data.data, ...prevProducts];
      });
      setProductsSuccessMessage(`${config.productName} ${t("has been added to your vendor list.")}`);
      setProductSearchValue("");
      setProductSelectedOption(null);
      setIsAddDialogOpen(false);
      window.scrollTo(0, 0);
    } else {
      setProductsErrorMessage(`${t("There was a issue while adding the vendor to your vendor list.")}`);
    }
    setIsAdding(false);
  };
  const handleCancel = () => {
    setIsAddDialogOpen(false);
    setProductSelectedOption(null);
  };

  return (
    <div className={styles.footer}>
      <TypographyComponent rootClassName={styles.requiredLabel} styling={"p4"}>{`*${t(
        "Required"
      )}`}</TypographyComponent>
      <div>
        <Button disabled={isAdding} onClick={handleCancel} rootClassName="btn-tertiary">
          {t("cancel")}
        </Button>
        <Button disabled={isAdding || !productSearchValue} onClick={handleClickAdd} rootClassName="btn-primary">
          {t("add to vendor list")}
        </Button>
      </div>
    </div>
  );
};

export default ProductsAddDialogFooterV2Component;
