import React from "react";

type propTypes = {
  children: React.ReactNode;
  tableCarouselScrollLeft?: React.ReactNode;
  rootClass: string;
};

const TableCarouselHeaderRowCol = ({ children, tableCarouselScrollLeft, rootClass }: propTypes) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child) ? React.cloneElement(child) : child;
  });

  return (
    <tr className={rootClass}>
      <th>
        {childrenWithProps}
        {tableCarouselScrollLeft}
      </th>
    </tr>
  );
};

export default TableCarouselHeaderRowCol;
