import { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TextField, TooltipV2 } from "gx-npm-ui";
import { objectivesTextFieldV2Styles as styles } from "./objectives-text-field-v2.styles";
import { OverviewContext } from "../../overview.context";
import { putAsyncRequest, UUID } from "gx-npm-lib";
import { updateEditCount } from "./planning-card-lib";

/**
 * create a textField that is stylized for BuySmart
 * @param {*} props.disabled - passes disable value on to Mui component
 * @param {*} props.initiativeId - passes initiativeId to make api call
 */

type ObjectivesTextFieldV2Props = {
  disabled: boolean;
  initiativeId: UUID;
};

const useStyles = makeStyles(() => styles);
const ObjectivesTextFieldV2 = ({ disabled = false, initiativeId }: ObjectivesTextFieldV2Props) => {
  const { objectives, setObjectives, setIsSnackbarOpen, setOverviewEditCount } = useContext(OverviewContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const newInputProps = {
    classes: {
      input: classes.grayText,
    },
  };
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event?.target?.value || "";
    setObjectives(newValue);
  };
  const handleOnBlur = async () => {
    try {
      updateEditCount(setOverviewEditCount, 1);
      const putResponse = await putAsyncRequest(`api/v3/initiatives/${initiativeId}`, { objectives });
      if (putResponse.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      setIsSnackbarOpen(true);
    } finally {
      updateEditCount(setOverviewEditCount, -1);
    }
  };

  return (
    <TooltipV2
      rootClassName="gx-objectives-field-tooltip"
      deactivate={!disabled}
      placement="top"
      title={t("This action is only available to evaluation owners.")}
    >
      <div aria-label="textbox" className={classes.container}>
        <TextField
          rootClassName="gx-objectives-field"
          InputProps={newInputProps}
          value={objectives}
          size="small"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          placeholder="Document the objectives you have for this new technology purchase"
          fullWidth
          multiline
          multilinev2={true}
          disabled={disabled}
          inputProps={{ className: "gx-objectives-field-input" }}
        />
      </div>
    </TooltipV2>
  );
};

export default ObjectivesTextFieldV2;
