import { AssignmentPopper, SnackbarBanner, AssignmentPopperPosition } from "gx-npm-ui";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { deleteAsyncRequest, getAsyncRequest, postAsyncRequest } from "gx-npm-lib";
import * as singleSpa from "single-spa";
import {
  ChecklistAssignmentComponentType,
  ChecklistTask,
  ChecklistUsersResponseType,
  Evaluator,
} from "./checklist-assignment.types";
import { useTranslation } from "react-i18next";

const ChecklistAssignmentComponent = (props: ChecklistAssignmentComponentType) => {
  const [isAssignmentPopperOpen, setIsAssignmentPopperOpen] = useState(false);
  const [evaluatorsAssignedList, setEvaluatorsAssignedList] = useState<Evaluator[]>([]);
  const [evaluatorsAvailableList, setEvaluatorsAvailableList] = useState<Evaluator[]>([]);
  const [viewOnlyUsersList, setViewOnlyUsersList] = useState<Evaluator[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [activeTask, setActiveTask] = useState<ChecklistTask | null>(null);
  const { t } = useTranslation();
  const assignmentPopperArgs = {
    showAddAllEvaluatorsBtn: true,
    showAddTeamMemberBtn: true,
    addTeamMemberButtonText: t("Add team member"),
    noTeamMembersAssignedText: t(
      "No members assigned. Choose team members from the list on the left to assign them to an item."
    ),
    allTeamMembersAssignedText: t("All available team members have been assigned to this item."),
    allMembersText: t("All owners and contributors"),
    availableTeamMembersText: t("Available team members"),
    viewOnlyTeamMembersText: t("View only team members"),
    assignedText: t("Assigned"),
    addBtnText: t("Add"),
    removeBtnText: t("Remove"),
    pendingBtnText: t("Pending"),
    categoryName: t("Assign team members"),
  };
  const handleRemoveAssignedEvaluatorClick = async (evaluator: { email: string }): Promise<void> => {
    const url = `api/v2/initiatives/${props.initiativeId}/checklist-assignment/item/${activeTask?.id}/remove`;
    const response = await deleteAsyncRequest(url, {
      data: {
        email: evaluator.email,
      },
    });
    if (response?.status === 200) {
      const removedUser = evaluatorsAssignedList.find((user) => user.email === evaluator.email);
      if (removedUser) {
        setEvaluatorsAssignedList((prevState) => prevState.filter((user) => user.email !== removedUser.email));
        setEvaluatorsAvailableList((prevState) => [...[removedUser], ...prevState]);
      }
    } else {
      setIsSnackbarOpen(true);
    }
  };
  const handleAssignEvaluatorClick = async (evaluator: Evaluator): Promise<void> => {
    const url = `api/v2/initiatives/${props.initiativeId}/checklist-assignment/item/${activeTask?.id}/add`;
    const response = await postAsyncRequest(url, { emails: [evaluator.email] });
    if (response?.status === 201) {
      const assignedUser = evaluatorsAvailableList.find((user) => user.email === evaluator.email);
      if (assignedUser) {
        setEvaluatorsAvailableList((prevState) => prevState.filter((user) => user.email !== assignedUser.email));
        setEvaluatorsAssignedList((prevState) => [...[assignedUser], ...prevState]);
      }
    } else {
      setIsSnackbarOpen(true);
    }
  };
  const handleAddAllEvaluatorsClick = async (): Promise<void> => {
    const url = `api/v2/initiatives/${props.initiativeId}/checklist-assignment/item/${activeTask?.id}/add`;
    const response = await postAsyncRequest(url, { emails: evaluatorsAvailableList.map((list) => list.email) });
    if (response?.status === 201) {
      const assignedUsers = evaluatorsAvailableList;
      if (assignedUsers.length > 0) {
        setEvaluatorsAvailableList([]);
        setEvaluatorsAssignedList((prevState) => [...assignedUsers, ...prevState]);
      }
    } else {
      setIsSnackbarOpen(true);
    }
  };
  const handleAddTeamMemberClick = (): void => {
    singleSpa.navigateToUrl(`/s/evaluation/${props.initiativeId}/checklist/team-management`);
  };
  const handleOnAssignmentPopperClose = useCallback(() => {
    setIsAssignmentPopperOpen(false);
    setActiveTask(null);
    props.onClose();
  }, [props]);

  const findPopperPosition = (): AssignmentPopperPosition => {
    const elementPosition = props.refElement?.getBoundingClientRect();
    return elementPosition
      ? window.innerHeight - elementPosition?.bottom > 360
        ? AssignmentPopperPosition.BOTTOM
        : AssignmentPopperPosition.TOP
      : AssignmentPopperPosition.BOTTOM;
  };

  useEffect(() => {
    /**
     * Changes from parent
     */
    if (!props.refElement || !props.activeChecklist || !props.refElement.isConnected) {
      handleOnAssignmentPopperClose();
      return;
    }
    if (props.refElement && props.activeChecklist?.id !== activeTask?.id) {
      setIsAssignmentPopperOpen(true);
      setActiveTask(props.activeChecklist);
      setEvaluatorsAssignedList([]);
      setEvaluatorsAvailableList([]);
      setViewOnlyUsersList([]);
      (async () => {
        try {
          const response: ChecklistUsersResponseType = await getAsyncRequest(
            `api/v2/initiatives/${props.initiativeId}/checklist-assignment/item/${props.activeChecklist.id}/team`
          );
          if (!(response.status === 200 && response?.data?.data)) {
            throw new Error();
          }
          const { assignedUsers, unassignedUsers, viewOnlyUsers } = response.data.data;
          setEvaluatorsAssignedList(assignedUsers);
          setEvaluatorsAvailableList(unassignedUsers);
          setViewOnlyUsersList(viewOnlyUsers);
        } catch (err) {
          setIsSnackbarOpen(true);
        }
      })();
    }
  }, [props, activeTask?.id, handleOnAssignmentPopperClose]);

  return (
    <Fragment>
      <AssignmentPopper
        {...assignmentPopperArgs}
        evaluatorsAvailableList={evaluatorsAvailableList}
        evaluatorsAssignedList={evaluatorsAssignedList}
        viewOnlyUsersList={viewOnlyUsersList}
        onClose={handleOnAssignmentPopperClose}
        onRemoveAssignedEvaluatorClick={handleRemoveAssignedEvaluatorClick}
        onAssignEvaluatorClick={handleAssignEvaluatorClick}
        onAddTeamMemberClick={handleAddTeamMemberClick}
        onAddAllEvaluatorsClick={handleAddAllEvaluatorsClick}
        anchor={props.refElement}
        open={isAssignmentPopperOpen}
        position={findPopperPosition()}
        onClickAway={(event: MouseEvent) => {
          if (!(event.target as EventTarget & { skipRefElement: boolean }).skipRefElement) {
            handleOnAssignmentPopperClose();
          }
        }}
      />
      <SnackbarBanner isOpen={isSnackbarOpen} setIsOpen={() => setIsSnackbarOpen(false)} isDefaultErrorMessage={true} />
    </Fragment>
  );
};

export default ChecklistAssignmentComponent;
