import { Fragment, KeyboardEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UUID } from "gx-npm-lib";
import { ProductLogo, TextLink, TooltipV2 } from "gx-npm-ui";
import { topVendorV2Styles as styles } from "./top-vendor-v2.styles";

type TopVendorV2Props = {
  imageLoc: string;
  id: UUID;
  name: string;
  onClickEvalLink: (pid: UUID, destination?: string) => void;
};

const useStyles = makeStyles(() => styles);
const TopVendorV2 = ({ id, imageLoc = "", name = "", onClickEvalLink }: TopVendorV2Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function debounce<T extends (...args: never[]) => void>(fn: T, delayTime: number) {
      let timer: ReturnType<typeof setTimeout> | null;
      return (...args: Parameters<T>) => {
        clearTimeout(Number(timer));
        timer = setTimeout(() => {
          timer = null;
          fn(...args);
        }, delayTime);
      };
    }

    const handleWindowResize = debounce(() => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 500);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onClickEvalLink(id);
    }
  };

  return (
    <Fragment>
      <TooltipV2
        enterDelay={1500}
        enterNextDelay={1500}
        placement={windowDimensions.width < 1240 ? "top" : "top-start"}
        PopperProps={{ modifiers: { offset: { offset: `${windowDimensions.width < 1240 ? "0" : "-50"}, 12` } } }}
        rootClassName={classNames(classes.toolTip)}
        title={t("Click to view profile")}
      >
        <div
          aria-label={t("Vendor logo and name")}
          className={classNames(classes.logoTitleButton, isHover && "hover")}
          onClick={() => onClickEvalLink(id)}
          onBlur={() => {
            setIsHover(false);
          }}
          onFocus={() => {
            setIsHover(true);
          }}
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseOut={() => {
            setIsHover(false);
          }}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <ProductLogo imageHeight="40px" imageWidth="40px" logo={imageLoc} name={name} />
          <div className={classes.vendorNameContainer}>
            <TextLink text={name} rootClassName={classes.vendorName} />
          </div>
        </div>
      </TooltipV2>
    </Fragment>
  );
};

export default TopVendorV2;
