// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QCqAglLIA7qRq5Z6ncjL .BPGFJnEnncELMj2nLPNP .r4ECfRzX7iLyOykaPJwg{margin-bottom:20px}.QCqAglLIA7qRq5Z6ncjL .nLyxDDnVQrM6kN80mzso{display:flex;float:right}.QCqAglLIA7qRq5Z6ncjL .nLyxDDnVQrM6kN80mzso .TWnwfbsKS61ZjyUgMkra{margin-left:24px}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-status-column/action-dialog/action-dialog.styles.module.scss"],"names":[],"mappings":"AAEI,kEACE,kBAAA,CAGJ,4CACE,YAAA,CACA,WAAA,CACA,kEACE,gBAAA","sourcesContent":[".root {\n  .body {\n    .bodyFirst {\n      margin-bottom: 20px;\n    }\n  }\n  .footer {\n    display: flex;\n    float: right;\n    .confirm {\n      margin-left: 24px;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `QCqAglLIA7qRq5Z6ncjL`,
	"body": `BPGFJnEnncELMj2nLPNP`,
	"bodyFirst": `r4ECfRzX7iLyOykaPJwg`,
	"footer": `nLyxDDnVQrM6kN80mzso`,
	"confirm": `TWnwfbsKS61ZjyUgMkra`
};
export default ___CSS_LOADER_EXPORT___;
