import { makeStyles } from "@material-ui/styles";
import { InitUserRole, isValidResponse, useCaptureEventsV2, useUserInitAccess } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import {
  Loader,
  SnackbarBanner,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanOff,
  useFeatureFlag,
} from "gx-npm-ui";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { ClientEvent } from "../../app.constants";
import { getApiUrl } from "../../context/actions/actionUtils";
import { getRequest } from "../../context/actions/apiRequests";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import SurveyHeader from "./header/survey-header.component";
import NoVendorsView from "./no-vendors-view/no-vendors-view.component";
import PreSurveyStartView from "./pre-survey-start-view/pre-survey-start-view.component";
import SurveyTeamManagementDrawer from "./survey-team-management/survey-team-management.component";
import VendorsSurveyListView from "./vendors-surveylist-view/vendors-surveylist-view.component";
import styles from "./survey-tab.styles";
import { GCOM_4304__manage_recipients_updates, GCOM_4180__questionnaireComparison } from "../../lib/feature-flags";
import { SurveyTableComponent } from "./survey-table/survey-table.component";
import { NoRequirementsErrorComponent } from "./no-requirements-error/no-requirements-error.component";
import NoRecipientsBannerComponent from "./no-recipients-banner/no-recipients-banner.component";

const useStyles = makeStyles(styles);
const SurveyTab = () => {
  const classes = useStyles();
  const { initiativeId = "" } = useParams();
  const { t } = useTranslation();
  const [hasSurvey, setHasSurvey] = useState(false);
  const [surveyId, setSurveyId] = useState("");
  const [initName, setInitName] = useState("");
  const [surveyVendors, setSurveyVendors] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerVendorData, setDrawerVendorData] = useState({});
  const captureEvents = useCaptureEventsV2();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);
  const [hasRequirements, setHasRequirements] = useState(true);
  const [hasRecipients, setHasRecipients] = useState(true);
  const isQuestionnaireComparisonFFOn = useFeatureFlag(GCOM_4180__questionnaireComparison);

  const loadSurveyData = useCallback(async () => {
    const url = isQuestionnaireComparisonFFOn
      ? `api/v2/initiatives/${initiativeId}/survey-overview`
      : getApiUrl(initiativeId, "survey");
    const response = await getRequest(url);

    if (isValidResponse(response)) {
      const payload = response.data?.data;
      setHasSurvey(!!payload.surveyId);
      setSurveyId(payload.surveyId);
      setInitName(payload.initName || "");
      if (isQuestionnaireComparisonFFOn) {
        setHasRecipients((payload.products || []).find((vendor) => vendor.hasRecipients));
        setHasRequirements(payload.hasRequirements);
        setSurveyVendors(payload.products || []);
      } else {
        setSurveyVendors(payload.vendors || []);
      }
    } else {
      setIsError(true);
    }
  }, [initiativeId, isQuestionnaireComparisonFFOn]);

  useEffect(() => {
    setShowHeader(hasSurvey);
  }, [hasSurvey]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setIsLoading(true);
    loadSurveyData().finally(() => setIsLoading(false));
  }, [initiativeId, loadSurveyData]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_SURVEY_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  useEffect(() => {
    if (!window.Intercom || !hasSurvey || surveyVendors.length === 0) {
      return;
    }
    if (JSON.parse(localStorage.getItem("hasViewedQuestionnaireTabAfterInEvaluation")) === true) {
      return;
    }
    window.Intercom("startTour", 483234);
    localStorage.setItem("hasViewedQuestionnaireTabAfterInEvaluation", true);
  }, [hasSurvey, surveyVendors]);

  const handleClick = () => {
    const url = surveyId
      ? `/s/evaluation/${initiativeId}/questionnaire/setup/${surveyId}`
      : `/s/evaluation/${initiativeId}/questionnaire/setup`;
    singleSpa.navigateToUrl(url);
  };

  const handleVendorClick = (data) => {
    if (data.action === "manage") {
      setDrawerVendorData({ id: data.id, imageLoc: data.imageLoc, name: data.name });
      setIsDrawerOpen(true);
    } else if (data.action === "view") {
      updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "questionnaire" });
      singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/product/${data.id}/responses`);
    }
  };

  const handleClose = () => {
    setIsDrawerOpen(false);
    setTimeout(() => setDrawerVendorData({}), 1000);
  };

  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_4180__questionnaireComparison}>
        <FeatureFlagBooleanOn>
          {!hasRequirements && hasSurvey && (
            <NoRequirementsErrorComponent
              handleClick={handleClick}
              disabled={hasLoadedAccess && role !== InitUserRole.OWNER}
            />
          )}
        </FeatureFlagBooleanOn>
      </FeatureFlagBooleanContainer>
      <TabPanelComponent>
        <Fragment>
          {showHeader && (
            <FeatureFlagBooleanContainer flagName={GCOM_4304__manage_recipients_updates}>
              <FeatureFlagBooleanOn>
                <SurveyHeader
                  isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
                  showSlideIn={surveyVendors?.length === 0}
                  onSetupClick={handleClick}
                  surveyId={surveyId}
                  initiativeId={initiativeId}
                  vendors={surveyVendors}
                  reloadSurveyData={loadSurveyData}
                />
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <SurveyHeader
                  isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
                  showSlideIn={surveyVendors?.length === 0}
                  onSetupClick={handleClick}
                  surveyId={surveyId}
                  initiativeId={initiativeId}
                />
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          )}
          {isLoading && (
            <div className={classes.loadingScreen}>
              <Loader size={40} variant="indeterminate" />
            </div>
          )}
          {!isLoading && !hasSurvey && (
            <PreSurveyStartView
              btnText={t("SET UP QUESTIONNAIRE")}
              onButtonClick={handleClick}
              isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
            />
          )}
          <FeatureFlagBooleanContainer flagName={GCOM_4180__questionnaireComparison}>
            <FeatureFlagBooleanOn>
              {!isLoading && hasSurvey && surveyVendors.length > 0 && !hasRecipients && <NoRecipientsBannerComponent />}
              {!isLoading && hasSurvey && (
                <SurveyTableComponent
                  initiativeId={initiativeId}
                  products={surveyVendors}
                  disabled={hasLoadedAccess && role !== InitUserRole.OWNER}
                />
              )}
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              {!isLoading && hasSurvey && surveyVendors.length === 0 && <NoVendorsView />}
              {!isLoading && hasSurvey && surveyVendors.length > 0 && (
                <VendorsSurveyListView
                  isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
                  vendors={surveyVendors}
                  onClickAction={handleVendorClick}
                  initiativeId={initiativeId}
                />
              )}
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          <SurveyTeamManagementDrawer
            surveyId={surveyId}
            initiativeId={initiativeId}
            initName={initName}
            isOpen={isDrawerOpen}
            onClose={handleClose}
            vendorData={drawerVendorData}
            reloadSurveyData={loadSurveyData}
          />
          <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
        </Fragment>
      </TabPanelComponent>
    </Fragment>
  );
};

export default SurveyTab;
