// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KMXajSYcrqviQ4ZZyI1L .rTOAya6c9LwUxGNug32a{cursor:default;padding-bottom:4px}.KMXajSYcrqviQ4ZZyI1L .Ugj5TRJ3BtYUAkQ9c9OD{align-items:center;display:inline-flex}.KMXajSYcrqviQ4ZZyI1L .Ugj5TRJ3BtYUAkQ9c9OD svg{margin-right:8px}.KMXajSYcrqviQ4ZZyI1L .Ocxz7gbpV6AM2_I4SkpY{align-self:flex-start;padding-left:4px}.KMXajSYcrqviQ4ZZyI1L .rTOAya6c9LwUxGNug32a,.KMXajSYcrqviQ4ZZyI1L .z8UuXEH7N3oGMXvFOm5N,.KMXajSYcrqviQ4ZZyI1L .Ocxz7gbpV6AM2_I4SkpY{color:var(--color-neutrals-iron)}`, "",{"version":3,"sources":["webpack://./src/sections/products-tab-v2/products/products-list-v2/product-list-row-v3/cells/peer-insights-cell/peer-insights-cell.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,cAAA,CACA,kBAAA,CAGF,4CACE,kBAAA,CACA,mBAAA,CAEA,gDACE,gBAAA,CAIJ,4CACE,qBAAA,CACA,gBAAA,CAGF,oIACE,gCAAA","sourcesContent":[".root {\n  & .piEmpty {\n    cursor: default;\n    padding-bottom: 4px;\n  }\n\n  & .piRating {\n    align-items: center;\n    display: inline-flex;\n\n    & svg {\n      margin-right: 8px;\n    }\n  }\n\n  & .piDenominator {\n    align-self: flex-start;\n    padding-left: 4px;\n  }\n\n  & .piEmpty, & .piReview, & .piDenominator {\n    color: var(--color-neutrals-iron)\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `KMXajSYcrqviQ4ZZyI1L`,
	"piEmpty": `rTOAya6c9LwUxGNug32a`,
	"piRating": `Ugj5TRJ3BtYUAkQ9c9OD`,
	"piDenominator": `Ocxz7gbpV6AM2_I4SkpY`,
	"piReview": `z8UuXEH7N3oGMXvFOm5N`
};
export default ___CSS_LOADER_EXPORT___;
