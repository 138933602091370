export type Product = {
  hasRecipients: boolean;
  id: string;
  imageLoc: string;
  name: string;
  productState: string;
  surveyStatus: string;
  categories: Record<string, UploadedCategory>;
  isSubmitted: boolean;
  files: Record<string, UploadedFile>;
};

export type SurveyResponse = {
  products: Product[];
  surveyId: string;
  initName: string;
  responseOptions: ResponseOption[];
  categories: Category[];
  hasRequirements: boolean;
  documents: RequestedDocument[];
};

type ResponseOption = {
  id: string;
  name: string;
};

type Requirement = {
  description: string;
  id: string;
  name: string;
  priority: number;
};

type Category = {
  id: string;
  name: string;
  requirements: Requirement[];
};

type RequestedDocument = {
  id: string;
  requestedDocName: string;
};

type UploadedRequirement = {
  comment: string;
  responseOptionId: string;
};

type UploadedCategory = {
  comment: string;
  requirements: Record<string, UploadedRequirement>;
};

type UploadedFile = {
  fileId: string;
  fileName: string;
};

export enum SurveyStatus {
  DEFAULT = "",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
}
