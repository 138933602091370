import { UUID } from "gx-npm-lib";
import { createContext, ReactNode, useState } from "react";
import { InitiativeProduct, ProductPriority, ProductsContextValue } from "./products.types";

const ProductsContext = createContext<ProductsContextValue>({} as ProductsContextValue);

const ProductsContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [gcomCcResId, setGcomCcResId] = useState<number>(-1);
  const [hasPriorities, setHasPriorities] = useState<boolean>(false);
  const [initId, setInitId] = useState<UUID>("");
  const [initName, setInitName] = useState<string>("");
  const [isBaseTemplate, setIsBaseTemplate] = useState<boolean>(false);
  const [isFading, setIsFading] = useState<boolean>(false);
  const [isFTUXView, setIsFTUXView] = useState<boolean>(false);
  const [isMqBasedTemplate, setIsMqBasedTemplate] = useState<boolean>(false);
  const [isPriorityDrawerOpen, setIsPriorityDrawerOpen] = useState<boolean>(false);
  const [isPriorityError, setIsPriorityError] = useState<boolean>(false);
  const [isPrioritySuccess, setIsPrioritySuccess] = useState<boolean>(false);
  const [isWeightedPriorityDrawerOpen, setIsWeightedPriorityDrawerOpen] = useState<boolean>(false);
  const [priorities, setPriorities] = useState<ProductPriority[]>([]);
  const [products, setProducts] = useState<InitiativeProduct[]>([]);
  const [productsErrorMessage, setProductsErrorMessage] = useState("");
  const [productsSuccessMessage, setProductsSuccessMessage] = useState("");
  const [selectedPriorityIds, setSelectedPriorityIds] = useState<string[]>([]);

  const contextValue = {
    gcomCcResId,
    hasPriorities,
    initId,
    initName,
    isBaseTemplate,
    isFading,
    isFTUXView,
    isMqBasedTemplate,
    isPriorityDrawerOpen,
    isPriorityError,
    isPrioritySuccess,
    isWeightedPriorityDrawerOpen,
    priorities,
    products,
    productsErrorMessage,
    productsSuccessMessage,
    selectedPriorityIds,
    setGcomCcResId,
    setHasPriorities,
    setInitId,
    setInitName,
    setIsBaseTemplate,
    setIsFading,
    setIsFTUXView,
    setIsMqBasedTemplate,
    setIsPriorityDrawerOpen,
    setIsPriorityError,
    setIsPrioritySuccess,
    setIsWeightedPriorityDrawerOpen,
    setPriorities,
    setProducts,
    setProductsErrorMessage,
    setProductsSuccessMessage,
    setSelectedPriorityIds,
  };
  return <ProductsContext.Provider value={contextValue}>{props.children}</ProductsContext.Provider>;
};

export { ProductsContext, ProductsContextProvider };
