import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rootClass: PropTypes.string,
};
const TableCarouselBodyCol = ({ children, rootClass }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child) ? React.cloneElement(child) : child;
  });
  return <td className={rootClass}>{childrenWithProps}</td>;
};

TableCarouselBodyCol.propTypes = propTypes;
export default TableCarouselBodyCol;
