import { SnackbarBanner } from "gx-npm-ui";
import { Fragment, useContext } from "react";
import { ProductsContext } from "../../products.context";

const ProductsSnackbarsV2Component = () => {
  const { productsErrorMessage, productsSuccessMessage, setProductsErrorMessage, setProductsSuccessMessage } =
    useContext(ProductsContext);

  return (
    <Fragment>
      <SnackbarBanner
        isOpen={productsSuccessMessage.length > 0}
        message={productsSuccessMessage}
        setIsOpen={() => setProductsSuccessMessage("")}
        type="SUCCESS"
      />
      <SnackbarBanner
        isOpen={productsErrorMessage.length > 0}
        message={productsErrorMessage}
        setIsOpen={() => setProductsErrorMessage("")}
        type="ERROR"
      />
    </Fragment>
  );
};

export default ProductsSnackbarsV2Component;
