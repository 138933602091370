import { useContext } from "react";
import styles from "./gartner-insights.module.scss";
import ProductProfile from "./components/product-profile/product-profile.component";
import TechnologyType from "./components/technology-type/technology-type.component";
import { SelectionContext } from "../../../../selection.context";

const GartnerInsights = () => {
  const { templateName, templateDesc, awardedProductOverview } = useContext(SelectionContext);
  const { mq, productReviews } = awardedProductOverview;

  if (!templateDesc && !templateName && !mq.qdPosition && productReviews.reviewCount === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      {(productReviews.reviewCount === 0 || mq.qdPosition) && (
        <div className={styles.productInfoContainer}>
          <ProductProfile productOverview={awardedProductOverview} />
        </div>
      )}
      {templateName && templateDesc && (
        <TechnologyType templateName={templateName} templateDescription={templateDesc} />
      )}
    </div>
  );
};
export default GartnerInsights;
